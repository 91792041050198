<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-02 14:02:43
 * @FilePath     : \leXue_manage_pc\src\components\contentNavBar.vue


 内容顶部的导航

-->

<template>
  <div>
    <div class="d-flex align-items-center px-20 nav">
      <div>{{ navObj.title }}</div>
      <div class="d-flex flex-wrap action-btns" v-if="actionBtns">
        <div
          class="d-flex align-items-baseline ml-10 px-15 btn"
          v-for="(item, index) in actionBtns"
          :key="'actionBtns' + index"
        >
          <comBtn :color="item.color" @click="item.onTap && item.onTap(item)">{{
            item.title
          }}</comBtn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      navObj: {
        title: null,
        isRenderBackBtn: false,
      },
      currentPageTitle: null,
      actionBtns: null,
    };
  },
  watch: {
    $route(toRouter) {
      this.setRouterInfo(toRouter);

      this.actionBtns = null;
      this.beforePageBack = null;
    },
  },
  mounted() {
    //监听：生成右上角的按钮栏
    this.$bus_unique.on(
      "updateContentNavBtns",
      "contentNavBar",
      (actionBtns) => {
        this.$nextTick(() => {
          this.actionBtns = actionBtns;
        });
      }
    );

    //根据路由设置页面的信息
    this.setRouterInfo(this.$route);

    //监听：更新标题
    this.$bus_unique.on("updateContentNavTitle", "contentNavBar", (options) => {
      this.navObj.title = options.title;
    });
  },
  methods: {
    
    //设置页面路由信息
    setRouterInfo(toRouter) {
      const { meta, path, query } = toRouter || {};

      const { title } = meta;
      const { pageType } = query;

      const currentTitle = typeof title === "object" ? title[pageType] : title;

      this.navObj = {
        title: currentTitle,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  right: 0;
  height: 2.2rem;
  background-color: #ffffff;
  border-bottom: 1px solid #eef3f6;
  font-size: 0.8rem;
  color: #262626;

  .back-btn {
    cursor: pointer;
    font-size: 0.92rem;
    color: #999999;

    .icon-right {
      transform: rotate(180deg);
    }
  }
}
</style>