<!--
 * @Date         : 2021-02-19 14:25:04
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-12 11:03:06
 * @FilePath     : \leXue_manage_pc\src\views\other\dataBoard.vue
-->
<template>
  <div class="d-flex align-items-start">
    <div class="col paymentDiv">
      <div class="d-flex">
        <!-- 模块：收支汇总 -->
        <dataPayment></dataPayment>
        <!-- 模块：数据总览 -->
        <dataAll class="col-2" :overviewData="overViewData"></dataAll>
      </div>
      <div class="d-flex">
        <!-- 模块：订单统计 -->
        <dataOrder class="col orderForm " :orderStatics="orderStatics"></dataOrder>
        <!-- 模块：今日统计 -->
        <dataToday class="col-4 totalAll" :todayDataInfo="todaySaleDetails"></dataToday>
      </div>

      <!-- 模块：收支 -->
      <dataFinal></dataFinal>

      <!-- 模块：各开设班级人数分布总览  -->
      <!-- <dataClassStudent></dataClassStudent> -->

      <!-- 模块：各产品销售金额分布总览   -->
      <!-- <productSaleRange></productSaleRange> -->
    </div>
  </div>
</template>

<script>
import dataBoardBox from "@/views/other/dataBoardBox.vue";
import dataToday from "@/views/other/data_today.vue";
import dataOrder from "@/views/other/data_order.vue";
import dataAll from "@/views/other/data_all.vue";
import dataFinal from "@/views/other/data_final.vue";
import dataClassStudent from "@/views/other/data_classStudent.vue";
import productSaleRange from "@/views/other/data_productSaleRange.vue";
import dataPayment from "@/views/other/data_payment.vue";

export default {
  components: {
    dataBoardBox,
    dataToday,
    dataOrder,
    dataAll,
    dataFinal,
    dataClassStudent,
    productSaleRange,
    dataPayment,
  },
  data() {
    return {
      todaySaleDetails: {},
      orderStatics: {},
      overViewData: {},
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.getTodaySaleData(); //获取今日销售数据
      this.getOrderCount(); //获取订单统计
      this.getOverviewData(); //获取全部预览
    },

    //获取今日销售数据
    async getTodaySaleData() {
      try {
        const { res } = await this.$ajax({
          apiKey: "getDataStatistics_todaySale",
        });

        this.todaySaleDetails = res;
      } catch (error) {
        this.$catchError(error);
      }
    },

    //获取订单统计
    async getOrderCount() {
      try {
        const { res } = await this.$ajax({
          apiKey: "getOrderCount",
        });

        this.orderStatics = res;
      } catch (error) {
        this.$catchError(error);
      }
    },

    //获取全部预览
    async getOverviewData() {
      try {
        const { res } = await this.$ajax({
          apiKey: "getDataStatistics_overview",
        });

        this.overViewData = res;
      } catch (error) {
        this.$catchError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.paymentDiv {
  height: 660px;
}

.orderForm{
  max-width: 58%;
}

.totalAll{
  max-width: 40.33%;
}
</style>
