<!--
 * @Date         : 2020-12-07 09:37:53
 * @LastEditors  : cxx
 * @LastEditTime : 2021-02-26 15:47:26
 * @FilePath     : \leXue_manage_pc\src\views\goods\otherGoodsInfoModules.vue
-->
<template>
  <div>
    <mainContentDiv class="my-10 normal-info-modules">
      <div class="body-title">特色</div>
      <div class="py-10 pr-15 body-table">
        <!-- 富文本编辑器 -->
        <wangEditor
          class="position-relative"
          style="z-index: 1; background-color: #fff"
          ref="editorElem_charact"
        />
      </div>
    </mainContentDiv>
    <mainContentDiv class="d-flex my-10">
      <div class="normal-info-modules">
        <div class="body-title">培养目标</div>
        <div class="py-10 body-table">
          <!-- 富文本编辑器 -->
          <wangEditor
            class="position-relative"
            style="z-index: 1; background-color: #fff"
            ref="editorElem_target"
          />
        </div>
      </div>
      <div class="normal-info-modules">
        <div class="body-title">适用人群</div>
        <div class="py-10 body-table">
          <!-- 富文本编辑器 -->
          <wangEditor
            class="position-relative"
            style="z-index: 1; background-color: #fff"
            ref="editorElem_people"
          />
        </div>
      </div>
    </mainContentDiv>
  </div>
</template>

<script>
import wangEditor from "@/components/wangEditor.vue";
export default {
  components: {
    wangEditor,
  },
  data() {
    return {
    }
  },
  methods: {
    inputValue(newValue){
      const {charact, target, people} = newValue;
      const $refs = this.$refs;

      $refs.editorElem_charact.inputValue(charact); //特色
      $refs.editorElem_target.inputValue(target); //培养目标
      $refs.editorElem_people.inputValue(people); //适用人群
    },
    getOtherInfo() {
      const $refs = this.$refs;
      
      const charact = $refs.editorElem_charact.getEditorData(); //特色
      const target = $refs.editorElem_target.getEditorData(); //培养目标
      const people = $refs.editorElem_people.getEditorData(); //适用人群

      return {
        charact,
        target,
        people,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
// 模块：普通信息
.normal-info-modules {
  .body-title {
    padding: 0.85rem 0.5rem 0.85rem 1.25rem;
    font-size: 0.8rem;
    color: #606060;

    .sub-title {
      font-size: 0.7rem;
      color: #606060;
    }
  }

  .body-label {
    padding-left: 0.55rem;
    padding-right: 0.55rem;
    background-color: #333744;
    border-radius: 0.2rem;
    border: solid 0.05rem #cccccc;
    line-height: 1.5rem;
    font-size: 0.7rem;
    color: #ffffff;
  }

  .body-table {
    padding-left: 1.15rem;
    border-top: 1px solid #eaeaea;

    .icon-del {
      width: initial;
      height: initial;
      line-height: 32px;
    }
  }
}
</style>