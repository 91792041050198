<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-20 11:34:59
 * @FilePath: /src/components/tableList.vue


超级表格

//  可分成以下几个部分来配置

  1) 左上角：搜索条件
      search: {  
        inputList: [] 参数配置参考 inputCenter
      }

  2) 左上角：操作按钮栏，  可存放 新增/筛选/导入导出等按钮
      titleBtns: []
      

  3) 中间：表头（表身数据渲染）
      headers：[{
        label: "名字", //表头名
        props: "name", //字段名
      }],
  
  4) 中间：表身 数据操作按钮
      actionBtns： [], //查看/编辑/启用禁用等
  
  5) 中间：选中表格
      select

  
  6) 底部：隐藏分页器
      hidePaginate


  7) 数据源
    getData(){

    }


 // 使用手册：
    tableOptions(value) {
      return {
        //左上角的按钮栏
        titleBtns: [
          {
            
            title: "新增管理员",
            authenKey: 'admin_add',  //按钮的权限key值, 在permitMap配置
            onTap: () => { //点击按钮回调

            },
          },
          {
            type: 'filter', //filter 查询
            title: "查询",
            authenKey: 'admin_add',  //按钮的权限key值, 在permitMap配置
          },
        ],

        //搜索
        search: {
          inputList: [  //参考 inputCenter 配置，
            {
              type: "input",
              label: "管理员",
              placeholder: "",
              value: "",
              props: "name", //name	否	string	管理员名称
              rules: ["!null"],
            },
          ],
        },
        
        //表身显示的字段配置
        headers: [
          {
            label: "管理员名",
            props: "name",
          },
          {
            label: "手机号",
            props: "mobile",
          },
        ],

        //表身操作的按钮
        actionBtns: [
          {
            title: "编辑",
            color: "deepBlue", //可选 | 按钮颜色
            authenKey: 'admin_edit', 
            onTap: ({ admin_id, status }) => {

            },
          },
          {
            title: "删除",
            color: "red",
            authenKey: 'admin_del', 
            onDelete: async ({ admin_id }) => { //删除按钮 专有的回调

            },
          },
          {
              type: "multiState", //多状态按钮，(可以用于启用 /禁用等)
              props: "status",
              authenKey: "class_isForbidden",
              options: [
                {
                  label: "启用",
                  value: 2,
                  color: "success",
                },
                {
                  label: "禁用",
                  value: 1,
                  color: "warning",
                },
              ],
              onTap: async (btnObj, tableItem) => {
               
              },
            },
        ],

        //选中表格td
        select: {
          type: 'mutiple', //多选
          onChange: (selectedList) => {
            this.selectedList = selectedList;
          }
        },

        //获取数据
        getData: async (options) => {
          let requestRet;
          let { res } = (requestRet = await this.$ajax({ 
            apiKey: "getAdminList",
            data: options,
          }));

          return requestRet;
        },
      };
    },

-->

<template>
  <div
    class="d-flex flex-column h-100"
    ref="tableDom"
    v-if="isGetRenderTableAuthen"
  >
    <div class="mb-10 table-title" v-if="initOptions.title">
      {{ initOptions.title }}
    </div>

    <!-- 搜索/新增 -->
    <div
      class="d-flex flex-wrap align-items-center mb-20 header-bar"
      ref="headerBar"
      v-if="initOptions.titleBtns && initOptions.titleBtns.length > 0"
    >
      <template v-if="initOptions.search">
        <div
          class="d-flex align-items-center mr-20 my-5"
          v-for="(item, index) in initOptions.search.inputList"
          :key="'inputList' + index"
        >
          <div
            class="flex-shrink-0 mr-5 com-title"
            :class="
              item.rules &&
              item.rules.find((item) => item === 'required') &&
              'active'
            "
          >
            {{ item.label }}：
          </div>
          <el-input
            size="small"
            clearable
            v-if="item.type === 'input'"
            v-model="item.value"
            :maxlength="item.maxLength"
            :placeholder="item.placeholder"
          >
          </el-input>
          <!-- 搜索框 -->
          <el-select
            size="small"
            filterable
            clearable
            v-else-if="item.type === 'select'"
            v-model="item.value"
            :multiple="item.multiple"
            :placeholder="item.placeholder"
            :remote="!!item.remoteMethod"
            :remote-method="item.remoteMethod"
            suffix-icon="el-icon-date"
            @change="item.onChange && item.onChange($event, item)"
          >
            <el-option
              v-for="item in item.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <!-- 级联选择 -->
          <el-cascader
            class="w-100"
            size="small"
            v-else-if="item.type === 'cascader'"
            v-model="item.value"
            :props="item.cascaderProps"
            :options="item.options"
            :clearable="true"
            :disabled="item.disabled"
            :key="item.key"
            :expandTrigger="item.expandTrigger || 'click'"
            @expand-change="
              item.onExpandChange && item.onExpandChange($event, item)
            "
            @change="item.onChange($event, item)"
            @clear="item.onClear && item.onClear($event, item)"
          ></el-cascader>

          <!-- 日期时间选择器 -->
          <el-date-picker
            type="datetime"
            size="small"
            v-else-if="item.type === 'dateTimePicker'"
            v-model="item.value"
            :placeholder="item.placeholder"
            @change="item.onChange && item.onChange($event, item)"
          >
          </el-date-picker>
          <el-date-picker
            type="week"
            size="small"
            v-else-if="item.type === 'weekPicker'"
            v-model="item.value"
            :placeholder="item.placeholder"
            format="yyyy 第 WW 周"
            :picker-options="{
              firstDayOfWeek: 1,
            }"
            @change="item.onChange && item.onChange($event, item)"
          >
          </el-date-picker>

          <el-radio-group
            v-else-if="item.type === 'radioBtnList'"
            v-model="item.value"
            size="mini"
            @change="item.onChange($event, item, initOptions.search.inputList)"
          >
            <el-radio-button
              v-for="(item, index) in item.options"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </div>
      </template>
      <!-- 按钮 -->
      <div
        v-for="(item, index) in initOptions.titleBtns"
        :key="'titleBtns' + index"
      >
        <comBtn
          class="mr-10 add-btn"
          size="small"
          v-if="item.isRender"
          :color="item.color || 'blue'"
          @click="handleTapTitleBtn(item)"
          >{{ item.title }}</comBtn
        >
      </div>
    </div>
    <!-- 表身 -->
    <div class="col position-relative overflow-hidden main-table">
      <el-table
        border
        stripe
        width="100%"
        height="100%"
        ref="elTable"
        :data="contentList"
        @selection-change="handleSelectionChange"
      >
        <!-- 选择 -->
        <el-table-column
          type="selection"
          width="55"
          v-if="
            initOptions.select &&
            initOptions.select.type === 'mutiple' &&
            (initOptions.select.authenKey
              ? $store.getters.getAuthenIsPermitted(
                  initOptions.select.authenKey
                )
              : true)
          "
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) in initOptions.headers"
          :key="'headers' + index"
          :prop="item.props"
          :label="item.label"
          :width="item.width || ''"
          :sortable="item.sortable || false"
        >
          <div slot-scope="scope">
            <div v-if="item.type === 'input'">
              <el-input
                size="small"
                v-model="scope.row[item.props]"
                :placeholder="item.placeholder"
                :readonly="item.readonly"
              ></el-input>
            </div>
            <span
              v-else-if="item.type === 'text'"
              style="white-space: pre-line"
              >{{ scope.row[item.props] }}</span
            >
            <div v-else>{{ scope.row[item.props] }}</div>
          </div>
        </el-table-column>
        <el-table-column label="操作" v-if="initOptions.actionBtns">
          <div class="d-flex align-items-center flex-wrap" slot-scope="scope">
            <!-- 操作按钮 -->
            <div
              v-for="(btn, index) in initOptions.actionBtns"
              :key="'actionBtns' + index"
            >
              <div @click.stop v-if="btn.isRender === true">
                <!-- 多状态按钮 -->
                <template v-if="btn.type === 'multiState'">
                  <!-- 类型：两个状态 （应用场景 ：禁用和启用） -->
                  <el-button
                    class="mr-10 my-5"
                    size="mini"
                    v-if="
                      btn.options.length === 2 &&
                      getCurrentBtnState(btn, scope.row).name
                    "
                    :type="getCurrentBtnState(btn, scope.row).color"
                    @click="
                      btn.onTap && btn.onTap(btn, scope.row, scope.$index)
                    "
                    >{{ getCurrentBtnState(btn, scope.row).name }}</el-button
                  >
                  <!-- 类型：带多个下拉选项（应用场景：待审核 通过 未通过） -->
                  <el-dropdown
                    size="mini"
                    split-button
                    v-if="
                      btn.options.length > 2 &&
                      getCurrentBtnState(btn, scope.row).name
                    "
                    :type="getCurrentBtnState(btn, scope.row).color"
                    @command="
                      btn.onSelect &&
                        btn.onSelect($event, btn, scope.row, scope.$index)
                    "
                  >
                    <span class="el-dropdown-link">
                      {{ getCurrentBtnState(btn, scope.row).name }}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(btnOpt, indexBtnOpt) in btn.options"
                        :key="indexBtnOpt"
                        :command="btnOpt.value"
                        :disabled="
                          btn.computedOptIsDisable &&
                          btn.computedOptIsDisable(btnOpt.value, btn, scope.row)
                        "
                        >{{ btnOpt.label }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
                <!-- 类型：普通按钮 -->
                <comBtn
                  class="my-5 mr-10"
                  size="small"
                  v-else
                  :color="btn.color"
                  @click="handleTapActionBtn(btn, scope.row, scope.$index)"
                  >{{ btn.title }}</comBtn
                >
              </div>
            </div>
          </div>
        </el-table-column>

        <!-- 空列表显示 -->
        <div class="empty-list-modules" slot="empty">
          <xx-img
            class="mx-auto img-box"
            :src="require('@/assets/img/com-emptyTips-list.png')"
          ></xx-img>
          <div class="empty-text">暂无数据</div>
        </div>
      </el-table>
    </div>

    <!-- 分页器 -->
    <div class="mt-10" v-if="initOptions.hidePaginate !== true">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="pageObj.currentPage"
        :page-sizes="pageSizeList"
        :page-size="pageObj.perPageSize"
        :total="pageObj.totalSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>

    <!-- 上传文件专用 -->
    <input
      type="file"
      class="d-none"
      ref="inputFile"
      v-if="isRenderInputFileDom"
      @change="handleUploadFile"
    />
  </div>
  <div
    class="h-100 d-flex justify-content-center align-items-center"
    v-else-if="isEmtyContent === true"
  >
    暂无权限，请联系管理员
  </div>
</template>

<script>
export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      contentList: null,
      pageSizeList: [10, 50, 100, 200, 1000, 3000],
      pageObj: {
        currentPage: 1,
        totallPage: 1,
        totalSize: null,
        perPageSize: 10,
      },
      isEmtyContent: null,
      isRenderInputFileDom: true,
    };
  },

  beforeDestroy() {
    const $headerBar = this.$refs.headerBar;

    $headerBar &&
      $headerBar.removeEventListener("keydown", this.keydownEntryCb);
  },

  methods: {
    init() {
      const { currentPage } = this.pageObj;

      this.getTableList(currentPage, this.getKeyword);
    },

    //监听：键盘回车键
    addEvt_keydown() {
      const { titleBtns } = this.initOptions;
      const hasFilterBtn =
        titleBtns && titleBtns.find((item) => item.type === "filter");

      //有搜索按钮，就监听键盘的entry按钮
      if (hasFilterBtn) {
        this.$nextTick(() => {
          this.$refs.headerBar.addEventListener(
            "keydown",
            (this.keydownEntryCb = (event) => {
              const e = event || window.event;

              //回车键的键值为13
              if (e && e.keyCode == 13) {
                this.filterDataList();
              }
            })
          );
        });
      }
    },

    //选择table tr 多选
    handleSelectionChange(val) {
      console.info("handleSelectionChange", val);

      const { onChange } = this.initOptions.select;

      onChange(val);
    },

    //获取列表
    async getTableList(getPage, keywordObj = {}) {
      const _initOptions = this.initOptions;

      try {
        if (!getPage) throw "页数有误";

        let toSubmitOptions = {
          page: getPage, //page	否	int	当前页码
          paginate: this.pageObj.perPageSize,
          ...keywordObj,
        };

        let { res: tableData } = await _initOptions.getData(toSubmitOptions);

        const {
          data: contentList,
          last_page: totallPage,
          current_page: currentPage,
          total: totalSize,
          per_page: perPageSize,
        } = tableData;

        // console.info("tableData", tableData);

        this.contentList = contentList;
        this.pageObj = {
          totallPage,
          currentPage,
          totalSize,
          perPageSize: Number(perPageSize),
        };
      } catch (error) {
        this.$toast({
          color: "error",
          msg: error,
        });
        throw error;
      }
    },

    //点击标题按钮
    handleTapTitleBtn(thisBtn) {
      const { type, onTap } = thisBtn;
      const currentKeyword = this.getKeyword;

      thisBtn.keyword = currentKeyword;

      switch (
        type //filter:搜索
      ) {
        case "filter":
          this.filterDataList();
          break;
        case "import":
          this.$refs.inputFile.click();
          this.importBtnInfo = thisBtn; //临时变量，暂时存储导入按钮的信息，用于选择模板之后上传
          break;
        default:
          onTap && onTap(thisBtn);
          break;
      }
    },

    //筛选
    filterDataList() {
      const currentKeyword = this.getKeyword;
      const lastKeyword = this.lastKeyword;

      console.info("currentKeyword", currentKeyword);

      if (
        Object.keys(currentKeyword).length > 0 &&
        JSON.stringify(currentKeyword) === JSON.stringify(lastKeyword)
      ) {
        console.warn("使用同个筛选条件，return");
        return false;
      }

      this.lastKeyword = currentKeyword;
      this.getTableList(1, currentKeyword);
    },

    // 点击table item 的action btn
    async handleTapActionBtn(actionItem, tableItem, index) {
      const { onTap, onDelete } = actionItem;

      onDelete && this.handleDeleteItem(actionItem, tableItem, index); //删除table
      onTap && onTap(tableItem);
    },

    //删除table  item
    handleDeleteItem(actionItem, tableItem, index) {
      this.$resureDialog({
        ok: async () => {
          try {
            const { onTap, onDelete, delSuccess } = actionItem;
            const { msg } = await onDelete(tableItem, index);

            const contentList = this.contentList;
            const currentPage = this.pageObj.currentPage;
            const totallPage = this.pageObj.totallPage;

            if (contentList.length === 1 && currentPage > 1) {
              this.pageObj.currentPage = currentPage - 1; //后退一页获取数据
            }

            this.init();
            delSuccess && delSuccess(); //删除成功后的回调函数

            this.$toast({
              color: "success",
              msg,
            });

            return false;
          } catch (error) {
            this.$catchError(error);
          }
        },
      });
    },

    //获取可变状态按钮的当前状态
    getCurrentBtnState(thisBtn, thisTableItem) {
      const { options, props } = thisBtn;
      const { label: name, color } =
        options.find((item) => item.value === thisTableItem[props]) || {};

      return {
        name,
        color,
      };
    },

    //切换页数
    async handleCurrentChange(getPage) {
      try {
        this.getTableList(getPage, this.getKeyword);
      } catch (error) {
        console.info("error", error);
        this.pageObj.currentPage = 1;
      }
    },

    //切换table item 条数
    handleSizeChange(perPageSize) {
      try {
        let { currentPage } = this.pageObj;

        this.pageObj.perPageSize = perPageSize;
        this.getTableList(currentPage, this.getKeyword);
      } catch (error) {
        console.info("error", error);
        this.pageObj.perPageSize = 10;
      }
    },

    //清空多选
    clearSelection() {
      this.$refs.elTable.clearSelection();
    },

    //上传文件
    async handleUploadFile(e) {
      try {
        //特殊操作: 解除不能重复同个文件的限制
        this.isRenderInputFileDom = false;

        this.$nextTick(() => {
          this.isRenderInputFileDom = true;
        });

        const currentSelectedFile = e.target.files[0];

        if (!currentSelectedFile) {
          console.log("用户取消上传");
          return false;
        }

        const { onRequest } = this.importBtnInfo;
        const { msg } = await onRequest(currentSelectedFile);

        this.init();
        this.$toast({
          msg,
        });
      } catch (error) {
        this.$catchError(error);
      }
    },
  },
  computed: {
    isGetRenderTableAuthen() {
      if (this.isInited === true) {
        return true;
      }

      const initOptions = this.initOptions;

      const {
        authenKey,
        actionBtns, //普通按钮
        titleBtns,
      } = initOptions;

      const getters = this.$store.getters;
      const getAuthenIsPermitted = getters.getAuthenIsPermitted;

      const isGetRenderTableAuthen = authenKey
        ? getAuthenIsPermitted(authenKey)
        : true;

      // 权限赋值
      if (getters.isLoadedAdminDetails() === true) {
        if (isGetRenderTableAuthen !== true) {
          this.$emit("isEmtyContent", true);
          this.isEmtyContent = true;
        } else {
          let authenBtnList = [...(actionBtns || []), ...(titleBtns || [])];

          authenBtnList.forEach((item) => {
            const { authenKey, isRender = true } = item;
            this.$set(
              item,
              "isRender",
              authenKey ? getAuthenIsPermitted(authenKey) : isRender
            );
          });

          this.init(); //初始化
          this.addEvt_keydown(); //监听：键盘事件
          this.isInited = true;
        }
      }

      return isGetRenderTableAuthen;
    },

    //搜索关键词
    getKeyword() {
      const { search } = this.initOptions;

      if (!search) {
        return null;
      }

      try {
        const keyword = this.$com.getValueByRules(search.inputList);

        return keyword;
      } catch (error) {
        this.$catchError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-title {
  font-size: 0.9rem;
  color: #333333;
}

.mutil-comand-search {
  .label {
    font-size: 1rem;
    color: #666;
  }

  .input-box {
    border: 1px solid #ddd;
    border-radius: 0.25rem;

    input {
      font-size: 1rem;
      color: #333;
      line-height: 2;
    }
  }
}
.header-bar {
  .label {
    font-size: 0.7rem;
    color: #333333;
  }
}

.keyword-list {
  left: 0.25rem;
  top: 0;
  transform: translate3d(0, -100%, 0);

  .keyword-item {
    font-size: 0.9rem;
    line-height: 1.8rem;
    color: #0177d5;
    // border-left: 1px solid #ddd;

    &.active {
      color: #fff;
      background-color: rgb(1, 119, 213);
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
  }
}

::v-deep .el-input--small .el-input__icon {
  line-height: 22px;
}

::v-deep .el-input__inner {
  height: 1.5rem;
}

::v-deep .el-table td,
::v-deep .el-table th {
  padding: 8px 0;
}

//特殊操作，解决 dropdown btn对不齐的问题
::v-deep .el-button-group > .el-button:last-child {
  height: 28px;
}

// 空列表显示
.empty-list-modules {
  .img-box {
    width: 3.83rem;
    height: 2.61rem;
    opacity: 1;
  }
  .empty-text {
    line-height: initial;
    font-size: 0.9rem;
    color: #999999;
  }
}
</style>
