<!--
 * @Date         : 2021-02-19 17:22:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-27 16:13:26
 * @FilePath     : \leXue_manage_pc\src\views\other\data_order.vue
-->
<template>
  <dataBoardBox title="订单统计" v-if="isLoadedAdminDetails">
    <div class="d-flex order-data-modules h-100">
      <div
        class="col-4 d-flex flex-column left-box"
        v-if="$store.getters.getAuthenIsPermitted('order_list')"
      >
        <div class="total-title">订单总量</div>
        <div class="total-data">
          <span class="value">{{ orderStatics.order_count || 0 }}</span>
          <span class="ml-10 label">单</span>
        </div>
        <div class="mt-auto">
          <div class="d-flex grow-bar mb-10">
            <div class="col">环比去年增长</div>
            <div>+{{ orderStatics.rate }}</div>
          </div>
          <div class="position-relative progress-bar">
            <div
              class="position-absolute current-progress"
              :style="{ width: orderStatics.rate }"
            ></div>
          </div>
        </div>
      </div>

      <div class="col px-20 right-box">
        <div class="d-flex align-items-center h-100 status-list">
          <div
            class="col-4 pa-10"
            v-for="(item, index) in orderStatusList"
            :key="index"
            @click="item.onTap && item.onTap(item)"
          >
            <div class="position-relative pb-100">
              <div
                class="position-absolute w-100 h-100 status-item"
                :style="{
                  borderColor: item.boxBorderColor,
                  borderLeftColor: item.boxBorderBgColor,
                }"
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center h-100"
                >
                  <div class="status">{{ item.status }}</div>
                  <div class="value">
                    <span>{{ orderStatics[item.props] || 0 }}</span>
                    <span class="label">单</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dataBoardBox>
</template>

<script>
import dataBoardBox from "@/views/other/dataBoardBox.vue";

export default {
  components: {
    dataBoardBox,
  },
  props: {
    orderStatics: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      orderStatusList: [],
    };
  },
  methods: {
    getOrderStatusList() {
      return [
        {
          status: "待审核",
          boxBorderColor: "#ff854f",
          boxBorderBgColor: "rgb(251, 229, 229)",
          props: "no_check_order_count",
          authenKey: ["order_list", "order_check"],
          onTap: (thisObj) => {
            this.handleOpenToWin({
              thisObj,
              routerUrl: "orderList?orderType=2",
              errorMsg: "暂无待审核订单",
            });
          },
        },
        {
          status: "已通过",
          boxBorderColor: "#1891ff",
          boxBorderBgColor: "rgb(229, 243, 251)",
          props: "yes_check_order_count",
          authenKey: ["order_list"],
          onTap: (thisObj) => {
            this.handleOpenToWin({
              thisObj,
              routerUrl: "orderList?orderType=1",
              errorMsg: "暂无已通过订单",
            });
          },
        },
        {
          status: "已退款",
          boxBorderColor: "#ff0000",
          boxBorderBgColor: "rgb(251, 229, 229)",
          props: "refund_order_count",
          authenKey: ["order_refundRecordList"],
          onTap: (thisObj) => {
            this.handleOpenToWin({
              thisObj,
              routerUrl: "refundOrderList",
              errorMsg: "暂无退款订单",
            });
          },
        },
      ];
    },

    handleOpenToWin({ thisObj, routerUrl, errorMsg }) {
      try {
        const value = this.orderStatics[thisObj.props];

        if (value === 0) throw errorMsg;

        this.$router.push(routerUrl);
      } catch (error) {
        this.$catchError(error);
      }
    },
  },
  computed: {
    isLoadedAdminDetails() {
      const getters = this.$store.getters;
      const isLoadedAdminDetails = getters.isLoadedAdminDetails();

      if (isLoadedAdminDetails === true) {
        const getAuthenIsPermitted = getters.getAuthenIsPermitted;

        //过滤出权限
        this.orderStatusList = this.getOrderStatusList().filter((item) =>
          getAuthenIsPermitted(item.authenKey)
        );
      }

      return isLoadedAdminDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
// 模块：订单统计
.order-data-modules {
  .left-box {
    padding: 0 2rem;
    margin: 1.5rem 0 2rem;
    border-right: 1px solid #f2f2f2;

    .total-title {
      font-size: 0.78rem;
      color: #606060;
    }

    .total-data {
      .value {
        font-size: 1.78rem;
        color: #333333;
      }
      .label {
        font-size: 0.78rem;
        color: #666666;
      }
    }

    .grow-bar {
      font-size: 0.67rem;
      color: #1891ff;
    }

    .progress-bar {
      height: 0.33rem;
      background-color: #e4e4e4;
      border-radius: 0.17rem;

      .current-progress {
        height: 100%;
        background-color: #1891ff;
        border-radius: 0.17rem;
      }
    }
  }

  .right-box {
    .status-list {
      .status-item {
        border: solid 0.44rem transparent;
        border-radius: 50%;

        .status {
          font-size: 0.78rem;
          color: #666666;
        }

        .value {
          font-size: 1.56rem;
          color: #000000;
        }
        .label {
          font-size: 0.78rem;
          color: #666666;
        }
      }
    }
  }
}
</style>