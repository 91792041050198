<template>
  <div class="d-flex flex-column h-100" v-if="classDetails">
    <!-- 模块：班级信息 -->
    <div class="d-flex align-items-center mb-10 class-info-modules">
      <div
        class="info-item"
        v-for="(item, index) in classInfoLsit"
        :key="index"
      >
        <span class="label">{{ item.label }}：</span>
        <span class="mr-40 value" :class="item.cssClass_value"
          >{{ classDetails[item.props] || "--" }}
        </span>
      </div>
    </div>
    <mainContentDiv class="col pa-20">
      <tableList
        ref="tableList"
        v-if="tableOptions"
        :initOptions="tableOptions"
      ></tableList>

      <!-- 弹窗：缺勤学生 -->
      <centerDialog ref="centerDialog_absenceStudent">
        <div style="height: 60vh" v-if="initOptions_absenceStudent">
          <absenceStudentList
            :initOptions="initOptions_absenceStudent"
          ></absenceStudentList>
        </div>
      </centerDialog>
    </mainContentDiv>
  </div>
</template>

<script>
import absenceStudentList from "@/views/class/absenceStudentList.vue";

export default {
  components: {
    absenceStudentList,
  },
  data() {
    return {
      tableOptions: null,
      initOptions_absenceStudent: null,
      classDetails: null,
    };
  },
  beforeMount() {
    this.tableOptions = this.getTableOptions();

    this.getClassDetails();
  },

  methods: {
    async getClassDetails() {
      try {
        const { classID } = this.$route.query;

        const { res } = await this.$ajax({
          apiKey: "getClassInfo",
          data: {
            cid: classID,
          },
        });

        this.classDetails = res;
      } catch (error) {
        this.$catchError(error);
      }
    },
    getTableOptions() {
      return {
        authenKey: "class_onDutyStudentList",
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
          {
            title: "导出",
            color: "cyan",
            onTap: async (options) => {
              console.log("options", options);

              const { classID } = this.$route.query;
              let query = {
                ...options.keyword,
                class_id: classID,
              };

              try {
                if (!query.weekData) {
                  throw "请选择周期";
                }

                let weekTime = this.getWeekTime(query.weekData);
                // console.log("weekTime", weekTime);
                query.start_time = weekTime[0];
                query.end_time = weekTime[weekTime.length - 1];

                this.$refs.tableList.init();
                console.log(";query", query);

                await this.$ajax({
                  isExportFile: true, //导出文件
                  methods: "POST",
                  apiKey: "exportAttend",
                  fileName:
                    query.start_time +
                    "至" +
                    query.end_time +
                    "的出勤学生列表.xls",
                  data: query,
                  submitByForm: true,
                });
              } catch (error) {
                this.$catchError(error);
              }
            },
          },
        ],
        search: {
          inputList: [
            {
              type: "select",
              label: "是否全勤",
              value: 0,
              props: "status",
              rules: ["!null"],
              options: [
                {
                  label: "全部",
                  value: 0,
                },
                {
                  label: "全勤",
                  value: 1,
                },
                {
                  label: "缺勤",
                  value: 2,
                },
              ],
            },
            {
              type: "weekPicker",
              label: "周期",
              value: "",
              props: "weekData",
              rules: ["!null"],
              placeholder: "请选择",
            },
          ],
        },
        headers: [
          {
            label: "上课日期",
            props: "class_time",
          },
          {
            label: "上课时间段",
            props: "__timePeriod",
          },
          {
            label: "出勤人数",
            props: "attend_count",
          },
          {
            label: "缺勤人数",
            props: "absence_count",
          },
          {
            label: "操作人",
            props: "admin_name",
          },
          {
            label: "是否全勤",
            props: "__isAllOnDuty",
          },
        ],
        actionBtns: [
          {
            title: "缺勤名单",
            color: "red",
            authenKey: "class_absenceStudentList",
            onTap: (options) => {
              this.initOptions_absenceStudent = false;

              setTimeout(() => {
                try {
                  console.log("options", options);
                  if (options.status === 1) {
                    throw "当前班级全勤";
                  }

                  this.initOptions_absenceStudent = options;

                  this.$refs.centerDialog_absenceStudent.render({
                    title: "缺勤名单",
                    width: "800px",
                  });
                } catch (error) {
                  this.$catchError(error);
                }
              }, 200);
            },
          },
        ],
        getData: async (options) => {
          const { classID } = this.$route.query;
          // console.log("options", options);

          if (options.weekData) {
            let weekTime = this.getWeekTime(options.weekData);
            // console.log("weekTime", weekTime);
            options.start_time = weekTime[0];
            options.end_time = weekTime[weekTime.length - 1];
          }

          let requestRet = await this.$ajax({
            apiKey: "getOndutyStudentList",
            data: {
              ...options,
              class_id: classID,
            },
          });

          requestRet.res.data.forEach((item) => {
            const { status, begin_time, end_time } = item;

            item.__isAllOnDuty = status === 1 ? "是" : "否";
            item.__timePeriod = begin_time
              ? begin_time + " 至 " + end_time
              : "-";
          });

          return requestRet;
        },
      };
    },
    // js获取当前一周的日期
    getWeekTime(date) {
      var new_Date = new Date(date);
      var timesStamp = new_Date.getTime();
      var currenDay = new_Date.getDay();
      var dates = [];
      for (var i = 0; i < 7; i++) {
        var das = new Date(
          timesStamp + 24 * 60 * 60 * 1000 * (i - ((currenDay + 6) % 7))
        ).toLocaleDateString();
        // console.log("das", das);
        let daySrt = das.replace(/\//gi, "-");
        // console.log('daySrt', daySrt)
        dates.push(daySrt);
      }
      return dates;
    },
  },
  computed: {
    classInfoLsit() {
      return [
        {
          label: "开设班级名称",
          props: "name",
          cssClass_value: "blue-text",
        },
        {
          label: "班主任",
          props: "admin_name",
        },
        {
          label: "开课时间",
          props: "created_at",
        },
        {
          label: "总课时",
          props: "count",
        },
        {
          label: "扣课时数（一节课）",
          props: "clock_count",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
// 模块：班级信息
.class-info-modules {
  .label {
    font-size: 0.89rem;
    color: #333333;
  }

  .value {
    font-size: 0.89rem;
    color: #333333;

    &.blue-text {
      color: #1891ff;
    }
  }
}
</style>
