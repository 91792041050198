<!--
 * @Date         : 2021-02-19 17:21:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-13 17:15:03
 * @FilePath     : \leXue_manage_pc\src\views\other\data_today.vue
-->
<template>
  <dataBoardBox title="今日统计">
    <div class="d-flex flex-wrap px-20 pb-20 today-data-modules">
      <div class="col-6" v-for="(item, index) in todayDataList" :key="index">
        <div class="ma-5 py-10 text-center data-item">
          <div class="value" :style="{ color: item.valueColor }">
            {{ todayDataInfo[item.props] || 0 }}
          </div>
          <div class="label">{{ item.label }}</div>
        </div>
      </div>
    </div>
  </dataBoardBox>
</template>

<script>
import dataBoardBox from "@/views/other/dataBoardBox.vue";

export default {
  components: {
    dataBoardBox,
  },
  props: {
    todayDataInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      todayDataList: [
        {
          label: "订单总量",
          value: 32,
          valueColor: "#0084ff",
          props: "order_count",
        },
        {
          label: "订单总额（元）",
          value: 32,
          valueColor: "#0084ff",
          props: "income_money",
        },
        // {
        //   label: "原存费",
        //   value: 32,
        //   valueColor: "#0084ff",
        //   props: "refund_order_count",
        // },
        // {
        //   label: "会员余额",
        //   value: 32,
        //   valueColor: "#0084ff",
        //   props: "refund_order_count",
        // },
        {
          label: "退款订单总量",
          value: 32,
          valueColor: "#ff0000",
          props: "refund_order_count",
        },
        {
          label: "退款总额（元）",
          value: 32,
          valueColor: "#ff0000",
          props: "refund_money",
        },
      ],
      todaySaleDetails: {},
    };
  },
};
</script>

<style lang="scss" scoped>
// 模块：今日统计
.today-data-modules {
  .data-item {
    background-color: #ffffff;
    box-shadow: -2px 0px 9px 0px rgba(228, 228, 228, 0.5),
      3px 4px 10px 0px rgba(228, 228, 228, 0.5);
    border-radius: 5px;

    .value {
      font-size: 1.78rem;
    }

    .label {
      font-size: 0.78rem;
      color: #909090;
    }
  }
}
</style>