<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-02 14:11:04
 * @FilePath     : \leXue_manage_pc\src\components\headerBar.vue

 顶部的页面导航条


-->


<template>
  <div>
    <div class="d-flex align-items-center header-bar">
      <div class="col">
        <div class="d-flex align-items-center px-10">
          <el-tag
            class="mr-10"
            size="small"
            effect="plain"
            type="info"
            @click="handleBackToHome()"
            >主界面</el-tag
          >
          <el-tag
            class="mr-10"
            size="small"
            type="dark"
            v-for="(item, index) in openedRouterTagList"
            :key="index"
            :effect="currentRouterTitle === item.title ? 'dark' : 'plain'"
            closable
            @click="hanleSwitchToExistedTag(item)"
            @close="handleRemoveThisRouterTag(index)"
            >{{ item.title }}</el-tag
          >
        </div>
      </div>
      <div class="d-flex align-items-center px-40 right-box">
        <div class="header-img" v-if="userInfo.avatar">
          <xx-img :src="userInfo.avatar"></xx-img>
        </div>
        <div
          class="position-relative z100 d-flex align-items-center py-20 ml-15 username"
          v-if="userInfo && userInfo.name"
        >
          <div>{{ userInfo.name }}</div>
          <div class="position-absolute cursor-pointer login-out-btn d-none">
            <div class="px-20 py-10 text-over-one" @click="handleOutOfLogin()">
              退出
            </div>
            <div
              class="px-20 py-10 text-over-one"
              @click="handleToEditPassword()"
            >
              修改密码
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
    },
  },
  data() {
    return {
      openedRouterTagList: [],
      currentRouterTitle: null,
    };
  },
  watch: {
    $route(to, from, next) {
      this.syncChangePageStack(to); //同步处理页面栈
    },
  },
  mounted() {
    this.$bus_unique.on("backToHome", "headerBar", () => {
      this.handleBackToHome();
    });
  },
  methods: {
    //同步处理页面栈
    syncChangePageStack(to) {
      const { name, query, meta } = to;
      const openedRouterTagList = this.openedRouterTagList;

      const thisRouterIndex = openedRouterTagList.findIndex(
        (item) => item.name === name
      );

      if (name === "home") {
        return false;
      }

      let currentRouterTitle;

      //还没缓存页面栈
      if (thisRouterIndex === -1) {
        const { title } = meta;
        const { pageType } = query;

        const currentTitle =
          typeof title === "object" ? title[pageType] : title;
        const tagQty = openedRouterTagList.length;

        //超过 tagQty 个页面栈，就把第一个tag移除掉。并且释放它的页面栈
        if (tagQty > 6) {
          const firstTag = openedRouterTagList[0];
          const pageStackList = this.$pageStack.getStack();

          const firstPageStackIndex = pageStackList.findIndex(
            (item) => item["stack-key"] === firstTag.pageStackKey
          );

          if (firstPageStackIndex !== -1) {
            pageStackList.splice(firstPageStackIndex, 1); //释放页面栈
          }

          openedRouterTagList.splice(0, 1); //移除第一个tags
        }

        openedRouterTagList.push({
          title: currentTitle,
          name,
          query,
          pageStackKey: query["stack-key"],
        });

        currentRouterTitle = currentTitle;
      } else {
        //更新当前页面栈key
        const curretRouter = openedRouterTagList[thisRouterIndex];

        currentRouterTitle = curretRouter.title;
        curretRouter.pageStackKey = query["stack-key"];
      }

      this.currentRouterTitle = currentRouterTitle;
    },
    //移除路由标签
    handleRemoveThisRouterTag(thisIndex) {
      const openedRouterTagList = this.openedRouterTagList;
      const currentRouter = openedRouterTagList[thisIndex];

      const pageStackList = this.$pageStack.getStack();
      const currentPageStackIndex = pageStackList.findIndex(
        (item) => item.key === currentRouter.pageStackKey
      );

      let toRouter;

      //关闭当前页面，默认回退到 thisIndex - 1 页面，否则回退到 thisIndex + 1, 否则回退到home
      if (thisIndex > 0) {
        toRouter = openedRouterTagList[thisIndex - 1]; //退回前一个页面
      } else if (thisIndex === 0) {
        if (openedRouterTagList.length > 1) {
          toRouter = openedRouterTagList[thisIndex + 1]; //退回后一个页面
        } else {
          toRouter = {
            name: "home",
          };
        }
      }

      if (pageStackList.length > 1) {
        pageStackList.splice(currentPageStackIndex, 1); //销毁当前页面栈
      }

      this.$router.replace(toRouter); //页面回退
      openedRouterTagList.splice(thisIndex, 1); //移除该路由卡片
    },

    //退出登录
    async handleOutOfLogin() {
      try {
        const { msg } = await this.$ajax({
          apiKey: "loginOut",
        });

        this.removeAllRouterTagList(); //移除所有路由标签
        this.$store.commit("toLogin", this); //去登录

        this.$toast({
          color: "success",
          msg,
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    //去修改密码
    handleToEditPassword() {
      this.$router.replace({
        name: "editMyUserInfo",
        query: {
          pageType: 1,
        },
      });
    },

    //退回到home页面
    handleBackToHome() {
      if (this.$route.name === "home") {
        return false;
      }

      this.removeAllRouterTagList(); //移除所有路由标签

      this.$router.replace({
        name: "home",
      });
    },

    //切换到已存在的页面
    hanleSwitchToExistedTag(thisTag) {
      this.$router.push(thisTag);
    },

    //移除所有路由标签
    removeAllRouterTagList() {
      const openedRouterTagList = this.openedRouterTagList;
      const openedRouterTagListLen = openedRouterTagList.length;

      openedRouterTagList.splice(0, openedRouterTagListLen);
      this.$pageStack.getStack().length = 1; //销毁所有页面栈
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.getUserInfoByStore || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.header-bar {
  left: 0;
  top: 0;
  right: 0;
  height: 2.2rem;

  .title {
    font-size: 1.375rem;
    color: #fff;
  }

  .tip-text {
    font-size: 1rem;
    color: #ffffff;
  }

  .right-box {
    .header-img {
      width: 2.063rem;
      height: 2.063rem;
      border-radius: 50%;
      background-color: #dadada;
      overflow: hidden;
    }

    .username {
      font-size: 0.9rem;
      color: #333333;

      &::after {
        margin-left: 0.5rem;
        width: 0;
        height: 0;
        content: "";
        display: block;
        border: 0.3rem solid transparent;
        border-top-color: #999;
        transform: translate3d(0, 50%, 0);
      }

      .login-out-btn {
        right: 0;
        bottom: 0;
        transform: translate3d(0, 100%, 0);
        min-width: 5rem;
        box-shadow: 0px 4px 10px 0px rgba(218, 218, 218, 0.5);
        background-color: #fff;
        border-radius: 0.5rem;
        text-align: center;
        color: #333;
      }

      &:hover {
        .login-out-btn {
          display: block !important;
        }
      }
    }
  }
}
</style>