<!--
 * @Description: 教学大纲-查看-考核评分
-->
<template>
  <div class="pageBox">
    <div class="col overflow-scroll-y h-100" style="padding: 20px;">
      <!-- 基本信息 -->
      <infoWrapper class="my-10" title="基本信息" style="height: auto;">
        <div class="basicInfo">
          <div class="itemLine">
            <div class="itemLine_label">考核表名称</div>
            <div class="itemLine_value">{{ info.assess_name }}</div>
          </div>
          <div class="itemLine">
            <div class="itemLine_label">教师名称</div>
            <div class="itemLine_value">{{ info.teacher_name }}</div>
          </div>
          <div class="itemLine">
            <div class="itemLine_label">部门</div>
            <div class="itemLine_value">{{ info.department }}</div>
          </div>
          <div class="itemLine">
            <div class="itemLine_label">教龄</div>
            <div class="itemLine_value">{{ info.teaching_age || '-' }}</div>
          </div>
        </div>
      </infoWrapper>
      <!-- 考核评分 -->
      <div style="margin-bottom: 70px;">
        <infoWrapper class="my-10" title="考核评分" style="height: auto;">
          <div class="pa-20">
            <div
              class="item_tableBox"
              v-for="(item, index) in info.assess"
              :key="index"
            >
              <table border="0" width="100%" cellspacing="0" cellpadding="0">
                <tr>
                  <th width="260">考核形式</th>
                  <th>考核标准</th>
                  <th width="110">满分</th>
                  <th width="160">得分</th>
                </tr>
                <template v-for="(itemJ, indexJ) in item.norm">
                  <tr align="center" :key="indexJ">
                    <td :rowspan="indexJ" v-if="indexJ === 0">
                      {{ item.name }}
                    </td>
                    <td style="padding: 0 10px;">{{ itemJ.name }}</td>
                    <td>{{ itemJ.total_points }}</td>
                    <td>
                      <el-input
                        v-model.number="itemJ.score"
                        type="number"
                        min="0"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
        </infoWrapper>
      </div>
    </div>
    <!-- 底部 -->
    <div class="bottomTheBox">
      <el-button size="medium" @click="onCancel">取消</el-button>
      <el-button size="medium" type="primary" @click="onSave">提交</el-button>
    </div>
  </div>
</template>

<script>
import infoWrapper from '@/components/infoWrapper.vue'

export default {
  components: {
    infoWrapper,
  },
  data() {
    return {
      info: {},
    }
  },
  created() {
    let { id } = this.$route.query
    this.getInfo(id)
  },
  methods: {
    // 获取详情
    async getInfo(id) {
      try {
        const { res } = await this.$ajax({
          apiKey: 'assess_info',
          data: { id },
        })
        console.log('获取数据', res)

        // 判断是否是第一次编辑
        for (let i of res.assess) {
          for (let j of i.norm) {
            if (j.is_score === 0 && j.score === 0) {
              j.score = ''
            }
          }
        }

        this.info = res
      } catch (error) {
        this.$catchError(error)
      }
    },

    // 取消
    onCancel() {
      this.$router.go(-1)
    },

    // 提交
    async onSave() {
      console.log('assess', this.info.assess)

      // // 校验
      // this.info.assess.forEach((item, index) => {
      //   item.norm.forEach((itemJ) => {
      //     if (!itemJ.score) {
      //       throw this.$message.error(`请输入 ${itemJ.name} 的得分`)
      //     }
      //   })
      // })

      try {
        let form = {
          ids: [],
          scores: [],
        }
        for (let i of this.info.assess) {
          for (let j of i.norm) {
            form.ids.push(j.id)
            form.scores.push(j.score || 0)
          }
        }
        console.log('form', form)

        const { msg } = await this.$ajax({
          apiKey: 'assess_score',
          submitByForm: true,
          data: form,
        })
        this.$toast({
          msg,
        })
        this.$bus_unique.emit('showSyllabusUpdateInfo')
        this.$router.go(-1)
      } catch (error) {
        this.$catchError(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pageBox {
  margin: -1.25rem;
  position: relative;
  height: calc(100% + 2.5rem);

  .basicInfo {
    display: flex;
    flex-wrap: wrap;
    padding: 22px 0 0;
  }

  .itemLine {
    width: 25%;
    padding-left: 30px;
    font-size: 14px;
    margin-bottom: 22px;

    .itemLine_label {
      color: #999;
    }
    .itemLine_value {
      margin-top: 2px;
      color: #333333;
    }
  }

  .item_tableBox {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    table {
      border: 1px solid #eaeaea;

      th,
      td {
        text-align: center;
        border-right: 1px solid #eaeaea;
        height: 50px;

        &:last-child {
          border-right: 0;
        }
      }
      td {
        border-top: 1px solid #eaeaea;
        color: #606060;
        font-size: 14px;
      }
      th {
        background: #f7f7f7;
      }

      .standardTd {
        display: flex;
        align-items: center;

        i {
          font-size: 18px;
          font-weight: bold;
          color: #1891ff;
          margin-right: 14px;
          cursor: pointer;
        }
        .el-icon-delete {
          color: #f9463f;
          margin-left: 14px;
        }
      }
    }
  }
}

::v-deep td {
  .el-input input {
    border: 0 !important;
    text-align: center;
  }
}

.bottomTheBox {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 30px;
  width: calc(100% - 208px);
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(218, 218, 218, 0.5);

  .el-button {
    margin: 0 14px;
  }
}
</style>
