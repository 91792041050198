<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-03 09:56:06
 * @FilePath: /src/views/member/memberList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>

    <!-- 弹窗：修改会员等级 -->
    <centerDialog ref="centerDialog_changeMemberLevel">
      <inputCenter
        v-if="initOptions_changeMemberLevel"
        :initOptions="initOptions_changeMemberLevel"
      >
      </inputCenter>
    </centerDialog>

    <!-- 弹窗：充值记录 -->
    <centerDialog ref="centerDialog_refundOrder">
      <div style="height: 60vh" v-if="initOptions_rechargeRecord">
        <memberRechargeRecordList
          :initOptions="initOptions_rechargeRecord"
        ></memberRechargeRecordList>
      </div>
    </centerDialog>

    <!-- 弹窗：消费记录 -->
    <centerDialog ref="centerDialog_consumeRecordList">
      <div style="height: 60vh" v-if="initOptions_consumeRecordList">
        <memberConsumeRecordList
          :initOptions="initOptions_consumeRecordList"
        ></memberConsumeRecordList>
      </div>
    </centerDialog>

    <!-- 弹窗：记录消费 -->
    <centerDialog ref="centerDialog_recordConsuption">
      <inputCenter
        v-if="initOptions_recordConsumption"
        :initOptions="initOptions_recordConsumption"
      >
      </inputCenter>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
import memberRechargeRecordList from "@/views/member/memberRechargeRecordList.vue";
import memberConsumeRecordList from "@/views/member/memberConsumeRecordList.vue";
import validValue from "@/assets/js/validValue.js";

export default {
  components: {
    memberRechargeRecordList,
    memberConsumeRecordList,
  },
  data() {
    return {
      tableOptions: null,
      initOptions_changeMemberLevel: null,
      initOptions_rechargeRecord: null,
      initOptions_consumeRecordList: null,
      initOptions_recordConsumption: null,
      selectedTrList: [],
    };
  },
  created() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    // 弹出新增/编辑会员等级
    renderChangeMemberLevelDialog({ type, inputedVal }) {
      try {
        let inputOptions = (this.initOptions_changeMemberLevel = {
          inputList: this.getInputRechargeLevel(),
        });

        if (inputedVal) {
          this.$com.fillData({
            inputList: this.initOptions_changeMemberLevel.inputList,
            valueListObj: inputedVal,
          });
        }

        this.$refs.centerDialog_changeMemberLevel.render({
          title: "编辑会员等级",
          width: "600px",
          verifyInputListIsChange: inputOptions.inputList,
          ok: async () => {
            try {
              const { inputList } = inputOptions;

              let requestOpt = this.$com.getValueByRules(inputList);

              // 1:新增 2:编辑
              if (type === 1) {
                const { id } = inputedVal;
                requestOpt.pid = id;
              }

              const { msg } = await this.$ajax({
                apiKey: "changeMemberLevel",
                data: requestOpt,
              });

              this.$toast({
                msg,
              });

              this.$refs.tableList.init();
            } catch (error) {
              throw error;
            }
          },
          cancel: () => {},
        });
      } catch (error) {
        this.$catchError(error);
      }
    },
    getInputRechargeLevel() {
      const _tempValObj = {};

      let getInputRechargeLevel = [
        (_tempValObj.memberLevel = {
          type: "select",
          label: "会员等级",
          placeholder: "请搜索/选择会员等级",
          rules: ["required", "!null"],
          value: "",
          props: "level_id",
          col: "col-6",
          options: [],
          remoteMethod: async (keyword) => {
            try {
              const {
                res: { data },
              } = await this.$ajax({
                apiKey: "getMemberLevelList",
                data: {
                  name: keyword || "",
                  status: 2, //1充值规则列表 2其他列表【必填】
                },
              });

              _tempValObj.memberLevel.options = data.map((item) => {
                const { id, name } = item;

                return {
                  label: name,
                  value: id,
                };
              });
            } catch (error) {
              this.$catchError(error);
            }
          },
          onChange: (val, thisObj) => {
            thisObj.id = val;
          },
        }),
      ];

      _tempValObj.memberLevel.remoteMethod();

      return getInputRechargeLevel;
    },

    //获取记录消费
    getInputList_recordConsuption(parentInfo) {
      const _tempValObj = {};

      const configList = [
        (_tempValObj.order = {
          type: "select",
          label: "选择订单",
          placeholder: "请选择输入订单编号",
          rules: ["!null"],
          value: "",
          props: "oid",
          options: [],
          remoteMethod: async (keyword = "") => {
            try {
              const {
                res: {
                  list: { data },
                },
              } = await this.$ajax({
                apiKey: "getOrderList",
                data: {
                  order_id: keyword, //mobile	否	string	家长手机号
                  pid: parentInfo.id,
                },
              });

              _tempValObj.order.options = data.map((item) => {
                const { oid, order_id, created_at, product } = item;

                return {
                  label: `订单号：${order_id}; 消费时间：${created_at}`,
                  value: oid, //订单id
                  orderPrice: product.reduce((a, b) => ({
                    count: parseFloat(
                      (Number(a.count) + Number(b.count)).toPrecision(12)
                    ),
                  })).count,
                };
              });
            } catch (error) {
              this.$catchError(error);
            }
          },
          onChange: (val, thisObj) => {
            if (!val) return false;

            const currentOpt = thisObj.options.find(
              (item) => item.value === val
            );

            _tempValObj.money.value = currentOpt.orderPrice;
          },
          onClear: () => {
            _tempValObj.money.value = null;
          },
        }),
        // (_tempValObj.formType = {
        //   type: "radio",
        //   label: "类别",
        //   placeholder: "请选择类别",
        //   rules: ["!null"],
        //   value: 1,
        //   props: "formType",
        //   options: [
        //     {
        //       label: "钱包",
        //       value: 1,
        //     },
        //     {
        //       label: "课包",
        //       value: 2,
        //     },
        //   ],
        //   onChange: (val, thisObj) => {
        //     console.log(val);

        //     _tempValObj.money.placeholder =
        //       val === 1 ? "请输入消费金额" : "请输入消费课包数量";
        //     _tempValObj.money.props = val === 1 ? "money" : "pack_num";
        //   },
        // }),
        (_tempValObj.money = {
          type: "input",
          label: "消费课时",
          placeholder: `请输入`,
          maxLength: 10,
          rules: ["required", "!null"],
          value: null,
          props: "hour_num",
          onChange: (thisObj) => {
            validValue.inputMoney(thisObj);
          },
        }),
        {
          type: "input",
          label: "备注",
          placeholder: "请输入备注",
          maxLength: 50,
          rules: ["!null"],
          value: null,
          props: "remark",
        },
      ];

      _tempValObj.order.remoteMethod();

      return configList;
    },

    getTableOptions() {
      const _tempValObj = {};

      const tableOptions = {
        authenKey: "member_list",
        search: {
          inputList: [
            (_tempValObj.memberLevel = {
              type: "select",
              label: "会员等级",
              placeholder: "请搜索/选择会员等级",
              rules: ["!null"],
              value: "",
              id: "",
              props: "level_name",
              props_id: "level_id",
              col: "col-6",
              options: [],
              remoteMethod: async (keyword) => {
                try {
                  const {
                    res: { data },
                  } = await this.$ajax({
                    apiKey: "getMemberLevelList",
                    data: {
                      name: keyword || "",
                      status: 2, //1充值规则列表 2其他列表【必填】
                    },
                  });

                  _tempValObj.memberLevel.options = data.map((item) => {
                    const { id, name } = item;

                    return {
                      label: name,
                      value: id,
                    };
                  });
                } catch (error) {
                  this.$catchError(error);
                }
              },
              onChange: (val, thisObj) => {
                thisObj.id = val;
              },
            }),
            {
              type: "input",
              label: "家长名称",
              placeholder: "",
              value: "",
              props: "name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "手机号码",
              placeholder: "",
              value: "",
              props: "mobile",
              rules: ["!null", "mobile"],
            },
            {
              type: "input",
              label: "会员号",
              placeholder: "",
              value: "",
              props: "member_number",
              rules: ["!null", "member_number"],
            },
          ],
        },
        headers: [
          { label: "会员号", props: "member_number" },
          { label: "会员等级", props: "level_name" },
          { label: "家长姓名", props: "name" },
          { label: "性别", props: "gender" },
          { label: "联系电话", props: "mobile" },
          { label: "学生名称集", props: "student_info" },
          { label: "跟进客服", props: "teacher_name" },
          { label: "购买订单数", props: "order_number" },
          { label: "消费总金额", props: "consume_money" },
          { label: "剩余余额", props: "balance" },
          // { label: "课包购买课程数", props: "cp_order_number" },
          // { label: "消费总课包数", props: "consume_pack_num" },
          { label: "余额课包", props: "course_pack_num" },
          { label: "余额课时", props: "class_hour_num" },
          { label: "备注", props: "remark" },
        ],
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
          {
            title: "导出",
            color: "normal",
            authenKey: "member_export",
            onTap: async (thisBtn) => {
              console.log("thisBtn", thisBtn);
              try {
                await this.$ajax({
                  isExportFile: true, //导出文件
                  methods: "POST",
                  apiKey: "exportMemberLiist",
                  fileName: "会员资料列表.xls",
                  // submitByForm: true,
                });
              } catch (error) {
                this.$catchError(error);
              }
            },
          },
          // {
          //   title: "钱包余额提醒",
          //   color: "blue",
          //   // 缺权限
          //   // authenKey: "",
          //   onTap: async (options) => {
          //     try {
          //       const selectedTrList = this.selectedTrList || [];
          //       const toExamineIDList = [];
          //       for (let i of selectedTrList) {
          //         toExamineIDList.push(i.id);
          //       }
          //       if (toExamineIDList.length <= 0) {
          //         throw "请至少选择一个会员";
          //       }
          //       console.log("toExamineIDList", toExamineIDList);

          //       // 模态框
          //       const confirmResult = await this.$confirm(
          //         "是否批量余额提醒？",
          //         "提示",
          //         {
          //           confirmButtonText: "确定",
          //           cancelButtonText: "取消",
          //           type: "warning",
          //         }
          //       ).catch((err) => err);
          //       // 取消
          //       if (confirmResult !== "confirm") return;

          //       const { msg } = await this.$ajax({
          //         apiKey: "balanceRemind",
          //         data: {
          //           id: JSON.stringify(toExamineIDList),
          //         },
          //       });
          //       this.$refs.tableList.init();
          //       this.$toast({
          //         msg,
          //       });
          //     } catch (error) {
          //       this.$catchError(error);
          //     }
          //   },
          // },
          // {
          //   title: "课包剩余提醒",
          //   color: "blue",
          //   // 缺权限
          //   // authenKey: "",
          //   onTap: async (options) => {
          //     try {
          //       const selectedTrList = this.selectedTrList || [];
          //       const toExamineIDList = [];
          //       for (let i of selectedTrList) {
          //         toExamineIDList.push(i.id);
          //       }
          //       if (toExamineIDList.length <= 0) {
          //         throw "请至少选择一个会员";
          //       }
          //       console.log("toExamineIDList", toExamineIDList);

          //       // 模态框
          //       const confirmResult = await this.$confirm(
          //         "是否批量提醒课包剩余？",
          //         "提示",
          //         {
          //           confirmButtonText: "确定",
          //           cancelButtonText: "取消",
          //           type: "warning",
          //         }
          //       ).catch((err) => err);
          //       // 取消
          //       if (confirmResult !== "confirm") return;

          //       const { msg } = await this.$ajax({
          //         apiKey: "coursePackBalanceRemind",
          //         data: {
          //           id: JSON.stringify(toExamineIDList),
          //         },
          //       });
          //       this.$refs.tableList.init();
          //       this.$toast({
          //         msg,
          //       });
          //     } catch (error) {
          //       this.$catchError(error);
          //     }
          //   },
          // },
        ],
        actionBtns: [
          {
            title: "修改会员等级",
            color: "cyan",
            authenKey: "member_changeLevel",
            onTap: (options) => {
              this.renderChangeMemberLevelDialog({
                type: 1,
                inputedVal: options,
              });
            },
          },
          {
            title: "充值记录",
            color: "cyan",
            authenKey: "member_rechargeRecordList",
            onTap: (options) => {
              this.initOptions_rechargeRecord = {
                parentID: options.id,
              };

              this.$refs.centerDialog_refundOrder.render({
                title: "钱包充值记录",
                width: "800px",
                complete: () => {
                  this.initOptions_rechargeRecord = null;
                },
              });
            },
          },
          {
            title: "消费记录",
            color: "cyan",
            authenKey: "member_consumptList",
            onTap: (options) => {
              this.initOptions_consumeRecordList = {
                parentID: options.id,
              };

              this.$refs.centerDialog_consumeRecordList.render({
                title: "钱包消费记录",
                width: "800px",
                complete: () => {
                  this.initOptions_consumeRecordList = null;
                },
              });
            },
          },
          {
            title: "补录订单记录",
            color: "blue",
            authenKey: "member_recordConsumption",
            onTap: (options) => {
              const inputList = this.getInputList_recordConsuption(options);

              this.initOptions_recordConsumption = {
                inputList: inputList,
              };

              this.$refs.centerDialog_recordConsuption.render({
                title: "补录订单记录",
                closeOnClickModal: false,
                ok: async () => {
                  const toSubmitOpt = this.$com.getValueByRules(inputList);
                  console.log("toSubmitOpt", toSubmitOpt);

                  const { msg } = await this.$ajax({
                    // apiKey:
                    //   toSubmitOpt.formType === 1
                    //     ? "recordConsumption"
                    //     : "coursePackConsume",
                    apiKey: "classHourConsume",
                    data: {
                      ...toSubmitOpt,
                      pid: options.id,
                    },
                  });

                  this.$toast({ msg });
                  this.$refs.tableList.init();
                },
                complete: () => {
                  this.initOptions_recordConsumption = null;
                },
              });
            },
          },
          {
            title: "查看订单",
            color: "warning",
            onTap: (options) => {
              // console.log('查看订单', options)
              this.$router.push({
                name: "orderList",
                query: {
                  mobile: options.mobile,
                },
              });
            },
          },
          {
            title: "删除",
            color: "red",
            authenKey: "member_del",
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: "delMember",
                data: {
                  id: options.id, //cid	是	int	班级id
                },
              });
            },
          },
        ],
        select: {
          type: "mutiple",
          onChange: (val) => {
            this.selectedTrList = val;
          },
        },
        getData: async (options) => {
          let ret = await this.$ajax({
            apiKey: "getMemberList",
            data: {
              ...options,
            },
          });

          return ret;
        },
      };

      _tempValObj.memberLevel.remoteMethod();

      return tableOptions;
    },
  },
};
</script>
