/*
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-06 16:07:15
 * @FilePath     : \leXue_manage_pc\src\assets\js\validValue.js
 *
 
数据校验
 
 */


export default {
    isEmial(email) {
        const sReg = /[_a-zA-Z\d\-\.]+@[_a-zA-Z\d\-]+(\.[_a-zA-Z\d\-]+)+$/;
        if (!sReg.test(email)) {
            return false;
        }
        return true;
    },
    isMobile(phone) {
        if (!(/^1[3456789]\d{9}$/.test(phone))) {
            return false;
        } else {
            return true;
        }
    },
    isAssetTypeAnImage(ext) {
        if (!ext) {
            console.error('没有图片源');
        }

        console.info('ext', ext);

        const imgeTypeList = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'];
        const existedImgTypeIndex = imgeTypeList.findIndex(item => {
            return new RegExp(item).test(ext.toLowerCase());
        });

        console.info('existedImgTypeIndex', existedImgTypeIndex);

        return existedImgTypeIndex !== -1;
    },

    //必须是数字或者空值
    isNumberOrEmptyValue() {
        return value => {
            if (value) {
                return Number(value) ? true : "请输入数字类型";
            } else {
                return true;
            }
        }
    },
    isNumber(value) {
        return (value) => !!Number(value) || '请输入数字类型';
    },

    //输入金额
    inputMoney(obj) {
        //得到第一个字符是否为负号
        obj.value = obj.value.toString();
        var t = obj.value.charAt(0);

        //先把非数字的都替换掉，除了数字和.和-号
        obj.value = obj.value.replace(/[^\d\.]/g, "");

        //前两位不能是0加数字
        obj.value = obj.value.replace(/^0\d[0-9]*/g, "");

        //必须保证第一个为数字而不是.
        obj.value = obj.value.replace(/^\./g, "");

        //保证只有出现一个.而没有多个.
        obj.value = obj.value.replace(/\.{2,}/g, ".");

        //保证.只出现一次，而不能出现两次以上
        obj.value = obj.value
            .replace(".", "$#$")
            .replace(/\./g, "")
            .replace("$#$", ".");
        //如果第一位是负号，则允许添加
        obj.value = obj.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
        
        //如果是以.结尾，则补充为 .00
        obj.value = obj.value.replace(/\.$/, '.00');

        if (t == "-") {
            return;
        }
    },

    isMoney(s) {
        if (/^[0-9]*(\.[0-9]{1,2})?$/.test(s)) {
            return true;
        } else {
            return false;
        }
    }

}





// reCheckInputForm({ inputObj } = options) {
//     if (
//       inputObj.checkIsMobile === true &&
//       inputObj.inputValue.length === 11
//     ) {
//       inputObj.errTips = "手机号码格式输入有误，请确认";
//       return validValue.isMobile(inputObj.inputValue);
//     }

//     return true;
//   }
// },
// computed: {
//   errStateList() {
//     const userInfo_inputCenter = this.userInfo_inputCenter;
//     const _validValue = validValue;
//     const _reCheckInputForm = this.reCheckInputForm;
//     console.log(_reCheckInputForm);

//     return userInfo_inputCenter.map(item => {
//       const inputValue = item.inputValue;

//       if (inputValue === null) {
//         return null;
//       } else if (inputValue !== "") {
//         item.errTips = item.placeholder;

//         // return _reCheckInputForm({
//         //   inputObj: item
//         // });

//         // if (item.checkIsMobile === true && inputValue.length === 11) {
//         //   item.errTips = "手机号码格式输入有误，请确认";
//         //   return _validValue.isMobile(inputValue);
//         // }
//       } else {
//         return false;
//       }
//     });
//   }