<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-16 11:41:45
 * @FilePath     : \leXue_manage_pc\src\views\finance\incomeList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="isLoadedAdminDetails"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  props: {
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableOptions: null,
    };
  },
  destroyed() {
    this.$bus_unique.off("updateOrderList");
  },
  mounted() {
    this.$bus_unique.on("updateOrderList", "orderList", () => {
      this.$refs.tableList.init();
    });
  },
  methods: {
    getTableOptions() {
      let configureOptions = {
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
        ],
        actionBtns: [
          {
            title: "查看",
            color: "cyan",
            authenKey: "finance_details",
            onTap: (options) => {
              this.$router.push({
                name: "finaceOrderDetails",
                query: {
                  pageType: 2,
                  id: options.oid,
                  studentID: options.sid,
                },
              });
            },
          },
        ],
      };

      const tableOptions = {
        authenKey: "finance_list",
        search: {
          inputList: [
            {
              type: "input",
              label: "订单编号",
              placeholder: "",
              value: "",
              props: "order_id", //order_id	否	string	订单编号
              rules: ["!null"],
            },
            {
              type: "input",
              label: "产品名称",
              placeholder: "",
              value: "",
              props: "pro_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "学生姓名",
              placeholder: "",
              value: "",
              props: "student_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "家长姓名",
              placeholder: "",
              value: "",
              props: "parent_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "家长手机号码",
              placeholder: "",
              value: "",
              maxLength: 11,
              props: "mobile",
              rules: ["!null"],
            },

            {
              type: "input",
              label: "所属校区",
              placeholder: "",
              value: "",
              props: "xq_name", //xq_name	否	string	校区名称关键词
              rules: ["!null"],
            },
            {
              type: "input",
              label: "消费地区",
              placeholder: "",
              value: "",
              props: "area_name", //area_name	否	string	地区名称
              rules: ["!null"],
            },
            {
              type: "radioBtnList",
              label: "时间筛选",
              value: 0,
              props: "time_type", //time_type	否	int	时间类型 1-本月 2-上月 3-本年 4-去年 5-自定义
              rules: ["!null"],
              options: [
                {
                  label: "全部",
                  value: 0,
                },
                {
                  label: "本月",
                  value: 1,
                },
                {
                  label: "上月",
                  value: 2,
                },
                {
                  label: "本年",
                  value: 3,
                },
                {
                  label: "去年",
                  value: 4,
                },
                {
                  label: "自定义",
                  value: 5,
                },
              ],
              onChange: (val, thisObj, inputList) => {
                //增加：自定义时间选择控件
                if (val === 5) {
                  const startAndEndTimeRange = this.getStartAndEndTimeRange();

                  startAndEndTimeRange.forEach((item) => {
                    inputList.push(item);
                  });
                }

                //移除：自定义时间选择控件
                const dateTimePickerIndex = inputList.findIndex(
                  (item) => item.ref === "dateTimePicker"
                );

                if (val < 5 && dateTimePickerIndex !== -1) {
                  inputList.splice(dateTimePickerIndex, 2);
                }
              },
            },
          ],
        },
        headers: [
          { label: "订单编号", props: "order_id" },
          { label: "创建时间", props: "created_at" },
          { label: "学生姓名", props: "student_name" },
          { label: "家长姓名", props: "_parentName" },
          ...(this.$store.getters.getAuthenIsPermitted("finance_orderPrice")
            ? [{ label: "消费总金额", props: "_consumeAmount" }]
            : []),
          { label: "所属校区", props: "xq_name" },
          { label: "产品名称", props: "_buyyedProduct", type: "text" },
          { label: "总课时", props: "count_sum" },
          { label: "剩余总课时", props: "surplus_count_sum" },
        ],
        ...configureOptions,
        getData: async (options) => {
          if (options.time_type === 0) {
            delete options.time_type;
          }

          let ret = await this.$ajax({
            apiKey: "getIncomeList",
            data: options,
          });

          this.$emit("incomeChange", ret.res.income); //总收入

          console.info("ret", ret);

          return {
            code: ret.code,
            res: ret.res.list,
          };
        },
      };

      return tableOptions;
    },

    getStartAndEndTimeRange() {
      return [
        {
          ref: "dateTimePicker",
          type: "dateTimePicker",
          label: "开始时间",
          placeholder: "请选择开始时间",
          rules: ["required", "!null"],
          value: null,
          props: "start_time", //start_time	否	string	开始时间，当时间类型为5，该参数必须，例如：2020-10-10 10:00:00
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd hh:mm:ss"
            );

            item.value = formatDate;
          },
        },
        {
          type: "dateTimePicker",
          label: "结束时间",
          placeholder: "请选择结束时间",
          maxLength: 40,
          rules: ["required", "!null"],
          value: null,
          props: "end_time", //end_time	否	string	结束时间，当时间类型为5，该参数必须 例如：2020-10-10 10:00:00
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd hh:mm:ss"
            );

            item.value = formatDate;
          },
        },
      ];
    },
  },
  computed: {
    isLoadedAdminDetails() {
      const isLoadedAdminDetails = this.$store.getters.isLoadedAdminDetails();

      if (isLoadedAdminDetails === true && !this.isInitted) {
        this.tableOptions = this.getTableOptions();
        this.isInitted = true;
      }

      return isLoadedAdminDetails;
    },
  },
};
</script>
