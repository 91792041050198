<template>
  <dataBoardBox class="paymentDiv" title="收支汇总">
    <div class="inpaymentDiv">
      <div class="paymentOne" v-for="(item, index) in paymentDiv" :key="index">
        <!-- 左边的名字 -->
        <div class="paymentLeft" :class="'paymentLeft' + (index + 1)">
          <div class="inPaymentLeft">{{ item.name }}</div>
        </div>
        <!-- 右边的数据 -->
        <div class="paymentRight">
          <!-- 上（今日收入） -->
          <div class="paymentRightTop">
            <img :src="item.img" width="8%" height="8%" />
            <div class="RightTopWord">
              今日收入<span class="paymentNum">{{ getNum(item.today) }}</span
              ><span>元</span>
            </div>
          </div>
          <!-- 下（各个时间段的收入） -->
          <div class="paymentRightDown">
            <!-- 左（本周+今年） -->
            <div class="inPaymentRight">
              <div class="leftDown">
                <div>本周收入</div>
                <div class="downNum">
                  {{ getNum(item.week) }} <span class="downWord">元</span>
                </div>
              </div>
              <div class="leftDown">
                <div>今年收入</div>
                <div class="downNum">
                  {{ getNum(item.year) }}<span class="downWord">元</span>
                </div>
              </div>
            </div>
            <!-- 右（本月+总收入） -->
            <div class="inRightRight">
              <div class="leftDown">
                <div>本月收入</div>
                <div class="downNum">
                  {{ getNum(item.month) }} <span class="downWord">元</span>
                </div>
              </div>
              <div class="leftDown">
                <div>总收入</div>
                <div class="downNum">
                  {{ getNum(item.total) }} <span class="downWord">元</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dataBoardBox>
</template>

<script>
import dataBoardBox from "@/views/other/dataBoardBox.vue";

export default {
  components: {
    dataBoardBox,
  },
  data() {
    return {
      //收支汇总数据
      paymentDiv: [
        {
          props: "old_wechat_qctn",
          name: "第三方\n·\n七彩童年",
          img: require("@/assets/img/001.png"),
          today: 0, //今日
          week: 0, //本周
          month: 0, //本月
          year: 0, //本年
          total: 0, //总额
        },
         {
          props: "new_wechat_qctn",
          name: "微信支付\n·\n七彩童年",
          img: require("@/assets/img/001.png"),
          today: 0, //今日
          week: 0, //本周
          month: 0, //本月
          year: 0, //本年
          total: 0, //总额
        },
        {
          props: "wechat_lxjw",
          name: "微信支付\n·\n乐学后台",
          img: require("@/assets/img/002.png"),
          today: 0, //今日
          week: 0, //本周
          month: 0, //本月
          year: 0, //本年
          total: 0, //总额
        },
        {
          props: "ycf",
          name: "原存费",
          img: require("@/assets/img/003.png"),
          today: 0, //今日
          week: 0, //本周
          month: 0, //本月
          year: 0, //本年
          total: 0, //总额
        },
        {
          props: "recharge_sum",
          name: "充值汇总",
          img: require("@/assets/img/004.png"),
          today: 0, //今日
          week: 0, //本周
          month: 0, //本月
          year: 0, //本年
          total: 0, //总额
        },
        {
          props: "bank_card",
          name: "银行卡",
          img: require("@/assets/img/005.png"),
          today: 0, //今日
          week: 0, //本周
          month: 0, //本月
          year: 0, //本年
          total: 0, //总额
        },
        {
          props: "cash",
          name: "现金",
          img: require("@/assets/img/006.png"),
          today: 0, //今日
          week: 0, //本周
          month: 0, //本月
          year: 0, //本年
          total: 0, //总额
        },
        {
          props: "alipay",
          name: "支付宝",
          img: require("@/assets/img/007.png"),
          today: 0, //今日
          week: 0, //本周
          month: 0, //本月
          year: 0, //本年
          total: 0, //总额
        },
        {
          props: "xft",
          name: "校付通",
          img: require("@/assets/img/008.png"),
          today: 0, //今日
          week: 0, //本周
          month: 0, //本月
          year: 0, //本年
          total: 0, //总额
        },
      ],
      //存放收支汇总数据
      paymentList: [],
    };
  },
  created() {
    this.getPaymentList();
  },
  methods: {
    // 获取收支汇总数据
    async getPaymentList() {
      try {
        const { res } = await this.$ajax({ apiKey: "getPaymentList" });
        this.paymentList = res;
        console.log("paymentList", this.paymentList);
        for (let i of this.paymentDiv) {
          for (let j in this.paymentList) {
            //j是下标
            if (i.props == j) {
              i.today = res[j].today;
              i.week = res[j].week;
              i.month = res[j].month;
              i.year = res[j].year;
              i.total = res[j].total;
            }
          }
        }
        this.getNum();
      } catch (error) {
        this.$catchError(error);
      }
    },
    //将超过万/亿的数字加上万/亿的单位
    getNum(i) {
      //math.floor 就是去除小数点向下取整 math.floor(3.84) = 3
      //x.toString() 就是把x变成字符串
      let num1 = Math.floor(i).toString();
      //如果num1长度大于4(num1是万级别的)
      if (num1.length > 4) {
        //如果num1长度大于8(num1是亿级别的)
        if (num1.length > 8) {
          //num1除以1亿再取整得到的数字再加'亿'
          let num2 = Math.floor(num1 / 100000000);
          return num2 + "亿";
        } else {
          //num1除以1亿再取整得到的数字再加'万'
          let num2 = Math.floor(num1 / 10000);
          return num2 + "万";
        }
      } else {
        return i;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.paymentDiv {
  width: 1340px;
  height: 660px;
  border-radius: 4px;
  overflow: auto;
  padding-bottom: 20px;
}

.inpaymentDiv {
  display: flex;
  flex-wrap: wrap;
}

.paymentOne {
  width: 401px;
  height: 171px;
  box-shadow: 0px 4px 9.6px 0.4px rgba(218, 218, 218, 0.5);
  border-radius: 4px;
  display: flex;
  background-color: #ffffff;
  margin-left: 30px;
  margin-top: 19px;
}

.paymentLeft {
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.paymentLeft1,.paymentLeft2,
.paymentLeft9 {
  background-color: #0084ff;
}

.paymentLeft3,
.paymentLeft5 {
  background-color: #fe8e02;
}

.paymentLeft4,
.paymentLeft6 {
  background-color: #ff80b3;
}

.paymentLeft7,
.paymentLeft8 {
  background-color: #6f5efd;
}

.inPaymentLeft {
  width: 18px;
  height: 161px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
  line-height: 18px;
  flex-direction: column;
}

.paymentRight {
  width: 365px;
}

.inPaymentRight {
  border-right: 1px solid #e2e2e2;
  padding-right: 10px;
  height: 60px;
}

.inRightRight {
  margin-left: 10px;
  height: 60px;
}

.paymentRightTop {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 10px auto 19px;
}

.RightTopWord {
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  margin-left: 5px;
}

.paymentNum {
  height: 45px;
  font-size: 36px;
  margin: 0 5px 0 15px;
  color: #2c6cec;
  text-align: center;
}

.paymentRightDown {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 341px;
  height: 78px;
  //   opacity: 0.05;
  margin: 20px auto 0;
  background-color: #f3f3f3;
}

.leftDown {
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  // width: 55px;
  // height: 13px;
  color: #505050;
  font-size: 14px;
}

.downNum {
  // width: 49px;
  // height: 16px;
  font-size: 20px;
  color: #fe8e02;
  //   margin-left: 20px;
}

.downWord {
  color: #505050;
  font-size: 14px;
}
</style>
