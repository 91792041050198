<!--
 * @Description: 教学大纲-查看(课程主题-美篇链接)
-->
<template>
  <div>
    <div class="lineBox">
      <template v-if="list.length">
        <template v-for="(item, index) in list">
          <a
            :key="index"
            v-if="!operationButton.length"
            :href="item.url"
            target="_blank"
          >
            <span>{{ item.url }}</span>
          </a>
          <div class="item" :key="index" v-else>
            <el-input
              placeholder="请输入网址"
              v-model="item.url"
              clearable
              @blur="onBlur($event, item)"
            >
            </el-input>
            <i
              v-if="status === 2"
              class="el-icon-plus"
              @click="onAdd(index)"
            ></i>
            <i
              class="el-icon-delete"
              v-if="status === 2"
              @click="onDel(item.id, index)"
            ></i>
          </div>
        </template>
      </template>
      <el-divider v-else>暂无内容</el-divider>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataItem: {
      type: Object,
      default: () => {},
    },
    // 按钮权限
    operationButton: {
      type: Array,
      default: () => [],
    },
    // 主题状态
    status: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      list: [],
    }
  },
  created() {
    let mpList = this.dataItem.mp
    // console.log('mpList', mpList)
    if (!mpList.length && this.operationButton.length) {
      this.onAdd(0)
    } else {
      this.list = mpList
    }
  },
  methods: {
    onAdd(index) {
      let obj = {
        url: '',
      }
      this.list.splice(index + 1, 0, obj)
    },

    onDel(id, index) {
      this.$confirm('是否删除此链接？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        try {
          if (this.list.length === 1) {
            throw '必须保留一个'
          }

          if (id) {
            await this.$ajax({
              apiKey: 'outline_mp_del',
              data: {
                id,
              },
            })
          }

          this.list.splice(index, 1)
          this.$toast({
            msg: '删除成功',
          })
        } catch (error) {
          this.$catchError(error)
        }
      })
    },

    // 输入框失去焦点保存
    async onBlur(e, item) {
      // console.log('e', e)
      // console.log('item', item)
      try {
        if (this.status !== 2) {
          throw '只能在启用状态下修改'
        }

        if (!item.url) {
          throw '内容不能为空'
        }

        let data = {
          ...item,
          theme_id: this.dataItem.id,
        }
        let { res, msg } = await this.$ajax({
          apiKey: 'outline_mp_save',
          data,
        })
        // console.log('res', res)
        this.$message.success(msg)
        item.id = res.id
      } catch (error) {
        this.$catchError(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.lineBox {
  padding: 40px 50px 20px;

  a {
    margin: 0 auto 20px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .item {
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0 auto 20px;

    .el-input {
      width: 90%;
    }

    i {
      color: #1891ff;
      font-size: 18px;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}

.el-divider__text {
  background: #f9f9f9;
}
</style>
