<template>
  <div class="d-flex flex-column h-100">
    <div class="col overflow-hidden">
      <mainContentDiv class="pa-10 overflow-scroll" v-if="roleDetails !== null">
        <div class="overflow-scroll-y h-100">
          <div class="authen-modules">
            <div class="d-flex align-items-center authen-details">
              <div class="px-10">
                <inputCenter
                  ref="inputCenter"
                  :initOptions="roleDetails.roleNameInfo"
                  >编辑用户</inputCenter
                >
              </div>
            </div>

            <el-tree
              show-checkbox
              default-expand-all
              node-key="id"
              ref="tree"
              highlight-current
              :props="defaultProps"
              :default-checked-keys="roleDetails.checkedPowerList"
              :data="roleDetails.powerList"
            >
            </el-tree>
          </div>
        </div>
      </mainContentDiv>
    </div>
    <!-- 模块：底部操作按钮 -->
    <footerBtnModules :btnList="curentPageFooterBtnList" />
  </div>
</template>

<script>
import footerBtnModules from "@/components/footerBtnModules.vue";

export default {
  components: {
    footerBtnModules,
  },
  data() {
    return {
      roleDetails: null,
      defaultProps: {
        children: "children",
        label: "label",
      },
      pageType: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        let { pageType, id: roleID } = this.$route.query;

        pageType = this.pageType = Number(pageType);

        let getAndSetRoleDetails = async (roleID) => {
          if (!roleID) throw "角色id有误";

          //获取角色详情
          let {
            res: { name, power },
          } = await this.$ajax({
            apiKey: "getRoleDetails",
            data: {
              id: roleID,
            },
          });

          this.roleDetails = this.sortOutPowerData(power, name);
        };

        switch (
          Number(pageType) //0：新增角色 1：编辑角色 2:查看角色
        ) {
          case 0:
            let { res } = await this.$ajax({
              apiKey: "getPowerList",
            });
            this.roleDetails = await this.sortOutPowerData(res);
            break;
          case 1:
            await getAndSetRoleDetails(roleID);
            break;
          case 2:
            await getAndSetRoleDetails(roleID);
            break;
        }
      } catch (error) {
        this.$toast({
          color: "error",
          msg: error,
        });
      }
    },

    //整理出权限数据
    sortOutPowerData(powerList, roleName) {
      let roleDetails = {
        roleNameInfo: {
          inputList: [
            {
              type: "input",
              label: "角色名称",
              placeholder: "请输入角色名称",
              maxLength: 50,
              rules: ["required", "!null"],
              value: roleName || null,
              props: "name", //name	是	string	角色名称
            },
          ],
        },
        powerList: [],
      };

      //选中的prowID
      let checkedPowerList = [];

      //生成权限列表
      const renderPowerList = (powerList) => {
        return powerList.map((item) => {
          const { name, checked, id, children, button } = item;
          const child = children || button || [];

          if (checked && child.length === 0) {
            checkedPowerList.push(id);
          }

          return {
            label: name,
            value: !!checked,
            id,
            children: renderPowerList(child),
          };
        });
      };

      roleDetails.powerList = renderPowerList(powerList);
      roleDetails.checkedPowerList = checkedPowerList;

      console.info("checkedPowerList", checkedPowerList);

      return roleDetails;
    },

    //提交
    async handleSubmit() {
      const getCheckedKeys = this.getCheckedKeys() || "";

      try {
        let apiKey;

        const getCheckedKeys = this.getCheckedKeys() || "";
        const { pageType, id: roleID } = this.$route.query;

        let requestOptions = await this.$com.getValueByRules(
          this.roleDetails.roleNameInfo.inputList
        );

        console.info("requestOptions", requestOptions);

        switch (Number(pageType)) {
          case 0:
            apiKey = "addRole";
            break;
          case 1:
            apiKey = "editRole";
            break;
        }

        const { msg } = await this.$ajax({
          apiKey,
          data: {
            ...requestOptions,
            id: roleID || "",
            power_ids: JSON.stringify(getCheckedKeys),
          },
        });

        //获取角色权限信息
        await this.$store.dispatch("getRoleAuthen", this);

        this.$bus_unique.emit("updateAuthenList");
        this.$router.back(-1);

        this.$toast({
          color: "success",
          msg,
        });
      } catch (error) {
        this.$toast({
          color: "error",
          msg: error,
        });
      }
    },

    getCheckedKeys() {
      const checkedKeys = this.$refs.tree.getCheckedKeys();
      const getHalfCheckedKeys = this.$refs.tree.getHalfCheckedKeys();

      console.info("checkedKeys", checkedKeys);
      console.info("getHalfCheckedKeys", getHalfCheckedKeys);

      return checkedKeys.concat(getHalfCheckedKeys);
    },
  },
  computed: {
    //页面底部的操作按钮
    curentPageFooterBtnList() {
      let btnList = [];
      let pageType = this.pageType;

      switch (
        pageType //0: 新增 1：编辑
      ) {
        case 0:
          btnList = [
            {
              title: "新增",
              authenKey: "role_add",
              onTap: () => {
                this.handleSubmit(pageType);
              },
            },
          ];
          break;
        case 1:
          btnList = [
            {
              title: "保存编辑",
              authenKey: "role_edit",
              onTap: () => {
                this.handleSubmit(pageType);
              },
            },
          ];
          break;
      }

      return btnList;
    },
  },
};
</script>

<style >
.el-tree--highlight-current {
  display: flex;
  flex-wrap: wrap;
}
</style>

