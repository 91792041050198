<!--
 * @Description: 考核模板
-->
<template>
  <div class="pageBox d-flex flex-column h-100">
    <div class="col overflow-scroll-y h-100" style="padding: 20px;">
      <template v-if="ifOperationButton('inspectionTemplateDetails')">
        <div class="itemBox" v-for="(item, index) in list" :key="index">
          <div class="item_titleBox">
            <span>模板-{{ index + 1 }}</span>
            <el-button
              size="mini"
              type="primary"
              @click="itemAddOrDel(0, index)"
              >新增</el-button
            >
            <el-button
              v-if="index > 0"
              size="mini"
              type="danger"
              @click="itemAddOrDel(1, index)"
              >删除</el-button
            >
          </div>
          <div class="item_tableBox">
            <table border="0" width="100%" cellspacing="0" cellpadding="0">
              <tr>
                <th width="260">考核形式</th>
                <th>考核标准</th>
                <th width="160">得分</th>
              </tr>
              <template v-for="(itemJ, indexJ) in item.norm">
                <tr align="center" :key="indexJ">
                  <td :rowspan="indexJ" v-if="indexJ === 0">
                    <el-input
                      v-model="item.name"
                      placeholder="请输入名称"
                      clearable
                    ></el-input>
                  </td>
                  <td class="standardTd">
                    <el-input
                      v-model="itemJ.name"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                    <i
                      class="el-icon-delete"
                      v-if="indexJ > 0"
                      @click="onNormAddOrDel(1, index, indexJ)"
                    ></i>
                    <i
                      class="el-icon-plus"
                      @click="onNormAddOrDel(0, index, indexJ)"
                    ></i>
                  </td>
                  <td>
                    <el-input
                      v-model="itemJ.total_points"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>
        <!-- 底部 -->
        <div class="bottomTheBox">
          <el-button
            size="medium"
            @click="onDownload"
            v-if="ifOperationButton('inspectionTemplateExport')"
          >
            下载表模
          </el-button>
          <el-button
            size="medium"
            type="primary"
            @click="onSave"
            v-if="ifOperationButton('inspectionTemplateEdit')"
          >
            编辑
          </el-button>
        </div>
      </template>
      <div
        class="h-100 d-flex justify-content-center align-items-center"
        style="background-color: #fff;"
        v-else
      >
        暂无权限，请联系管理员
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    // 获取数据
    async getInfo() {
      try {
        const { res } = await this.$ajax({
          apiKey: 'assessInfo',
        })
        console.log('获取数据', res)
        this.list = res
      } catch (error) {
        this.$catchError(error)
      }
    },

    // 新增、删除模块
    async itemAddOrDel(type, index) {
      if (type) {
        this.$confirm('是否删除此备课考核', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          // console.log('index', index)
          this.list.splice(index, 1)
        })
      } else {
        let item = {
          name: '',
          norm: [
            {
              name: '',
              total_points: '',
            },
          ],
        }
        this.list.splice(index + 1, 0, item)
      }
    },

    // 新增、删除子模块
    async onNormAddOrDel(type, index, normIndex) {
      if (type) {
        this.$confirm('是否删除此备课考核', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          // console.log('index', index)
          this.list[index].norm.splice(normIndex, 1)
        })
      } else {
        let item = {
          name: '',
          total_points: '',
        }
        this.list[index].norm.splice(normIndex + 1, 0, item)
      }
    },

    // 下载表模
    onDownload() {
      this.$confirm('是否下载模板？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        try {
          await this.$ajax({
            isExportFile: true, //导出文件
            methods: 'POST',
            apiKey: 'assessExport',
            fileName: '考核表模板.xls',
            data: {},
            submitByForm: true,
          })
        } catch (error) {
          this.$catchError(error)
        }
      })
    },

    // 编辑
    async onSave() {
      console.log('list', this.list)

      // 校验
      this.list.forEach((item, index) => {
        if (!item.name) {
          throw this.$message.error(
            `第${index + 1}个备课考核的 '考核形式' 未完善`
          )
        }

        item.norm.forEach((itemJ) => {
          if (!itemJ.name) {
            throw this.$message.error(
              `第${index + 1}个备课考核的 '考核标准' 未完善`
            )
          } else if (!itemJ.total_points) {
            throw this.$message.error(
              `第${index + 1}个备课考核的 '得分' 未完善`
            )
          }
        })
      })

      console.log('ok')

      try {
        const { msg } = await this.$ajax({
          apiKey: 'assessEdit',
          submitByForm: true,
          data: {
            data: this.list,
          },
        })
        this.$toast({
          msg,
        })
        this.getInfo()
      } catch (error) {
        this.$catchError(error)
      }
    },

    // 权限按钮判断
    ifOperationButton(authenKey) {
      const getters = this.$store.getters
      const getAuthenIsPermitted = getters.getAuthenIsPermitted
      const isGetAuthen = getAuthenIsPermitted(authenKey)
      // console.log('isGetAuthen', isGetAuthen)
      return isGetAuthen
    },
  },
}
</script>

<style lang="scss" scoped>
.pageBox {
  margin: -1.25rem;
  position: relative;
  padding-bottom: 20px;
}

.itemBox {
  margin-bottom: 20px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(218, 218, 218, 0.5);
  border-radius: 4px;

  .item_titleBox {
    border-bottom: 1px solid #eaeaea;
    padding: 18px 22px 16px;

    span {
      font-size: 16px;
      color: #333333;
      margin-right: 16px;
    }
  }

  .item_tableBox {
    padding: 20px 24px;

    table {
      border: 1px solid #eaeaea;

      th,
      td {
        text-align: center;
        border-right: 1px solid #eaeaea;
        height: 40px;

        &:last-child {
          border-right: 0;
        }
      }
      td {
        border-top: 1px solid #eaeaea;
      }
      th {
        background: #f7f7f7;
      }

      .standardTd {
        display: flex;
        align-items: center;

        i {
          font-size: 18px;
          font-weight: bold;
          color: #1891ff;
          margin-right: 14px;
          cursor: pointer;
        }
        .el-icon-delete {
          color: #f9463f;
          margin-left: 14px;
        }
      }
    }
  }
}

::v-deep td {
  .el-input input {
    border: 0 !important;
  }
}

.bottomTheBox {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 30px;
  width: calc(100% - 208px);
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(218, 218, 218, 0.5);

  .el-button {
    margin: 0 14px;
  }
}
</style>
