<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-20 11:31:10
 * @FilePath: /src/views/customer/parentList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>

    <!-- 新增/编辑家长 -->
    <addOrEditParent ref="addOrEditParent"></addOrEditParent>

    <!-- 账户合并 -->
    <el-dialog
      :visible.sync="dialogVisible"
      class="FormDialogBox"
      width="700px"
      append-to-body
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="账户合并"
      @close="cancel()"
    >
      <el-form :model="form" :rules="rules" ref="formRef" label-width="auto">
        <el-form-item label="需要转移的手机号：" prop="old_mobile">
          <el-input
            v-model="form.old_mobile"
            placeholder="请输入需要转移的手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="目标手机号：" prop="new_mobile">
          <el-input
            v-model="form.new_mobile"
            placeholder="请输入目标手机号"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine(1)">确定</el-button>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog
      :visible.sync="studentDetailsDialogVisible"
      class="studentDetailsDialogBox"
      width="700px"
      append-to-body
      title="查看详情"
      fullscreen
    >
      <studentDetails
        v-if="studentDetailsDialogVisible"
        :dialog="true"
        :pageType="2"
        :studentID="studentID"
        :parentID="parentID"
        @closeDialog="studentDetailsDialogVisible = false"
      />
    </el-dialog>

    <!-- 余额课包修改 -->
    <el-dialog
      :visible.sync="changeCoursePackDialogVisible"
      class="FormDialogBox fileInfoFormDialogBox"
      width="990px"
      title="余额课包修改"
      :close-on-click-modal="false"
      @close="changeCoursePackDialogClose"
    >
      <div>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="会员号">{{
            changeCoursePackForm.member_number || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="家长姓名">{{
            changeCoursePackForm.name || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{
            changeCoursePackForm.mobile || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="学生名称集">{{
            changeCoursePackForm.student_info || "-"
          }}</el-descriptions-item>
        </el-descriptions>
        <el-tabs
          style="margin-top: 20px"
          v-model="changeCoursePackDialogType"
          @tab-click="changeCoursePackDialogHandleClick"
        >
          <el-tab-pane label="编辑" name="edit"></el-tab-pane>
          <el-tab-pane label="修改记录列表" name="list"></el-tab-pane>
        </el-tabs>
        <div v-show="changeCoursePackDialogType === 'edit'">
          <el-form
            :model="changeCoursePackForm"
            :rules="changeCoursePackFormRules"
            ref="changeCoursePackFormRef"
            label-width="auto"
            label-position="top"
            size="small"
          >
            <el-form-item label="原余额课包：">
              <el-input
                :value="changeCoursePackForm.course_pack_num"
                readonly
              ></el-input>
            </el-form-item>
            <el-form-item label="修改数量（填写修改后的结果）：" prop="num">
              <el-input-number
                v-model="changeCoursePackForm.num"
                :min="0"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="备注：" style="width: 100%" prop="remark">
              <el-input
                v-model="changeCoursePackForm.remark"
                placeholder="请输入"
                type="textarea"
                :rows="2"
              ></el-input>
            </el-form-item>
            <el-button
              type="primary"
              size="mini"
              :loading="changeCoursePackSubmitLoading"
              @click="changeCoursePackOnSubmit"
            >
              修 改
            </el-button>
          </el-form>
        </div>
        <div
          style="height: 400px"
          v-show="changeCoursePackDialogType === 'list'"
        >
          <tableList
            ref="changeCoursePackTableList"
            v-if="changeCoursePackTableOptions"
            :initOptions="changeCoursePackTableOptions"
          ></tableList>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button
          type="danger"
          size="mini"
          @click="changeCoursePackDialogVisible = false"
        >
          关 闭
        </el-button>
      </span> -->
    </el-dialog>

    <!-- 余额课时修改 -->
    <el-dialog
      :visible.sync="changeCoursePackDialogVisible_keshi"
      class="FormDialogBox fileInfoFormDialogBox"
      width="990px"
      title="余额课时修改"
      :close-on-click-modal="false"
      @close="changeCoursePackDialogClose_keshi"
    >
      <div>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="会员号">{{
            changeCoursePackForm_keshi.member_number || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="家长姓名">{{
            changeCoursePackForm_keshi.name || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{
            changeCoursePackForm_keshi.mobile || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="学生名称集">{{
            changeCoursePackForm_keshi.student_info || "-"
          }}</el-descriptions-item>
        </el-descriptions>
        <el-tabs
          style="margin-top: 20px"
          v-model="changeCoursePackDialogType_keshi"
          @tab-click="changeCoursePackDialogHandleClick_keshi"
        >
          <el-tab-pane label="编辑" name="edit"></el-tab-pane>
          <el-tab-pane label="修改记录列表" name="list"></el-tab-pane>
        </el-tabs>
        <div v-show="changeCoursePackDialogType_keshi === 'edit'">
          <el-form
            :model="changeCoursePackForm_keshi"
            :rules="changeCoursePackFormRules_keshi"
            ref="changeCoursePackForm_keshiRef"
            label-width="auto"
            label-position="top"
            size="small"
          >
            <el-form-item label="原余额课包：">
              <el-input
                :value="changeCoursePackForm_keshi.class_hour_num"
                readonly
              ></el-input>
            </el-form-item>
            <el-form-item label="修改数量（填写修改后的结果）：" prop="num">
              <el-input-number
                v-model="changeCoursePackForm_keshi.num"
                :min="0"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="备注：" style="width: 100%" prop="remark">
              <el-input
                v-model="changeCoursePackForm_keshi.remark"
                placeholder="请输入"
                type="textarea"
                :rows="2"
              ></el-input>
            </el-form-item>
            <el-button
              type="primary"
              size="mini"
              :loading="changeCoursePackSubmitLoading_keshi"
              @click="changeCoursePackOnSubmit_keshi"
            >
              修 改
            </el-button>
          </el-form>
        </div>
        <div
          style="height: 400px"
          v-show="changeCoursePackDialogType_keshi === 'list'"
        >
          <tableList
            ref="changeCoursePackTableList_keshi"
            v-if="changeCoursePackTableOptions_keshi"
            :initOptions="changeCoursePackTableOptions_keshi"
          ></tableList>
        </div>
      </div>
    </el-dialog>
  </mainContentDiv>
</template>

<script>
import addOrEditParent from "./addOrEditParent.vue";
import studentDetails from "./studentDetails.vue";
import { throttle } from "@/assets/js/index";

export default {
  components: {
    addOrEditParent,
    studentDetails,
  },
  data() {
    return {
      tableOptions: null,
      dialogVisible: false,
      studentDetailsDialogVisible: false,
      studentID: null,
      parentID: null,
      form: {
        old_mobile: "",
        new_mobile: "",
      },
      rules: {
        old_mobile: [
          {
            required: true,
            message: "请输入需要转移的手机号",
            trigger: "blur",
          },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
          },
        ],
        new_mobile: [
          { required: true, message: "请输入目标手机号", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
          },
        ],
      },
      // 余额课包修改
      changeCoursePackDialogVisible: false,
      changeCoursePackDialogType: "edit",
      changeCoursePackForm: {
        pid: "",
        num: "",
        remark: "",
        member_number: "",
        name: "",
        mobile: "",
        student_info: "",
        course_pack_num: 0,
      },
      changeCoursePackFormRules: {
        num: [
          {
            required: true,
            message: "请输入修改数量",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入备注",
            trigger: "blur",
          },
        ],
      },
      changeCoursePackSubmitLoading: false,
      changeCoursePackTableOptions: null,
      // 余额课时修改
      changeCoursePackDialogVisible_keshi: false,
      changeCoursePackDialogType_keshi: "edit",
      changeCoursePackForm_keshi: {
        pid: "",
        num: "",
        remark: "",
        member_number: "",
        name: "",
        mobile: "",
        student_info: "",
        class_hour_num: 0,
      },
      changeCoursePackFormRules_keshi: {
        num: [
          {
            required: true,
            message: "请输入修改数量",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入备注",
            trigger: "blur",
          },
        ],
      },
      changeCoursePackSubmitLoading_keshi: false,
      changeCoursePackTableOptions_keshi: null,
    };
  },
  props: {
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.tableOptions = this.getTableOptions();

    this.$bus_unique.on("updateAuthenList", "authenList", () => {
      this.$refs.tableList.init();
    });
  },
  destroyed() {
    this.$bus_unique.off("updateAuthenList");
  },
  methods: {
    getTableOptions() {
      const isSelect = this.isSelect; //选择模式

      let configureOptions;

      if (isSelect === true) {
        configureOptions = {
          actionBtns: [
            {
              title: "选择",
              color: "cyan",
              isRender: true,
              onTap: (options) => {
                this.$emit("change", options);
              },
            },
          ],
        };
      } else {
        configureOptions = {
          titleBtns: [
            {
              type: "filter",
              title: "查询",
            },
            {
              title: "新增家长",
              authenKey: "parent_add",
              onTap: async () => {
                await this.$refs.addOrEditParent.render({
                  type: 0,
                });
                this.$refs.tableList.init(); //刷新数据
              },
            },
            {
              title: "导出",
              color: "normal",
              authenKey: "parent_export",
              onTap: async (thisBtn) => {
                try {
                  console.info("thisBtn", thisBtn);

                  await this.$ajax({
                    isExportFile: true, //导出文件
                    methods: "POST",
                    apiKey: "exportParentList",
                    fileName: "家长列表.xls",
                    data: thisBtn.keyword || "",
                    submitByForm: true,
                  });
                } catch (error) {
                  this.$catchError(error);
                }
              },
            },
            {
              title: "导入模板下载",
              color: "normal",
              authenKey: "parent_importTemplate",
              onTap: async () => {
                try {
                  await this.$ajax({
                    isExportFile: true, //导出文件
                    methods: "POST",
                    apiKey: "importParentListTemplate",
                    fileName: "家长导入模板.xls",
                  });
                } catch (error) {
                  this.$catchError(error);
                }
              },
            },
            {
              type: "import",
              title: "导入",
              color: "normal",
              authenKey: "parent_import",
              onRequest: async (currentSelectedFile) => {
                const ret = await this.$ajax({
                  responseAll: true,
                  methods: "POST",
                  apiKey: "importParentList",
                  submitByForm: true,
                  data: {
                    file: currentSelectedFile,
                  },
                });

                return ret;
              },
            },
            {
              title: "账户合并",
              color: "cyan",
              authenKey: "parent_account_merge",
              onTap: async (thisBtn) => {
                console.log("点击");
                this.dialogVisible = true;
              },
            },
          ],
          actionBtns: [
            {
              title: "查看",
              color: "cyan",
              authenKey: "parent_details",
              onTap: (options) => {
                this.parentID = options.id;
                this.studentDetailsDialogVisible = true;

                // this.$router.push({
                //   name: 'studentDetails',
                //   query: {
                //     pageType: 2,
                //     parentID: options.id,
                //   },
                // })
              },
            },
            {
              title: "查看订单",
              color: "warning",
              onTap: (options) => {
                // console.log('查看订单', options)
                this.$router.push({
                  name: "orderList",
                  query: {
                    mobile: options.mobile,
                  },
                });
              },
            },
            {
              title: "编辑",
              color: "cyan",
              authenKey: "parent_edit",
              onTap: async (options) => {
                // console.log('options', options)
                await this.$refs.addOrEditParent.render({
                  type: 1,
                  inputedVal: options,
                });
                this.$refs.tableList.init(); //刷新数据
              },
            },
            {
              title: "删除",
              color: "red",
              authenKey: "parent_del",
              onDelete: async (options) => {
                return await this.$ajax({
                  responseAll: true,
                  apiKey: "delParent",
                  data: {
                    id: options.id,
                  },
                });
              },
            },
            {
              title: "余额课包修改(过度)",
              color: "cyan",
              authenKey: "change_course_pack",
              onTap: (options) => {
                this.changeCoursePackDialogVisibleShow(options);
              },
            },
            {
              title: "余额课时修改",
              color: "cyan",
              authenKey: "parent_edit",
              onTap: (options) => {
                this.changeCoursePackDialogVisibleShow_keshi(options);
              },
            },
          ],
        };
      }

      return {
        authenKey: "parent_list",
        search: {
          inputList: [
            {
              type: "input",
              label: "家长名称",
              placeholder: "",
              value: "",
              props: "parent_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "学生名称",
              placeholder: "",
              value: "",
              props: "student_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "家长手机号码",
              maxLength: 11,
              value: "",
              props: "mobile",
              rules: ["!null, mobile"],
            },
            {
              type: "input",
              label: "备注",
              value: "",
              props: "remark",
              rules: ["!null"],
            },
          ],
        },
        headers: [
          { label: "家长名称", props: "name" },
          { label: "家长手机号码", props: "mobile" },
          { label: "学生名称", props: "student", type: "text" },
          { label: "购买产品数量", props: "num" },
          { label: "消费总金额", props: "money" },
          { label: "跟进客服（老师）", props: "teacher_name" },
          { label: "原存费", props: "ycf_balance", sortable: true },
          { label: "余额课包", props: "course_pack_num", sortable: true },
          { label: "余额课时", props: "class_hour_num", sortable: true },
          { label: "备注", props: "remark" },
        ],
        ...configureOptions,
        getData: async (options) => {
          const ret = await this.$ajax({
            apiKey: "getParentList",
            data: options,
          });

          return ret;
        },
      };
    },

    determine() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return;

        this.$confirm("是否确认合并？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            await this.$ajax({
              apiKey: "account_merge",
              data: this.form,
            });

            this.$message({
              type: "success",
              message: "合并成功",
            });

            this.dialogVisible = false;
            this.$refs.tableList.init(); //刷新数据
          })
          .catch(() => {});
      });
    },
    cancel() {
      this.form.old_mobile = "";
      this.form.new_mobile = "";
    },

    changeCoursePackDialogHandleClick() {
      console.log(
        "changeCoursePackDialogHandleClick-changeCoursePackDialogType",
        this.changeCoursePackDialogType
      );

      if (this.changeCoursePackDialogType === "list") {
        if (!this.changeCoursePackTableOptions) {
          this.changeCoursePackTableOptions =
            this.getChangeCoursePackTableOptions();
        } else {
          this.$refs.changeCoursePackTableList.init();
        }
      }
    },
    changeCoursePackDialogVisibleShow(e) {
      console.log("changeCoursePackDialogVisibleShow-e", e);
      let { id, member_number, name, mobile, student_info, course_pack_num } =
        e;

      this.changeCoursePackForm.pid = id;
      this.changeCoursePackForm.member_number = member_number;
      this.changeCoursePackForm.name = name;
      this.changeCoursePackForm.mobile = mobile;
      this.changeCoursePackForm.student_info = student_info || "";
      this.changeCoursePackForm.course_pack_num =
        this.changeCoursePackForm.num = Number(course_pack_num);

      this.changeCoursePackDialogVisible = true;
    },
    async changeCoursePackOnSubmit() {
      throttle(async () => {
        // 验证-基本信息
        let formValidate = await this.$refs.changeCoursePackFormRef.validate();
        if (!formValidate) return false;
        console.log("form-ok", this.changeCoursePackForm);

        try {
          this.changeCoursePackSubmitLoading = true;

          let { msg, res } = await this.$ajax({
            apiKey: "changeCoursePack",
            data: this.changeCoursePackForm,
          });
          this.$toast({
            msg,
          });

          this.changeCoursePackSubmitLoading = false;
          this.changeCoursePackDialogVisible = false;
          this.$refs.tableList.init();
        } catch (error) {
          this.changeCoursePackSubmitLoading = false;
          this.$catchError(error);
        }
      }, 800);
    },
    changeCoursePackDialogClose() {
      this.$refs.changeCoursePackFormRef.resetFields();
      this.changeCoursePackForm = this.$options.data().changeCoursePackForm;
      this.changeCoursePackDialogType = "edit";
      this.changeCoursePackTableOptions = null;
    },
    getChangeCoursePackTableOptions() {
      return {
        authenKey: "",
        headers: [
          { label: "原余额课包数量", props: "old_pack_num" },
          { label: "修改后余额课包数量 ", props: "new_pack_num" },
          { label: "备注", props: "remark" },
          { label: "操作人", props: "oper_user" },
          { label: "操作时间", props: "created_at" },
        ],
        getData: async (options) => {
          console.log("options", options);
          const ret = await this.$ajax({
            apiKey: "changeCoursePackLogList",
            data: {
              type: 1,
              ...options,
              pid: this.changeCoursePackForm.pid,
            },
          });
          return ret;
        },
      };
    },

    changeCoursePackDialogVisibleShow_keshi(e) {
      console.log("changeCoursePackDialogVisibleShow_keshi-e", e);
      let { id, member_number, name, mobile, student_info, class_hour_num } = e;

      this.changeCoursePackForm_keshi.pid = id;
      this.changeCoursePackForm_keshi.member_number = member_number;
      this.changeCoursePackForm_keshi.name = name;
      this.changeCoursePackForm_keshi.mobile = mobile;
      this.changeCoursePackForm_keshi.student_info = student_info || "";
      this.changeCoursePackForm_keshi.class_hour_num =
        this.changeCoursePackForm_keshi.num = Number(class_hour_num);

      this.changeCoursePackDialogVisible_keshi = true;
    },
    changeCoursePackDialogHandleClick_keshi() {
      console.log(
        "changeCoursePackDialogHandleClick-changeCoursePackDialogType",
        this.changeCoursePackDialogType_keshi
      );

      if (this.changeCoursePackDialogType_keshi === "list") {
        if (!this.changeCoursePackTableOptions_keshi) {
          this.changeCoursePackTableOptions_keshi =
            this.getChangeCoursePackTableOptions_keshi();
        } else {
          this.$refs.changeCoursePackTableList_keshi.init();
        }
      }
    },
    async changeCoursePackOnSubmit_keshi() {
      throttle(async () => {
        // 验证-基本信息
        let formValidate =
          await this.$refs.changeCoursePackForm_keshiRef.validate();
        if (!formValidate) return false;
        console.log("form-ok", this.changeCoursePackForm_keshi);

        try {
          this.changeCoursePackSubmitLoading_keshi = true;

          let { msg, res } = await this.$ajax({
            apiKey: "changeClassHour",
            data: this.changeCoursePackForm_keshi,
          });
          this.$toast({
            msg,
          });

          this.changeCoursePackSubmitLoading_keshi = false;
          this.changeCoursePackDialogVisible_keshi = false;
          this.$refs.tableList.init();
        } catch (error) {
          this.changeCoursePackSubmitLoading_keshi = false;
          this.$catchError(error);
        }
      }, 800);
    },
    changeCoursePackDialogClose_keshi() {
      this.$refs.changeCoursePackForm_keshiRef.resetFields();
      this.changeCoursePackForm_keshi =
        this.$options.data().changeCoursePackForm_keshi;
      this.changeCoursePackDialogType_keshi = "edit";
      this.changeCoursePackTableOptions_keshi = null;
    },
    getChangeCoursePackTableOptions_keshi() {
      return {
        authenKey: "",
        headers: [
          { label: "原余额课时数量", props: "old_hour_num" },
          { label: "修改后余额课时数量 ", props: "new_hour_num" },
          { label: "备注", props: "remark" },
          { label: "操作人", props: "oper_user" },
          { label: "操作时间", props: "created_at" },
        ],
        getData: async (options) => {
          console.log("options", options);
          const ret = await this.$ajax({
            apiKey: "changeCoursePackLogList",
            data: {
              ...options,
              type: 2,
              pid: this.changeCoursePackForm_keshi.pid,
            },
          });
          return ret;
        },
      };
    },
  },
};
</script>

<style lang="scss">
.studentDetailsDialogBox {
  .el-dialog__body {
    height: 94%;
    padding: 0 10px 20px;
    overflow: hidden;
    background: #f4f4f4;
  }
}
</style>
