<template>
  <div class="position-fixed login-modules" v-if="!initOptions.disRenderDom">
    <div class="position-fixed z100 center-content">
      <div class="mb-30 modules-title">乐学后台系统</div>
      <div class="overflow-hidden main-content">
        <div class="text-center loign-title">登录</div>
        <div
          class="input-item"
          v-for="(item, index) in userInfo.inputList"
          :key="'inputList' + index"
        >
          <div class="mb-20 input">
            <el-input
              clearable
              v-model="item.value"
              :placeholder="item.placeholder"
              :maxlength="item.maxLength"
              :show-password="item.isPassword"
              @keyup.enter.native="handleLogin"
            >
            </el-input>
          </div>
        </div>

        <checkFile
          class="mt-n3"
          :initOptions="userInfo.remmemberUserObj"
        ></checkFile>

        <comBtn
          class="my-40 mx-auto login-btn"
          color="deepBlue"
          @click="handleLogin()"
          >登录</comBtn
        >
      </div>
    </div>
  </div>
</template>

<script>
let _fromPage

export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      userInfo: {
        inputList: [
          {
            type: 'input',
            label: '手机号',
            placeholder: '请输入手机号',
            value: '',
            props: 'mobile', //	是	string	用户名
            maxLength: 11,
            rules: ['required', '!null'],
          },
          {
            type: 'input',
            label: '密码',
            placeholder: '请输入密码',
            value: '',
            isPassword: true,
            props: 'password', //	是	string	密码
            rules: ['required', '!null'],
          },
        ],
        remmemberUserObj: {
          label: '记住用户名',
          value: true,
          labelStyle: {
            fontSize: '0.8rem;',
          },
        },
      },
    }
  },

  beforeRouteEnter(to, from, next) {
    console.info('to', to)
    console.info('from', from)

    _fromPage = from

    next()
  },

  async mounted() {
    try {
      console.info('this', this)

      this.$store.commit('saveAdminDetails', null)

      //初始化用户名记录
      const username_local = localStorage.getItem('username')
      let userInfo = this.userInfo

      console.info('username_local', username_local)

      if (username_local !== null) {
        userInfo.remmemberUserObj.value = true
        userInfo.inputList[0].value = username_local
      } else {
        userInfo.remmemberUserObj.value = false
      }
    } catch (error) {
      this.$toast({
        color: 'error',
        msg: error,
      })
    }
  },
  methods: {
    async handleLogin() {
      try {
        if (this.isSubmitted_login === true) {
          console.info('请勿重复提交')

          return false
        }

        const toSubmitOptions = await this.$com.getValueByRules(
          this.userInfo.inputList
        )

        //登录
        this.isSubmitted_login = true

        const { res, code, msg } = await this.$ajax({
          apiKey: 'login',
          data: toSubmitOptions,
        })

        if (code === -90003) {
          throw msg
        }

        await this.loginSuccess(0, res)
      } catch (error) {
        this.isSubmitted_login = false
        this.$catchError(error)
      }
    },

    //登录成功的后续操作
    async loginSuccess(loginType, ret_doLogin) {
      try {
        //存储token等基础信息
        const { token, admin } = ret_doLogin
        const _localStorage = localStorage

        token && _localStorage.setItem('token', token)
        _localStorage.setItem('userInfo', ret_doLogin)

        //用户名登录：是否记住用户名
        if (loginType === 0) {
          if (this.userInfo.remmemberUserObj.value === true) {
            _localStorage.setItem('username', this.userInfo.inputList[0].value)
          } else {
            _localStorage.removeItem('username')
          }
        }

        this.$bus_unique.emit('loginSuccess')
        this.$store.commit('saveAdminDetails', admin)

        //回到原来的页面 || 根目录
        // this.$router.replace(_fromPage)
        this.$router.push({
          name: 'home',
        })

        this.$toast({
          color: 'success',
          msg: '登录成功',
        })
      } catch (error) {
        throw error
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.login-modules {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #333744;

  .center-content {
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    .modules-title {
      text-align: center;
      font-size: 2.2rem;
      color: #fff;
    }

    .main-content {
      padding-left: 4rem;
      padding-right: 4rem;
      width: 40.563rem;
      background-color: #ffffff;
      box-shadow: 0rem 0rem 5.625rem 0rem rgba(163, 194, 225, 0.16);

      .loign-title {
        padding-top: 4rem;
        padding-bottom: 2rem;
        font-size: 1.875rem;
        font-weight: bold;
        color: #000000;
      }

      .login-btn {
        text-align: center;
        line-height: 2.875rem;
        font-size: 1.125rem;
        width: 12rem;
        display: block !important;
      }
    }
  }
}
</style>
