<!--
 * @Description: 排课表列表-排课日历
-->
<template>
  <div class="pageBox">
    <div class="col overflow-scroll-y h-100" style="padding: 20px">
      <div style="background: #fff; padding: 20px">
        <!--
          events: 数据源
          回调:
            eventClick: 日历时间点击事件
            change: 日期改变了
            changeType: 切换周/月
        -->
        <Calendar
          v-if="info"
          :info="info"
          @eventClick="fetchEvent"
          @change="changeDateRange"
        >
          <!-- 自定义头部左边内容 -->
          <template slot="header-right">
            <div class="headerRightBox">
              <div class="queryInputBox">
                <el-select
                  v-model="selectType"
                  placeholder="请选择"
                  size="mini"
                >
                  <el-option label="教师" :value="1"></el-option>
                  <el-option label="班级" :value="2"></el-option>
                </el-select>
                <el-input
                  placeholder="请输入搜索内容"
                  v-model="selectInput"
                  class="input-with-select"
                  size="mini"
                >
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="getInfo()"
                  ></el-button>
                </el-input>
              </div>
              <el-button
                type="primary"
                size="mini"
                @click="onAdd"
                v-if="ifOperationButton('arrangingTableItemAdd')"
              >
                新增事件
              </el-button>
              <el-button
                type="success"
                size="mini"
                @click="onExport"
                v-if="ifOperationButton('arrangingTableExport')"
              >
                导出
              </el-button>
            </div>
          </template>
        </Calendar>
      </div>
    </div>
    <!-- 新增编辑对话框 -->
    <el-dialog
      class="mapLocationDialog"
      :title="type ? '编辑' : '新增'"
      top="18vh"
      :visible.sync="addOrEditDialogShow"
      v-if="addOrEditDialogShow"
      width="888px"
      destroy-on-close
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="addOrEditDialogClone"
    >
      <el-form
        class="addOrEditForm"
        inline
        :model="form"
        :rules="formRules"
        ref="formRef"
        label-width="auto"
        label-position="top"
        size="mini"
      >
        <el-form-item label="班级名称：" prop="class_id">
          <el-select
            v-model="form.class_id"
            placeholder="请选择班级名称"
            filterable
            @change="classIdChange"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in classList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班主任：" prop="teacher_id">
          <el-select
            v-model="form.teacher_id"
            placeholder="请选择班主任"
            filterable
            :disabled="!form.class_id"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in teacherList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教室名称：" prop="classroom">
          <el-input
            v-model="form.classroom"
            placeholder="请输入教室名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="助教：" prop="ass_ids">
          <el-select
            v-model="form.ass_ids"
            placeholder="请选择助教"
            filterable
            multiple
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in teacherList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <template>
          <template v-if="type === 0">
            <el-form-item label="选用排课表日期范围：" prop="is_course_date">
              <el-radio v-model="form.is_course_date" :label="1">是</el-radio>
              <el-radio v-model="form.is_course_date" :label="2">否</el-radio>
            </el-form-item>
            <el-form-item
              label="日期范围："
              prop="date"
              v-if="form.is_course_date === 2"
            >
              <el-date-picker
                v-model="form.date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="重复规律：" prop="repeat_type">
              <el-select
                v-model="form.repeat_type"
                placeholder="请选择重复规律"
                @change="repeatTypeChange"
              >
                <el-option label="一次性" :value="1"></el-option>
                <el-option label="每天" :value="2"></el-option>
                <el-option label="每周几" :value="3"></el-option>
                <el-option label="每月几" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="重复时段："
              prop="single_date"
              v-if="form.repeat_type === 1"
            >
              <el-date-picker
                v-model="form.single_date"
                type="date"
                :picker-options="pickerOptions"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="重复时段："
              prop="repeat_num"
              v-if="form.repeat_type === 3 || form.repeat_type === 4"
            >
              <el-select
                v-model="form.repeat_num"
                placeholder="请选择"
                filterable
                multiple
              >
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-for="(item, index) in repeatNumList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="日期：" prop="date">
              <el-date-picker
                v-model="form.date"
                type="date"
                :picker-options="pickerOptions"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </template>
        </template>
        <el-form-item label="开始时间：" prop="begin_time">
          <el-time-picker
            v-model="form.begin_time"
            placeholder="请选择"
            value-format="HH:mm"
            :picker-options="{
              format: 'HH:mm',
            }"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="结束时间：" prop="end_time">
          <el-time-picker
            v-model="form.end_time"
            placeholder="请选择"
            value-format="HH:mm"
            :picker-options="{
              format: 'HH:mm',
            }"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="地点：" prop="address">
          <TxMapLocation
            ref="txMapLocationRef"
            v-model="checkedMap"
            size="mini"
            appendToBody
          />
        </el-form-item>
        <el-form-item label="背景颜色：" prop="background_color">
          <el-color-picker v-model="form.background_color"></el-color-picker>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addOrEditDialogShow = false">取 消</el-button>
        <el-button type="primary" @click="addOrEditDialogSave">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 操作选项对话框 -->
    <centerDialog ref="classOperateSelectDialog">
      <el-radio-group v-model="editSelectType">
        <el-radio
          :label="item.type"
          v-for="(item, index) in editSelectTypeList"
          :key="index"
          style="display: block; margin-bottom: 20px"
        >
          {{ item.remark }}
        </el-radio>
      </el-radio-group>
    </centerDialog>

    <!-- 导出 -->
    <ExportDialog ref="exportDialogRef" />
  </div>
</template>

<script>
import Calendar from "@/components/Calendar";
import TxMapLocation from "@/components/TxMapLocation";
import ExportDialog from "./exportDialog.vue";

export default {
  components: {
    Calendar,
    TxMapLocation,
    ExportDialog,
  },
  data() {
    return {
      query: {
        id: "",
      },
      selectType: 1,
      selectInput: "",
      info: null,
      type: 0, // 新增，编辑
      form: {},
      classList: [],
      teacherList: [],
      repeatNumList: [],
      pickerOptions: {
        // 限制只能在开始和结束周期内选择
        disabledDate: (time) => {
          // 如果没有后面的 -8.64e7 就是不可以选择今天的
          const beginDate = new Date(this.info.begin_date).getTime() - 8.64e7;
          const endDate = new Date(this.info.end_date).getTime();
          return beginDate > time.getTime() || endDate < time.getTime();
        },
      },
      formRules: {
        class_id: [
          { required: true, message: "请选择班级名称", trigger: "change" },
        ],
        teacher_id: [
          { required: true, message: "请选择班主任", trigger: "change" },
        ],
        date: [{ required: true, message: "请选择日期", trigger: "change" }],
        repeat_type: [
          { required: true, message: "请选择重复规律", trigger: "change" },
        ],
        // ass_ids: [{ required: true, message: "请选择助教", trigger: "change" }],
        repeat_num: [
          { required: true, message: "请选择重复规律", trigger: "change" },
        ],
        single_date: [
          { required: true, message: "请选择重复时段", trigger: "change" },
        ],
        begin_time: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        end_time: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
      },
      editId: null,
      editSelectTypeList: [],
      editSelectType: null,
      // 地图
      checkedMap: {},
      addOrEditDialogShow: false,
    };
  },
  created() {
    let { id } = this.$route.query;
    this.query.id = id;
    this.getInfo();
    this.getclassList();
    this.getteacherList();
  },
  mounted() {
    //监听：编辑
    this.$bus_unique.on(
      "arrangingTableShowAddOrEdit",
      "arrangingTableShow",
      async (id) => {
        console.log("编辑", id);
        this.editId = id;

        try {
          const { res } = await this.$ajax({
            apiKey: "courseclass_info",
            data: {
              class_id: this.editId,
            },
          });
          console.log("获取数据-编辑", res);
          delete res.deleted_at;
          this.checkedMap.longitude = res.lng;
          this.checkedMap.latitude = res.lat;
          this.checkedMap.title = res.address;
          this.form = res;

          this.type = 1;
          this.addOrEditDialogShow = true;
        } catch (error) {
          this.$catchError(error);
        }
      }
    );
    //监听：删除
    this.$bus_unique.on(
      "arrangingTableShowDel",
      "arrangingTableShow",
      async (id) => {
        console.log("删除", id);
        this.editId = id;
        await this.ifEventSelect(2);
      }
    );
  },
  methods: {
    // 获取详情
    async getInfo() {
      try {
        if (this.selectInput) {
          switch (this.selectType) {
            case 1:
              this.query.search_field = "teacher_name";
              break;

            default:
              this.query.search_field = "class_name";
              break;
          }
          this.query.search_value = this.selectInput;
        } else {
          delete this.query.search_field;
          delete this.query.search_value;
        }
        console.log("this.query", this.query);

        let { res } = await this.$ajax({
          apiKey: "courseinfo",
          data: this.query,
        });
        console.log("获取详情", res);
        this.info = res;
      } catch (error) {
        this.$catchError(error);
      }
    },
    // 获取班级列表
    async getclassList() {
      try {
        let { res } = await this.$ajax({
          apiKey: "getClassList",
          data: {
            paginate: 100,
            status: 1,
          },
        });
        console.log("获取班级列表", res);
        this.classList = res.data;
      } catch (error) {
        this.$catchError(error);
      }
    },
    // 获取老师列表
    async getteacherList() {
      try {
        let { res } = await this.$ajax({
          apiKey: "teacherServiceList",
          data: {
            paginate: 100,
            status: 1,
          },
        });
        console.log("获取老师列表", res);
        this.teacherList = res.data;
      } catch (error) {
        this.$catchError(error);
      }
    },

    fetchEvent(event, jsEvent) {
      console.log("event", event);
      console.log("jsEvent", jsEvent);
    },
    changeDateRange(start, end, currentStart, weekDays) {
      console.log("改变了日期");
      console.log("start", start);
      console.log("end", end);
      console.log("currentStart", currentStart);
      console.log("weekDays", weekDays);
    },

    // 新增/编辑
    async addOrEditDialogSave() {
      console.log("form", this.form);
      let validate = await this.$refs.formRef.validate();
      // console.log('validate', validate)
      if (!validate) throw false;
      console.log("ok");

      try {
        console.log("checkedMap", this.checkedMap);
        this.form.address = this.checkedMap.title || "";
        this.form.lat = this.checkedMap.latitude || "";
        this.form.lng = this.checkedMap.longitude || "";
        this.form.ass_ids = JSON.stringify(this.form.ass_ids);

        if (this.type) {
          await this.ifEventSelect(1);
        } else {
          if (this.form.date.length) {
            this.form.begin_date = this.form.date[0];
            this.form.end_date = this.form.date[1];
          }
          this.form.repeat_num.length &&
            (this.form.repeat_num = JSON.stringify(this.form.repeat_num));
          this.form.course_id = this.info.id;

          // 新增
          const { msg } = await this.$ajax({
            apiKey: "courseEvent_save",
            data: this.form,
          });
          this.$toast({
            msg,
          });
        }

        console.log("wc");

        this.getInfo();
        this.$refs.formRef.resetFields();
        this.checkedMap = {};
        this.addOrEditDialogShow = false;
      } catch (error) {
        this.$catchError(error);
        throw false;
      }
    },
    addOrEditDialogClone() {
      this.$refs.formRef.resetFields();
      this.checkedMap = {};
    },

    // 判断是否是单个事件还是多个
    ifEventSelect(eventType) {
      return new Promise(async (resolve, reject) => {
        try {
          this.editSelectTypeList = [];
          this.editSelectType = null;

          let { res } = await this.$ajax({
            apiKey: "courseclass_operate_select",
            data: {
              id: this.editId,
            },
          });
          console.log("获取操作选项", res);
          if (res.length) {
            // 多个
            this.editSelectTypeList = res;
            this.editSelectType = res[0].type;
            this.$refs.classOperateSelectDialog.render({
              title: "操作选项",
              width: "600px",
              ok: async () => {
                await this.delOrEditFun(eventType);
                resolve();
              },
              cancel: () => {
                reject("取消操作");
              },
            });
          } else {
            // 单个
            await this.delOrEditFun(eventType);
            resolve();
          }
        } catch (error) {
          this.$catchError(error);
          reject(error);
        }
      });
    },
    async delOrEditFun(eventType) {
      if (eventType === 2) {
        /* 删除 */
        await this.delEvent();
      } else {
        /* 编辑 */
        await this.editEvent();
      }
    },
    // 删除
    async delEvent() {
      this.$confirm("是否删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let data = { id: this.editId };
          this.editSelectType && (data.type = this.editSelectType);
          let { msg } = await this.$ajax({
            apiKey: "courseclass_del",
            data,
          });
          this.$toast({
            msg,
          });
          this.getInfo();
        } catch (error) {
          this.$catchError(error);
        }
      });
    },
    // 编辑
    editEvent() {
      return new Promise(async (resolve, reject) => {
        try {
          console.log("编辑", this.form);
          this.editSelectType && (this.form.type = this.editSelectType);
          const { msg } = await this.$ajax({
            apiKey: "courseclass_edit",
            data: {
              ...this.form,
            },
          });
          this.$toast({
            msg,
          });
          resolve();
        } catch (error) {
          this.$catchError(error);
          reject(error);
        }
      });
    },

    // 新增事件
    onAdd() {
      this.form = {
        is_course_date: 1,
        begin_date: "",
        end_date: "",
        repeat_type: 1,
        single_date: "",
        repeat_num: [],
        class_id: "",
        teacher_id: "",
        classroom: "",
        ass_ids: [],
        date: [],
        begin_time: "",
        end_time: "",
        background_color: "#62aef3",
        lng: "",
        lat: "",
        address: "",
        remark: "",
      };
      this.checkedMap = {};

      this.type = 0;
      this.addOrEditDialogShow = true;
    },

    // 导出
    async onExport() {
      try {
        console.log("info", this.info);
        this.$refs.exportDialogRef.showDialog(this.info);
      } catch (error) {
        this.$catchError(error);
      }
    },

    // 选择班级
    classIdChange(e) {
      // console.log('选择班级', e)
      let item = this.classList.filter((i) => {
        return i.id == e;
      });
      // console.log('item', item)
      this.form.teacher_id = item[0].teacher_id;
    },
    // 选择重复规律
    repeatTypeChange(e) {
      // console.log('选择重复规律', e)
      this.form.repeat_num = "";

      let repeatNumList = [];
      if (e === 3) {
        /* 星期 */
        repeatNumList = [
          {
            label: "周一",
            value: 1,
          },
          {
            label: "周二",
            value: 2,
          },
          {
            label: "周三",
            value: 3,
          },
          {
            label: "周四",
            value: 4,
          },
          {
            label: "周五",
            value: 5,
          },
          {
            label: "周六",
            value: 6,
          },
          {
            label: "周日",
            value: 7,
          },
        ];
      } else if (e === 4) {
        /* 月 */
        for (let i = 0; i < 31; i++) {
          repeatNumList.push({
            value: i + 1,
            label: i + 1 + "日",
          });
        }
      }
      this.repeatNumList = repeatNumList;
    },

    // 权限按钮判断
    ifOperationButton(authenKey) {
      const getters = this.$store.getters;
      const getAuthenIsPermitted = getters.getAuthenIsPermitted;
      const isGetAuthen = getAuthenIsPermitted(authenKey);
      // console.log('isGetAuthen', isGetAuthen)
      return isGetAuthen;
    },
  },
};
</script>

<style lang="scss" scoped>
.pageBox {
  margin: -1.25rem;
  position: relative;
  height: calc(100% + 2.5rem);
}

::v-deep .addOrEditForm {
  .el-form-item__label {
    padding: 0;
  }
  .el-form-item {
    margin-right: 26px;
  }
}

::v-deep .queryInputBox {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;

  .el-select {
    width: 80px;
    .el-input__inner {
      border-right: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .input-with-select {
    width: 200px;

    .el-input__inner {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

.headerRightBox {
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
}
</style>
