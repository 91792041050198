<!--
 * @Date         : 2020-11-30 17:04:52
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-05 16:02:40
 * @FilePath     : \leXue_manage_pc\src\views\class\ableAddToClassStudentList.vue
-->

<template>
  <mainContentDiv class="h-100">
    <centerDialog ref="centerDialog">
      <div class="overflow-hidden">
        <div
          class="d-flex flex-wrap"
          style="margin-right: -0.625rem"
          v-if="initOptions_input"
        >
          <div
            class="col-6"
            v-for="(item, index) in initOptions_input.inputList"
            :key="index"
          >
            <div class="mr-10 mb-10">
              <div
                class="my-5 com-title"
                v-if="item.label"
                :class="{
                  active:
                    item.rules &&
                    item.rules.find((item) => item === 'required'),
                }"
              >
                {{ item.label }}
              </div>
              <!-- 输入框 -->
              <textFile v-if="item.type === 'input'" :initOptions="item">
              </textFile>
            </div>
          </div>
        </div>
      </div>
    </centerDialog>

    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      tableOptions: null,
      initOptions_input: null,
      dialogVisible: false,
    }
  },
  mounted() {
    this.tableOptions = this.getTableOptions()
  },

  methods: {
    getTableOptions() {
      const _tempValObj = {}

      const tableOptions = {
        authenKey: 'class_canJoinClass',
        search: {
          inputList: [
            {
              type: 'input',
              label: '学生名称',
              placeholder: '',
              value: '',
              props: 'student_name',
              rules: ['!null'],
            },
            (_tempValObj.school = {
              type: 'select',
              label: '所在学校',
              placeholder: '请搜索/选择所在学校',
              rules: ['!null'],
              value: [],
              id: '',
              multiple: true, //多选
              props: 'school_json_name',
              props_id: 'school_json',
              col: 'col-3',
              options: [],
              remoteMethod: async (keyword) => {
                try {
                  const {
                    res: { data },
                  } = await this.$ajax({
                    apiKey: 'getSchoolList',
                    data: {
                      name: keyword || '', //name	否	string	学校名称
                    },
                  })

                  _tempValObj.school.options = data.map((item) => {
                    const { id, name } = item

                    return {
                      label: name,
                      value: id,
                    }
                  })
                } catch (error) {
                  this.$catchError(error)
                }
              },
              onChange: (valList, thisObj) => {
                console.info('valList', valList)
                thisObj.id = JSON.stringify(valList)
              },
            }),
            //选择学校年级
            (_tempValObj.productDetails = {
              type: 'cascader',
              expandTrigger: 'hover',
              label: '学校年级',
              placeholder: '请选择学校年级',
              cascaderProps: {
                multiple: true,
              },
              rules: ['!null'],
              value: [],
              id: '',
              props: 'grade_json_name',
              props_id: 'grade_json',
              col: 'col-3',
              options: [
                {
                  value: '小学',
                  label: '小学',
                  children: [
                    {
                      value: '一年级',
                      label: '一年级',
                    },
                    {
                      value: '二年级',
                      label: '二年级',
                    },
                    {
                      value: '三年级',
                      label: '三年级',
                    },
                    {
                      value: '四年级',
                      label: '四年级',
                    },
                    {
                      value: '五年级',
                      label: '五年级',
                    },
                    {
                      value: '六年级',
                      label: '六年级',
                    },
                  ],
                },
                {
                  value: '初中',
                  label: '初中',
                  children: [
                    {
                      value: '初一',
                      label: '初一',
                    },
                    {
                      value: '初二',
                      label: '初二',
                    },
                    {
                      value: '初三',
                      label: '初三',
                    },
                  ],
                },
                {
                  value: '高中',
                  label: '高中',
                  children: [
                    {
                      value: '高一',
                      label: '高一',
                    },
                    {
                      value: '高二',
                      label: '高二',
                    },
                    {
                      value: '高三',
                      label: '高三',
                    },
                  ],
                },
              ],
              key: 0,
              onChange: (newValList, thisObj) => {
                console.info('newValList', newValList)

                const allSelectedGradeList = []

                newValList.forEach((item) => {
                  item.forEach((item, index) => {
                    if (index === 1) {
                      allSelectedGradeList.push(item)
                    }
                  })
                })

                console.info('allSelectedGradeList', allSelectedGradeList)

                thisObj.id = JSON.stringify(allSelectedGradeList)
              },
            }),
            //选择产品
            (_tempValObj.product = {
              type: 'select',
              label: '产品名称',
              placeholder: '请搜索/选择产品',
              rules: ['!null'],
              value: '',
              props: 'pro_name',
              col: 'col-3',
              options: [],
              remoteMethod: async (keyword) => {
                try {
                  console.info('keyword', keyword)

                  const {
                    res: { data },
                  } = await this.$ajax({
                    apiKey: 'getProdutList',
                    data: {
                      name: keyword || '',
                    },
                  })

                  _tempValObj.product.options = data.map((item) => {
                    const { id, name, type_name } = item

                    return {
                      label: name,
                      value: name,
                      typeName: type_name,
                    }
                  })
                } catch (error) {
                  this.$catchError(error)
                }
              },
              onChange: async (val, thisObj) => {
                try {
                  const { options, value } = thisObj
                  const { product, productDetails } = _tempValObj

                  const currentOpt = options.find(
                    (item) => item.value === value
                  )

                  if (!currentOpt) {
                    return false
                  }

                  //设置产品id
                  product.id = currentOpt.value
                } catch (error) {
                  this.$catchError(error)
                }
              },
            }),
          ],
        },
        headers: [
          { label: '学生名字', props: 'student_name' },
          { label: '所在学校', props: 'school_name' },
          { label: '所在年级', props: 'grade_name' },
          { label: '产品名称', props: '_buyyedProduct' },
          { label: '产品规格', props: '_specificationDetails' },
          { label: '订单编号', props: 'order_id' },
          { label: '订单类型', props: 'order_type_str' },
        ],
        titleBtns: [
          {
            type: 'filter',
            title: '查询',
          },
        ],
        select: {
          type: 'mutiple',
          onChange: (val) => {
            this.selectedTrList = val
          },
        },
        getData: async (options) => {
          const { id } = this.initOptions

          const ret = await this.$ajax({
            apiKey: 'getAbleAddToClassStudentList',
            data: {
              ...options,
              cid: id, //cid	是	int	班级id
            },
          })
          console.log('ret', ret)

          for (let i of ret.res.data) {
            i._specificationDetails = `${i.classify_name} / ${i.grade_name} / ${i.spec_name} / ${i.attr_name}`
            i._buyyedProduct = `${i.pro_name}`
          }

          return ret
        },
      }

      _tempValObj.school.remoteMethod()

      return tableOptions
    },
  },
}
</script>
