<template>
  <mainContentDiv v-if="initOptions_inputCenter">
    <div class="w-50 pl-5 pt-5 mt-30 mb-40 mx-40">
      <inputCenter
        ref="inputCenter"
        :initOptions="initOptions_inputCenter"
      ></inputCenter>
      <comBtn
        class="mr-20"
        color="cyan"
        v-if="submitBtnAuthen !== null"
        @click="handleSaveData()"
        >{{ submitBtnAuthen.title }}</comBtn
      >
    </div>

    <!-- 弹窗：权限列表 -->
    <centerDialog ref="authenListDialog">
      <authenList
        style="height: 60vh"
        :isSelect="true"
        @change="openDialogObj && openDialogObj.onChange(openDialogObj, $event)"
      ></authenList>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
import authenList from '@/views/authen/authenList.vue'

export default {
  components: {
    authenList,
  },
  data() {
    return {
      initOptions_inputCenter: null,
      pageType: null,
      openDialogObj: null,
    }
  },
  async mounted() {
    this.init()
  },
  methods: {
    async init() {
      try {
        let inputList = await this.getNativeTemplateinputCenter()
        let { pageType, id: adminID } = this.$route.query
        let initOptions_inputCenter = null

        this.pageType = pageType = Number(pageType)

        //0：新增 1：编辑 2：查看
        if (pageType === 0) {
          initOptions_inputCenter = {
            inputList,
          }
        } else if (pageType === 1 || pageType === 2) {
          if (!adminID) throw '参数有误 adminID'

          const { res: userDetails } = await this.$ajax({
            apiKey: 'getAdminDetailsByID',
            data: {
              id: adminID,
            },
          })

          initOptions_inputCenter = {
            inputList,
            valueListObj: userDetails,
          }

          if (pageType === 2) {
            initOptions_inputCenter.inputList.forEach((item) => {
              item.readonly = true

              if (item.onTap) {
                delete item.onTap
              }
            })
          }
        }

        this.initOptions_inputCenter = initOptions_inputCenter
      } catch (error) {
        this.$toast({
          color: 'error',
          msg: error,
        })
      }
    },
    async handleSaveData() {
      try {
        let requestOptions = await this.$com.getValueByRules(
          this.initOptions_inputCenter.inputList
        )

        console.info('requestOptions', requestOptions)

        let { pageType, id } = this.$route.query
        pageType = Number(pageType)

        if (pageType === 2) return false //只有查看的功能

        let apiKey

        switch (
          pageType //0: 新增管理员 1：编辑管理员
        ) {
          case 0:
            apiKey = 'addAdmin'
            break
          case 1:
            apiKey = 'editAdmin'
            requestOptions.id = id
            break
        }

        //提交
        const { msg } = await this.$ajax({
          responseAll: true,
          apiKey: apiKey,
          data: requestOptions,
        })

        if (pageType === 1) {
          this.checkIsEditMyUserInfo(id) //特殊操作：编辑后检查当前编辑的guyan是不是自己
        }

        this.$bus_unique.emit('updateAdminList')
        this.$router.back(-1)

        this.$toast({
          color: 'success',
          msg,
        })
      } catch (error) {
        this.$toast({
          color: 'error',
          msg: error,
        })
      }
    },

    //检查是不是编辑了我的信息
    checkIsEditMyUserInfo(currentEditUserID) {
      const userInfo =
        this.currentEditUserID ||
        (this.currentEditUserID = this.$store.getters.getUserInfoByStore)

      const userID = userInfo.admin_id

      console.info('userID', userID)
      console.info('currentEditUserID', currentEditUserID)

      if (Number(currentEditUserID) === Number(userID)) {
        this.$store.dispatch('getRoleAuthen', this)
      }
    },

    handleSelectedAuthen(selectedAuthen) {
      const authenObj = this.initOptions_inputCenter.inputList.find(
        (item) => item.ref === 'roleID'
      )
      authenObj.onChange(authenObj, selectedAuthen)
    },

    //原始列表数据
    getNativeTemplateinputCenter() {
      return [
        {
          type: 'headerImg',
          label: '头像',
          placeholder: '请上传管理员头像',
          value: '',
          //   defaultVal: "storage/20200812/5f33b6d6ec3b2.png",
          rules: ['!null'],
          props: 'avatar',
        },
        {
          ref: 'name',
          type: 'input',
          label: '管理员名称',
          placeholder: '请输入管理员名称',
          maxLength: 50,
          rules: ['required', '!null'],
          value: null,
          props: 'name',
        },
        // {
        //   ref: 'password',
        //   type: 'input',
        //   label: '密码',
        //   placeholder: '请输入密码',
        //   maxLength: 10,
        //   value: 123456,
        //   defaultVal: 123456,
        //   rules: ['required', '!null'],
        //   props: 'password',
        // },
        {
          ref: 'mobile',
          type: 'input',
          label: '手机号',
          placeholder: '请输入手机号',
          maxLength: 11,
          value: '',
          rules: ['required', 'mobile'],
          props: 'mobile',
        },
        {
          ref: 'roleID',
          type: 'input',
          label: '选择角色',
          placeholder: '请选择角色',
          readonly: true,
          rules: ['required', '!null'],
          value: null,
          props: 'role_name',
          props_id: 'role_id',
          onTap: (roleInfo) => {
            console.info('roleInfo', roleInfo)

            this.openDialogObj = roleInfo //临时变量
            this.$refs.authenListDialog.render({
              title: '选择角色',
              width: '70%',
            })
          },
          onChange: (roleInfo, selectedAuthen) => {
            roleInfo.value = selectedAuthen.name
            roleInfo.id = selectedAuthen.id

            this.$refs.authenListDialog.hide()
          },
        },
        {
          type: 'radio',
          label: '是否查看所有订单',
          placeholder: '',
          rules: ['!null'],
          value: 2,
          props: 'is_all_order',
          options: [
            {
              label: '是',
              value: 1,
            },
            {
              label: '否',
              value: 2,
            },
          ],
          col: 'col-6',
        },
        {
          type: 'radio',
          label: '是否查看所有充值单',
          placeholder: '',
          rules: ['!null'],
          value: 2,
          props: 'is_all_recharge',
          options: [
            {
              label: '是',
              value: 1,
            },
            {
              label: '否',
              value: 2,
            },
          ],
          col: 'col-6',
        },
      ]
    },
  },
  computed: {
    submitBtnAuthen() {
      const pageType = this.pageType
      const getAuthenIsPermitted = this.$store.getters.getAuthenIsPermitted

      let submitBtnAuthen = null

      switch (pageType) {
        case 0:
          if (getAuthenIsPermitted('admin_add') === true) {
            submitBtnAuthen = {
              title: '新增',
            }
          }
          break
        case 1:
          if (getAuthenIsPermitted('admin_edit') === true) {
            submitBtnAuthen = {
              title: '保存编辑',
            }
          }
          break
      }

      return submitBtnAuthen
    },
  },
}
</script>
