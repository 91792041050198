<!--
 * @Date         : 2021-02-19 17:33:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-12 14:55:26
 * @FilePath     : \leXue_manage_pc\src\views\other\data_final.vue
-->
<template>
  <dataBoardBox title="收入与退款总览">
    <div class="mr-20" slot="rightTopBox">
      <el-date-picker
        v-model="currentDate"
        type="year"
        placeholder="选择年"
        format="yyyy年"
        @change="handleSwitchYear"
      >
      </el-date-picker>
    </div>
    <div class="d-flex align-items-center">
      <div></div>
    </div>
    <div ref="chart" style="height: 400px"></div>
  </dataBoardBox>
</template>

<script>
import echarts from "echarts";
import dataBoardBox from "@/views/other/dataBoardBox.vue";

export default {
  components: {
    dataBoardBox,
  },
  data() {
    return {
      currentDate: new Date(),
    };
  },
  beforeDestroy() {
    this.myChart.dispose();
  },
  mounted() {
    this.myChart = echarts.init(this.$refs.chart);
    this.getIncomeAndRefundData();
  },
  methods: {
    async getIncomeAndRefundData(year = "") {
      try {
        const toSubmitOptions = {};

        year && (toSubmitOptions.year = year);

        const { res } = await this.$ajax({
          apiKey: "getDataStatistics_incomeOutcome",
          data: toSubmitOptions,
        });

        this.renderChart(res); //渲染柱状图
      } catch (error) {
        this.$catchError(error);
      }
    },
    handleSwitchYear($event) {
      const currentYear = new Date($event).getFullYear();

      // console.info("currentYear", currentYear);

      this.getIncomeAndRefundData(currentYear);
    },

    //生成表格
    renderChart({ income_data, refund_data }) {
      var option = {
        color: ["#ff0000", "#0084ff"],
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: income_data.map((item) => Number(Object.keys(item)[0]) + "月"),
        },
        yAxis: {
          type: "value",
          
        },
        series: [
          {
            data: income_data.map((item) => Number(Object.values(item)[0])),
            type: "line",
            areaStyle: {},
          },
          {
            data: refund_data.map((item) => Number(Object.values(item)[0])),
            type: "line",
            areaStyle: {},
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      };

      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>