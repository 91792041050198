<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-02 17:44:15
 * @FilePath     : \leXue_manage_pc\src\components\wangEditor.vue
 
  富文本编辑器

  插件手册：https://www.kancloud.cn/wangfupeng/wangeditor3/335782

  使用注意事项: 上传图片需要配置服务器地址

  使用方式：
  <wangEditor
    class="position-relative"
    style="z-index: 1; background-color: #fff"
    ref="editorElem_charact"
  />

  import wangEditor from "@/components/wangEditor.vue";

  components: {
    wangEditor,
  },


  回显赋值：
  this.$refs.editorElem_charact.inputValue(charact);
  
  提取数据：
  this.$refs.editorElem_charact.getEditorData();

-->
<template>
  <div>
    <div id="wangeditor">
      <div ref="editorElem" style="text-align: left"></div>
    </div>
  </div>
</template>
<script>

import E from "wangeditor";
import appConfig from '@/assets/js/appConfig.js'; //配置项（域名等）

export default {
  name: "editorElem",
  data() {
    return {
      editor: null,
      editorContent: "",
    };
  },
  mounted() {
    let editor = (this.editor = new E(this.$refs.editorElem));

    editor.customConfig.menus = [
      // 菜单配置
      "head", // 标题
      "bold", // 粗体
      "fontSize", // 字号
      "fontName", // 字体
      "italic", // 斜体
      "underline", // 下划线
      "strikeThrough", // 删除线
      "foreColor", // 文字颜色
      "backColor", // 背景颜色
      "link", // 插入链接
      "list", // 列表
      "justify", // 对齐方式
      "quote", // 引用
      "emoticon", // 表情
      "image", // 插入图片
      "table", // 表格
      "code", // 插入代码
      "undo", // 撤销
      "redo", // 重复,
    ];

    // 注意：上传图片要配置好接口，并且返回的格式有要求，具体看顶部的插件开发手册
    editor.customConfig.showLinkImg = false;
    editor.customConfig.uploadImgServer = appConfig.uploadImgServerUrl; // 上传图片的接口地址
    editor.customConfig.uploadFileName = "file"; // formdata中的name属性
    editor.customConfig.debug = true; // 开启debug模式
    editor.customConfig.uploadImgHeaders = {
      token: localStorage.getItem("token"), //设置请求头
    };
    
    editor.customConfig.uploadImgHooks = {
      // 图片上传并返回结果，但图片插入错误时触发
      fail: function (xhr, editor, result) {
        const { msg } = result;

        this.$catchError(msg);
      },
      success: function (xhr, editor, result) {
        // 图片上传并返回结果，图片插入成功之后触发
        console.info("success", result);
      },
    };

    editor.create(); // 创建富文本实例
    editor.txt.html("");

    const initOption = this.initOption;

    if (initOption && initOption.value) {
      editor.txt.html(initOption.value);
    }

    // this.initWangEditorToolIndex(); //特殊操作：解决移动端富文本工具换行之后，工具栏详情被工具栏子项遮挡到的问题
  },
  methods: {
    inputValue(newValue) {
      this.editor.txt.html(newValue);
    },
    getEditorData() {
      let editor = this.editor;
      let editorData = editor.txt.html();

      console.info("editor.txt.html()", editorData);

      if (!editorData.replace("<p><br></p>", "")) {
        editorData = "";
      }

      return editorData;
    },
  },
};
</script>
<style>
.w-e-panel-container {
  width: 300px !important;
  margin-left: -150px !important;
}
.w-e-toolbar {
  flex-wrap: wrap !important;
}
</style>