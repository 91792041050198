<!--
 * @Date         : 2021-03-15 16:06:42
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-18 18:00:21
 * @FilePath     : \leXue_manage_pc\src\views\order\recordListModules.vue
-->
<template>
  <div>
    <infoWrapper
      class="my-10"
      title="信息记录"
      v-if="inputList_record && (pageType === 0 || pageType === 1)"
    >
      <div class="pa-20 w-75">
        <inputCenter :initOptions="inputList_record"></inputCenter>
      </div>
    </infoWrapper>

    <infoWrapper class="my-10 pa-20" v-if="isRenderRecordedTable">
      <div style="height: 200px">
        <tableList
          ref="tableList"
          v-if="tableOptions"
          :initOptions="tableOptions"
        ></tableList>
      </div>
    </infoWrapper>
  </div>
</template>

<script>
import infoWrapper from "@/components/infoWrapper.vue";

export default {
  components: {
    infoWrapper,
  },
  props: {
    pageType: {
      type: Number, //0：新增 1：编辑
    },
  },
  data() {
    return {
      inputList_record: null,
      tableOptions: null,
      isRenderRecordedTable: false,
    };
  },
  mounted() {
    this.inputList_record = {
      inputList: this.getInputList_record(),
    };
  },
  methods: {
    //获取：学生信息
    getInputList_record() {
      return [
        {
          type: "input",
          label: "新增信息记录",
          placeholder: "请输入新增信息记录",
          maxLength: 100,
          rules: ["!null"],
          value: null,
          props: "record",
          col: "col-6",
        },
      ];
    },

    getRecordedInfo() {
      let { inputList } = this.inputList_record;

      return this.$com.getValueByRules(inputList);
    },

    // 输入已记录的列表
    inputRecordedList(recordedList) {
      this.tableOptions = this.getTableOptions(recordedList);

      if (recordedList.length > 0) {
        this.isRenderRecordedTable = true;
      }
    },
    getTableOptions(dataList) {
      const tableOptions = {
        title: "已记录的信息",
        authenKey: true,
        headers: [
          { label: "记录内容", props: "content" },
          { label: "创建时间", props: "time" },
          { label: "操作人", props: "operator" },
        ],
        hidePaginate: true,
        getData: async (options) => {
          return {
            code: 1,
            res: {
              data: dataList,
            },
          };
        },
      };

      return tableOptions;
    },
  },
  computed: {
    // recordBtnList() {
    //   return [
    //     {
    //       title: "新增",
    //       onTap: () => {
    //         let { inputList } = this.inputList_record;
    //         inputList.push(this.getInputList_record()[0]);
    //       },
    //     },
    //   ];
    // },
  },
};
</script>

<style lang="scss" scoped>
</style>