<!--
 * @Description: 学生卡片
-->
<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="990px"
      :title="infoItem.name + '的卡片'"
      @close="dialogClose"
    >
      <mainContentDiv class="pa-5" style="height: 400px">
        <tableList
          ref="tableList"
          v-if="dialogVisible && tableOptions"
          :initOptions="tableOptions"
        ></tableList>
        <centerDialog ref="centerDialog">
          <inputCenter :initOptions="initOptions_input"></inputCenter>
        </centerDialog>
      </mainContentDiv>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      infoItem: {},
      cardItem: {},
      tableOptions: null,
      initOptions_input: null,
    };
  },
  created() {},
  mounted() {},
  methods: {
    async _show(item) {
      console.log("item", item);
      this.infoItem = item;
      this.dialogVisible = true;

      this.tableOptions = this.getTableOptions();
    },

    dialogClose() {},

    // 弹出新增/编辑
    async renderEditDialog({ type, inputedVal }) {
      console.log("type", type);

      try {
        let inputOptions = (this.initOptions_input = {
          inputList: this.getInputRechargeLevel(type),
        });

        if (inputedVal) {
          this.$com.fillData({
            inputList: this.initOptions_input.inputList,
            valueListObj: inputedVal,
          });
        }

        this.$refs.centerDialog.render({
          title: type === 0 ? "新增" : "编辑",
          width: "50%",
          verifyInputListIsChange: inputOptions.inputList,
          ok: async () => {
            try {
              const { inputList } = inputOptions;

              let requestOpt = this.$com.getValueByRules(inputList);
              console.log("requestOpt", requestOpt);

              console.log("this.cardItem", this.cardItem);

              requestOpt = {
                ...requestOpt,
                student_id: this.infoItem.id,
                card_code: this.cardItem.value,
                card_number: this.cardItem.label,
                id: this.cardItem.cardId,
              };
              console.log("requestOpt", requestOpt);

              // 新增/编辑
              const { msg } = await this.$ajax({
                apiKey: "studentCardSave",
                data: requestOpt,
              });

              this.$toast({
                msg,
              });

              this.$refs.tableList.init();
            } catch (error) {
              throw error;
            }
          },
          cancel: () => {},
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    //获取输入配置表
    getInputRechargeLevel(type) {
      const _tempValObj = {};

      let getInputRechargeLevel = [
        (_tempValObj.card_code = {
          type: "select",
          label: "卡片",
          placeholder: "请选择卡片",
          rules: ["required", "!null"],
          value: "",
          id: "",
          props: "card_code",
          props_id: "card_code",
          options: [],
          disabled: type === 1,
          remoteMethod: async (keyword) => {
            try {
              const {
                res: { data },
              } = await this.$ajax({
                apiKey: "studentCardList",
                data: {
                  student_id: 0,
                  page: 1,
                  paginate: 30,
                },
              });

              const thisObj = _tempValObj.card_code;
              thisObj.options = data.map((item) => {
                const { id, card_code, card_number } = item;

                return {
                  cardId: id,
                  label: card_number || "未编辑卡号-" + card_code,
                  value: card_code,
                };
              });
            } catch (error) {
              this.$catchError(error);
            }
          },
          onChange: (val, thisObj) => {
            console.log("val, thisObj", val, thisObj);
            thisObj.id = val;

            let item = thisObj.options.find((item) => item.value === val);
            console.log("item", item);
            this.cardItem = item;
          },
          onFocus: (event, thisObj) => {
            //如果选择的数据非法，则使用默认获取的所有options
            this.tryReInputAllSelectOptions(thisObj);
          },
        }),
      ];

      return getInputRechargeLevel;
    },

    getTableOptions() {
      return {
        authenKey: "StudentCard",
        search: {
          inputList: [],
        },
        headers: [
          { label: "卡片编码", props: "card_code" },
          { label: "卡号", props: "card_number" },
        ],
        titleBtns: [
          {
            title: "新增",
            authenKey: "StudentCard",
            onTap: () => {
              this.renderEditDialog({
                type: 0,
              });
            },
          },
        ],
        actionBtns: [
          // {
          //   title: "编辑",
          //   color: "deepBlue",
          //   authenKey: "StudentCard",
          //   onTap: (options) => {
          //     this.renderEditDialog({
          //       type: 1,
          //       inputedVal: options,
          //     });
          //   },
          // },
          {
            title: "解绑",
            color: "red",
            authenKey: "StudentCard",
            onDelete: async (options) => {
              console.log("options", options);
              await this.$ajax({
                apiKey: "studentCardSave",
                data: {
                  student_id: 0,
                  bind_time: '',
                  card_code: options.card_code,
                  card_number: options.card_number,
                  id: options.id,
                },
              });

              return {
                msg: "解绑成功",
              };
            },
          },
        ],
        getData: async (options) => {
          console.log("获取列表", options);
          // 获取列表
          let ret = await this.$ajax({
            apiKey: "studentCardList",
            data: {
              student_id: this.infoItem.id,
              ...options,
            },
          });

          return ret;
        },
      };
    },

    tryReInputAllSelectOptions(thisObj) {
      const { options, value, allOptions } = thisObj;

      //如果选择的数据非法，则使用默认获取的所有options
      if (!value || options.length === 0) {
        options.length = 0;

        thisObj.remoteMethod();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .fileInfoFormDialogBox {
  .el-dialog__body {
    background: #fff !important;
    padding: 0 30px 10px;

    .el-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .el-form-item {
        width: 48%;
        margin-right: 0;

        .formItemLabelDiv {
          font-size: 12px;
          color: #999;
          margin-top: -12px;
          margin-bottom: 4px;
        }
      }
    }

    .el-form--label-top .el-form-item__label {
      padding-bottom: 2px !important;
    }

    .el-select {
      width: 100%;
    }
  }

  .el-dialog__footer {
    background: #eee;
    padding: 20px;
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.multipleLinesBox {
  .multipleLines_item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    i {
      margin-left: 10px;
      font-size: 20px;
      color: red;
      cursor: pointer;
    }
  }
}
</style>
