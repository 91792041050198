<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-26 14:34:19
 * @FilePath     : \leXue_manage_pc\src\views\authen\authenList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
    };
  },
  props: {
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  destroyed() {
    this.$bus_unique.off("updateAuthenList");
  },
  mounted() {
    this.tableOptions = this.getTableOptions();

    this.$bus_unique.on("updateAuthenList", "authenList", () => {
      this.$refs.tableList.init();
    });
  },
  methods: {
    getTableOptions() {
      const { role_id: myRoleID } = this.$store.getters.getUserInfoByStore;
      const isSelect = this.isSelect; //选择模式

      let configureOptions;

      if (isSelect === true) {
        configureOptions = {
          actionBtns: [
            {
              title: "选择",
              color: "cyan",
              isRender: true,
              onTap: (options) => {
                this.$emit("change", options);
              },
            },
          ],
        };
      } else {
        configureOptions = {
          titleBtns: [
            {
              type: "filter",
              title: "查询",
            },
            {
              title: "新增角色",
              authenKey: "role_add",
              onTap: () => {
                this.$router.push({
                  name: "addOrEditAuthen",
                  query: {
                    pageType: 0,
                  },
                });
              },
            },
          ],
          actionBtns: [
            {
              title: "查看",
              color: "cyan",
              authenKey: "role_details",
              onTap: (options) => {
                this.$router.push({
                  name: "addOrEditAuthen",
                  query: {
                    pageType: 2,
                    id: options.id,
                  },
                });
              },
            },
            {
              title: "编辑",
              color: "cyan",
              authenKey: "role_edit",
              onTap: (options) => {
                try {
                  const { id } = options;

                  if (id === myRoleID) {
                    throw "不可以编辑您自己当前使用的权限";
                  }

                  this.$router.push({
                    name: "addOrEditAuthen",
                    query: {
                      pageType: 1,
                      id,
                    },
                  });
                } catch (error) {
                  this.$catchError(error);
                }
              },
            },
            {
              title: "删除",
              color: "red",
              authenKey: "role_del",
              onDelete: async (options) => {
                try {
                  const roleID = options.id;

                  if (roleID === myRoleID) {
                    throw "不可以删除您自己当前使用的权限";
                  }

                  return await this.$ajax({
                    responseAll: true,
                    apiKey: "delRole",
                    data: {
                      id: roleID,
                    },
                  });
                } catch (error) {
                  throw error;
                }
              },
            },
          ],
        };
      }

      return {
        authenKey: "role_list",
        search: {
          inputList: [
            {
              type: "input",
              label: "角色名称",
              placeholder: "",
              value: "",
              props: "name",
              rules: ["!null"],
            },
          ],
        },
        headers: [{ label: "角色名称", props: "name" }],
        ...configureOptions,
        getData: async (options) => {
          return await this.$ajax({
            apiKey: "getRoleList",
            data: options,
          });
        },
      };
    },
  },
};
</script>
