<!--
 * @Description: 排课表列表
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
    <centerDialog ref="centerDialog">
      <inputCenter :initOptions="initOptions_input"></inputCenter>
    </centerDialog>
    <!-- 开课提醒设置 -->
    <centerDialog ref="remindRef">
      <div class="remindFormBox">
        <div
          class="remindFormItem"
          v-for="(item, index) in remindForm"
          :key="index"
        >
          <span class="title1">开课前</span>
          <el-input-number
            v-model="item.value"
            :disabled="item.status === 2"
            size="mini"
            :min="0"
            :step="0.01"
            label="请输入"
          ></el-input-number>
          <span class="title2">{{ item.type === 1 ? "天" : "小时" }}</span>
          <el-switch
            v-model="item.status"
            :active-value="1"
            :inactive-value="2"
          >
          </el-switch>
        </div>
      </div>
    </centerDialog>
    <!-- 导出 -->
    <ExportDialog ref="exportDialogRef" />
  </mainContentDiv>
</template>

<script>
import ExportDialog from "./exportDialog.vue";

export default {
  components: {
    ExportDialog,
  },
  data() {
    return {
      tableOptions: null,
      initOptions_input: null,
      remindForm: [
        {
          type: 1,
          value: "",
          status: 1,
        },
        {
          type: 2,
          value: "",
          status: 1,
        },
      ],
    };
  },
  created() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    // 弹出新增/编辑
    async renderEditDialog({ type, inputedVal }) {
      try {
        let inputOptions = (this.initOptions_input = {
          inputList: this.getInputRechargeLevel(),
        });

        if (inputedVal) {
          // console.log('inputedVal', inputedVal)
          this.$com.fillData({
            inputList: this.initOptions_input.inputList,
            valueListObj: inputedVal,
          });
        }

        this.$refs.centerDialog.render({
          title: type === 0 ? "新增" : "编辑",
          width: "1000px",
          verifyInputListIsChange: inputOptions.inputList,
          ok: async () => {
            try {
              const { inputList } = inputOptions;

              let requestOpt = this.$com.getValueByRules(inputList);
              console.log("requestOpt", requestOpt);

              // 1:新增 2:编辑
              if (type === 1) {
                const { id } = inputedVal;
                requestOpt.id = id;
              }

              // 新增/编辑
              const { msg } = await this.$ajax({
                apiKey: "courseSave",
                submitByForm: true,
                data: requestOpt,
              });

              this.$toast({
                msg,
              });

              this.$refs.tableList.init();
            } catch (error) {
              throw error;
            }
          },
          cancel: () => {},
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    //获取输入配置表
    getInputRechargeLevel(inputedVal) {
      const _tempValObj = {};

      let getInputRechargeLevel = [
        {
          type: "input",
          label: "名称",
          placeholder: "请输入名称",
          rules: ["required", "!null"],
          value: null,
          props: "name",
          col: "col-4",
        },
        {
          type: "datePicker",
          label: "开始日期",
          placeholder: "请选择开始日期",
          maxLength: 40,
          suffixIcon: "el-icon-date",
          rules: ["required", "!null"],
          value: null,
          props: "begin_date",
          col: "col-3",
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd"
            );

            item.value = formatDate;
          },
        },
        {
          type: "datePicker",
          label: "结束日期",
          placeholder: "请选择结束日期",
          maxLength: 40,
          suffixIcon: "el-icon-date",
          rules: ["required", "!null"],
          value: null,
          props: "end_date",
          col: "col-3",
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd"
            );

            item.value = formatDate;
          },
        },
      ];

      return getInputRechargeLevel;
    },

    getTableOptions() {
      return {
        authenKey: "arrangingTableList",
        search: {
          inputList: [
            {
              type: "input",
              label: "排课表名称",
              placeholder: "",
              value: "",
              props: "name",
              rules: ["!null"],
            },
          ],
        },
        headers: [
          { label: "排课表名称", props: "name" },
          { label: "周期开始", props: "begin_date" },
          { label: "周期结束", props: "end_date" },
          { label: "创建时间", props: "created_at" },
        ],
        titleBtns: [
          {
            type: "filter",
            color: "normal",
            title: "查询",
          },
          {
            title: "新增",
            authenKey: "arrangingTableAdd",
            onTap: () => {
              this.renderEditDialog({
                type: 0,
              });
            },
          },
          {
            title: "开课提醒",
            color: "cyan",
            authenKey: "arrangingTablePushDetails",
            onTap: async (options) => {
              let { res } = await this.$ajax({
                apiKey: "courseremind_info",
              });
              console.log("res", res);
              this.remindForm = res;
              this.remindEditDialog();
            },
          },
        ],
        actionBtns: [
          {
            title: "查看",
            color: "normal",
            authenKey: "arrangingTableShow",
            onTap: async (options) => {
              let { id } = options;
              this.$router.push({
                name: "arrangingTableShow",
                query: { id },
              });
            },
          },
          {
            title: "编辑",
            color: "blue",
            authenKey: "arrangingTableEdit",
            onTap: async (options) => {
              // console.log('options', options)
              let { id } = options;
              let { res } = await this.$ajax({
                apiKey: "courseBasic_info",
                data: { id },
              });
              console.log("res", res);

              this.renderEditDialog({
                type: 1,
                inputedVal: res,
              });
            },
          },
          {
            title: "删除",
            color: "red",
            authenKey: "arrangingTableDel",
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: "courseDel",
                data: {
                  id: options.id, //cid	是	int	班级id
                },
              });
            },
          },
          {
            title: "导出",
            color: "cyan",
            authenKey: "arrangingTableExport",
            onTap: async (options) => {
              try {
                this.$refs.exportDialogRef.showDialog(options);
              } catch (error) {
                this.$catchError(error);
              }
            },
          },
        ],
        getData: async (options) => {
          // console.log('获取列表', options)
          // 获取列表
          let ret = await this.$ajax({
            apiKey: "courseList",
            data: {
              ...options,
            },
          });

          return ret;
        },
      };
    },

    // 开课提醒设置
    async remindEditDialog() {
      try {
        this.$refs.remindRef.render({
          title: "开课提醒设置",
          width: "600px",
          ok: async () => {
            try {
              console.log("remindForm", this.remindForm);

              // 校验
              for (let i of this.remindForm) {
                if (i.status === 1 && !i.value) {
                  throw `请输入开课前${i.type === 1 ? "多少天" : "多少小时"}`;
                }
              }
              const { msg } = await this.$ajax({
                apiKey: "courseremind_edit",
                submitByForm: true,
                data: {
                  data: this.remindForm,
                },
              });
              this.$toast({
                msg,
              });
            } catch (error) {
              throw error;
            }
          },
          cancel: () => {},
        });
      } catch (error) {
        this.$catchError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.remindFormBox {
  text-align: center;

  .remindFormItem {
    margin-bottom: 14px;

    .title1 {
      margin-right: 16px;
    }
    .title2 {
      margin: 0 16px;
      width: 30px;
      text-align: left;
      display: inline-block;
    }
  }
}
</style>
