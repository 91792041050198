<!--
 * @Date         : 2020-11-26 13:54:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-29 10:14:35
 * @FilePath     : \leXue_manage_pc\src\views\customer\addOrEditStudent.vue
-->
<template>
  <!-- 弹窗：新增/编辑 -->
  <centerDialog ref="centerDialog" class="center-dialog">
    <div class="overflow-hidden">
      <div
        class="d-flex flex-wrap"
        style="margin-right: -0.625rem"
        v-if="initOptions_school"
      >
        <div
          class="col-3"
          v-for="(item, index) in initOptions_school.inputList"
          :key="index"
        >
          <div class="mr-10 mb-10">
            <div
              class="my-5 com-title"
              v-if="item.label"
              :class="{
                active:
                  item.rules && item.rules.find((item) => item === 'required'),
              }"
            >
              {{ item.label }}
            </div>
            <!-- 输入框 -->
            <textFile v-if="item.type === 'input'" :initOptions="item">
            </textFile>
            <!-- 搜索框 -->
            <el-select
              class="w-100"
              size="small"
              filterable
              clearable
              remote
              reserve-keyword
              v-else-if="item.type === 'select'"
              v-model="item.value"
              :placeholder="item.placeholder"
              :remote-method="item.remoteMethod"
              suffix-icon="el-icon-date"
              @change="item.onChange && item.onChange($event, item)"
              @focus="item.onFocus && item.onFocus($event, item)"
            >
              <el-option
                v-for="item in item.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- 单选 -->
            <el-radio-group
              v-if="item.type === 'radioGroup'"
              v-model="item.value"
            >
              <el-radio
                v-for="(item, index) in item.options"
                :key="index"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
            <!-- 日期选择 -->
            <el-date-picker
              class="w-100"
              type="date"
              size="small"
              placeholder="选择日期"
              v-else-if="item.type === 'datePicker'"
              v-model="item.value"
              @change="item.onChange && item.onChange($event, item)"
            >
            </el-date-picker>
            <!-- 日期时间选择器 -->
            <el-date-picker
              class="w-100"
              type="datetime"
              size="small"
              v-else-if="item.type === 'dateTimePicker'"
              v-model="item.value"
              :placeholder="item.placeholder"
              @change="item.onChange && item.onChange($event, item)"
            >
            </el-date-picker>
          </div>
        </div>
      </div>

      <!-- 产品规格列表 -->
      <div style="height: 40vh">
        <tableList
          ref="tableList_productSku"
          v-if="tableOptions_selectedParentList"
          :initOptions="tableOptions_selectedParentList"
        >
        </tableList>
      </div>

      <!-- 弹窗：选择家长和家长与学生的关系 -->
      <centerDialog ref="centerDialog_selectParent">
        <inputCenter
          ref="inputCenter_parStudentRelation"
          v-if="initOptions_selectParent"
          :initOptions="initOptions_selectParent"
        >
        </inputCenter>
      </centerDialog>
    </div>
  </centerDialog>
</template>

<script>
export default {
  data() {
    return {
      initOptions_school: null,
      tableOptions_selectedParentList: null,
      initOptions_selectParent: null,
    };
  },
  methods: {
    //弹窗选择属性
    handleSelectAttrByDialog() {
      return new Promise((resolve, reject) => {
        let inputList;

        this.initOptions_selectParent = {
          inputList: (inputList = this.getInputCenter_selectParent()),
        };

        //弹出：选择产品
        this.$refs.centerDialog_selectParent.render({
          title: "选择家长",
          width: "800px",
          verifyInputListIsChange: inputList,
          ok: () => {
            try {
              let {
                pid: parentID,
                rid: relationID,
              } = this.$com.getValueByRules(inputList);

              const selectedParentList = this.tableDataList_selectedParentList;
              const isExisted = selectedParentList.find(
                (item) => item.pid === parentID
              );

              if (isExisted) {
                throw "已选择了该家长";
              }

              const _tempValObj = this._tempValObj;

              //填充赋值
              selectedParentList.push({
                parentName: _tempValObj.parent.options.find(
                  (item) => item.value === parentID
                ).label,
                relation: _tempValObj.relation.options.find(
                  (item) => item.value === relationID
                ).label,
                pid: parentID,
                rid: relationID,
              });
            } catch (error) {
              throw error;
            }
          },
          cancel: () => {
            this.initOptions_goods = null; // 销毁选择商品实例
            reject(null);
          },
        });
      });
    },

    // 弹出新增/编辑学校
    render({ type, inputedVal }) {
      try {
        let dialogTitle = "";
        let inputOptions;

        switch (
          type // 1:新增 2:编辑
        ) {
          case 1:
            dialogTitle = "新增学生";
            inputOptions = {
              inputList: this.getInputStudent(),
            };
            break;
          case 2:
            dialogTitle = "编辑学生";
            inputOptions = {
              inputList: this.getInputStudent(inputedVal),
            };
            break;
        }

        let selectedParentList = [];

        if (inputedVal) {
          this.$com.fillData({
            inputList: inputOptions.inputList,
            valueListObj: inputedVal,
          });

          inputedVal.parent_info.forEach((item) => {
            selectedParentList.push({
              pid: item.id,
              rid: item.student_relation_id,
              parentName: item.name,
              relation: item.student_relation_name,
            });
          });
        }

        this.initOptions_school = inputOptions;
        this.tableOptions_selectedParentList = this.getTableOptions_selectedParent();
        this.tableDataList_selectedParentList = selectedParentList;

        return new Promise((resovle, reject) => {
          this.$refs.centerDialog.render({
            title: dialogTitle,
            width: "800px",
            verifyInputListIsChange: inputOptions.inputList,
            ok: async () => {
              try {
                const { inputList } = inputOptions;

                const requestOpt = this.$com.getValueByRules(inputList);
                const selectedParent = this.tableDataList_selectedParentList;

                if (selectedParent.length <= 0) {
                  throw "请至少选择一个家长";
                }

                let parentStudentRelation = selectedParent.map((item) => {
                  return {
                    pid: item.pid,
                    student_relation_id: item.rid,
                  };
                });

                requestOpt.student_relation_parent = JSON.stringify(
                  parentStudentRelation
                );

                switch (
                  type // 1:新增 2:编辑
                ) {
                  case 2:
                    const { id } = inputedVal;
                    requestOpt.id = id;
                    break;
                }

                const { msg } = await this.$ajax({
                  apiKey: "saveStudent",
                  data: requestOpt,
                });

                this.$toast({
                  msg,
                });

                resovle();
              } catch (error) {
                reject(error);
                throw error;
              }
            },
            cancel: () => {
              this.tableOptions_selectedParentList = null;
            },
          });
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    getTableOptions_selectedParent() {
      return {
        authenKey: "class_list",
        headers: [
          { label: "家长名称", props: "parentName" },
          { label: "学生与家长的关系", props: "relation" },
        ],
        titleBtns: [
          {
            title: "选择家长",
            isRender: true,
            onTap: async () => {
              try {
                const tableDataList_selectedParentList = this
                  .tableDataList_selectedParentList;
                const productAttr = await this.handleSelectAttrByDialog();

                const isExisted = tableDataList_selectedParentList.find(
                  (item) => item.id === productAttr.id
                );

                if (isExisted) {
                  throw "已选择了该产品规格";
                }

                tableDataList_selectedParentList.push(productAttr);

                console.info(
                  "tableDataList_selectedParentList",
                  tableDataList_selectedParentList
                );
              } catch (error) {
                this.$catchError(error);
              }
            },
          },
        ],
        actionBtns: [
          {
            title: "删除",
            color: "red",
            isRender: true,
            onDelete: async (options, index) => {
              this.tableDataList_selectedParentList.splice(index, 1); //删除

              return {
                msg: "删除成功",
              };
            },
          },
        ],
        getData: async (options) => {
          return {
            code: 1,
            res: {
              data: this.tableDataList_selectedParentList,
              last_page: 1,
              current_page: 1,
              total: 30,
              per_page: 30,
            },
          };
        },
      };
    },

    getInputCenter_selectParent() {
      let _tempValObj = (this._tempValObj = {}); //临时变量

      let inputList = [
        //家长名称
        (_tempValObj.parent = {
          type: "select",
          label: "家长名称",
          placeholder: "请搜索/选择家长名称",
          rules: ["required", "!null"],
          value: "",
          id: "",
          props: "parent_name",
          props_id: "pid",
          col: "col-6",
          options: [],
          remoteMethod: async (keyword) => {
            try {
              const {
                res: { data },
              } = await this.$ajax({
                apiKey: "getParentList",
                data: {
                  keyword: keyword || "",
                },
              });

              _tempValObj.parent.options = data.map((item) => {
                const { id, name, mobile } = item;

                return {
                  label: `${name}(${mobile})`,
                  value: id,
                };
              });
            } catch (error) {
              this.$catchError(error);
            }
          },
          onChange: (val, thisObj) => {
            thisObj.id = val;
          },
          onFocus: (event, thisObj) => {
            //如果选择的数据非法，则使用默认获取的所有options
            this.tryReInputAllSelectOptions(thisObj);
          },
        }),
        //学生与家长的关系
        (_tempValObj.relation = {
          type: "select",
          label: "与家长的关系",
          placeholder: "请选择与家长的关系",
          rules: ["required", "!null"],
          value: "",
          id: "",
          props: "relation_name",
          props_id: "rid",
          col: "col-6",
          options: [],
          remoteMethod: async (keyword, success) => {
            try {
              const {
                res: { data },
              } = await this.$ajax({
                apiKey: "getRelationList",
                data: {
                  name: keyword || "",
                },
              });

              const thisObj = _tempValObj.relation;
              thisObj.options = data.map((item) => {
                const { id, name } = item;

                return {
                  label: name,
                  value: id,
                };
              });

              success && success(thisObj);
            } catch (error) {
              this.$catchError(error);
            }
          },
          onChange: (val, thisObj) => {
            thisObj.id = val;
          },
          onFocus: (event, thisObj) => {
            //如果选择的数据非法，则使用默认获取的所有options
            this.tryReInputAllSelectOptions(thisObj);
          },
        }),
      ];

      _tempValObj.parent.remoteMethod();
      _tempValObj.relation.remoteMethod("", (thisObj) => {
        //默认选择家长
        const parentOpt = thisObj.options.find((item) =>
          /家长/.test(item.label)
        );

        if (parentOpt) {
          thisObj.value = parentOpt.value;
          thisObj.id = parentOpt.value;
        }
      });

      return inputList;
    },

    //获取输入配置表
    getInputStudent(inputedValueObj) {
      let _tempValObj = (this._tempValObj = {}); //临时变量

      let getInputStudent = [
        {
          type: "input",
          label: "学生名称",
          placeholder: "请输入学生名称",
          maxLength: 40,
          rules: ["required", "!null"],
          value: null,
          props: "name",
        },
        //选择总校
        (_tempValObj.totalSchool = {
          type: "select",
          label: "所属学校",
          placeholder: "请搜索/选择所属学校",
          rules: ["required", "!null"],
          value: "",
          id: "",
          props: "school_name",
          props_id: "school_id",
          options: [],
          remoteMethod: async (keyword) => {
            try {
              console.info("keyword", keyword);

              const {
                res: { data },
              } = await this.$ajax({
                apiKey: "getSchoolList",
                data: {
                  name: keyword || "",
                },
              });

              const thisObj = _tempValObj.totalSchool;
              thisObj.options = data.map((item) => {
                const { id, name } = item;

                return {
                  label: name,
                  value: id,
                };
              });
            } catch (error) {
              this.$catchError(error);
            }
          },
          onChange: (val, thisObj) => {
            thisObj.id = val;

            _tempValObj.subSchool.value = ""; //清空校区的值
            _tempValObj.grade.value = ""; //清空年级
            _tempValObj.grade.options = []; //清空 年级

            if (val) {
              _tempValObj.subSchool.remoteMethod();
            }
          },
          onFocus: (event, thisObj) => {
            //如果选择的数据非法，则使用默认获取的所有options
            this.tryReInputAllSelectOptions(thisObj);
          },
        }),
        //选择分校区
        (_tempValObj.subSchool = {
          type: "select",
          label: "所属校区",
          placeholder: "请搜索/选择所属校区",
          rules: ["required", "!null"],
          value: "",
          id: "",
          props: "xq_name",
          props_id: "xq_id",
          options: [],
          remoteMethod: async (keyword) => {
            try {
              const { id } = _tempValObj.totalSchool;

              if (!id) throw "请先输入/选择所属总学校";

              console.info("_tempValObj", _tempValObj);

              const { res } = await this.$ajax({
                apiKey: "getSubSchoolList",
                data: {
                  name: keyword || "",
                  id,
                },
              });

              const thisObj = _tempValObj.subSchool;
              thisObj.options = res.map((item) => {
                const { id, xq_name, grade } = item;

                return {
                  label: xq_name,
                  value: id,
                  gradeList: grade,
                };
              });
            } catch (error) {
              this.$catchError(error);
            }
          },
          onChange: (val, thisObj) => {
            thisObj.id = val;

            _tempValObj.grade.value = null; //清空年级
            _tempValObj.grade.options = [];

            if (!val) {
              return false;
            }

            const { gradeList } = thisObj.options.find(
              (item) => item.value === val
            );

            _tempValObj.grade.options = gradeList.map((item) => {
              return {
                value: item,
              };
            });
          },
          onFocus: (event, thisObj) => {
            //如果选择的数据非法，则使用默认获取的所有options
            this.tryReInputAllSelectOptions(thisObj);
          },
        }),
        {
          type: "radioGroup",
          label: "性别",
          placeholder: "请选择性别",
          rules: ["required", "!null"],
          value: 1,
          props: "gender",
          options: [
            {
              label: "男",
              value: 1,
            },
            {
              label: "女",
              value: 2,
            },
          ],
        },
        //选择分校区的年级
        (_tempValObj.grade = {
          type: "select",
          label: "年级",
          placeholder: "请选择年级",
          rules: ["required", "!null"],
          value: "",
          id: "",
          props: "grade",
          options: [],
        }),
        {
          type: "input",
          label: "班级",
          placeholder: "请输入班级",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "class",
        },
        {
          type: "input",
          label: "户籍",
          placeholder: "请输入户籍",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "city",
        },
        {
          type: "input",
          label: "身份证",
          placeholder: "请输入身份证",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "card",
        },
        {
          type: "datePicker",
          label: "生日",
          placeholder: "请选择生日",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "birth",
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd"
            );

            item.value = formatDate;
          },
        },
        {
          type: "dateTimePicker",
          label: "入读时间",
          placeholder: "请选择入读时间",
          rules: ["!null"],
          value: null,
          props: "enrollment_time",
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd hh:mm:ss"
            );

            item.value = formatDate;
          },
        },
        {
          type: "input",
          label: "兴趣特长（艺术类）",
          placeholder: "请输入兴趣特长（艺术类）",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "interest",
        },
        {
          type: "input",
          label: "备注",
          placeholder: "请输入备注",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "remark",
        },
      ];

      return getInputStudent;
    },

    //回填所有的options
    tryReInputAllSelectOptions(thisObj) {
      const { options, value, allOptions } = thisObj;

      //如果选择的数据非法，则使用默认获取的所有options
      if (!value || options.length === 0) {
        options.length = 0;

        thisObj.remoteMethod();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.com-title {
  &.active::before {
    content: "*";
    color: #ff1818;
    line-height: 1rem;
  }

  line-height: 1rem;
  font-size: 0.6rem;
  color: #333744;
}
</style>
