<!--
 * @Description: 教学大纲-查看(课程主题-教案)
-->
<template>
  <div>
    <div class="notUploadBox">
      <span>备课教案</span>
      <el-button
        type="primary"
        size="mini"
        @click="onUpload"
        v-if="
          (getStatus === 0 || getStatus === 2) && ifOperationButton('BKJASC')
        "
        key="upload"
      >
        上传<i class="el-icon-upload el-icon--right"></i>
      </el-button>
      <el-button
        size="mini"
        type="warning"
        @click="onBell"
        v-if="getStatus === 0 && ifOperationButton('BKJATXSC')"
        key="bell"
      >
        提醒上传<i class="el-icon-bell el-icon--right"></i>
      </el-button>
      <el-button
        size="mini"
        type="danger"
        v-if="getStatus === 1 && ifOperationButton('BKJABH')"
        key="rejected"
        @click="onRejected"
      >
        撤回<i class="el-icon-folder-delete el-icon--right"></i>
      </el-button>
    </div>
    <div class="uploadListBox" v-if="getList.length">
      <div class="uploadList" :class="{ rejected: getStatus === 2 }">
        <table border="0" width="100%" cellspacing="0" cellpadding="0">
          <tr>
            <!-- <th width="100">基本信息</th> -->
            <th>文件名称</th>
            <th width="80">状态</th>
          </tr>
          <template v-for="(item, index) in getList">
            <tr align="center" :key="index">
              <td class="fileName">
                <span>{{ item.file_name }}</span>
                <i
                  class="el-icon-download"
                  v-if="getStatus === 1 && ifOperationButton('BKJAXZ')"
                  key="download"
                  @click="onDownload(item)"
                ></i>
              </td>
              <td :rowspan="index" v-if="index === 0">
                <span v-if="getStatus === 2">已撤回</span>
                <span v-else-if="getStatus === 1">正常</span>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>
    <!-- 上传 -->
    <el-dialog
      :visible.sync="uploadShow"
      width="700px"
      title="上传备课教案"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="uploadCancel()"
    >
      <!-- accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation" -->
      <Uplads v-model="fileList" uploadWidth="100%" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="uploadShow = false">取 消</el-button>
        <el-button type="primary" @click="uploadDetermine()">确定</el-button>
      </div>
    </el-dialog>
    <el-divider v-if="!operationButton.length && !getList.length"
      >暂无内容</el-divider
    >
  </div>
</template>

<script>
import Uplads from '@/components/Uplads'
import { deepClone } from '@/assets/js/index.js'
import { saveAs } from 'file-saver'
import appConfig from '@/assets/js/appConfig.js'

export default {
  components: {
    Uplads,
  },
  props: {
    // 文件列表
    list: {
      type: Array,
      default: () => [],
    },
    // 课程主题ID
    theme_id: {
      type: Number,
      default: null,
    },
    // 状态：0未上传 1已上传 2已撤回
    status: {
      type: Number,
      default: null,
    },
    // 按钮权限
    operationButton: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      uploadShow: false,
      fileList: [],
    }
  },
  computed: {
    // 监听状态
    getStatus: {
      set(val) {
        this.$emit('update:status', val)
      },
      get() {
        return this.status
      },
    },
    // 监听文件列表
    getList: {
      set(val) {
        this.$emit('update:list', val)
      },
      get() {
        return this.list
      },
    },
  },
  created() {},
  methods: {
    // 提醒
    onBell() {
      this.$confirm('是否发送提醒？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          console.log('提醒', this.theme_id)

          try {
            let { msg } = await this.$ajax({
              apiKey: 'outline_plan_remind',
              data: {
                theme_id: this.theme_id,
              },
            })
            this.$message.success(msg)
          } catch (error) {
            this.$catchError(error)
          }
        })
        .catch(() => {})
    },

    // 撤回
    onRejected() {
      console.log('撤回', this.theme_id)
      this.$confirm('是否撤回此教案？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          try {
            let { msg } = await this.$ajax({
              apiKey: 'outline_plan_reject',
              data: {
                theme_id: this.theme_id,
              },
            })
            this.$message.success(msg)
            this.getStatus = 2
            this.$bus_unique.emit('uploadFileOrRejected')
          } catch (error) {
            this.$catchError(error)
          }
        })
        .catch(() => {})
    },

    // 下载
    onDownload(item) {
      this.$confirm('是否下载此文件？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          // console.log('item', item)
          let { file_name, id, url } = item
          let type = this.$globalFun.getFileType(url)
          // console.log('type', type)

          // 下载word和pdf要水印，其他不需要
          if (type === 'word' || type === 'pdf') {
            let fileName = file_name.split('.')
            fileName.pop()
            fileName = fileName.join('.')
            // console.log('fileName', fileName)

            let { res } = await this.$ajax({
              methods: 'POST',
              apiKey: 'outline_plan_export',
              data: {
                id,
              },
            })
            // console.log('res', res.path)
            saveAs(appConfig.httpDomainName + res.path, fileName + '.pdf')
          } else {
            saveAs(url, file_name)
          }
        })
        .catch(() => {})
    },

    // 上传教案对话框
    onUpload() {
      if (this.getStatus === 2) {
        // console.log('getList', this.getList)
        this.fileList = deepClone(this.getList)
      }
      this.uploadShow = true
    },
    async uploadDetermine() {
      // console.log('fileList', this.fileList)
      if (!this.fileList.length) return this.$message.error('请上传文件')

      try {
        let data = {
          theme_id: this.theme_id,
          urls: JSON.stringify(this.fileList),
        }
        let { res } = await this.$ajax({
          apiKey: 'outline_plan_upload',
          data,
        })
        console.log('上传教案', res)

        this.$message.success('上传成功')
        this.uploadCancel()
        this.getList = res
        this.getStatus = 1
        this.$bus_unique.emit('uploadFileOrRejected')
      } catch (error) {
        this.$catchError(error)
      }
    },
    // 取消(清空对话框)
    uploadCancel() {
      this.uploadShow = false
      this.fileList = []
    },

    // 权限按钮判断
    ifOperationButton(name) {
      // console.log('this.operationButton', this.operationButton)
      let item = this.operationButton.find((i) => {
        return i.code === name
      })
      // console.log('权限按钮判断-item', item)
      return item ? true : false
    },
  },
}
</script>

<style lang="scss" scoped>
.notUploadBox {
  span {
    font-size: 14px;
    color: #475774;
    margin-right: 10px;
  }
}
.uploadListBox {
  margin-top: 10px;

  table {
    border: 1px solid #eaeaea;

    &:last-child {
      border-right: 0;
    }

    th,
    td {
      text-align: center;
      border-right: 1px solid #eaeaea;
      height: 50px;
      font-size: 14px;
    }
    th {
      background: #a0a8b6;
      color: #fff;
    }
    td {
      border-top: 1px solid #eaeaea;
      color: #606060;

      i {
        margin-left: 16px;
        font-size: 16px;
        color: #1891ff;
        cursor: pointer;
      }
    }
  }
}
.rejected {
  td {
    color: #999999 !important;
  }
  .fileName {
    span {
      text-decoration: line-through;
    }
  }
}

.el-divider__text {
  background: #f9f9f9;
}
</style>
