<!--
 * @Description: 日历主体
-->
<template>
  <div class="full-calendar-body">
    <div class="right-body">
      <!-- 星期显示 -->
      <div class="weeks">
        <div class="blank" v-if="tableType == 'week'">时间</div>
        <strong class="week" v-for="(week, index) in weekNames" :key="index">
          {{ week }}
          <span v-if="tableType == 'week' && weekDate.length">
            ({{ weekDate[index].showDate }})
          </span>
        </strong>
      </div>
      <!-- 月-内容 -->
      <div class="dates" ref="dates" v-if="tableType == 'month'">
        <div class="dates-events">
          <!-- 月-任务渲染开始 -->
          <template v-for="(week, index) in currentDates">
            <div
              class="events-week"
              v-if="week[0].isCurMonth || week[week.length - 1].isCurMonth"
              :key="index"
            >
              <div
                class="events-day"
                v-for="(day, dayIndex) in week"
                :key="dayIndex"
                track-by="$index"
                :class="{
                  today: day.isToday,
                  'not-cur-month': !day.isCurMonth,
                }"
              >
                <p class="day-number">{{ day.monthDay }}</p>
                <div class="event-box" v-if="day.events.length">
                  <!-- 任务内容 -->
                  <div
                    v-for="(event, eventIndex) in day.events"
                    :key="eventIndex"
                    @click="eventClick(event, $event)"
                  >
                    <ItemContent
                      :eventData="event"
                      :cardClass="cardClass"
                      :showCard="showCard"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- 周-内容 -->
      <div class="time" ref="time" v-else-if="tableType == 'week'">
        <div class="row" v-for="(time, index) in timeDivide" :key="index">
          <!-- 时间段 -->
          <div class="left-info" v-if="tableType == 'week'">
            <div class="time-info first" v-if="index == 0">
              <span class="center">上午</span>
            </div>
            <div class="time-info" v-if="index == 1">
              <span class="top">12:00</span>
              <span class="center">下午</span>
            </div>
            <div class="time-info" v-if="index == 2">
              <span class="top">18:00</span>
              <span class="center">晚上</span>
            </div>
          </div>
          <!-- 周-任务渲染开始 -->
          <template v-for="(item, itemIndex) in weekDate">
            <div
              :key="itemIndex"
              class="events-day"
              v-if="weekDate.length"
              :class="{ today: item.isToday }"
            >
              <div class="event-box" v-if="item.events.length">
                <template v-for="(event, eventIndex) in item.events">
                  <!-- 任务内容 -->
                  <div
                    :key="eventIndex"
                    v-if="
                      isTheday(item.date, event.start) &&
                        isInTime(time, event.start)
                    "
                    @click="eventClick(event, $event)"
                  >
                    <ItemContent
                      :eventData="event"
                      :cardClass="cardClass"
                      :showCard="showCard"
                    />
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateFunc from '../dateFunc.js'
import ItemContent from './item.vue'

export default {
  components: {
    ItemContent,
  },
  props: {
    // 当前日期
    currentDate: {},
    // 数据源
    events: {
      type: Array,
    },
    // 星期名称
    weekNames: {
      type: Array,
      default: () => [],
    },
    // 月名称
    monthNames: {},
    // 表的起始日期
    firstDay: {},
    // 日历类型(周/月)
    tableType: {
      type: String,
      default: '',
    },
    // 这周的起始日期
    weekDays: {
      type: Array,
      default() {
        return dateFunc.getDates(new Date())
      },
    },
  },
  data() {
    return {
      // 时间段拆分
      timeDivide: [
        {
          start: 0,
          end: 12,
          label: '上午',
        },
        {
          start: 12,
          end: 18,
          label: '下午',
        },
        {
          start: 18,
          end: 24,
          label: '晚上',
        },
      ],
      showCard: -1,
      cardClass: '',
    }
  },
  watch: {
    currentDate() {
      this.getCalendar()
    },
  },
  computed: {
    currentDates() {
      return this.getCalendar()
    },
    weekDate() {
      return this.getWeekDate()
    },
  },
  created() {
    const _this = this
    document.addEventListener('click', function(e) {
      _this.showCard = -1
    })
  },
  methods: {
    // 数据处理
    getCalendar() {
      // calculate 2d-array of each month
      // first day of this month
      const now = new Date() // today
      const current = new Date(this.currentDate)

      const startDate = dateFunc.getStartDate(current) // 1st day of this month

      const curWeekDay = startDate.getDay()

      // begin date of this table may be some day of last month
      let diff = parseInt(this.firstDay) - curWeekDay + 1
      diff = diff > 0 ? diff - 7 : diff

      startDate.setDate(startDate.getDate() + diff)
      const calendar = []
      for (let perWeek = 0; perWeek < 6; perWeek++) {
        const week = []

        for (let perDay = 0; perDay < 7; perDay++) {
          // console.log(startDate)
          week.push({
            monthDay: startDate.getDate(),
            isToday: now.toDateString() === startDate.toDateString(),
            isCurMonth: startDate.getMonth() === current.getMonth(),
            weekDay: perDay,
            date: new Date(startDate),
            events: this.slotEvents(new Date(startDate)),
          })
          startDate.setDate(startDate.getDate() + 1)
        }
        calendar.push(week)
      }
      // console.log('calendar', calendar)
      return calendar
    },
    // 提取任务数据
    slotEvents(date) {
      // console.log(date)
      const thisDayEvents = []
      this.events.filter((event) => {
        const day = new Date(event.start)
        if (date.toLocaleDateString() === day.toLocaleDateString()) {
          thisDayEvents.push(event)
        }
      })
      this.judgeTime(thisDayEvents)
      // console.log('thisDayEvents', thisDayEvents)
      return thisDayEvents
    },
    // 获取周视图的天元素格式化
    getWeekDate() {
      const newWeekDays = this.weekDays
      newWeekDays.forEach((e, index) => {
        // console.log('e', e)
        e.showDate = dateFunc.format(e, 'MM-dd')
        e.date = dateFunc.format(e, 'yyyy-MM-dd')
        e.isToday = new Date().toDateString() === e.toDateString()
        e.events = this.slotTimeEvents(e) // 整理事件集合 （拿事件去比较时间，分发事件到时间插槽内）
      })
      // console.log('newWeekDays', newWeekDays)
      return newWeekDays
    },
    // 发现该时间段所有的事件
    slotTimeEvents(date) {
      const thisDayEvents = this.events
      thisDayEvents.filter((event) => {
        const day = new Date(event.start)
        return date.toLocaleDateString() === day.toLocaleDateString()
      })
      this.judgeTime(thisDayEvents)
      return thisDayEvents
    },
    judgeTime(arr) {
      arr.forEach((event) => {
        const day = new Date(event.start)
        // 加上时间戳后判断时间段
        const hour = day.getHours()
        let week = day.getDay()
        if (week === 0) {
          week = 7
        }
        if (this.timeDivide[0].start < hour < this.timeDivide[0].end) {
          event.time = 0
        } else if (this.timeDivide[1].start < hour < this.timeDivide[1].end) {
          event.time = 1
        } else if (this.timeDivide[2].start < hour < this.timeDivide[2].end) {
          event.time = 2
        }
        event.weekDay = this.weekNames[Number(week) - 1]
        event.weekDayIndex = week
      })
    },
    isTheday(day1, day2) {
      return new Date(day1).toDateString() === new Date(day2).toDateString()
    },
    isInTime(time, date) {
      const hour = new Date(date).getHours()
      return time.start <= hour && hour < time.end
    },

    // 任务点击事件
    eventClick(event, jsEvent) {
      // console.log(event,jsEvent, 'evenvet')
      this.showCard = event.id
      jsEvent.stopPropagation()
      const x = jsEvent.x
      const y = jsEvent.y
      // console.log('jsEvent', jsEvent)
      // 判断出左右中三边界的取值范围进而分配class
      if (x > 400 && x < window.innerWidth - 200) {
        this.cardClass = 'center-card'
      } else if (x <= 400) {
        this.cardClass = 'left-card'
      } else {
        this.cardClass = 'right-card'
      }
      if (y > window.innerHeight - 300) {
        this.cardClass += ' ' + 'bottom-card'
      }

      this.$emit('eventclick', event, jsEvent)
    },
  },
}
</script>

<style lang="scss">
.full-calendar-body {
  background-color: #fff;
  display: flex;
  margin-top: 12px;
  border: 1px solid #88c3f8;
  .left-info {
    width: 60px;
    .time-info {
      height: 100%;
      position: relative;
      &.first {
        // border-top: 1px solid #88C3F8;
      }
      &:nth-child(2) {
        border-top: 1px solid #88c3f8;
        border-bottom: 1px solid #88c3f8;
      }
      .center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 14px;
        font-size: 14px;
        word-wrap: break-word;
        letter-spacing: 10px;
      }
      .top {
        position: absolute;
        top: -8px;
        width: 100%;
        text-align: center;
      }
    }
  }
  .right-body {
    flex: 1;
    width: 100%;
    position: relative;
    .weeks {
      display: flex;
      background: #0c75d5;
      color: #fff;

      .blank {
        text-align: center;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        border-right: 1px solid #88c3f8;
      }

      // border-bottom: 1px solid #ffcc36;
      .week {
        flex: 1;
        text-align: center;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #88c3f8;

        &:last-child {
          border-right: 0;
        }
      }
    }
    .dates {
      position: relative;
      // overflow: auto;
      // max-height: calc(100vh - 280px);
      .dates-events {
        z-index: 1;
        width: 100%;
        .events-week {
          display: flex;
          min-height: 180px;
          .events-day {
            // cursor: pointer;
            text-overflow: ellipsis;
            flex: 1;
            width: 0;
            height: auto;
            padding: 8px;
            border-right: 1px solid #88c3f8;
            border-bottom: 1px solid #88c3f8;
            background-color: #fff;
            .day-number {
              text-align: left;
              margin-bottom: 8px;
            }
            &.not-cur-month {
              .day-number {
                color: #ececed;
              }
            }

            &.today {
              background-color: #ffdae5;
            }
            &:last-child {
              border-right: 0;
            }
            .event-box {
              .more-link {
                cursor: pointer;
                // text-align: right;
                padding-left: 8px;
                padding-right: 2px;
                color: rgba(0, 0, 0, 0.38);
                font-size: 12px;
              }
            }
          }
          &:last-child {
            .events-day {
              border-bottom: 0;
            }
          }
        }
      }
      .more-events {
        position: absolute;
        width: 150px;
        z-index: 2;
        border: 1px solid #eee;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
        .more-header {
          background-color: #eee;
          padding: 5px;
          display: flex;
          align-items: center;
          font-size: 14px;
          .title {
            flex: 1;
          }
          .close {
            margin-right: 2px;
            cursor: pointer;
            font-size: 16px;
          }
        }
        .more-body {
          height: 125px;
          overflow: hidden;
          background: #fff;
          .body-list {
            height: 120px;
            padding: 5px;
            overflow: auto;
            background-color: #fff;
            .body-item {
              cursor: pointer;
              font-size: 12px;
              background-color: #c7e6fd;
              margin-bottom: 2px;
              color: rgba(0, 0, 0, 0.87);
              padding: 0 0 0 4px;
              height: 18px;
              line-height: 18px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .time {
      position: relative;
      // overflow: auto;
      // max-height: calc(100vh - 280px);
      .row {
        width: 100%;
        display: flex;
        min-height: 180px;
        .events-day {
          border-top: 1px solid #88c3f8;
          border-left: 1px solid #88c3f8;
          background-color: #fff;
          height: auto;
          text-overflow: ellipsis;
          flex: 1;
          width: 0;
          padding: 8px;
          &.today {
            background-color: #ffdae5;
          }
        }
        &:first-child {
          .left-info {
            border-top: 1px solid #88c3f8;
          }
        }
        &:last-child {
          .single {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
