<!--
 * @Description: 导出
-->
<template>
  <div>
    <el-dialog
      v-if="dialogShow"
      title="请选择导出的周期"
      :visible.sync="dialogShow"
      width="400px"
      destroy-on-close
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-date-picker
        style="width: 100%;"
        v-model="value"
        type="week"
        :picker-options="pickerOptions"
        placeholder="请选择周"
        @change="changeweek"
        format="yyyy 第 WW 周"
      >
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="dialogSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dateFunc from '@/components/Calendar/dateFunc.js'

export default {
  data() {
    return {
      dialogShow: false,
      options: null,
      pickerOptions: {
        firstDayOfWeek: 1,
        // 限制只能在开始和结束周期内选择
        disabledDate: (time) => {
          // 如果没有后面的 -8.64e7 就是不可以选择今天的
          const beginDate = new Date(this.options.begin_date).getTime() - 8.64e7
          const endDate = new Date(this.options.end_date).getTime() - 8.64e7
          return beginDate > time.getTime() || endDate < time.getTime()
        },
      },
      value: '',
      date: [],
    }
  },
  created() {},
  methods: {
    // 显示
    showDialog(options) {
      this.dialogClone()
      this.options = options
      this.dialogShow = true
    },
    dialogClone() {
      this.options = null
      this.value = ''
      this.date = []
    },
    async dialogSave() {
      if (!this.value) return this.$message.error('请选择周')

      try {
        // console.log('id', this.id)
        this.dialogShow = false

        // 导出
        await this.$ajax({
          isExportFile: true, //导出文件
          methods: 'POST',
          apiKey: 'courseExport',
          fileName: this.options.name + '.xls',
          data: {
            id: this.options.id,
            begin_date: this.date[0],
            end_date: this.date[1],
          },
          submitByForm: true,
        })
      } catch (error) {
        this.$catchError(error)
      }
    },

    // 日期处理
    changeweek(val) {
      // console.log('val', val)

      if (val) {
        const weekDays = dateFunc.getDates(val)
        this.date[0] = dateFunc.format(weekDays[0], 'yyyy-MM-dd')
        this.date[1] = dateFunc.format(weekDays[6], 'yyyy-MM-dd')
        // console.log('this.date', this.date)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
