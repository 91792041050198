<!--
 * @Date         : 2020-11-20 09:16:09
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-06 14:14:41
 * @FilePath     : \leXue_manage_pc\src\views\sideLayout.vue
-->
<template>
  <div class="d-flex" style="height: 100vh" id="app" >
    <!-- 侧边栏 -->
    <sideBar class="flex-shrink-0"></sideBar>
    <div class="col overflow-hidden">
      <div class="d-flex flex-column h-100">
        <!-- 头部 -->
        <headerBar></headerBar>
        <!-- 主要内容 上面显示的一栏-->
        <contentNavBar></contentNavBar>
        <div class="col flex-shrink-1 overflow-hidden">
          <!-- 主要内容 -->
          <noAuthenTips v-if="isEmptyMainContent === true"
            >暂无查看此页面内容的权限，请向管理员申请</noAuthenTips
          >
          <div class="pa-20 h-100">
            <!-- 正式 -->
            <vue-page-stack>
              <router-view></router-view>
            </vue-page-stack>
            <!-- 测试 -->
            <!-- <router-view></router-view> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 空白遮罩，当没有获取到用户权限的时候，显示出来，遮掉主内容 -->
    <div class=" position-fixed empty-mask" v-if="!isGotAdminDetails"></div>
  </div>
</template>

<script>
import noAuthenTips from "@/components/noAuthenTips.vue";
import headerBar from "@/components/headerBar.vue";
import sideBar from "@/components/sideBar.vue";
import contentNavBar from "@/components/contentNavBar.vue";

export default {
  components: {
    noAuthenTips,
    headerBar,
    sideBar,
    contentNavBar,
  },
  data() {
    return {
      isEmptyMainContent: null,
      isMounted: null,
    };
  },
   mounted() {
    this.getRoleAuthenInfo();

    //监听空页面提示
    this.$bus_unique.on("isEmtyContent", "App", () => {
      this.isEmptyMainContent = true;
    });
  },
  methods: {
    //获取角色权限信息
    getRoleAuthenInfo() {
      return this.$store.dispatch("getRoleAuthen", this);
    },
  },
  computed: {
    isGotAdminDetails(){
      const isLoadedAdminDetails = this.$store.getters.isLoadedAdminDetails();

      return isLoadedAdminDetails;
    }
  }
};
</script>

<style lang="scss">
body,
html {
  height: 100vh;
  background-color: #eef3f6 !important;
}

.empty-mask{
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  background-color: #fff;
}
</style>
