<!--
 * @LastEditors: Please set LastEditors
 * @FilePath: /src/views/order/addOrEditOrder.vue
-->
<template>
  <div class="d-flex flex-column h-100">
    <div class="col overflow-scroll">
      <div>
        <!-- 模块：学生信息 -->
        <infoWrapper class="my-10" title="学生信息">
          <div class="px-20 py-10 w-75">
            <inputCenter
              ref="inputCenter"
              :initOptions="inputList_studentInfo"
            ></inputCenter>
          </div>
        </infoWrapper>

        <!-- 模块：家长信息 -->
        <infoWrapper class="my-10" title="家长信息">
          <div class="px-20 py-10 w-75">
            <inputCenter
              ref="inputCenter"
              :initOptions="inputList_parentInfo"
            ></inputCenter>
          </div>
        </infoWrapper>

        <!-- 模块: 选择产品信息 -->
        <selectProductModules
          ref="selectProductModules"
          :selectedProductList="selectedProductList"
          :pageType="pageType"
          v-if="selectedProductList"
          @updateProductInfo="handleUpdateProductInfo"
        >
        </selectProductModules>

        <!-- 模块：记录付款方式 -->
        <infoWrapper
          class="my-10"
          title="记录付款方式"
          v-if="inputCenter_payOrder"
        >
          <div class="px-20 py-10 w-75">
            <inputCenter
              ref="inputCenter"
              :initOptions="inputCenter_payOrder"
            ></inputCenter>
          </div>
        </infoWrapper>

        <!-- 模块：信息记录 -->
        <recordListModules
          ref="recordListModules"
          :pageType="pageType"
        ></recordListModules>

        <!-- 模块：其他订单信息 -->
        <infoWrapper class="my-10" title="其他订单信息">
          <div class="px-20 py-10 w-75">
            <inputCenter
              ref="inputCenter"
              :initOptions="inputList_otherOrderInfo"
            ></inputCenter>
          </div>
        </infoWrapper>

        <!-- 模块：订单类型 -->
        <infoWrapper class="my-10" title="订单类型">
          <div class="px-20 py-10 w-75">
            <inputCenter
              ref="inputCenter"
              :initOptions="inputList_otherOrderInfoType"
            ></inputCenter>
          </div>
        </infoWrapper>

        <!-- 模块：退款信息 -->
        <refundInfoModules ref="refundInfoModules" v-if="pageType === 3" />
      </div>
    </div>

    <!-- 模块：底部信息 -->
    <div
      class="d-flex justify-content-end align-items-center footer-info-modules"
    >
      <template v-if="pageType === 0 || pageType === 1 || pageType === 3">
        <div
          class="mr-20 info-item"
          v-for="(item, index) in footerInfoList"
          :key="index"
        >
          <span class="label">{{ item.label }}</span>
          <span class="value" :class="item.cssClass">
            {{ item.state || "" }}
            {{ orderProductInfo[item.valueKey] || 0 }}</span
          >
        </div>
        <!-- <div class="mr-20 info-item">
          <span class="label">｜ 课包：</span>
          <span class="value font-bold">
            {{ orderProductInfo.price / 100 || 0 }}
          </span>
        </div> -->
      </template>
      <div
        class="px-20 submit-btn"
        v-for="item in footerBtnList"
        :key="item.title"
        @click="item.onTap(footerBtnList)"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import infoWrapper from "@/components/infoWrapper.vue";
import selectProductModules from "@/views/order/selectProductModules.vue";
import recordListModules from "@/views/order/recordListModules.vue";
import refundInfoModules from "@/views/order/refundInfoModules.vue";
import validValue from "@/assets/js/validValue.js";

export default {
  components: {
    infoWrapper,
    selectProductModules,
    recordListModules,
    refundInfoModules,
  },
  data() {
    return {
      inputList_studentInfo: {
        inputList: this.getInputList_studentInfo(),
      },
      inputList_parentInfo: {
        inputList: this.getInputList_parentInfo(),
      },

      inputList_otherOrderInfo: {
        inputList: this.getInputList_otherOrderInfo(),
      },
      inputList_otherOrderInfoType: {
        inputList: this.getinputList_otherOrderInfoType(),
      },
      inputCenter_payOrder: null,
      orderProductInfo: {},
      selectedProductList: null,
      pageType: 0,
      pid: null, // 记录家长id
      // 是否数据渲染完毕
      ifRendering: false,
    };
  },
  destroyed() {
    this.$bus_unique.off("updateParentInfo");
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      let { pageType, id, studentID } = this.$route.query;

      this.pageType = pageType = Number(pageType);

      //pageType  0：新增  1：编辑  2：详情  3：退款
      if (pageType === 1 || pageType === 2 || pageType === 3) {
        this.getAndSetOrderDetails(id); //获取并设置订单详情
      } else {
        let Info = this.$route.params.studentInfo;
        console.log("this.$route.studentInfo", Info);

        // 新增有带学生信息回填
        if (Info) {
          const { res } = await this.$ajax({
            apiKey: "getStudentDetails",
            data: {
              id: Info.id,
            },
          });

          const studentInfo = res[0];
          console.log("studentInfo", studentInfo);

          //填充学生信息
          this.$com.fillData({
            inputList: this.inputList_studentInfo.inputList,
            valueListObj: studentInfo,
          });
          let options = this.inputList_studentInfo.inputList[0].options;
          // console.log('studentInfo', studentInfo)
          // console.log('options', options)
          // 列表第一个没有的时候填充
          let i = options.find((item) => {
            return item.value == studentInfo.id;
          });
          if (!i) {
            options.push({
              label: studentInfo.name,
              value: studentInfo.id,
            });
          }
          studentInfo.__currentParentID = Info.parent_info[0].id;

          //解锁选择家长
          this.$bus_unique.emit("updateParentInfo", studentInfo);
        }
        this.selectedProductList = [];
      }
    },

    //获取并设置订单详情
    async getAndSetOrderDetails(orderID) {
      try {
        const { res: orderInfo } = await this.$ajax({
          apiKey: "getOrderDetails",
          data: {
            oid: orderID,
          },
        });
        const {
          sid, //学生id
          pid, //家长id
          product,
          record,
        } = orderInfo;
        // console.log("orderInfo", orderInfo);

        const { res } = await this.$ajax({
          apiKey: "getStudentDetails",
          data: {
            id: sid,
          },
        });
        console.log("getStudentDetails-res", res);

        const studentInfo = Array.isArray(res) ? res[0] : res;
        console.log("studentInfo", studentInfo);

        //填充学生信息
        this.$com.fillData({
          inputList: this.inputList_studentInfo.inputList,
          valueListObj: studentInfo,
        });
        let options = this.inputList_studentInfo.inputList[0].options;
        // console.log('studentInfo', studentInfo)
        // console.log('options', options)
        // 列表第一个没有的时候填充
        let i = options.find((item) => {
          return item.value == studentInfo.id;
        });
        if (!i) {
          options.push({
            label: studentInfo.name,
            value: studentInfo.id,
          });
        }

        studentInfo.__currentParentID = pid;

        //解锁选择家长
        this.$bus_unique.emit("updateParentInfo", studentInfo);
        this.selectedProductList = product;

        //回显信息记录
        if (record && record.length > 0) {
          this.$refs.recordListModules.inputRecordedList(record);
        }

        //回显其他订单信息
        this.$com.fillData({
          inputList: this.inputList_otherOrderInfo.inputList,
          valueListObj: orderInfo,
        });

        //回显其他订单信息
        this.$com.fillData({
          inputList: this.inputList_otherOrderInfoType.inputList,
          valueListObj: orderInfo,
        });

        //订单信息
        this.orderInfo = orderInfo;

        setTimeout(() => {
          // 填充老师
          this.$refs.selectProductModules.setTeacher_name({
            name: orderInfo.teacher_name,
            id: orderInfo.teacher_id,
          });
        }, 200);
      } catch (error) {
        this.$catchError(error);
      }
    },

    //提交订单
    async handleSubmit(pageType, orderID) {
      try {
        //提取输入值：学生信息
        const { sid } = this.$com.getValueByRules(
          this.inputList_studentInfo.inputList
        );

        //提取输入值：记录信息
        const recordedObj = this.$refs.recordListModules.getRecordedInfo();
        //提取输入值：选择的产品
        const selectedProduct =
          this.$refs.selectProductModules.getSelectedProduct(recordedObj);
        //提取输入值：其他订单信息
        const otherOrderInfo = this.$com.getValueByRules(
          this.inputList_otherOrderInfo.inputList
        );

        // 对接老师
        const { id: teacher_id } = this.$com.getValueByRules(
          this.$refs.selectProductModules.inputList_parentInfo.inputList
        );
        // console.log('teacher_id', teacher_id)

        const toSubmitOpt = {
          sid,
          pid: this.pid,
          skus: JSON.stringify(selectedProduct),
          ...recordedObj,
          ...otherOrderInfo,
          teacher_id,
        };
        console.log("toSubmitOpt", toSubmitOpt);

        let apiKey;

        switch (
          pageType //0: 新增订单 1: 编辑订单
        ) {
          case 0:
            apiKey = "addOrder";
            break;
          case 1:
            apiKey = "editOrder";
            toSubmitOpt.oid = orderID;
            break;
        }

        const { price } = this.orderProductInfo;
        const orderInfo = this.orderInfo;

        console.log("price", price);

        //付款金额和方式
        toSubmitOpt.pay_types = await this.handleCheckPayOrder({
          currentOrderPrice: price,
          orderInfo,
        });

        //提交
        const { msg } = await this.$ajax({
          apiKey,
          data: toSubmitOpt,
        });

        this.$bus_unique.emit("updateOrderList");
        this.$router.back(-1);

        this.$toast({
          msg,
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    /**
     * @description: 检查支付订单

     * @param {*}currentOrderPrice //当前需要支付的金额
     * @param {*} orderInfo //当前订单信息
     * @return {JSON String} 各支付方式 及 相应的金额
     */
    async handleCheckPayOrder({ currentOrderPrice, orderInfo }) {
      const pageType = this.pageType;
      const inputCenter_payOrder = this.inputCenter_payOrder;

      console.log("currentOrderPrice", currentOrderPrice);
      console.log("orderInfo", orderInfo);
      console.log("pageType", pageType);
      console.log("inputCenter_payOrder", inputCenter_payOrder);

      const { inputList = [] } = inputCenter_payOrder || {};
      const payWayOptions = this.$com.getValueByRules(inputList);

      console.log("inputList", inputList);

      //所有支付方式录入的总金额
      const recordedOrderPrice =
        inputList.length > 0
          ? Object.values(payWayOptions).reduce(
              (a, b) => parseFloat((Number(a) + Number(b)).toPrecision(12)) //避免因js数字精度问题导致金额相加有误
            )
          : 0;

      switch (
        pageType // pageType  1:编辑订单
      ) {
        case 1:
          //比较编辑前和编辑后的订单总价，多退（退到余额）少补（记录差价）
          const { product } = orderInfo;
          const {
            price: orderPrice_beforeEdit, //编辑之前的订单价格
          } = product.reduce((a, b) => {
            return {
              price: a.price + b.price,
            };
          });

          const orderPriceDiff = currentOrderPrice - orderPrice_beforeEdit; //订单差价

          if (orderPriceDiff > 0) {
            if (recordedOrderPrice < orderPriceDiff) {
              throw `请记录订单差价，还差${orderPriceDiff}`;
            }
          } else if (orderPriceDiff < 0) {
            await this.$confirm(
              `订单差价${orderPriceDiff}将退回到余额，请确认`
            );
          }

          break;
        // default:
        //   if (inputCenter_payOrder) {
        //     if (
        //       inputCenter_payOrder.inputList[6].value ||
        //       recordedOrderPrice == currentOrderPrice
        //     ) {
        //       await this.$confirm(`请确认是否支付？`);
        //     } else if (
        //       !inputCenter_payOrder.inputList[6].value &&
        //       recordedOrderPrice < currentOrderPrice
        //     ) {
        //       throw `输入金额小于订单金额, 还差${this.accSub(
        //         currentOrderPrice,
        //         recordedOrderPrice
        //       )}元`;
        //     }
        //   }

        //   break;
      }

      //整理：返回各支付方式 及 支付金额
      let payTypeList = [];

      inputList.forEach((item) => {
        const { props, payType, value } = item;

        if (payWayOptions[props] && value > 0) {
          payTypeList.push({
            pay_type: payType,
            money: value,
          });
        }
      });

      return JSON.stringify(payTypeList);
    },
    accSub(arg1, arg2) {
      var r1, r2, m, n;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
      n = r1 >= r2 ? r1 : r2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },

    //提交退款
    async handeSubmitRefund(id) {
      try {
        //提取输入值：退款理由，方式，退回账号等
        const requestOpt = this.$refs.refundInfoModules.getValueByParent();
        //提取输入值：选择的退款产品
        const selectedProduct =
          this.$refs.selectProductModules.getSelectedProduct();
        const selectedProductList = this.selectedProductList;

        let refundSkuList = [];

        selectedProduct.forEach((item, index) => {
          const { refundCount, refundPrice, refund_pack_num } = item;

          if (refundPrice && refundCount) {
            refundSkuList.push({
              op_id: selectedProductList[index].id, //op_id	是	int	订单关联产品id
              refund_count: refundCount, //refund_count	是	int	退回课时
              refund_price: refundPrice, //refund_price	是	decimal	退款金额
              // refund_pack_num: refund_pack_num,
            });
          }
        });

        if (refundSkuList.length <= 0) {
          throw "请输入至少一个完整的 退回金额 和 退回课时";
        }

        console.log("refundSkuList", refundSkuList);

        // if (
        //   Number(refundSkuList[0].refund_price) > 0 &&
        //   Number(refundSkuList[0].refund_pack_num) > 0
        // ) {
        //   throw "退回方式： 金额/课包 二选一";
        // }

        // if (
        //   Number(refundSkuList[0].refund_pack_num) > 0 &&
        //   requestOpt.refund_type !== 6
        // ) {
        //   throw "退回方式请选择为 ‘课包’ ";
        // }

        // if (
        //   Number(refundSkuList[0].refund_price) > 0 &&
        //   requestOpt.refund_type === 6
        // ) {
        //   throw "退回方式请选择为 ‘课包’ 除外的方式";
        // }

        requestOpt.oid = id;
        requestOpt.refund_products = JSON.stringify(refundSkuList);

        console.log("requestOpt", requestOpt);

        const { msg } = await this.$ajax({
          apiKey: "submitRefundOrder",
          data: requestOpt,
        });

        this.$router.back(-1);
        this.$bus_unique.emit("updateOrderList");
        this.$bus_unique.emit("updateUnCheckOrderQty"); //更新未审核的数量标识

        this.$toast({
          msg,
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    //更新选择的产品信息
    handleUpdateProductInfo(newProductInfo) {
      console.log("newProductInfo", newProductInfo);
      const { price } = (this.orderProductInfo = newProductInfo);
      const pageType = this.pageType;

      console.log("price", price);

      //pageType 0: 新增 1:编辑
      // && (pageType === 0 || pageType === 1)
      // if (price > 0) {
      //提取值：学生家长信息
      const { balance, ycf_balance } = this.$com.getValueByRules(
        this.inputList_parentInfo.inputList
      );
      console.log("ycf_balance", ycf_balance);

      // 回填记录付款方式
      let datas = null;
      if (pageType !== 0 && !this.ifRendering) {
        // console.log('this.orderInfo', this.orderInfo)
        datas = {
          balance, //账户余额
          ycf_balance, // 原存费
        };
        this.orderInfo.pay_info.forEach((item, index) => {
          console.log("item.money", item.money);
          // 支付方式 1余额 2银行卡 3现金 4微信 5支付宝 6原存费 7余额课包  8余额课时
          switch (item.pay_type) {
            case 1:
              datas.balanceVal = item.money;
              break;

            case 2:
              datas.bankCardVal = item.money;
              break;

            case 3:
              datas.cashVal = item.money;
              break;

            case 4:
              datas.wxVal = item.money;
              break;

            case 5:
              datas.zfbVal = item.money;
              break;

            case 6:
              datas.ycfVal = item.money;
              break;

            case 7:
              datas.packNumVal = item.pack_num;
              break;

            case 8:
              datas.hourNumVal = item.money;
              break;
          }
        });

        this.ifRendering = true;
      } else {
        datas = {
          balance, //账户余额
          ycf_balance, // 原存费
          balanceVal: price, //订单金额
        };
      }

      console.log("datas", datas);
      const inputList = this.getInputList_payMethods(datas);
      this.inputCenter_payOrder = { inputList };
      // }
    },

    //获取：学生信息
    getInputList_studentInfo() {
      const _tempValObj = {};

      const inputList = [
        //选择学生
        (_tempValObj.student = {
          type: "select",
          label: "学生姓名",
          placeholder: "请搜索/选择学生姓名",
          rules: ["required", "!null"],
          value: "",
          props: "sid",
          col: "col-3",
          options: [],
          remoteMethod: async (keyword) => {
            try {
              const {
                res: { data },
              } = await this.$ajax({
                apiKey: "getStudentList",
                data: {
                  student_name: keyword || "",
                },
              });

              _tempValObj.student.options = data.map((item) => {
                const { id, name, type_name } = item;

                return {
                  label: name,
                  value: id,
                  typeName: type_name,
                };
              });

              _tempValObj.studentList = data;
            } catch (error) {
              this.$catchError(error);
            }
          },
          onChange: async (val, thisObj) => {
            const { baseInfoList, studentList } = _tempValObj;
            const currentStudentInfo = studentList.find((item) => {
              return item.id === val;
            });

            // console.info('currentStudentInfo', currentStudentInfo)

            this.$com.fillData({
              inputList: baseInfoList,
              valueListObj: currentStudentInfo || {},
            });

            //触发更新：家长信息
            this.$bus_unique.emit("updateParentInfo", currentStudentInfo);
          },
        }),
        ...(_tempValObj.baseInfoList = [
          {
            type: "radio",
            label: "性别",
            placeholder: "请选择性别",
            disabled: true,
            rules: ["!null"],
            value: 1,
            props: "gender",
            options: [
              {
                label: "男",
                value: 1,
              },
              {
                label: "女",
                value: 2,
              },
            ],
            col: "col-3",
          },
          {
            type: "input",
            label: "出生日期",
            placeholder: "--",
            rules: ["!null"],
            value: "",
            props: "birth",
            col: "col-3",
            disabled: true,
          },
          {
            type: "input",
            label: "身份证号码",
            placeholder: "--",
            rules: ["!null"],
            value: "",
            props: "card",
            col: "col-3",
            disabled: true,
          },
          {
            type: "input",
            label: "所属学校",
            placeholder: "--",
            rules: ["!null"],
            value: "",
            props: "s_name",
            col: "col-3",
            disabled: true,
          },
          {
            type: "input",
            label: "所属校区",
            placeholder: "--",
            rules: ["!null"],
            value: "",
            props: "xq_name",
            col: "col-3",
            disabled: true,
          },
          {
            type: "input",
            label: "年级",
            placeholder: "--",
            rules: ["!null"],
            value: "",
            props: "grade",
            col: "col-3",
            disabled: true,
          },
          {
            type: "input",
            label: "所在班级",
            placeholder: "--",
            rules: ["!null"],
            value: "",
            props: "class",
            col: "col-3",
            disabled: true,
          },
        ]),
      ];

      _tempValObj.student.remoteMethod();

      return inputList;
    },

    //获取：家长信息
    getInputList_parentInfo() {
      const _tempValObj = {};

      const inputList = [
        (_tempValObj.parent = {
          type: "select",
          label: "家长联系电话",
          placeholder: "请选择家长联系电话",
          rules: ["required", "!null"],
          value: "",
          props: "mobile",
          col: "col-3",
          options: [],
          disabled: true,
          onChange: async (val, thisObj) => {
            const { otherInfo, parentList } = _tempValObj;

            const currentParentInfo = parentList.find((item) => {
              return item.id === val;
            });

            renderParentOtherInfo(currentParentInfo);
          },
        }),
        ...(_tempValObj.otherInfo = [
          {
            type: "input",
            label: "家长姓名",
            placeholder: "--",
            rules: ["!null"],
            value: "",
            props: "name",
            col: "col-3",
            disabled: true,
          },
          {
            type: "radio",
            label: "性别",
            placeholder: "请选择性别",
            disabled: true,
            rules: ["!null"],
            value: 1,
            props: "gender",
            options: [
              {
                label: "男",
                value: 1,
              },
              {
                label: "女",
                value: 2,
              },
            ],
            col: "col-3",
          },
          {
            type: "input",
            label: "家长与学生的关系",
            placeholder: "--",
            rules: ["!null"],
            value: "",
            props: "student_relation_name",
            col: "col-3",
            disabled: true,
          },
          {
            type: "input",
            label: "跟进客服/老师",
            placeholder: "--",
            rules: ["!null"],
            value: "",
            props: "teacher_name",
            col: "col-3",
            disabled: true,
          },
          {
            type: "input",
            label: "账户余额",
            placeholder: "--",
            rules: ["!null"],
            value: "",
            props: "balance",
            col: "col-3",
            disabled: true,
          },
          {
            type: "input",
            label: "原存费",
            placeholder: "--",
            rules: ["!null"],
            value: "",
            props: "ycf_balance",
            col: "col-3",
            disabled: true,
          },
          // {
          //   type: "input",
          //   label: "余额课包",
          //   placeholder: "--",
          //   rules: ["!null"],
          //   value: "",
          //   props: "course_pack_num",
          //   col: "col-3",
          //   disabled: true,
          // },
          {
            type: "input",
            label: "余额课时",
            placeholder: "--",
            rules: ["!null"],
            value: "",
            props: "class_hour_num",
            col: "col-3",
            disabled: true,
          },
        ]),
      ];

      //监听更新：家长信息
      this.$bus_unique.on(
        "updateParentInfo",
        "addOrEditOrder",
        (newParentInfo) => {
          if (newParentInfo) {
            const { parent_info, __currentParentID } = newParentInfo;
            // console.log('newParentInfo', newParentInfo)
            const { parent } = _tempValObj;

            let currentParentIndex = 0;

            //生成家长信息
            parent.options = parent_info.map((item, index) => {
              const { id, mobile } = item;
              // console.log('item', item)

              if (id === __currentParentID) {
                currentParentIndex = index;
              }

              return {
                label: `${mobile}`,
                value: id,
              };
            });

            parent.value = parent.options[currentParentIndex].value; //默认选中第一个value
            _tempValObj.parentList = parent_info; //保存家长列表

            let { pageType } = this.$route.query;

            pageType = Number(pageType);

            //0:新增 1： 编辑
            if (pageType === 0 || pageType === 1) {
              parent.disabled = false;
            }

            //生成家长其他信息
            renderParentOtherInfo(parent_info[currentParentIndex]);
          } else {
            //清空家长信息
            this.$com.fillData({
              inputList,
              valueListObj: {},
            });

            _tempValObj.parent.disabled = true;
          }
        }
      );

      //生成家长其他信息
      const renderParentOtherInfo = (inputedVal = {}) => {
        console.info("inputedVal", inputedVal);

        this.$com.fillData({
          inputList: _tempValObj.otherInfo,
          valueListObj: inputedVal,
        });

        this.pid = inputedVal.id;
      };

      return inputList;
    },

    getInputList_otherOrderInfo() {
      let { pageType } = this.$route.query;
      pageType = Number(pageType);

      return [
        {
          type: "input",
          label: "校付通订单编号",
          placeholder: "请输入校付通订单编号",
          //pageType： 0:新增 1：编辑
          disabled: pageType === 0 || pageType === 1 ? false : true,
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "xft_order_sn",
          col: "col-3",
        },
        {
          type: "input",
          label: "备注",
          placeholder: "请输入备注",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "remark",
          col: "col-3",
          disabled: pageType === 0 || pageType === 1 ? false : true,
        },
      ];
    },

    getinputList_otherOrderInfoType() {
      let { pageType } = this.$route.query;
      pageType = Number(pageType);

      return [
        {
          type: "input",
          label: "订单类型",
          placeholder: "请输入订单类型",
          disabled: true,
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "order_type_str",
          col: "col-3",
        },
      ];
    },

    getInputList_payMethods({
      balance,
      ycf_balance,
      balanceVal,
      bankCardVal,
      cashVal,
      wxVal,
      zfbVal,
      ycfVal,
      packNumVal,
      hourNumVal,
    }) {
      const _validValue = validValue;
      const _isAbleUseBalance = balance > 0;
      const _isAbleUseYcfBalance = ycf_balance > 0;

      let { pageType } = this.$route.query;
      pageType = Number(pageType);

      // console.log('balance', balance)
      // console.log('balanceVal', balanceVal)

      // 处理会员余额
      let balanceValCompute = "";
      if (pageType !== 2 && !balanceVal) {
        if (balance !== "0.00") {
          // console.log('新增、编辑没有输入值、有钱显示')
          balanceValCompute = "";
        } else {
          // console.log('新增、编辑没有输入值、没钱显示')
          balanceValCompute = "0.0";
        }
      } else if (
        (pageType === 2 && !balanceVal) ||
        (pageType !== 2 && balance === "0.00")
      ) {
        // console.log('新增、编辑没有钱显示 ---- 查看没有输入值显示')
        balanceValCompute = "0.0";
      } else if (pageType !== 2 && balanceVal) {
        if (balance < balanceVal) {
          // console.log('新增、编辑有值，值小于钱显示')
          balanceValCompute = balance;
        } else {
          // console.log('新增、编辑有值，值小于钱显示')
          balanceValCompute = balanceVal;
        }
      } else {
        // console.log('其他')
        balanceValCompute = balanceVal;
      }

      let ycfValCompute = "";
      if (pageType === 2) {
        ycfValCompute = !ycfVal ? "0.0" : ycfVal;
      } else {
        if (ycf_balance === "0.00") {
          ycfValCompute = "0.0";
        } else {
          ycfValCompute = ycfVal;
        }
      }

      let list = [
        {
          type: "input",
          label: "会员余额",
          placeholder: "请输入会员余额",
          maxLength: 40,
          rules: ["!null"],
          value: balanceValCompute,
          props: "balance",
          col: "col-3",
          disabled:
            !_isAbleUseBalance ||
            (pageType === 0 || pageType === 1 ? false : true),
          onChange: (thisObj) => {
            _validValue.inputMoney(thisObj);
          },
          payType: 1,
        },
        {
          type: "input",
          label: "原存费",
          placeholder: "请输入原存费",
          maxLength: 40,
          rules: ["!null"],
          value: ycfValCompute,
          props: "ycf_balance",
          col: "col-3",
          disabled:
            !_isAbleUseYcfBalance ||
            (pageType === 0 || pageType === 1 ? false : true),
          onChange: (thisObj) => {
            _validValue.inputMoney(thisObj);
          },
          payType: 6,
        },
        // {
        //   type: "input",
        //   label: "课包",
        //   placeholder: "请输入课包支付数量",
        //   maxLength: 40,
        //   rules: ["!null"],
        //   value: pageType === 2 && !packNumVal ? "0.0" : packNumVal,
        //   props: "pack_num",
        //   col: "col-3",
        //   disabled: pageType === 0 || pageType === 1 ? false : true,
        //   onChange: (thisObj) => {
        //     _validValue.inputMoney(thisObj);
        //   },
        //   payType: 7,
        // },
        {
          type: "input",
          label: "余额课时",
          placeholder: "请输入余额课时支付数量",
          maxLength: 40,
          rules: ["required", "!null"],
          value: pageType === 2 && !hourNumVal ? "0.0" : hourNumVal,
          props: "pack_num",
          col: "col-3",
          disabled: pageType === 0 || pageType === 1 ? false : true,
          onChange: (thisObj) => {
            _validValue.inputMoney(thisObj);
          },
          payType: 8,
        },
      ];
      if (pageType !== 0 && pageType !== 1) {
        list.push(
          {
            type: "input",
            label: "银行卡",
            placeholder: "请输入银行卡支付金额",
            maxLength: 40,
            rules: ["!null"],
            // value:
            //   needPayPrice - balance > 0
            //     ? parseFloat((needPayPrice - balance).toPrecision(12))
            //     : null,
            value: pageType === 2 && !bankCardVal ? "0.0" : bankCardVal,
            props: "bank_card",
            col: "col-3",
            disabled: pageType === 0 || pageType === 1 ? false : true,
            onChange: (thisObj) => {
              _validValue.inputMoney(thisObj);
            },
            payType: 2,
          },
          {
            type: "input",
            label: "现金",
            placeholder: "请输入现金支付金额",
            maxLength: 40,
            rules: ["!null"],
            value: pageType === 2 && !cashVal ? "0.0" : cashVal,
            props: "cash",
            col: "col-3",
            disabled: pageType === 0 || pageType === 1 ? false : true,
            onChange: (thisObj) => {
              _validValue.inputMoney(thisObj);
            },
            payType: 3,
          },
          {
            type: "input",
            label: "微信",
            placeholder: "请输入微信支付金额",
            maxLength: 40,
            rules: ["!null"],
            value: pageType === 2 && !wxVal ? "0.0" : wxVal,
            props: "wx",
            col: "col-3",
            disabled: pageType === 0 || pageType === 1 ? false : true,
            onChange: (thisObj) => {
              _validValue.inputMoney(thisObj);
            },
            payType: 4,
          },
          {
            type: "input",
            label: "支付宝",
            placeholder: "请输入支付宝支付金额",
            maxLength: 40,
            rules: ["!null"],
            value: pageType === 2 && !zfbVal ? "0.0" : zfbVal,
            props: "zfb",
            col: "col-3",
            disabled: pageType === 0 || pageType === 1 ? false : true,
            onChange: (thisObj) => {
              _validValue.inputMoney(thisObj);
            },
            payType: 5,
          }
        );
      }

      return list;
    },
  },
  computed: {
    footerInfoList() {
      return [
        {
          label: "总数量：",
          valueKey: "qty",
        },
        {
          label: "/ 总课时：",
          valueKey: "count",
        },
        {
          label: "/ 合计：",
          cssClass: "font-bold",
          valueKey: "price",
          state: "¥",
        },
      ];
    },

    //是否加载了管理员权限详情
    isLoadedAdminDetails() {
      const getters = this.$store.getters;
      const getAuthenIsPermitted = getters.getAuthenIsPermitted;
      const isLoadedAdminDetails = getters.isLoadedAdminDetails();
      const btnList = this.btnList;

      if (isLoadedAdminDetails === true) {
        btnList.forEach((item) => {
          const { authenKey } = item;
          const isGetAuthen = authenKey
            ? getAuthenIsPermitted(authenKey)
            : true;

          if (isGetAuthen === true) {
            this.$set(item, "isRender", true);
            this.getPermiseBtnQty = 1;
          }
        });
      }

      return isLoadedAdminDetails;
    },

    footerBtnList() {
      const getters = this.$store.getters;
      const getAuthenIsPermitted = getters.getAuthenIsPermitted;
      const isLoadedAdminDetails = getters.isLoadedAdminDetails();

      let btnList = [];
      let { pageType, id } = this.$route.query;

      pageType = Number(pageType);

      if (isLoadedAdminDetails) {
        if (pageType === 0) {
          btnList = [
            {
              title: "新增订单",
              authenKey: "order_add",
              onTap: () => {
                this.handleSubmit(pageType);
              },
            },
          ];
        } else if (pageType === 1) {
          btnList = [
            {
              title: "确定编辑",
              authenKey: "order_edit",
              onTap: () => {
                this.handleSubmit(pageType, id);
              },
            },
          ];
        } else if (pageType === 3) {
          btnList = [
            {
              title: "确定退款",
              authenKey: "order_refund",
              onTap: () => {
                this.handeSubmitRefund(id);
              },
            },
          ];
        }

        btnList = btnList.filter((item) => {
          return getAuthenIsPermitted(item.authenKey);
        });
      }

      return btnList;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-info-modules {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-bottom: -1.25rem;
  height: 3.7rem;
  background-color: #fff;

  .info-item {
    .label {
      font-size: 0.89rem;
      color: #333333;
    }

    .value {
      font-size: 0.89rem;
      color: #1891ff;

      &.font-bold {
        font-weight: bold;
      }
    }
  }

  .submit-btn {
    line-height: 3.7rem;
    background-color: #1891ff;
    border-radius: 0.17rem;
    font-size: 0.89rem;
    color: #ffffff;
  }
}
</style>
