<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-02 17:49:52
 * @FilePath     : \leXue_manage_pc\src\components_global\uploadAndPreviewImg\uploadAndViewImg.vue

 使用手册

----安装----

npm install swiper


----引入-----

1) html

<uploadAndViewImg
  class="my-3"
  :initOptions="initOptions_uploadImg"
></uploadAndViewImg>


2) js 

initOptions_uploadImg: {
  value: [],                //必填 | 已上传的图片地址
  maxLength: 1,             //必填 | 最大上传数量
  readonly: false,          //不必填 | 是否只读 | 默认值：false
  onUpload: async () => {   //不必填 | 上传后返回的图片路径 | 如果不填：选择图片后，以base64格式push到value 数组里
    // const { url: fileUrl } = await this.$ajax({
    //   apiKey: "uploadFile",
    //   isUploadFile: true,
    //   data: formData
    // });

    return "https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1607506153397&di=96acb3624f0cd193e6b346f66e0f8ec9&imgtype=0&src=http%3A%2F%2Fuploads.xuexila.com%2Fallimg%2F1610%2F761-16100P95048.jpg";
  },
},


-->

<template>
  <div>
    <!-- 图片上传管理模块 -->
    <div class="d-flex flex-wrap upload-img-list">
      <div
        class="col-4"
        v-for="(item, index) in uploadedImgList"
        :key="'uploadedImgList' + index"
        @click.stop="showThisBigImg(index)"
      >
        <div class="position-relative upload">
          <div class="position-absolute upload-box">
            <img :src="item" class="imgCover" />
            <i
              class="position-absolute add-icon del"
              v-if="!readonly"
              @click.stop="removeThisImg(index)"
            ></i>
          </div>
          <!-- 暂时注释 -->
          <!-- <div class="position-absolute pl-4 upload-txt">上传人</div> -->
        </div>
      </div>
      <div class="col-4" v-if="showUploadBtn && !readonly">
        <div class="position-relative upload" @click="triggerUpload()">
          <div class="position-absolute upload-box">
            <i class="position-relative add-icon"></i>
            <span class="add-txt">添加图片</span>
          </div>
          <!-- 调用相机 -->
          <input
            class="d-none"
            type="file"
            accept="image/*"
            capture="camera"
            @change="toUploadImg"
            ref="inputCamera"
          />
          <!-- 调用上传文件：安卓是打开相册，苹果是打开相册和相机 -->
          <input
            type="file"
            class="d-none"
            @change="toUploadImg"
            ref="uploadImgsBtn"
          />
        </div>
      </div>
    </div>
    <!-- 照片查看器 -->
    <transition
      enter-active-class="animated faster fadeIn"
      leave-active-class="animated faster fadeOut"
    >
      <photoView
        v-if="viewBigPhoto"
        :options="{
          imgList: uploadedImgList,
          initImgIndex: currentShowBigImgIndex,
        }"
        @closePhotoView="closePhotoView"
      ></photoView>
    </transition>
    <!-- 选择打开上传的方式 -->
    <chooseCameraOrImgFrame
      @android_openCarmera="android_openCarmera"
      @android_openLocationImg="android_openLocationImg"
      ref="chooseCameraOrImgFrame"
    ></chooseCameraOrImgFrame>
  </div>
</template>

<script>
import photoView from "./photoView.vue";
import chooseCameraOrImgFrame from "./chooseCameraOrImgFrame.vue";
import publicFun from "./js/public.js";

export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {
        {
        }
      },
    },
  },
  components: {
    photoView,
    chooseCameraOrImgFrame,
  },
  data() {
    return {
      viewBigPhoto: false,
      currentShowBigImgIndex: 0,
      uploadedImgList: [],
      // openChooseCameraOrImgFrame: false
    };
  },
  mounted() {
    this.isAndroid = this.isAndroid(); //正式
    this.uploadedImgList = this.initOptions.value;
  },
  methods: {
    // 是否是安卓系统
    isAndroid() {
      var u = navigator.userAgent;
      if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
        return true;
      } else {
        return false;
      }
    },

    // 安卓机专用---打开相机
    android_openCarmera() {
      this.$refs.inputCamera.click();
    },

    // 安卓机专用---打开本地相册
    android_openLocationImg() {
      this.$refs.uploadImgsBtn.click();
    },

    // 触发上传照片
    triggerUpload() {
      if (this.isAndroid === true) {
        // this.openChooseCameraOrImgFrame = true;
        this.$refs.chooseCameraOrImgFrame.render();
      } else {
        this.$refs.uploadImgsBtn.click();
      }
    },
    // 上传照片
    async toUploadImg(e) {
      try {
        if (this.isSubmitting_upload === true) throw "上传中，请稍后";

        const { onUpload } = this.initOptions;
        const { base64, file } = await this.readImg(e); //读取图片

        const uploadedImgList = this.uploadedImgList;
        const isExitedImg = uploadedImgList.find((item) => item === base64);

        if (this.isAndroid === true) {
          this.$refs.chooseCameraOrImgFrame.disRender();
        }

        if (isExitedImg) throw "请勿重复上传";

        //base64形式
        if (!onUpload) {
          uploadedImgList.push(base64);
        } else {
          //上传文件形式
          const formData = new FormData();
          formData.append("file", file);

          this.isSubmitting_upload = true;

          const imgUrl = await onUpload(formData);

          uploadedImgList.push(imgUrl);
        }
      } catch (error) {
        alert(error);
      } finally {
        this.isSubmitting_upload = false;
      }
    },

    // 读取本地图片
    readImg(e) {
      return new Promise((resolve, reject) => {
        let input = e.target;
        let file = input.files[0];

        let reader = new FileReader();

        publicFun.getPhotoOrientation(file);

        //  检测
        if (!/image\/\w+/.test(file.type)) {
          alert("必须为图片类型");
          return false;
        }

        reader.readAsDataURL(file);
        reader.onload = (e) => {
          let data;
          input.value = "";

          if (e.target.result.length <= 100 * 1024) {
            resolve({
              base64: e.target.result,
              file,
            });
            return false;
          }

          let img = new Image();

          img.src = e.target.result;
          img.onload = () => {
            data = publicFun.compress(img); //js canvas压缩图片
            resolve({
              base64: data,
              file,
            });
          };
        };
      });
    },
    // 删除上传的图片
    removeThisImg(index) {
      this.uploadedImgList.splice(index, 1);
    },
    // 查看大图
    showThisBigImg(index) {
      this.viewBigPhoto = true;
      this.currentShowBigImgIndex = index;
    },
    // 关闭查看大图
    closePhotoView() {
      this.viewBigPhoto = false;
    },
  },
  computed: {
    showUploadBtn() {
      const initOptions = this.initOptions;
      const uploadedImgList = this.uploadedImgList;

      let isRender = (initOptions.maxLength || 3) > uploadedImgList.length;

      return isRender;
    },
    //是否只读
    readonly() {
      const initOptions = this.initOptions;

      return initOptions && initOptions.readonly;
    },
  },
};
</script>

<style lang="scss">
.upload-img-list {
  margin-right: -0.5rem;
  margin-bottom: 0.5rem;

  .col-4 {
    padding: 0;
  }

  .upload {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    padding-bottom: calc(100% - 0.5rem);
    background-color: #f4f4f4;

    .upload-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    .add-icon {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      border: 2px solid #999;
      background-color: #fff;

      &::before,
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        content: "";
        display: block;
        width: 60%;
        height: 1px;
        border-radius: 1px;
        background-color: #999;
      }
      &::after {
        transform: rotate(90deg);
      }

      &.del {
        right: 0;
        top: 0;
        border-color: rgba(254, 123, 20, 0.6);
        transform: translate3d(30%, -30%, 0) rotate(45deg);

        &::before,
        &::after {
          background-color: rgba(254, 123, 20, 0.6);
        }
      }
    }
    .upload-txt {
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ddd;
      line-height: 2.5rem;
      font-size: 1rem;
      // color: #fff;
    }
    .add-txt {
      font-size: 0.65rem;
      color: #999;
    }
  }
}
</style>