<!--
 * @Description: 教学大纲-新增、编辑
-->
<template>
  <div class="pageBox">
    <div
      class="col overflow-scroll-y h-100"
      style="padding: 20px; padding-bottom: 100px;"
    >
      <!-- 基本信息 -->
      <infoWrapper class="my-10" title="基本信息" style="height: auto;">
        <div class="lineFormBox">
          <div class="lineForm_l px-20 pt-20">
            <el-form
              inline
              :model="infoForm"
              :rules="infoFormRules"
              ref="infoFormRef"
              label-width="auto"
              label-position="top"
              size="mini"
            >
              <el-form-item label="教学大纲名称：" prop="name">
                <el-input
                  v-model="infoForm.name"
                  placeholder="请输入教学大纲名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="课程体系名称：" prop="teaching_system_id">
                <el-select
                  v-model="infoForm.teaching_system_id"
                  placeholder="请选择课程体系名称"
                  filterable
                >
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="item in teaching_systemList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="教研组名称：" prop="teaching_group_id">
                <el-select
                  v-model="infoForm.teaching_group_id"
                  placeholder="请选择教研组名称"
                  filterable
                >
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="item in teaching_groupList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="产品：" prop="product_ids">
                <el-select
                  v-model="infoForm.product_ids"
                  placeholder="请选择产品"
                  filterable
                  multiple
                >
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="item in productList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="时间段：" prop="date">
                <el-date-picker
                  v-model="infoForm.date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <div class="lineForm_r">
            <el-button type="primary" size="mini" @click="infoFormSave">
              保存
            </el-button>
          </div>
        </div>
      </infoWrapper>
      <!-- 课程主题 -->
      <div v-if="themeShow">
        <div class="title-modules">
          <div
            class="d-flex align-items-center pl-20 py-10 title show-active-line"
          >
            <div class="text mr-20">课程主题</div>
            <el-button type="primary" size="mini" @click="themeAdd()">
              新增
            </el-button>
          </div>
        </div>
        <div
          class="lineFormBox"
          v-for="(item, index) in themeList"
          :key="index"
          style="margin-bottom: 12px;"
        >
          <div class="lineForm_l px-20 pt-20">
            <el-form
              inline
              :model="item"
              :rules="themeRules"
              :ref="'themeRef_' + index"
              label-width="auto"
              label-position="top"
              size="mini"
            >
              <el-form-item label="课程名称：" prop="name">
                <el-input
                  v-model="item.name"
                  placeholder="请输入课程名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="备课老师：" prop="bk_teacher_ids">
                <el-select
                  v-model="item.bk_teacher_ids"
                  placeholder="请选择备课老师"
                  filterable
                  multiple
                >
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="item in teacherList"
                    :key="item.id"
                    :disabled="item.status === 2"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="任课老师：" prop="rk_teacher_ids">
                <el-select
                  v-model="item.rk_teacher_ids"
                  placeholder="请选择任课老师"
                  filterable
                  multiple
                >
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="item in teacherList"
                    :key="item.id"
                    :disabled="item.status === 2"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="时间段：" prop="date">
                <el-date-picker
                  v-model="item.date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <div class="lineForm_r">
            <el-button type="primary" size="mini" @click="themeItemSave(index)">
              保存
            </el-button>
            <el-button
              type="danger"
              size="mini"
              @click="themeItemDel(item, index)"
            >
              删除
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="bottomTheBox" v-if="themeShow && themeList.length">
      <el-button size="medium" type="primary" @click="$router.go(-1)">
        返回
      </el-button>
    </div>
  </div>
</template>

<script>
import infoWrapper from "@/components/infoWrapper.vue";

export default {
  components: {
    infoWrapper,
  },
  data() {
    return {
      pageType: null,
      // 基本信息
      infoForm: {
        name: "",
        teaching_system_id: "",
        teaching_group_id: "",
        product_ids: [],
        date: "",
      },
      infoFormRules: {
        name: [
          { required: true, message: "请输入教学大纲名称", trigger: "blur" },
        ],
        teaching_system_id: [
          { required: true, message: "请选择课程体系名称", trigger: "change" },
        ],
        teaching_group_id: [
          { required: true, message: "请选择教研组名称", trigger: "change" },
        ],
        product_ids: [
          { required: true, message: "请选择产品", trigger: "change" },
        ],
        date: [{ required: true, message: "请选择时间段", trigger: "change" }],
      },
      teaching_systemList: [],
      teaching_groupList: [],
      productList: [],
      teacherList: [],
      // 课程主题
      themeShow: false,
      themeList: [],
      themeRules: {
        name: [{ required: true, message: "请输入课程名称", trigger: "blur" }],
        bk_teacher_ids: [
          { required: true, message: "请选择备课老师", trigger: "change" },
        ],
        rk_teacher_ids: [
          { required: true, message: "请选择任课老师", trigger: "change" },
        ],
        date: [{ required: true, message: "请选择时间段", trigger: "change" }],
      },
      pickerOptions: {
        firstDayOfWeek: 1,
        // 限制只能在开始和结束周期内选择
        disabledDate: (time) => {
          // 如果没有后面的 -8.64e7 就是不可以选择今天的
          const beginDate = new Date(this.infoForm.date[0]).getTime() - 8.64e7;
          const endDate = new Date(this.infoForm.date[1]).getTime();
          return beginDate > time.getTime() || endDate < time.getTime();
        },
      },
    };
  },
  computed: {
    // 判断是否显示课程主题
    ifShowThemeBox: function() {
      if (this.infoForm.id && this.themeList.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    let { pageType, id } = this.$route.query;
    console.log("pageType", pageType);
    this.pageType = Number(pageType);

    this.getTeachingSystem();
    this.getTeachingGroupList();
    this.getproductList();
    this.getteacher();

    // 获取详情
    if (id) {
      this.getInfo(id);
    }
  },
  methods: {
    // 获取课程体系
    async getTeachingSystem() {
      try {
        let { res } = await this.$ajax({
          apiKey: "curriculumSystemList",
          data: {
            paginate: 100,
            page: 1,
          },
        });
        // console.log('获取课程体系', res.data)
        this.teaching_systemList = res.data;
      } catch (error) {
        this.$catchError(error);
      }
    },
    // 获取教研组
    async getTeachingGroupList() {
      try {
        let { res } = await this.$ajax({
          apiKey: "teachingList",
          data: {
            paginate: 100,
            page: 1,
            status: 1,
          },
        });
        // console.log('获取教研组', res.data)
        this.teaching_groupList = res.data;
      } catch (error) {
        this.$catchError(error);
      }
    },
    // 获取产品
    async getproductList() {
      try {
        let { res } = await this.$ajax({
          apiKey: "getProdutList",
          data: {
            paginate: 100,
            page: 1,
          },
        });
        // console.log('获取产品', res.data)
        this.productList = res.data;
      } catch (error) {
        this.$catchError(error);
      }
    },
    // 获取老师列表
    async getteacher() {
      try {
        let { res } = await this.$ajax({
          apiKey: "teacherServiceList",
          data: {
            paginate: 100,
            page: 1,
            // status: 1,
          },
        });
        console.log("获取老师列表", res.data);
        this.teacherList = res.data;
      } catch (error) {
        this.$catchError(error);
      }
    },

    // 获取详情
    async getInfo(id) {
      try {
        let { res } = await this.$ajax({
          apiKey: "outline_info",
          data: { id },
        });
        console.log("获取详情", res);

        // 数据处理
        let infoForm = {
          id: res.id,
          name: res.name,
          teaching_system_id: res.teaching_system_id,
          teaching_group_id: res.teaching_group_id,
          date: [res.begin_date, res.end_date],
        };
        let product_ids = [];
        for (let i of res.product) {
          product_ids.push(i.id);
        }
        infoForm.product_ids = product_ids;
        this.infoForm = infoForm;
        console.log("this.infoForm", this.infoForm);

        if (res.theme.length) {
          let list = [];
          for (let i of res.theme) {
            // console.log('i', i)
            let newObj = {
              id: i.id,
              name: i.name,
              date: [i.begin_date, i.end_date],
            };
            let bk_teacher_ids = [];
            for (let j of i.bk_teacher) {
              bk_teacher_ids.push(j.id);
            }
            newObj.bk_teacher_ids = bk_teacher_ids;

            let rk_teacher_ids = [];
            for (let j of i.rk_teacher) {
              rk_teacher_ids.push(j.id);
            }
            newObj.rk_teacher_ids = rk_teacher_ids;

            list.push(newObj);
          }
          this.themeList = list;
        } else {
          this.themeAdd();
        }
        this.themeShow = true;
      } catch (error) {
        this.$catchError(error);
      }
    },

    // 基本信息保存
    infoFormSave() {
      // console.log('infoForm', this.infoForm)

      this.$refs.infoFormRef.validate(async (valid) => {
        if (!valid) return;

        try {
          let form = this.$globalFun.deepClone(this.infoForm);
          form.begin_date = form.date[0];
          form.end_date = form.date[1];
          delete form.date;
          form.product_ids = JSON.stringify(form.product_ids);
          // console.log('form', form)

          let { msg, res } = await this.$ajax({
            apiKey: "outline_basic_save",
            data: form,
          });
          this.$toast({
            msg,
          });

          if (this.pageType === 0 && !this.themeList.length) {
            this.infoForm.id = res.teaching_outline_id;
            this.themeAdd();
            this.themeShow = true;
          }
          // 更新列表
          this.$bus_unique.emit("updateSyllabusList");
        } catch (error) {
          this.$catchError(error);
        }
      });
    },

    // 课程主题
    // 新增
    themeAdd() {
      this.themeList.push({
        name: "",
        bk_teacher_ids: [],
        rk_teacher_ids: [],
        date: "",
      });
    },
    themeItemSave(index) {
      console.log("themeItemSave", this.themeList[index]);

      this.$refs["themeRef_" + index][0].validate(async (valid) => {
        if (!valid) return;

        try {
          let form = this.$globalFun.deepClone(this.themeList[index]);
          form.begin_date = form.date[0];
          form.end_date = form.date[1];
          delete form.date;
          form.bk_teacher_ids = JSON.stringify(form.bk_teacher_ids);
          form.rk_teacher_ids = JSON.stringify(form.rk_teacher_ids);
          form.teaching_outline_id = this.infoForm.id;
          console.log("form", form);

          let { msg, res } = await this.$ajax({
            apiKey: "outline_theme_save",
            data: form,
          });
          this.$toast({
            msg,
          });

          this.themeList[index].id = res.theme_id;
        } catch (error) {
          this.$catchError(error);
        }
      });
    },
    themeItemDel(item, index) {
      this.$confirm("是否删除此课程主题", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        if (this.themeList.length === 1) {
          return this.$catchError("需要保留一个课程主题");
        }
        try {
          console.log("item.id", item.id);
          item.id &&
            (await this.$ajax({
              apiKey: "outline_theme_del",
              data: {
                theme_id: item.id,
              },
            }));

          this.themeList.splice(index, 1);
          this.$toast({
            msg: "删除成功",
          });
        } catch (error) {
          this.$catchError(error);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pageBox {
  margin: -1.25rem;
  position: relative;
  height: calc(100% + 2.5rem);
}

::v-deep .lineFormBox {
  display: flex;
  justify-content: space-between;
  background: #fff;

  .lineForm_l {
    flex: 6;
    .el-form--label-top .el-form-item__label {
      padding: 0;
    }
    .el-form--inline .el-form-item {
      margin-right: 26px;
    }
  }
  .lineForm_r {
    background: #f9f9f9;
    width: 110px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .el-button + .el-button {
      margin: 0;
      margin-top: 10px;
    }
  }
}

.title-modules {
  background: #fff;

  .title {
    border-bottom: 1px solid #eaeaea;

    .text {
      font-size: 0.89rem;
      color: #333333;
    }

    &.show-active-line {
      position: relative;

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        content: "";
        display: block;
        width: 4px;
        height: 20px;
        background-color: #1891ff;
      }
    }

    .title-btn {
      padding-left: 0.55rem;
      padding-right: 0.55rem;
      background-color: #333744;
      border-radius: 0.2rem;
      border: solid 0.05rem #cccccc;
      line-height: 1.5rem;
      font-size: 0.7rem;
      color: #ffffff;
    }
  }
}

.bottomTheBox {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 30px;
  width: calc(100% - 208px);
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(218, 218, 218, 0.5);

  .el-button {
    margin: 0 14px;
  }
}
</style>
