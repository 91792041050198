<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
    }
  },
  destroyed() {
    this.$bus_unique.off('updateAdminList')
  },
  mounted() {
    this.tableOptions = this.getTableOptions()

    //监听：更新管理员列表
    this.$bus_unique.on('updateAdminList', 'adminList', () => {
      this.$refs.tableList.init()
    })
  },
  methods: {
    getTableOptions() {
      const { role_id: myRoleID } = this.$store.getters.getUserInfoByStore

      return {
        authenKey: 'admin_list',
        titleBtns: [
          {
            type: 'filter',
            title: '查询',
          },
          {
            title: '新增管理员',
            authenKey: 'admin_add',
            onTap: () => {
              this.$router.push({
                name: 'addOrEditAdmin',
                query: {
                  pageType: 0,
                },
              })
            },
          },
        ],
        search: {
          inputList: [
            {
              type: 'input',
              label: '管理员',
              placeholder: '',
              value: '',
              props: 'name', //name	否	string	管理员名称
              rules: ['!null'],
            },
            {
              type: 'input',
              label: '管理员手机号',
              placeholder: '',
              value: '',
              props: 'mobile',
              rules: ['!null'],
            },
          ],
        },
        headers: [
          {
            label: '管理员名',
            props: 'name',
          },
          {
            label: '手机号',
            props: 'mobile',
          },
          {
            label: '所属角色',
            props: 'roleName',
          },
          { label: '状态', props: 'status_str' },
        ],
        actionBtns: [
          {
            title: '查看',
            color: 'cyan',
            authenKey: 'admin_details',
            onTap: (options) => {
              this.$router.push({
                name: 'addOrEditAdmin',
                query: {
                  pageType: 2,
                  id: options.admin_id,
                },
              })
            },
          },
          {
            title: '编辑',
            color: 'deepBlue',
            authenKey: 'admin_edit',
            onTap: ({ admin_id, status }) => {
              try {
                if (Number(status) === 3) {
                  throw '当前管理员已被禁用'
                }

                //权限校验
                this.$com.authenCheck(this, [
                  {
                    key: 'admin_details',
                    rejectTips: '管理员详情',
                  },
                ])

                this.$router.push({
                  name: 'addOrEditAdmin',
                  query: {
                    pageType: 1,
                    id: admin_id,
                  },
                })
              } catch (error) {
                this.$catchError(error)
              }
            },
          },
          {
            title: '修改密码',
            color: 'cyan',
            authenKey: 'admin_edit',
            onTap: (options) => {
              this.$router.push({
                name: 'adminEditPd',
                query: {
                  id: options.admin_id,
                },
              })
            },
          },
          {
            title: '删除',
            color: 'red',
            authenKey: 'admin_del',
            onDelete: async ({ admin_id }) => {
              try {
                if (myRoleID === admin_id) {
                  throw '不可删除自己'
                }

                return await this.$ajax({
                  responseAll: true,
                  apiKey: 'delAdmin',
                  data: {
                    id: admin_id,
                  },
                })
              } catch (error) {
                throw error
              }
            },
          },
          {
            type: 'multiState', //多状态按钮
            props: 'status',
            authenKey: 'admin_changeStatus',
            options: [
              {
                label: '启用',
                value: 2,
                color: 'success',
              },
              {
                label: '禁用',
                value: 1,
                color: 'warning',
              },
            ],
            onTap: async (btnObj, tableItem) => {
              this.$resureDialog({
                ok: async () => {
                  try {
                    const { admin_id, status } = tableItem
                    const afterStatus = Number(status) === 1 ? 2 : 1

                    const { msg } = await this.$ajax({
                      apiKey: 'changeAdminStatus',
                      data: {
                        id: admin_id, //admin_idp	是	int	用户ID
                        status: afterStatus, //status	是	int	类型 1启用 2删除(暂无) 3禁用
                      },
                    })

                    tableItem.status = afterStatus

                    this.$toast({
                      msg,
                    })
                    this.$refs.tableList.init()
                  } catch (error) {
                    this.$catchError(error)
                  }
                },
              })
            },
          },
        ],
        getData: async (options) => {
          let requestRet = await this.$ajax({
            apiKey: 'getAdminList',
            data: options,
          })

          requestRet.res.data.forEach((item) => {
            const { status, roles } = item

            item.roleName = roles && roles[0] && roles[0].name
          })

          return requestRet
        },
      }
    },
  },
}
</script>
