<!--
 * @Description: 教学大纲-查看

课程主题（权限，超级管理员拥有所有、待启用和结束无法操作）: 
 一、教案：
  上传、撤回：备课老师
  下载：任课老师、备课老师、教研组
  
 二、考核表：
  评分：教研组
  下载：自己、教研组
 
 三、美篇链接
   显示操作：备课老师
   显示链接：其他人
-->
<template>
  <div class="pageBox">
    <div class="col overflow-scroll-y h-100" style="padding: 20px;">
      <!-- 基本信息 -->
      <infoWrapper class="my-10" title="基本信息" style="height: auto;">
        <div class="basicInfo">
          <div class="itemLine">
            <div class="itemLine_label">教学大纲名称</div>
            <div class="itemLine_value">{{ info.name }}</div>
          </div>
          <div class="itemLine">
            <div class="itemLine_label">课程体系名称</div>
            <div class="itemLine_value">{{ info.teaching_system_name }}</div>
          </div>
          <div class="itemLine">
            <div class="itemLine_label">教研组名称</div>
            <div class="itemLine_value">{{ info.teaching_group_name }}</div>
          </div>
          <div class="itemLine">
            <div class="itemLine_label">产品</div>
            <div class="itemLine_value">{{ info.productName }}</div>
          </div>
          <div class="itemLine">
            <div class="itemLine_label">时间段</div>
            <div class="itemLine_value">
              {{ info.begin_date }} 至 {{ info.end_date }}
            </div>
          </div>
          <div class="itemLine">
            <div class="itemLine_label">状态</div>
            <div
              class="itemLine_value"
              :style="{ color: statusColorList[info.status] }"
            >
              {{ info.status_str }}
            </div>
          </div>
        </div>
      </infoWrapper>
      <!-- 课程主题 -->
      <infoWrapper
        class="my-10"
        title="课程主题"
        style="height: auto;"
        v-if="info.theme"
      >
        <div class="topicsBox" v-if="info.theme.length">
          <div
            class="topics_item"
            v-for="(item, index) in info.theme"
            :key="index"
          >
            <!-- 基本信息 -->
            <TopicsInfo :dataItem="item"></TopicsInfo>
            <div class="topics_item_tabs">
              <div
                class="tabs_item "
                :class="{ tabs_item_select: item.itemTabs === tabsIndex }"
                v-for="(tabsItem, tabsIndex) in topicsTabsList"
                :key="tabsIndex"
                @click="onTabsItem(item, tabsIndex)"
              >
                {{ tabsItem }}
              </div>
            </div>
            <div class="topics_item_content">
              <!-- 教案 -->
              <TopicsLessonPlan
                v-show="item.itemTabs === 0"
                :dataItem="item"
                :operationButton="operationButtonList[index]"
              ></TopicsLessonPlan>
              <!-- 考核表 -->
              <TopicsProgressReview
                v-show="item.itemTabs === 1"
                :dataItem="item"
                :operationButton="operationButtonList[index].assess_button"
              ></TopicsProgressReview>
              <!-- 美篇链接 -->
              <TopicsArticleLinks
                v-show="item.itemTabs === 2"
                :status="info.status"
                :dataItem="item"
                :operationButton="operationButtonList[index].mp_button"
              ></TopicsArticleLinks>
            </div>
          </div>
        </div>
        <div v-else class="description">暂无内容</div>
      </infoWrapper>
      <!-- 操作日志 -->
      <infoWrapper
        class="mt-10"
        title="操作日志"
        style="height: auto;"
        v-if="ifOperationButton('syllabusOperationLog')"
      >
        <div class="outlineLogBox">
          <tableList
            ref="tableList"
            v-if="tableOptions"
            :initOptions="tableOptions"
          ></tableList>
        </div>
      </infoWrapper>
    </div>
  </div>
</template>

<script>
import infoWrapper from '@/components/infoWrapper.vue'
import TopicsInfo from './showTopicsInfo.vue'
import TopicsLessonPlan from './showTopicsLessonPlan.vue'
import TopicsProgressReview from './showTopicsProgressReview.vue'
import TopicsArticleLinks from './showTopicsArticleLinks.vue'

export default {
  components: {
    infoWrapper,
    TopicsInfo,
    TopicsLessonPlan,
    TopicsProgressReview,
    TopicsArticleLinks,
  },
  data() {
    return {
      info: {},
      topicsTabsList: ['教案', '考核表', '美篇链接'],
      tableOptions: null,
      operationButtonList: [],
      statusColorList: ['', '#FF0000', '#1891FF', '#333333'],
    }
  },
  async created() {
    let { id } = this.$route.query
    await this.getOperationButton(id)
    this.getInfo(id)
  },
  mounted() {
    //监听
    this.$bus_unique.on('showSyllabusUpdateInfo', 'showSyllabus', () => {
      this.getInfo(this.info.id)
    })
    this.$bus_unique.on('uploadFileOrRejected', 'showSyllabus', () => {
      this.getOperationButton(this.info.id)
    })
  },
  methods: {
    // 获取详情
    async getInfo(id) {
      try {
        let { res } = await this.$ajax({
          apiKey: 'outline_theme_info',
          data: { id },
        })
        console.log('获取详情', res)

        // 数据处理
        let productName = []
        for (let i of res.product) {
          productName.push(i.name)
        }
        productName = productName.join(',')
        res.productName = productName

        if (res.theme) {
          for (let i of res.theme) {
            i.itemTabs = 0
          }
        }

        this.info = res

        this.$nextTick(() => {
          this.tableOptions = this.getTableOptions()
        })
      } catch (error) {
        this.$catchError(error)
      }
    },
    // 获取详情可操作按钮
    async getOperationButton(id) {
      try {
        let { res } = await this.$ajax({
          apiKey: 'operation_button',
          data: { id },
        })
        console.log('获取详情可操作按钮', res)
        this.operationButtonList = res
      } catch (error) {
        this.$catchError(error)
      }
    },

    // 选项卡切换
    onTabsItem(item, index) {
      item.itemTabs = index
    },

    // 获取操作日志
    getTableOptions() {
      return {
        authenKey: '',
        headers: [
          {
            label: '操作人姓名',
            props: 'operator',
          },
          {
            label: '操作时间',
            props: 'created_at',
          },
          {
            label: '操作内容',
            props: 'log_info',
          },
          // {
          //   label: '登录ip',
          //   props: 'log_ip',
          // },
        ],
        getData: async (options) => {
          console.log('options', options)
          options.id = this.info.id
          const ret = await this.$ajax({
            apiKey: 'outline_log_index',
            data: options,
          })

          return ret
        },
      }
    },

    // 权限按钮判断
    ifOperationButton(authenKey) {
      const getters = this.$store.getters
      const getAuthenIsPermitted = getters.getAuthenIsPermitted
      const isGetAuthen = getAuthenIsPermitted(authenKey)
      // console.log('isGetAuthen', isGetAuthen)
      return isGetAuthen
    },
  },
}
</script>

<style lang="scss" scoped>
.pageBox {
  margin: -1.25rem;
  position: relative;
  height: calc(100% + 2.5rem);

  .basicInfo {
    display: flex;
    flex-wrap: wrap;
    padding: 22px 0 0;
  }

  .itemLine {
    width: 25%;
    padding-left: 30px;
    font-size: 14px;
    margin-bottom: 22px;

    .itemLine_label {
      color: #999;
    }
    .itemLine_value {
      margin-top: 2px;
      color: #333333;
    }
  }

  .topicsBox {
    padding: 22px 38px 0;

    .topics_item {
      margin-bottom: 40px;

      .topics_item_tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tabs_item {
          width: 32%;
          background: #f0f2f5;
          // border-top-left-radius: 10px;
          // border-top-right-radius: 10px;
          text-align: center;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          color: #606060;
          cursor: pointer;
        }
        .tabs_item_select {
          background: #1891ff;
          color: #fff;
        }
      }
      .topics_item_content {
        background: #f9f9f9;
        overflow: hidden;
        border-radius: 0px 0px 10px 10px;
      }
    }
  }

  .description {
    padding: 20px;
    text-align: center;
  }

  .outlineLogBox {
    padding: 20px;
    height: 600px;
  }
}
</style>
