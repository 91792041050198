<!--
 * @Date         : 2020-12-10 11:24:08
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-13 17:58:16
 * @FilePath     : \leXue_manage_pc\src\views\home.vue
-->
<template>
  <div class="h-100 overflow-scroll">
    <dataBoard v-if="$store.state.ifAdmin"></dataBoard>
    <div class="flipBox" v-else>
      <flip-clock />
    </div>
  </div>
</template>

<script>
import dataBoard from '@/views/other/dataBoard.vue'
import FlipClock from 'kuan-vue-flip-clock'

export default {
  components: {
    dataBoard,
    FlipClock,
  },
}
</script>

<style lang="scss" scoped>
.flipBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}
</style>
