/*
 * @Date         : 2020-11-18 09:36:19
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-11-16 10:02:34
 * @FilePath     : \leXue_manage_pc\src\assets\js\ajax.js
 * 
 * 
 * 更新记录：
 * 2021/04/16  升级简化 transDataAsForm方法
 

    引入 main.js

        import { Ajax } from '@/assets/js/ajax.js';

        const newAjax = new Ajax(vue.prototype);

        vue.prototype.$ajax = function (options) {
            return newAjax.request(options, this);
        };
    
    调用
    
    方式一  常规操作 POST 和 GET
        const { res } = await this.$ajax({
            url: '', || apiKey： ''
            data: {
                
            }
        });

    方式二  导出文件
        await this.$ajax({
            isExportFile: true, //导出文件
            apiKey: "getB2BBillList",
            fileName: "B2C账单.xls",
            data: {

            },
        });

    方式二  表单提交
        await this.$ajax({
            apiKey: "uploadFile",
            submitByForm: true,
            data: {
            file,
            },
        });
 */


import axios from "axios";
import apiObj from "@/assets/js/api.js";
// import { stringify } from "qs";
import exportFile from '@/assets/js/exportFile.js';
import appConfig from '@/assets/js/appConfig.js';

class Ajax {
    constructor(vue) {
        this.domainName = appConfig.httpDomainName;

        this.axios = axios;
        this.apiObjList = apiObj;
        this.Vue = vue;

        this.toReSendAjaxAfterLogin = [];
        this.addEvt_onLoginSuccess();
    }

    request(requestOptions = {}, $vue) {
        return new Promise(async (resolve, reject) => {
            try {
                let {
                    url,
                    apiKey,
                    data,
                    methods,
                    ContentType,
                    submitByForm,
                    isExportFile,
                    fileName,
                } = requestOptions;

                let apiOpt = this.apiObjList[apiKey];
                let api;
                let transformData;

                if (typeof apiOpt === 'string') {
                    api = apiOpt
                } else {
                    api = apiOpt.url;
                    transformData = apiOpt.transformData; //请求返回的数据进行数据格式转换
                }

                const requestUrl = url || this.domainName + api;
                const token = localStorage.getItem("token") || "";

                if (!url && !api) {
                    throw '请求地址有误';
                }

                this.tryLoading();

                let ajaxRet;

                if (submitByForm) {
                    data = this.transDataAsForm(data); //v1方案，直接转化为form
                    // data = stringify(data); //v2方案，使用qs库
                }

                if (!isExportFile) {
                    //请求发送
                    ajaxRet = await axios({
                        url: requestUrl,
                        method: methods || 'POST',
                        headers: {
                            "Content-Type": ContentType || "application/x-www-form-urlencoded",
                            "token": token,
                        },
                        transformRequest: !submitByForm ? [this.transformRequest] : [],
                        data: data || {},
                    });
                } else {
                    //下载文件
                    if (!fileName) {
                        throw '缺少参数：文件名';
                    }

                    ajaxRet = await exportFile({
                        url: requestUrl,
                        data,
                        fileName,
                        methods,
                        submitByForm,
                        token,
                    });
                    if (!ajaxRet) {
                        return false;
                    }
                }

                //请求完毕：处理正常数据
                this.handleRequestResponse({
                    transformData,
                    requestOptions,
                    ajaxRet,
                    reject,
                    resolve,
                    $vue
                });

            } catch (error) {
                console.error('有错误：请求', error);
                reject(error);
            } finally {
                this.tryHideLoading();
            }
        });
    };

    //请求完毕：处理正常数据
    handleRequestResponse({
        transformData,
        requestOptions,
        ajaxRet,
        reject,
        resolve,
        $vue
    }) {
        const { status, data } = ajaxRet;
        const { msg, code, res } = data || {};

        switch (status) {
            case 200:
                console.info('请求成功：', data);

                if (Number(code) === - 1) {
                    throw msg;
                }

                //去登录
                if (code === -90001) {
                    //将需要登录后才能发起请求的ajax存起来
                    const toReSendAjaxAfterLogin = this.toReSendAjaxAfterLogin;

                    toReSendAjaxAfterLogin.push({
                        requestOptions,
                        resolve,
                        reject,
                    });

                    if (toReSendAjaxAfterLogin.length === 1) {
                        $vue.$store.commit('toLogin', $vue);
                        throw msg;
                    }

                    return false;
                }

                //没权限
                if (code === -90002) {
                    throw msg;
                }

                resolve(transformData ? transformData(data) : data);
                break;
            case 500:
                throw '网络欠佳';
            default:
                throw '网络请求出错，未知错误';
        }
    }

    //监听：登录成功
    addEvt_onLoginSuccess() {
        this.Vue.$bus_unique.on('loginSuccess', 'ajax', () => {
            this.reSendRequestAfterLogined(); //重新发起请求
        });
    }

    //重新发起请求
    reSendRequestAfterLogined() {
        const toReSendAjaxAfterLogin = this.toReSendAjaxAfterLogin;

        toReSendAjaxAfterLogin.forEach(async item => {
            const {
                requestOptions,
                resolve,
                reject,
            } = item;

            try {
                const currentRet = await this.request(requestOptions);

                resolve(currentRet);
            } catch (error) {
                reject(error);
            }
        });

        toReSendAjaxAfterLogin.length = 0;

    }

    tryLoading() {
        clearTimeout(this.showLoadingTimer);

        this.showLoadingTimer = setTimeout(() => {
            this.openedLoading = true;
            this.Vue.$showLoading();
        }, 200);
    }

    tryHideLoading() {
        clearTimeout(this.showLoadingTimer);

        if (this.openedLoading === true) {
            this.openedLoading = false;
            this.Vue.$hideLoading();
        }
    }

    transformRequest(data) {
        let ret = '';
        let _encodeURIComponent = encodeURIComponent;

        let transData = (data) => {
            for (let key in data) {
                const item = data[key];
                if (item.__proto__.constructor === Array) {
                    for (let index = 0, len = item.length; index < len; index++) {
                        ret += _encodeURIComponent(key + '[]') + '=' + _encodeURIComponent(item[index]) + '&';
                    }
                } else {
                    ret += _encodeURIComponent(key) + '=' + _encodeURIComponent(item) + '&';
                }
            }
        }

        console.info('data', data)
        transData(data);

        return ret;
    };


    /**
     * @description: //转化为form提交
     * @param {*} data
        data: {
            name: 'cxx',
            children: [{
                id: 1,
                age: 2,
            }, {
                id: 11,
                age: 22,
            },
            ]
        }
     * @return        
     *  name: 'cxx',
        children[0]id: 1,
        children[0]age: 2,
        children[1]id: 11,
        children[1]age: 22,
     */
    transDataAsForm(data) {
        const formData = new FormData();

        const trans = ((parentKey, data) => {
            const dataType = typeof data;
            const isFile = data.type && (/image/.test(data.type) || /application/.test(data.type)); //是文件类型

            if (dataType === 'object' && data !== null && !isFile) {
                //遍历解析
                for (let key in data) {
                    const value = data[key];

                    //开始递归，继续解析
                    trans(parentKey ? `${parentKey}[${key}]` : key, value);
                }
            } else {
                //结束递归，赋值数据
                formData.append(
                    parentKey,
                    data
                );
            }
        });

        trans('', data);

        return formData;
    };
}

export {
    Ajax
};