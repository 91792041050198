<!--
 * @Date         : 2020-11-24 16:47:36
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-06 16:21:49
 * @FilePath     : \leXue_manage_pc\src\components\sideTable.vue
 

 竖着的表格，配置参数与 tableList.vue 类似


    getInputTable() {
      return {
        title: "总校列表",
        authenKey: "school_list",
        header: {
          //右上角的按钮栏
          rightTopBtns: [
            {
              title: "新增总校",
              authenKey: "school_add",
              onTap: (options) => {
                this.renderAddOrEditSchoolDialog(1);
              },
            },
          ],

          //搜索的输入条件
          inputList: [
            {
              type: "input",
              placeholder: "请输入总校名称",
              maxLength: 50,
              rules: ["required", "!null"],
              value: null,
              props: "name",
              actionBtns: [
                {
                  type: "filter",
                  title: "查询",
                },
              ],
            },
            {
              type: "cascader",
              label: "区域",
              placeholder: "请选择省市区",
              rules: ["required", "!null"],
              value: null,
              props: "region_ids",
              lazyProps: {
                lazy: true,
                multiple: true,
                lazyLoad: async (node, resolve) => {
                  console.info("node", node);

                  try {
                    const { parentID, value, level } = node;
                    const isLastLevel = level >= 2;

                    const {
                      res: { data },
                    } = await this.$ajax({
                      apiKey: "getRegionList",
                      data: {
                        parent_id: value || 1, //parent_id	否	int	父级id，默认1
                        paginate: 50,
                      },
                    });

                    let nodes = data.map((item) => {
                      const {
                        region_id,
                        region_name,
                        parent_id,
                        region_type,
                      } = item;

                      return {
                        value: region_id,
                        label: region_name,
                        parentID: parent_id,
                        leaf: isLastLevel,
                      };
                    });

                    // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                    resolve(nodes);
                  } catch (error) {
                    this.$catchError(error);
                  }
                },
              },
            },
          ],
        },
        body: {
          td: {
            checkType: "single", //必填 | 选择的类型 | 参数：mutil(多选) single(单选)
            props: "name",

            //表身中的table item的操作按钮
            actionBtns: [ 
              {
                type: "normal", //必填 | 按钮类型 | 参数: del(删除)
                iconfont: "icon-edit", //必填 | 按钮的iconfont | 参数：iconfont.css
                authenKey: "school_edit", //必填 | 权限配置key
                //必填 | 编辑的请求 | 类型：Promise
                onTap: (tableItem) => {
                  this.renderAddOrEditSchoolDialog(2, tableItem);
                },
              },
              {
                type: "del", //必填 | 按钮类型 | 参数: del(删除)
                iconfont: "icon-del", //必填 | 按钮的iconfont | 参数：iconfont.css
                authenKey: "school_del", //必填 | 权限配置key
                //必填 | 删除 | 类型：Promise
                onDelete: async (tableItem) => {
                  const { id } = tableItem;

                  const { msg } = await this.$ajax({
                    apiKey: "delSchool",
                    data: {
                      id,
                    },
                  });
                },
              },
            ],

            //点击一整行表格
            onTap: (options) => {
              try {
                const lastID = this.lastID;
                const { id } = options;

                if (lastID === id) {
                  return false;
                }

                this.lastID = id;
                this.$emit("change", options);
              } catch (error) {
                this.$catchError(error);
              }
            },
          },
        },

        //获取table数据
        getData: async (options) => {
          const { region_ids } = options;

          if (region_ids) {
            options.region_ids = JSON.stringify(
              region_ids.map((item) => item[item.length - 1])
            );
          }

          return await this.$ajax({
            apiKey: "getSchoolList",
            data: options,
          });
        },
      };
    },

-->

<template>
  <div
    class="d-flex flex-column h-100 side-table"
    v-if="isGetRenderTableAuthen"
  >
    <!-- 模块：头部 -->
    <div class="header-modules">
      <div class="d-flex align-items-center py-10">
        <div class="col header-title">{{ initOptions.title }}</div>
        <div
          v-for="(item, index) in initOptions.header.rightTopBtns"
          :key="index"
        >
          <comBtn
            size="small"
            color="blue"
            v-if="item.isRender"
            @click="item.onTap && item.onTap(item)"
            >{{ item.title }}</comBtn
          >
        </div>
      </div>
    </div>

    <!-- 模块：搜索 -->
    <div class="search-modules">
      <div
        class="d-flex align-items-center py-10"
        v-for="(item, index) in initOptions.header.inputList"
        :key="index"
      >
        <div class="col pr-10">
          <!-- input输入框 -->
          <el-input
            v-if="item.type === 'input'"
            v-model="item.value"
            :placeholder="item.placeholder"
          ></el-input>

          <!-- 级联选择 -->
          <el-cascader
            class="w-100"
            size="small"
            v-else-if="item.type === 'cascader'"
            v-model="item.value"
            :placeholder="item.placeholder"
            :options="item.options"
            :clearable="true"
            :disabled="item.disabled"
            :key="item.key"
            :expandTrigger="item.expandTrigger || 'click'"
            @expand-change="
              item.onExpandChange && item.onExpandChange($event, item)
            "
            @change="item.onChange && item.onChange($event, item)"
            @clear="item.onClear && item.onClear($event, item)"
            :props="item.lazyProps"
          ></el-cascader>
        </div>
        <comBtn
          size="small"
          color="blue"
          v-for="(itemBtn, index) in item.actionBtns"
          :key="index"
          @click="handleTapInputBtns(itemBtn, item)"
          >{{ itemBtn.title }}</comBtn
        >
      </div>
    </div>

    <!-- 模块：主体 -->
    <div class="col position-relative overflow-hidden my-10 body-modules">
      <div class="position-absolute tips-text" v-if="contentList === null">
        加载中，请稍候
      </div>
      <div class="inner-box h-100" v-else-if="contentList.length > 0">
        <div
          class="list-item"
          v-for="(item, index) in contentList"
          :key="index"
          :class="{ active: item[tdProps] === currentSelect }"
          @click="handleTapTableItem(item)"
        >
          <div class="d-flex align-items-center py-10 px-15">
            <div class="col title">{{ item[tdProps] }}</div>
            <div
              v-for="(itemBtn, indexBtn) in initOptions.body.td.actionBtns"
              :key="indexBtn"
            >
              <div
                class="iconfont cursor-pointer"
                v-if="itemBtn.isRender"
                :class="itemBtn.iconfont"
                @click.stop="handleTapTdActionBtns(item, itemBtn, index)"
              ></div>
            </div>
            <div class="iconfont icon-right"></div>
          </div>
        </div>
      </div>
      <div
        class="position-absolute tips-text"
        v-else-if="contentList.length === 0"
      >
        暂无数据~
      </div>
    </div>

    <!-- 模块：分页器 -->
    <el-pagination
      center
      small
      layout="prev, pager, next"
      :current-page="pageObj.currentPage"
      :page-size="pageObj.perPageSize"
      :total="pageObj.totalSize || 50"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>


<script>
export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      checkList: ["选中且禁用", "复选框 A"],
      contentList: null,
      pageObj: {
        currentPage: 1,
        totallPage: 1,
        totalSize: null,
        perPageSize: 20,
      },
      editTableItem: null,
      currentSelect: null,
    };
  },
  methods: {
    init() {
      const { currentPage } = this.pageObj;

      this.getTableList(currentPage, {});
    },

    //获取列表
    async getTableList(getPage, keywordObj) {
      const _initOptions = this.initOptions;

      try {
        if (!getPage) throw "页数有误";

        let toSubmitOptions = {
          page: getPage, //page	否	int	当前页码
          paginate: this.pageObj.perPageSize,
          ...keywordObj,
        };

        let { res: tableData } = await _initOptions.getData(toSubmitOptions);

        const {
          data: contentList,
          last_page: totallPage,
          current_page: currentPage,
          total: totalSize,
          per_page: perPageSize,
        } = tableData;

        // console.info("tableData", tableData);

        const tdProps = this.tdProps;

        //默认全部不选中
        contentList.forEach((item) => {
          item.value = false;
          item.label = item[tdProps];
        });

        this.contentList = contentList;
        this.pageObj = {
          totallPage,
          currentPage,
          totalSize,
          perPageSize: Number(perPageSize),
        };
      } catch (error) {
        this.$toast({
          color: "error",
          msg: error,
        });

        throw error;
      }
    },

    async handleTapInputBtns(thisBtn, thisBtnParentObj) {
      try {
        const { type, onTap } = thisBtn;
        const inputList = this.initOptions.header.inputList;

        switch (
          type //add：新增  //filter：
        ) {
          case "add":
            await (onTap && onTap(this.inputedValue));
            await this.init();

            break;
          case "filter":
            await this.getTableList(1, this.inputValue_unRequired);
            break;
        }
      } catch (error) {
        this.$catchError(error);
      }
    },

    //点击td操作按钮
    async handleTapTdActionBtns(tableItem, thisActionBtn, tableIndex) {
      try {
        const { type, onDelete, onTap } = thisActionBtn;

        if (!type) throw "缺少按钮类型";

        switch (type) {
          case "del":
            this.$resureDialog({
              ok: async () => {
                try {
                  onDelete && (await onDelete(tableItem));

                  this.contentList.splice(tableIndex, 1); //删除
                  this.$toast({
                    msg: "删除成功",
                  });
                } catch (error) {
                  this.$catchError(error);
                }
              },
            });
            break;
          case "normal":
            onTap && onTap(tableItem, thisActionBtn, tableIndex);
            break;
        }
      } catch (error) {
        this.$catchError(error);
      }
    },

    //删除table  item
    handleDeleteItem(actionItem, tableItem, index) {
      this.$resureDialog({
        ok: async () => {
          try {
            const { onTap, onDelete, delSuccess } = actionItem;
            const { msg } = await onDelete(tableItem, index);

            const contentList = this.contentList;
            const currentPage = this.pageObj.currentPage;
            const totallPage = this.pageObj.totallPage;

            this.init();
            delSuccess && delSuccess(); //删除成功后的回调函数

            this.$toast({
              color: "success",
              msg,
            });

            return false;
          } catch (error) {
            this.$toast({
              color: "error",
              msg: error,
            });
          }
        },
      });
    },

    //点击tableItem
    handleTapTableItem(tableItem) {
      const { onTap, props } = this.initOptions.body.td;

      onTap && onTap(tableItem);
      this.currentSelect = tableItem[props];
    },

    //切换页数
    async handleCurrentChange(getPage) {
      try {
        this.getTableList(getPage, this.inputValue_unRequired);
      } catch (error) {
        console.info("error", error);
        this.pageObj.currentPage = 1;
      }
    },
  },
  computed: {
    inputedValue() {
      try {
        let requestOptions = this.$com.getValueByRules(
          this.initOptions.header.inputList
        );

        console.info(";requestOptions", requestOptions);

        return requestOptions;
      } catch (error) {
        this.$catchError(error);
        throw error;
      }
    },

    //去除输入框的必填条件，从而自由组合条件搜索
    inputValue_unRequired() {
      try {
        const inputList = this.initOptions.header.inputList;
        const keywordInput = JSON.parse(JSON.stringify(inputList));

        keywordInput.forEach((item) => {
          item.rules = item.rules.filter((item) => item !== "required");
        });

        let keywordObj = this.$com.getValueByRules(keywordInput);

        return keywordObj;
      } catch (error) {
        this.$catchError(error);
        throw error;
      }
    },

    //权限校验
    isGetRenderTableAuthen() {
      if (this.isInited === true) {
        return true;
      }

      const {
        authenKey,
        header: { rightTopBtns },
        body,
      } = this.initOptions;

      const getters = this.$store.getters;
      const getAuthenIsPermitted = getters.getAuthenIsPermitted;

      const isGetRenderTableAuthen = authenKey
        ? getAuthenIsPermitted(authenKey)
        : true;

      if (getters.isLoadedAdminDetails() === true) {
        if (isGetRenderTableAuthen !== true) {
          this.$emit("isEmtyContent", true);
        } else {
          const actionBtns = body.td.actionBtns;
          let authenBtnList = [...(rightTopBtns || []), ...(actionBtns || [])];

          console.info("authenBtnList", authenBtnList);

          authenBtnList.forEach((item) => {
            const { authenKey, isRender } = item;
            this.$set(
              item,
              "isRender",
              isRender == undefined ? getAuthenIsPermitted(authenKey) : isRender
            );
          });

          this.editTableItem = actionBtns.find((item) => item.type === "edit");
          this.init(); //获取数据
          this.isInited = true;
        }
      }

      return isGetRenderTableAuthen;
    },

    checkType() {
      return this.initOptions.body.td.checkType;
    },

    tdProps() {
      return this.initOptions.body.td.props;
    },
  },
};
</script>
<style lang="scss" scoped>
.side-table {
  // 模块：头部
  .header-modules {
    padding-left: 0.9rem;
    padding-right: 1rem;

    .header-title {
      font-size: 0.8rem;
      color: #333333;
    }
  }

  // 模块：搜索
  .search-modules {
    padding-left: 0.8rem;
    padding-right: 1rem;
    background-color: #f7f7f7;

    ::v-deep .el-input__inner {
      height: 1.5rem;
      line-height: 1.5rem;
      font-size: 0.7rem;
    }
  }

  //模块：主体
  .body-modules {
    .tips-text {
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      font-size: 0.8rem;
      color: #999;
    }

    .inner-box {
      overflow-y: scroll;
      margin-right: -20px;

      &::-webkit-scrollbar {
        overflow-y: hidden;
      }
    }

    .list-item {
      font-size: 0.7rem;
      color: #606060;

      &.active {
        background-color: #f4faff;
        color: #1891ff;
      }

      .iconfont {
        font-size: 0.8rem;
        color: #333744;
      }
    }
  }
}
</style>