import { render, staticRenderFns } from "./chooseAndUploadLoaclImg.vue?vue&type=template&id=23596c20&scoped=true&"
import script from "./chooseAndUploadLoaclImg.vue?vue&type=script&lang=js&"
export * from "./chooseAndUploadLoaclImg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23596c20",
  null
  
)

export default component.exports