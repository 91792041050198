<!--
 * @Description: 操作日志
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
    }
  },
  mounted() {
    this.tableOptions = this.getTableOptions()
  },
  methods: {
    getTableOptions() {
      return {
        authenKey: 'teacherServiceLog',
        headers: [
          {
            label: '教师姓名',
            props: 'teacher_name',
          },
          {
            label: '操作时间',
            props: 'created_at',
          },
          {
            label: '操作内容',
            props: 'log_info',
          },
          {
            label: '登录ip',
            props: 'log_ip',
          },
        ],
        getData: async (options) => {
          const ret = await this.$ajax({
            apiKey: 'teacherServiceLog',
            data: options,
          })

          const { formatDate } = this.$com

          //时间戳转化为标准时间
          ret.res.data.forEach((item) => {
            const { log_time } = item

            item.log_time = formatDate(
              new Date(log_time * 1000),
              'yyyy-MM-dd hh:mm'
            )
          })

          return ret
        },
      }
    },
  },
}
</script>
