<!--
 * @Date         : 2020-12-02 14:10:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-04 10:03:05
 * @FilePath: /src/views/goods/baseInfoModules.vue
-->
<template>
  <div>
    <!-- 模块：基础信息 -->
    <mainContentDiv class="mb-10 normal-info-modules">
      <div class="body-title">基础信息</div>
      <div class="body-table">
        <div class="col-11">
          <div class="d-flex flex-wrap" v-if="initOptions_input">
            <div
              class="my-5"
              v-for="(item, index) in initOptions_input.inputList"
              :key="index"
              :class="item.col || 'col-3'"
            >
              <div class="mr-30 mb-10">
                <div
                  class="my-5 com-title"
                  v-if="item.label"
                  :class="{
                    active:
                      item.rules &&
                      item.rules.find((item) => item === 'required'),
                  }"
                >
                  {{ item.label }}
                </div>
                <!-- 输入框 -->
                <textFile v-if="item.type === 'input'" :initOptions="item">
                </textFile>
                <!-- 搜索框 -->
                <el-select
                  class="w-100"
                  size="small"
                  filterable
                  clearable
                  remote
                  reserve-keyword
                  v-else-if="item.type === 'select'"
                  v-model="item.value"
                  :placeholder="item.placeholder"
                  :remote-method="item.remoteMethod"
                  suffix-icon="el-icon-date"
                  @change="item.onChange && item.onChange($event, item)"
                >
                  <el-option
                    v-for="item in item.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <!-- 单选 -->
                <el-radio-group
                  v-if="item.type === 'radioGroup'"
                  v-model="item.value"
                  :disabled="item.readonly || item.disabled"
                >
                  <el-radio
                    v-for="(item, index) in item.options"
                    :key="index"
                    :label="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
                <!-- 日期选择 -->
                <el-date-picker
                  class="w-100"
                  type="date"
                  size="small"
                  placeholder="选择日期"
                  v-else-if="item.type === 'datePicker'"
                  v-model="item.value"
                  :readonly="item.readonly"
                  @change="item.onChange && item.onChange($event, item)"
                >
                </el-date-picker>
                <!-- 上传单个图片 -->
                <div
                  class="upload-header-img-bar"
                  v-else-if="item.type === 'headerImg'"
                >
                  <div
                    class="position-relative cursor-pointer header-img"
                    :class="!item.value && 'add-icon'"
                    @click="
                      !item.readonly && handleTriggerUploadHeaderImg(item)
                    "
                  >
                    <img
                      class="imgCover"
                      v-if="item.value"
                      :src="imgAddHostUrl(item.value || item.defaultVal)"
                    />
                  </div>
                  <chooseAndUploadLoaclImg
                    ref="chooseLoactImgDom"
                    @change="saveUploadImgData($event)"
                  ></chooseAndUploadLoaclImg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mainContentDiv>

    <!-- 弹窗：班级分类 -->
    <centerDialog ref="centerDialog_goodsType">
      <div style="height: 40vh">
        <goodsTypeModules
          ref="goodsTypeModules"
          :initOptions="initOptions_goodsType"
        ></goodsTypeModules>
      </div>
    </centerDialog>
  </div>
</template>

<script>
import goodsTypeModules from "@/views/goods/goodsTypeModules.vue";
import chooseAndUploadLoaclImg from "@/components_global/chooseAndUploadLoaclImg.vue";

export default {
  components: {
    goodsTypeModules,
    chooseAndUploadLoaclImg,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initOptions_input: null,
      initOptions_goodsType: {
        isSelect: true,
      },
    };
  },
  mounted() {
    this.initOptions_input = {
      inputList: this.getInputStudent(),
    };
  },

  methods: {
    //填充数据
    inputValue(inputedValObj) {
      this.$com.fillData({
        inputList: this.initOptions_input.inputList,
        valueListObj: inputedValObj,
      });
    },
    //获取输入配置表
    getInputStudent() {
      let { pageType } = this.$route.query;
      pageType = Number(pageType);

      let getInputStudent = [
        {
          type: "input",
          label: "产品名称",
          placeholder: "请输入产品名称",
          maxLength: 40,
          rules: ["required", "!null"],
          value: null,
          props: "name",
          col: "col-10",
          disabled: pageType === 0 || pageType === 1 ? false : true,
        },
        {
          type: "radioGroup",
          label: "类别",
          maxLength: 40,
          rules: ["required", "!null"],
          value: 0,
          props: "is_course", //is_course	是	int	0-实物 1-课程
          col: "col-4",
          options: [
            {
              label: "实物",
              value: 0,
            },
            {
              label: "课程",
              value: 1,
            },
          ],
          disabled: pageType === 0 || pageType === 1 ? false : true,
        },
        {
          type: "radioGroup",
          label: "班级类型",
          maxLength: 40,
          rules: ["required", "!null"],
          value: 1,
          props: "type",
          col: "col-4",
          options: [
            {
              label: "中心班",
              value: 1,
            },
            {
              label: "校内班",
              value: 2,
            },
          ],
          disabled: pageType === 0 || pageType === 1 ? false : true,
        },
        {
          type: "radioGroup",
          label: "金额显示方式",
          maxLength: 40,
          rules: ["required", "!null"],
          value: 0,
          props: "show_type",
          col: "col-4",
          disabled: pageType === 0 || pageType === 1 ? false : true,

          options: [
            {
              label: "全部",
              value: 0,
            },
            {
              label: "余额课时支付",
              value: 1,
            },
            {
              label: "微信支付",
              value: 2,
            },
          ],
        },
        {
          type: "input",
          label: "类型",
          placeholder: "请选择类型",
          maxLength: 40,
          rules: ["required", "!null"],
          value: null,
          id: "",
          props: "type_name",
          props_id: "tid",
          col: "col-6",
          disabled: pageType === 0 || pageType === 1 ? false : true,
          readonly: true,
          onTap: (thisObj) => {
            const $goodsType = this.$refs.centerDialog_goodsType;

            this.initOptions_goodsType.onSelect = (options) => {
              const { id, name } = options;

              thisObj.value = name;
              thisObj.id = id;

              $goodsType.hide(); //关闭弹窗
            };

            //弹出弹窗
            $goodsType.render({
              title: "选择类型",
              width: "50vw",
            });
          },
        },
        // {
        //   type: "radioGroup",
        //   label:
        //     "核销模式（如选择了`扣家长课包余额`时，产品的价格、课时、课包 请设置为 0。）",
        //   maxLength: 40,
        //   rules: ["required", "!null"],
        //   value: 1,
        //   props: "settlement_type",
        //   col: "col-8",
        //   disabled: pageType === 0 || pageType === 1 ? false : true,
        //   options: [
        //     {
        //       label: "扣课时",
        //       value: 1,
        //     },
        //     {
        //       label: "扣家长课包余额",
        //       value: 2,
        //     },
        //   ],
        // },
        {
          type: "input",
          label: "一口价",
          placeholder: "请输入一口价",
          maxLength: 40,
          rules: ["required", "!null", "num", "money"],
          value: null,
          props: "price",
          col: "col-6",
          disabled: pageType === 0 || pageType === 1 ? false : true,
        },
        {
          type: "input",
          label: "总课时",
          placeholder: "请输入总课时",
          maxLength: 40,
          rules: ["required", "!null", "num", "money"],
          value: null,
          props: "hour_num",
          col: "col-6",
          disabled: pageType === 0 || pageType === 1 ? false : true,
        },
        // {
        //   type: "input",
        //   label: "课包",
        //   placeholder: "请输入课包",
        //   maxLength: 40,
        //   rules: ["required", "!null", "num", "money"],
        //   value: null,
        //   props: "pack_num",
        //   col: "col-6",
        //   disabled: pageType === 0 || pageType === 1 ? false : true,
        // },
        {
          type: "headerImg",
          label: "产品图",
          placeholder: "请上传产品图",
          value: "",
          rules: null,
          props: "pic",
          col: "col-7",
          disabled: pageType === 0 || pageType === 1 ? false : true,
        },
      ];

      const readonly = this.readonly;

      if (readonly === true) {
        getInputStudent.forEach((item) => {
          item.readonly = true;

          item.onTap && delete item.onTap;
        });
      }

      console.info("readonly", readonly);
      console.info("getInputStudent", getInputStudent);

      return getInputStudent;
    },

    //删除规格
    handleRemoveThisAttrItem(thisIndexInList, thisIndexInAttrList) {
      this.propsListModules[thisIndexInAttrList].list.splice(
        thisIndexInList,
        1
      );
    },

    //提取数据
    getBaseInfo() {
      try {
        const { inputList } = this.initOptions_input;
        const baseInfoObj = this.$com.getValueByRules(inputList);

        console.info("baseInfoObj", baseInfoObj);

        return baseInfoObj;
      } catch (error) {
        console.info("baseInfoModuelsError", error);
        throw error;
      }
    },

    handleTriggerUploadHeaderImg(headerImgObj) {
      this.$refs.chooseLoactImgDom[0].chooseImg();

      this.headerImgObj = headerImgObj;
    },
    saveUploadImgData(e) {
      this.headerImgObj.value = e;

      console.info("e", e);
    },
    //图片增加域名
    imgAddHostUrl(imgUrl) {
      return /(http|assets)/.test(imgUrl) ? imgUrl : this.$imgBaseUrl + imgUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-details .main-content-div {
  height: initial;
}
// 模块：普通信息
.normal-info-modules {
  .body-title {
    padding: 0.85rem 0.5rem 0.85rem 1.25rem;
    font-size: 0.8rem;
    color: #606060;

    .sub-title {
      font-size: 0.7rem;
      color: #606060;
    }
  }

  .body-label {
    padding-left: 0.55rem;
    padding-right: 0.55rem;
    background-color: #333744;
    border-radius: 0.2rem;
    border: solid 0.05rem #cccccc;
    line-height: 1.5rem;
    font-size: 0.7rem;
    color: #ffffff;
  }

  .body-table {
    padding-left: 1.15rem;
    border-top: 1px solid #eaeaea;

    .icon-del {
      width: initial;
      height: initial;
      line-height: 32px;
    }
  }
}

.upload-header-img-bar {
  font-size: 0.75rem;
  color: #010101;

  .header-img {
    width: 5.625rem;
    height: 5.688rem;
    border: 1px dashed #ddd;
    border-radius: 0.25rem;
    overflow: hidden;

    &.add-icon::before,
    &.add-icon::after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      content: "";
      display: block;
      width: 1rem;
      height: 2px;
      background-color: #ddd;
    }
    &::after {
      transform: rotate(90deg);
    }

    img {
      border: none;
    }
  }
}
</style>
