<!--
 * @Description: 项目模板
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
      initOptions_input: null,
    };
  },
  destroyed() {
    this.$bus_unique.off("updateProjectTemplateList");
  },
  mounted() {
    // 更新列表
    this.$bus_unique.on(
      "updateProjectTemplateList",
      "projectTemplateList",
      () => {
        this.$refs.tableList.init();
      }
    );
  },
  created() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    getTableOptions() {
      return {
        authenKey: "projectTemplateList",
        search: {
          inputList: [
            {
              type: "input",
              label: "模板名称",
              placeholder: "",
              value: "",
              props: "task_name",
              rules: ["!null"],
            },
          ],
        },
        headers: [
          { label: "排课表名称", props: "temp_name" },
          { label: "状态", props: "status_str" },
          { label: "创建时间", props: "created_at" },
        ],
        titleBtns: [
          {
            type: "filter",
            color: "normal",
            title: "查询",
          },
          {
            title: "新增",
            authenKey: "projectTemplateAdd",
            onTap: () => {
              this.$router.push({
                name: "projectTemplateAddOrEdit",
                query: { pageType: 0 },
              });
            },
          },
        ],
        actionBtns: [
          {
            title: "查看",
            color: "normal",
            authenKey: "projectTemplateGetInfo",
            onTap: async (options) => {
              let { temp_id: id } = options;
              this.$router.push({
                name: "projectTemplateAddOrEdit",
                query: { id, pageType: 2 },
              });
            },
          },
          {
            title: "编辑",
            color: "blue",
            authenKey: "projectTemplateEdit",
            onTap: async (options) => {
              // console.log('options', options)
              let { temp_id: id } = options;
              this.$router.push({
                name: "projectTemplateAddOrEdit",
                query: { id, pageType: 1 },
              });
            },
          },
          {
            title: "删除",
            color: "red",
            authenKey: "projectTemplateDel",
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: "projectTemplateDel",
                data: {
                  temp_id: options.temp_id,
                },
              });
            },
          },
          {
            type: "multiState", //多状态按钮
            props: "status",
            authenKey: "projectTemplateChangeStatus",
            options: [
              {
                label: "启用",
                value: 2,
                color: "success",
              },
              {
                label: "禁用",
                value: 1,
                color: "warning",
              },
            ],
            onTap: async (btnObj, tableItem) => {
              this.$resureDialog({
                ok: async () => {
                  try {
                    const { temp_id, status } = tableItem;
                    const afterStatus = Number(status) === 1 ? 2 : 1;

                    // 启用/禁用
                    const { msg } = await this.$ajax({
                      apiKey: "projectTemplateChangeStatus",
                      data: {
                        temp_id,
                        status: afterStatus,
                      },
                    });

                    tableItem.status = afterStatus;

                    this.$toast({
                      msg,
                    });
                    this.$refs.tableList.init();
                  } catch (error) {
                    this.$catchError(error);
                  }
                },
              });
            },
          },
        ],
        getData: async (options) => {
          // console.log('获取列表', options)
          // 获取列表
          let ret = await this.$ajax({
            apiKey: "projectTemplateList",
            data: {
              ...options,
            },
          });

          return ret;
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.remindFormBox {
  text-align: center;

  .remindFormItem {
    margin-bottom: 14px;

    .title1 {
      margin-right: 16px;
    }
    .title2 {
      margin: 0 16px;
      width: 30px;
      text-align: left;
      display: inline-block;
    }
  }
}
</style>
