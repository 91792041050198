<!--
 * @Date         : 2020-11-30 17:04:52
 * @LastEditors  : cxx
 * @LastEditTime : 2020-12-14 15:48:42
 * @FilePath     : \leXue_manage_pc\src\views\goods\sku_component\gradeModules.vue
 年级弹窗
-->
<template>
  <mainContentDiv class="h-100">
    <centerDialog ref="centerDialog">
      <div class="overflow-hidden">
        <div
          class="d-flex flex-wrap"
          style="margin-right: -0.625rem"
          v-if="initOptions_input"
        >
          <div
            class="col-6"
            v-for="(item, index) in initOptions_input.inputList"
            :key="index"
          >
            <div class="mr-10 mb-10">
              <div
                class="my-5 com-title"
                v-if="item.label"
                :class="{
                  active:
                    item.rules &&
                    item.rules.find((item) => item === 'required'),
                }"
              >
                {{ item.label }}
              </div>
              <!-- 输入框 -->
              <textFile v-if="item.type === 'input'" :initOptions="item">
              </textFile>
            </div>
          </div>
        </div>
      </div>
    </centerDialog>

    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
      @isEmtyContent="$catchError('无使用权限，请咨询管理员')"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
      initOptions_input: null,
      dialogVisible: false,
    };
  },
  props: {
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.tableOptions = this.getTableOptions();
  },

  methods: {
    // 弹出新增/编辑学校
    renderEditDialog({ type, inputedVal }) {
      try {
        let inputOptions = (this.initOptions_input = {
          inputList: this.getInputStudent(),
        });

        if (inputedVal) {
          this.$com.fillData({
            inputList: this.initOptions_input.inputList,
            valueListObj: inputedVal,
          });
        }

        return new Promise((resovle, reject) => {
          this.$refs.centerDialog.render({
            title: type === 1 ? "新增" : "编辑",
            width: "600px",
            cancel: () => {},
            ok: async () => {
              try {
                const { inputList } = inputOptions;

                let requestOpt = this.$com.getValueByRules(inputList);
                let apiKey;

                switch (
                  type // 1:新增 2:编辑
                ) {
                  case 1:
                    apiKey = "addGrade";
                    break;
                  case 2:
                    const { id } = inputedVal;

                    apiKey = "editGrade";

                    requestOpt.id = id;
                    break;
                }

                const { msg } = await this.$ajax({
                  apiKey,
                  data: requestOpt,
                });

                this.$toast({
                  msg,
                });

                resovle();
              } catch (error) {
                reject(error);
                throw error;
              }
            },
          });
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    //获取输入配置表
    getInputStudent(inputedValueObj) {
      let getInputStudent = [
        {
          type: "input",
          label: "年级",
          placeholder: "请输入年级",
          maxLength: 40,
          rules: ["required", "!null"],
          value: null,
          props: "name",
        },
      ];

      return getInputStudent;
    },

    getTableOptions() {
      const isSelect = this.isSelect; //选择模式

      return {
        authenKey: "goods_grade_list",
        search: {
          inputList: [
            {
              type: "input",
              label: "年级名",
              placeholder: "",
              value: "",
              props: "name",
              rules: ["!null"],
            },
          ],
        },
        headers: [{ label: "年级名", props: "name" }],
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
          {
            title: "新增",
            authenKey: "goods_grade_add",
            onTap: async () => {
              await this.renderEditDialog({
                type: 1,
              });
              this.$refs.tableList.init(); //刷新数据
            },
          },
        ],
        actionBtns: [
          {
            title: "编辑",
            color: "cyan",
            authenKey: "goods_grade_edit",
            onTap: async (options) => {
              await this.renderEditDialog({
                type: 2,
                inputedVal: options,
              });
              this.$refs.tableList.init(); //刷新数据
            },
          },
          {
            title: "删除",
            color: "red",
            authenKey: "goods_grade_del",
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: "delGrade",
                data: {
                  id: options.id,
                },
              });
            },
          },
        ],
        select: {
          type: "mutiple",
          onChange: (val) => {
            this.selectedTrList = val;
          },
        },
        getData: async (options) => {
          const ret = await this.$ajax({
            apiKey: "getGradeList",
            data: options,
          });

          return ret;
        },
      };
    },
  },
};
</script>