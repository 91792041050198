<!--
 * @Date         : 2020-12-14 17:29:58
 * @LastEditors  : cxx
 * @LastEditTime : 2021-01-19 15:57:28
 * @FilePath     : \leXue_manage_pc\src\views\finance\financeIndex.vue
-->
<template>
  <div class="h-100 d-flex flex-column">
    <div v-if="isGetShowOrderAmountAuthen">
      <mainContentDiv>
        <div class="overflow-hidden">
          <div class="d-flex align-items-center income-expend-modules my-20">
            <div class="px-40 text-center item">
              <div class="label">{{ currentFinanceObj.label || "收入" }}</div>
              <div class="value">{{ currentFinanceObj.value || 0 }}</div>
            </div>
          </div>
        </div>
      </mainContentDiv>
    </div>
    <div class="col my-10">
      <mainContentDiv class="d-flex flex-column pa-20">
        <el-tabs
          v-model="currentNavName"
          type="card"
          @tab-click="handleChangeCurrentNavValue"
        >
          <el-tab-pane
            v-for="(item, index) in navList"
            :key="index"
            :label="item.label"
            :name="item.name"
          >
          </el-tab-pane>
        </el-tabs>
        <keep-alive>
          <incomeList
            v-if="currentNavName === 'income'"
            @incomeChange="handleIncomeChange"
          >
          </incomeList>
          <!-- 退款单 -->
          <outgoingList
            v-if="currentNavName === 'expend'"
            @expendChange="handleExpendChange"
          >
          </outgoingList>
        </keep-alive>
      </mainContentDiv>
    </div>
  </div>
</template>

<script>
import incomeList from "@/views/finance/incomeList.vue";
import outgoingList from "@/views/finance/outgoingList.vue";

export default {
  components: {
    incomeList,
    outgoingList,
  },
  data() {
    return {
      // incomeExpendList: [
      //   {
      //     label: "收入",
      //     value: "122523",
      //   },
      //   {
      //     label: "支出",
      //     value: "122523",
      //   },
      // ],
      navList: [
        {
          label: "收入明细",
          name: "income",
          tableOptions: null,
        },
        {
          label: "支出明细",
          name: "expend",
          tableOptions: null,
        },
      ],
      currentNavName: "income",
      tableOptions: null,
      currentFinanceObj: {
        label: "收入",
        value: 0,
      },
    };
  },
  methods: {
    handleIncomeChange(value) {
      this.navList[0].label_total = "收入";
      this.navList[0].value_total = value;

      this.currentFinanceObj = {
        label: "收入",
        value,
      };
    },
    handleExpendChange(value) {
      this.navList[1].label_total = "支出";
      this.navList[1].value_total = value;

      this.currentFinanceObj = {
        label: "支出",
        value,
      };
    },
    handleChangeCurrentNavValue(value) {
      console.info("currentNavName", this.currentNavName);
      const currentNavName = this.currentNavName;

      const currentFinaceObj = this.navList.find(
        (item) => item.name === currentNavName
      );

      this.currentFinanceObj = {
        label: currentFinaceObj.label_total,
        value: currentFinaceObj.value_total,
      };
    },
  },
  computed: {
    isGetShowOrderAmountAuthen() {
      const getters = this.$store.getters;
      const getAuthenIsPermitted = getters.getAuthenIsPermitted;

      return getAuthenIsPermitted("finance_incomeAndOutgoingAmount");
    },
  },
};
</script>

<style lang="scss" scoped>
//模块：收支
.income-expend-modules {
  margin-left: -1px;

  .item {
    border-left: 1px solid #eaeaea;
    .label {
      font-size: 0.75rem;
      color: #666666;
    }
    .value {
      font-size: 1.25rem;
      font-weight: bold;
      color: #000000;
    }
  }
}
</style>