<!--
 * @Author: WZC
 * @Date: 2023-10-18 10:41:51
 * @FilePath: /src/views/educationalMiniApp/taskDescription/index.vue
 * @Description: 任务概述
-->
<template>
  <div class="pageBox">
    <div
      class="col overflow-scroll-y h-100"
      style="padding: 20px; padding-bottom: 100px"
      v-loading="loading"
    >
      <infoWrapper class="my-10" title="提示" style="height: auto">
        <div class="px-20 pt-20 pb-20" style="font-size: 16px; color: red">任务概述细项拆分请使用 ｜ 进行分割</div>
      </infoWrapper>
      <el-form
        :model="form"
        :rules="formRules"
        ref="formRef"
        label-width="auto"
        label-position="top"
      >
        <infoWrapper class="my-10" title="班主任" style="height: auto">
          <div class="px-20 pt-20">
            <el-form-item label="课前描述：" prop="teacher.before">
              <el-input
                clearable
                v-model="form.teacher.before"
                placeholder="请输入"
                type="textarea"
                :autosize="{ minRows: 4 }"
              ></el-input>
            </el-form-item>
            <el-form-item label="课后描述：" prop="teacher.after">
              <el-input
                clearable
                v-model="form.teacher.after"
                placeholder="请输入"
                type="textarea"
                :autosize="{ minRows: 4 }"
              ></el-input>
            </el-form-item>
            <el-form-item label="课中描述：" prop="teacher.middle">
              <el-input
                clearable
                v-model="form.teacher.middle"
                placeholder="请输入"
                type="textarea"
                :autosize="{ minRows: 4 }"
              ></el-input>
            </el-form-item>
          </div>
        </infoWrapper>
        <infoWrapper class="my-10" title="助教" style="height: auto">
          <div class="px-20 pt-20">
            <el-form-item label="课前描述：" prop="assistant.before">
              <el-input
                clearable
                v-model="form.assistant.before"
                placeholder="请输入"
                type="textarea"
                :autosize="{ minRows: 4 }"
              ></el-input>
            </el-form-item>
            <el-form-item label="课后描述：" prop="assistant.after">
              <el-input
                clearable
                v-model="form.assistant.after"
                placeholder="请输入"
                type="textarea"
                :autosize="{ minRows: 4 }"
              ></el-input>
            </el-form-item>
            <el-form-item label="课中描述：" prop="assistant.middle">
              <el-input
                clearable
                v-model="form.assistant.middle"
                placeholder="请输入"
                type="textarea"
                :autosize="{ minRows: 4 }"
              ></el-input>
            </el-form-item>
          </div>
        </infoWrapper>
      </el-form>
    </div>
    <!-- 模块：底部操作按钮 -->
    <div class="bottomTheBox">
      <el-button size="medium" type="primary" @click="handleSubmit">
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import infoWrapper from "@/components/infoWrapper.vue";

export default {
  components: {
    infoWrapper,
  },
  data() {
    return {
      form: {
        teacher: {
          before: "",
          middle: "",
          after: "",
        },
        assistant: {
          before: "",
          middle: "",
          after: "",
        },
      },
      formRules: {},
      loading: false,
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      this.loading = true;
      let { res } = await this.$ajax({
        apiKey: "taskTemplateGetTaskIntro",
      });
      console.log("getInfo-res", res);

      res && (this.form = res);
      this.loading = false;
    },

    async handleSubmit() {
      this.$confirm("确定保存？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(async () => {
          try {
            let formValidate = await this.$refs.formRef.validate();
            if (!formValidate) return false;
            console.log("formValidate-ok", this.form);

            await this.$ajax({
              apiKey: "taskTemplateSetTaskIntro",
              data: {
                value: JSON.stringify(this.form),
              },
            });
            this.$toast({
              msg: "保存成功",
            });
          } catch (error) {
            this.$catchError(error);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.bottomTheBox {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 30px;
  width: calc(100% - 208px);
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(218, 218, 218, 0.5);

  .el-button {
    margin: 0 14px;
  }
}

.pageBox {
  margin: -1.25rem;
  position: relative;
  height: calc(100% + 2.5rem);

  .el-form--label-top .el-form-item__label {
    padding: 0;
  }
  .el-form--inline .el-form-item {
    margin-right: 26px;
  }
}
</style>
