<!--
 * @Date         : 2021-02-19 17:25:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-11 15:11:42
 * @FilePath     : \leXue_manage_pc\src\views\other\data_all.vue
-->
<template>
  <dataBoardBox class="side-data-modules" title="数据总览">
    <div
      class="side-data-list"
      v-for="(item, index) in sideDataList"
      :key="index"
    >
      <div
        class="d-flex my-30 data-item"
        v-for="(itemData, indexData) in item"
        :key="indexData"
      >
        <div
          class="mt-5 iconfont"
          :class="itemData.iconfont"
          :style="{ backgroundColor: itemData.iconBg }"
        ></div>
        <div class="col">
          <div class="label">{{ itemData.label }}</div>
          <div class="value">{{ overviewData[itemData.props] }}</div>
        </div>
      </div>
    </div>
  </dataBoardBox>
</template>

<script>
import dataBoardBox from "@/views/other/dataBoardBox.vue";

export default {
  components: {
    dataBoardBox,
  },
  props: {
    overviewData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      sideDataList: [
        [
          {
            iconfont: "icon-people",
            iconBg: "#0084ff",
            label: "家长总数",
            value: "13,788",
            props: "parent_count",
          },
          {
            iconfont: "icon-student",
            iconBg: "#fe8e02",
            label: "学生总数",
            value: "13,788",
            props: "student_count",
          },
        ],
        [
          {
            iconfont: "icon-campus",
            iconBg: "#ff80b3",
            label: "校区总数",
            value: "13,788",
            props: "xq_school_count",
          },
          {
            iconfont: "icon-class-2",
            iconBg: "#60d8da",
            label: "开设班级总数",
            value: "13,788",
            props: "class_count",
          },
          {
            iconfont: "icon-qty",
            iconBg: "#6f5efd",
            label: "产品数量",
            value: "13,788",
            props: "product_count",
          },
        ],
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
// 模块：数据总览
.side-data-modules {
  width: 329px;
  height: 660px;
  border-radius: 4px;
  .side-data-list {
    border-bottom: 1px solid #e7e7e7;

    .data-item {
      padding-left: 1.7rem;
      padding-right: 1.7rem;

      .iconfont {
        margin-right: 0.9rem;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        font-size: 1.3rem;
        color: #fff;
      }

      .label {
        font-size: 0.8rem;
        color: #909090;
      }

      .value {
        font-size: 1.33rem;
        color: #333333;
      }
    }
  }
  .side-data-list:last-child {
    border-bottom: 0;
    }
}
</style>