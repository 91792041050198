<!--
 * @Date         : 2020-11-21 17:05:18
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-19 11:16:43
 * @FilePath     : \leXue_manage_pc\src\views\goods\mutilGoodsList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>

    <!-- 弹窗：已选择的产品 -->
    <!-- <centerDialog ref="centerDialog_selectedGoods">
        <currentSelectGoods
          style="height: 50vh"
          ref="currentSelectGoods"
        ></currentSelectGoods>
    </centerDialog> -->
  </mainContentDiv>
</template>

<script>
export default {
  props: {
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableOptions: null,
    };
  },
  destroyed() {
    this.$bus_unique.off("updateAuthenList");
  },
  mounted() {
    this.tableOptions = this.getTableOptions();

    //监听：更新商品列表
    this.$bus_unique.on("updateMutilGoodsList", "mutilGoodsList", () => {
      this.$refs.tableList.init();
    });
  },
  methods: {
    //新增/选择产品组合
    handleRenderEditGoodsCompose(type) {
      this.$refs.centerDialog_selectedGoods.render({
        title: ["新增产品组合"][type],
        width: "800px",
        ok: () => {
          try {
            const selectedGoodsList = this.$refs.currentSelectGoods
              .selectedGoodsList;

            console.info("selectedGoodsList", selectedGoodsList);
          } catch (error) {
            this.$catchError(error);
          }
        },
        cancel: () => {},
      });
    },

    getTableOptions() {
      const isSelect = this.isSelect; //选择模式

      let configureOptions = {
        titleBtns: [
          {
            title: "查询",
            type: "filter",
            authenKey: "goods_add",
          },
          {
            title: "新增产品组合",
            authenKey: "goods_add",
            onTap: () => {

              this.$router.push({
                name: "addOrEditMutilGoods",
                query: {
                  pageType: 0,
                },
              });
            },
          },
        ],
        actionBtns: [
          {
            title: "查看",
            color: "cyan",
            authenKey: "goods_details",
            onTap: (options) => {
              this.$router.push({
                name: "addOrEditMutilGoods",
                query: {
                  pageType: 2,
                  id: options.id,
                },
              });
            },
          },
          {
            title: "编辑",
            color: "cyan",
            authenKey: "goods_edit",
            onTap: (options) => {
              this.$router.push({
                name: "addOrEditMutilGoods",
                query: {
                  pageType: 1,
                  id: options.id,
                },
              });
            },
          },
          {
            title: "删除",
            color: "red",
            authenKey: "goods_del",
            onDelete: async (options) => {},
          },
        ],
      };

      return {
        authenKey: "goods_list",
        search: {
          inputList: [
            {
              type: "input",
              label: "产品名称",
              placeholder: "请输入产品名称",
              value: "",
              props: "name",
              rules: ["!null"],
            },
          ],
        },
        headers: [
          { label: "产品名称", props: "name" },
          { label: "价格", props: "price" },
        ],
        ...configureOptions,
        getData: async (options) => {
          let requestRet;
          let { res } = (requestRet = await this.$ajax({
            apiKey: "getMutilProductList",
            data: options,
          }));

          return requestRet;
        },
      };
    },
  },
};
</script>
