<!--
 * @Description: 排课日历
-->
<template>
  <div class="comp-full-calendar">
    <!-- 头部 -->
    <CalendarHeader
      :current-date="currentDate"
      :title-format="titleFormat"
      :first-day="firstDay"
      :month-names="monthNames"
      :tableType="tableType"
      :begin_date="info.begin_date"
      :end_date="info.end_date"
      @change="changeDateRange"
      @changeTableType="changeTableType"
    >
      <!-- 自定义头部左边内容 -->
      <template slot="header-left">
        <div class="headerLeftBox">
          <div class="tableName">{{ info.name }}</div>
          <div class="tableTime">
            周期：{{ info.begin_date }} 至 {{ info.end_date }}
          </div>
        </div>
      </template>
      <!-- 自定义头部右边内容 -->
      <template slot="header-right">
        <slot name="header-right"></slot>
      </template>
    </CalendarHeader>
    <!-- 日期、日期和事件 -->
    <CalendarBody
      v-if="info.class"
      ref="fcbody"
      :current-date="currentDate"
      :events="info.class"
      :week-names="weekNames"
      :month-names="monthNames"
      :first-day="firstDay"
      :tableType="tableType"
      :weekDays="weekDays"
      @eventclick="emitEventClick"
    >
    </CalendarBody>
  </div>
</template>

<script>
import CalendarHeader from './components/header.vue'
import CalendarBody from './components/body.vue'

export default {
  components: {
    CalendarHeader,
    CalendarBody,
  },
  props: {
    // 数据源
    info: {
      type: Object,
      default: () => {},
    },
    // 表的起始日期
    firstDay: {
      type: [Number, String],
      validator(val) {
        const res = parseInt(val)
        return res >= 0 && res <= 6
      },
      default: 0,
    },
    // 头部标题形式
    titleFormat: {
      type: String,
      default() {
        return 'yyyy年MM月'
      },
    },
    // 月名称
    monthNames: {
      type: Array,
      default() {
        return [
          '1月',
          '2月',
          '3月',
          '4月',
          '5月',
          '6月',
          '7月',
          '8月',
          '9月',
          '10月',
          '11月',
          '12月',
        ]
      },
    },
    // 星期名称
    weekNames: {
      type: Array,
      default() {
        const arr = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        return arr.slice(this.firstDay).concat(arr.slice(0, this.firstDay))
      },
    },
  },
  data() {
    return {
      // 日历类型(周/月)
      tableType: 'week',
      // 这周的起始日期
      weekDays: [],
      currentDate: new Date(),
    }
  },
  created() {},
  methods: {
    // 切换周/月
    changeTableType(type) {
      this.tableType = type
      this.$emit('changeType', type)
    },

    // 日期点击回调
    changeDateRange(start, end, currentStart, current, weekDays) {
      this.currentDate = current
      this.weekDays = weekDays
      this.$emit('change', start, end, currentStart, weekDays)
    },

    // 点击任务回调
    emitEventClick(event, jsEvent) {
      this.$emit('eventClick', event, jsEvent)
    },
  },
}
</script>

<style lang="scss">
.comp-full-calendar {
  // font-family: "elvetica neue", tahoma, "hiragino sans gb";
  // background: #fff;
  min-width: 960px;
  margin: 0 auto;
  ul,
  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
  .cancel {
    border: 0;
    outline: none;
    box-shadow: unset;
    background-color: #ececed;
    color: #8b8f94;
    &:hover {
      color: #3e444c;
      z-index: 0;
    }
  }
  .primary {
    border: 0;
    outline: none;
    box-shadow: unset;
    background-color: #5272ff;
    color: #fff;
    &:hover {
      z-index: 0;
    }
  }
}

.headerLeftBox {
  .tableName {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 2px;
  }
  .tableTime {
    font-size: 14px;
    color: #606060;
  }
}
</style>
