<!--
 * @Date         : 2021-02-19 14:26:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-02-19 17:08:39
 * @FilePath     : \leXue_manage_pc\src\views\other\dataBoardBox.vue
-->
<template>
  <div class="d-flex flex-column ma-10 data-board-box">
    <div class="d-flex align-items-center">
      <div class="d-flex py-15 mr-auto title">
        {{ title }}
      </div>
      <slot name="rightTopBox"> </slot>
    </div>

    <div class="col">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.data-board-box {
  background-color: #ffffff;
  box-shadow: 0rem 0.22rem 0.53rem 0.02rem rgba(218, 218, 218, 0.5);
  border-radius: 0.22rem;

  .title {
    padding-left: 1.5rem;
    font-size: 0.89rem;
    color: #1891ff;

    &::before {
      margin-right: 0.5rem;
      content: "";
      display: block;
      width: 4px;
      background-color: #1891ff;
    }
  }
}
</style>