<!--
 * @Description: 部门管理
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
    <centerDialog ref="centerDialog">
      <inputCenter :initOptions="initOptions_input"></inputCenter>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
      initOptions_input: null,
    }
  },
  created() {
    this.tableOptions = this.getTableOptions()
  },
  methods: {
    // 弹出新增/编辑
    renderEditDialog({ type, inputedVal }) {
      try {
        let inputOptions = (this.initOptions_input = {
          inputList: this.getInputRechargeLevel(),
        })

        if (inputedVal) {
          this.$com.fillData({
            inputList: this.initOptions_input.inputList,
            valueListObj: inputedVal,
          })
        }

        this.$refs.centerDialog.render({
          title: type === 0 ? '新增' : '编辑',
          width: '600px',
          verifyInputListIsChange: inputOptions.inputList,
          ok: async () => {
            try {
              const { inputList } = inputOptions

              let requestOpt = this.$com.getValueByRules(inputList)

              // 1:新增 2:编辑
              if (type === 1) {
                const { id } = inputedVal
                requestOpt.id = id
              }

              // 新增/编辑
              const { msg } = await this.$ajax({
                apiKey: 'departmentSave',
                data: requestOpt,
              })

              this.$toast({
                msg,
              })

              this.$refs.tableList.init()
            } catch (error) {
              throw error
            }
          },
          cancel: () => {},
        })
      } catch (error) {
        this.$catchError(error)
      }
    },

    //获取输入配置表
    getInputRechargeLevel(inputedValueObj) {
      const _tempValObj = {}

      let getInputRechargeLevel = [
        {
          type: 'input',
          label: '名称',
          placeholder: '请输入名称',
          rules: ['required', '!null'],
          value: null,
          props: 'name',
          col: 'col-6',
        },
      ]

      return getInputRechargeLevel
    },

    getTableOptions() {
      return {
        authenKey: 'departmentList',
        search: {
          inputList: [
            {
              type: 'input',
              label: '部门名称',
              placeholder: '',
              value: '',
              props: 'name',
              rules: ['!null'],
            },
          ],
        },
        headers: [
          { label: '部门名称', props: 'name' },
          { label: '创建时间', props: 'created_at' },
        ],
        titleBtns: [
          {
            type: 'filter',
            title: '查询',
          },
          {
            title: '新增',
            authenKey: 'departmentAdd',
            onTap: () => {
              this.renderEditDialog({
                type: 0,
              })
            },
          },
        ],
        actionBtns: [
          {
            title: '编辑',
            color: 'deepBlue',
            authenKey: 'departmentEdit',
            onTap: (options) => {
              this.renderEditDialog({
                type: 1,
                inputedVal: options,
              })
            },
          },
          {
            title: '删除',
            color: 'red',
            authenKey: 'departmentDel',
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: 'departmentDel',
                data: {
                  id: options.id, //cid	是	int	班级id
                },
              })
            },
          },
        ],
        getData: async (options) => {
          console.log('获取列表', options)
          // 获取列表
          let ret = await this.$ajax({
            apiKey: 'departmentList',
            data: {
              ...options,
            },
          })

          return ret
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
