<!--
 * @Description: 日历头部
-->
<template>
  <div class="full-calendar-header">
    <!-- 左侧 -->
    <div class="header-left">
      <slot name="header-left"></slot>
    </div>
    <!-- 中间 -->
    <div class="header-center">
      <span class="prev-month" @click.stop="goPrev">
        <i class="el-icon-arrow-left" style="font-weight: bold;"></i>
      </span>
      <div class="pickerTitle">
        <!-- 日期选择器 -->
        <el-date-picker
          v-if="pickerShow"
          class="pickerClass"
          ref="pickerRef"
          v-model="headDate"
          :picker-options="pickerOptions"
          :type="tableType"
          @change="pickerchange"
          :editable="false"
          :clearable="false"
        >
        </el-date-picker>
        <span class="title" @click="onPicker">{{ title }}</span>
      </div>
      <span class="next-month" @click.stop="goNext">
        <i class="el-icon-arrow-right" style="font-weight: bold;"></i>
      </span>
      <!-- 切换按钮 -->
      <div class="switchBtnBox">
        <div class="btnItem" @click="changeType()">今日</div>
        <div
          class="btnItem"
          :class="{ primary: tableType == 'week' }"
          @click="changeType('week')"
        >
          周
        </div>
        <div
          class="btnItem"
          :class="{ primary: tableType == 'month' }"
          @click="changeType('month')"
        >
          月
        </div>
      </div>
    </div>
    <!-- 右侧 -->
    <div class="header-right">
      <slot name="header-right"></slot>
    </div>
  </div>
</template>

<script>
import dateFunc from '../dateFunc.js'

export default {
  props: {
    // 当前日期
    currentDate: {},
    // 头部标题形式
    titleFormat: {},
    // 表的起始日期
    firstDay: {},
    // 月名称
    monthNames: {},
    // 日历类型(周/月)
    tableType: {
      type: String,
      default: '',
    },
    // 开始和结束周期
    begin_date: {
      type: String,
      default: '',
    },
    end_date: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title: '',
      headDate: new Date(),
      pickerOptions: {
        firstDayOfWeek: 1,
        // 限制只能在开始和结束周期内选择
        disabledDate: (time) => {
          // 如果没有后面的 -8.64e7 就是不可以选择今天的
          const beginDate = new Date(this.begin_date).getTime() - 8.64e7
          const endDate = new Date(this.end_date).getTime() - 8.64e7
          return beginDate > time.getTime() || endDate < time.getTime()
        },
      },
      pickerShow: true,
    }
  },
  watch: {
    // 监听当前日期
    currentDate(val) {
      if (!val) return
      this.headDate = val
      // this.headDate = JSON.parse(JSON.stringify(val))
    },
    // 监听是否改变了日历显示类型
    tableType(val) {
      this.dispatchEvent(this.tableType)

      // 初始化日期选择器
      this.$refs.pickerRef.pickerVisible = false
      this.pickerShow = false
      this.$nextTick(() => {
        this.pickerShow = true
      })
    },
  },
  created() {
    this.dispatchEvent(this.tableType)
  },
  methods: {
    // 切换周/月
    changeType(type) {
      if (type) {
        this.$emit('changeTableType', type)
      } else {
        // 返回今日
        this.headDate = new Date()
        this.dispatchEvent(this.tableType)
      }
    },

    // 日历选择器
    onPicker() {
      // console.log(this.$refs.pickerRef)
      this.$refs.pickerRef.pickerVisible = true
    },
    pickerchange(e) {
      console.log(e)
      this.headDate = e
      this.dispatchEvent(this.tableType)
    },

    // 上一页
    goPrev() {
      this.headDate = this.changeDateRange(this.headDate, -1)
      console.log('headDate-上一页', this.headDate)

      this.dispatchEvent(this.tableType)
    },
    // 下一页
    goNext() {
      this.headDate = this.changeDateRange(this.headDate, 1)
      console.log('headDate-下一页', this.headDate)

      this.dispatchEvent(this.tableType)
    },
    changeDateRange(date, num) {
      const dt = new Date(date)
      if (this.tableType === 'month') {
        return new Date(dt.setMonth(dt.getMonth() + num))
      } else {
        return new Date(dt.valueOf() + num * 7 * 24 * 60 * 60 * 1000)
      }
    },

    // 判断日历类型渲染对应内容
    dispatchEvent(type) {
      if (type === 'month') {
        this.title = dateFunc.format(
          this.headDate,
          this.titleFormat,
          this.monthNames
        )
        const startDate = dateFunc.getStartDate(this.headDate)
        const curWeekDay = startDate.getDay()
        // 1st day of this monthView
        let diff = parseInt(this.firstDay) - curWeekDay
        if (diff) diff -= 7
        startDate.setDate(startDate.getDate() + diff)

        // the month view is 6*7
        const endDate = dateFunc.changeDay(startDate, 41)

        // 1st day of current month
        const currentDate = dateFunc.getStartDate(this.headDate)
        this.$emit(
          'change',
          dateFunc.format(startDate, 'yyyy-MM-dd'),
          dateFunc.format(endDate, 'yyyy-MM-dd'),
          dateFunc.format(currentDate, 'yyyy-MM-dd'),
          this.headDate
        )
      } else if (type === 'week') {
        const weekDays = dateFunc.getDates(this.headDate)

        this.title =
          dateFunc.format(weekDays[0], 'MM-dd') +
          '  至  ' +
          dateFunc.format(weekDays[6], 'MM-dd')
        this.$emit(
          'change',
          dateFunc.format(weekDays[0], 'yyyy-MM-dd'),
          dateFunc.format(weekDays[6], 'yyyy-MM-dd'),
          dateFunc.format(weekDays[0], 'yyyy-MM-dd'),
          this.headDate,
          weekDays
        )
      }
    },
  },
}
</script>

<style lang="scss">
.full-calendar-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .header-left,
  .header-right {
    flex: 1;
  }
  .header-center {
    text-align: center;
    user-select: none;
    font-weight: bold;
    display: flex;
    align-items: center;

    .title {
      position: relative;
      margin: 0 5px;
      width: 150px;
      cursor: pointer;
      z-index: 2000;
    }
    .prev-month,
    .next-month {
      cursor: pointer;
      margin: 5px;

      i {
        color: #c1bebe;
      }
    }
    .pickerTitle {
      display: inline-block;
      border-radius: 17px;
      border: solid 1px #1891ff;
      padding: 3px 16px;
    }
    .switchBtnBox {
      display: inline-block;
      border-radius: 17px;
      overflow: hidden;
      margin-left: 40px;

      .btnItem {
        display: inline-block;
        width: 56px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #f7f7f7;
        font-size: 16px;
        color: #606060;
        cursor: pointer;

        &:nth-child(2) {
          margin: 0 1px;
        }
      }

      .primary {
        background: #1891ff;
        color: #fff;
      }
    }
  }

  .header-left {
    font-size: 20px;
    font-weight: bold;
  }
}

.pickerClass {
  width: 0 !important;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
</style>
