<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tableOptions: null,
    };
  },
  mounted() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    getTableOptions() {
      return {
        authenKey: "class_studentList",
        search: {
          inputList: [
            {
              type: "input",
              label: "学生名称",
              placeholder: "",
              value: "",
              props: "student_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "家长名称",
              placeholder: "",
              value: "",
              props: "parent_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "手机号",
              placeholder: "",
              value: "",
              props: "mobile",
              rules: ["!null"],
            },
          ],
        },
        headers: [
          {
            label: "学生名称",
            props: "student_name",
          },
          {
            label: "家长名称",
            props: "parent_name",
          },
          {
            label: "手机号",
            props: "parent_mobile",
          },
          {
            label: "学生状态",
            props: "_statusText",
          },
        ],
        actionBtns: [
          {
            title: "删除",
            color: "red",
            authenKey: "admin_del",
            onDelete: async (options) => {
              const { classID } = this.initOptions;
              return await this.$ajax({
                apiKey: "removeClassStudent",
                data: {
                  cid: classID, //cid	是	int	班级id
                  sid: options.sid, //sid	是	int	学生id
                },
              });
            },
          },
        ],
        getData: async (options) => {
          const { classID } = this.initOptions;

          if (!classID) {
            throw "缺少参数：班级id";
          }

          let requestRet = await this.$ajax({
            apiKey: "getClassStudent",
            data: {
              ...options,
              type: 2, //状态类型 1-在读（正常）2-历史(转班，退班，结班) ——默认1
              cid: classID,
            },
          });

          const statusTextList = ["", "正常", "转班", "结班", "退班"];

          requestRet.res.data.forEach((item) => {
            const { status, roles } = item;

            item.isAbleUse = status === 1;
            item._statusText = statusTextList[status]; // status	int	学生状态 1-正常 2-转班 3-结班 4-退班
          });

          return requestRet;
        },
      };
    },
  },
};
</script>
