<!--
 * @Author: WZC
 * @Date: 2023-07-19 14:26:11
 * @FilePath: /src/views/studentCardList/index.vue
 * @Description: 学生卡管理
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
    <!-- 新增or编辑 -->
    <centerDialog ref="AddOrEditDialogRef">
      <inputCenter :initOptions="AddOrEditOptionsInput"></inputCenter>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tableOptions: null,
      AddOrEditOptionsInput: null,
      couponGenerateOptionsInput: null,
    };
  },
  destroyed() {},
  mounted() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    getTableOptions() {
      let getTableOptions = {
        authenKey: "studentCardList",
        search: {
          inputList: [
            {
              type: "input",
              label: "卡号",
              placeholder: "",
              value: "",
              props: "card_number",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "学生名称",
              placeholder: "",
              value: "",
              props: "student_name",
              rules: ["!null"],
            },
            {
              type: "select",
              label: "状态",
              placeholder: "",
              value: "",
              props: "student_id",
              rules: ["!null"],
              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "未绑定",
                  value: 0,
                },
              ],
            },
          ],
        },
        headers: [
          { label: "卡片编码", props: "card_code" },
          { label: "卡号", props: "card_number" },
          { label: "绑定学生姓名", props: "name" },
          // { label: "绑定时间", props: "bind_time" },
        ],
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
        ],
        actionBtns: [
          {
            title: "编辑",
            color: "cyan",
            authenKey: "studentCardList_edit",
            onTap: async (options) => {
              console.log("options", options);

              if (!options.sid) {
                await this.AddOrEditDialog({
                  type: 1,
                  inputedVal: options,
                });

                this.$refs.tableList.init();
              } else {
                this.$toast({
                  msg: "已绑定学生，请先解绑",
                  color: "error",
                });
              }
            },
          },
          {
            title: "删除",
            color: "red",
            authenKey: "studentAndParentRelation_del",
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: "studentCardDel",
                data: {
                  id: options.id,
                },
              });
            },
          },
        ],
        getData: async (options) => {
          const ret = await this.$ajax({
            apiKey: "studentCardList",
            data: options,
          });

          return ret;
        },
      };

      return getTableOptions;
    },

    // 弹出新增/编辑
    AddOrEditDialog({ type, inputedVal }) {
      try {
        let inputOptions = (this.AddOrEditOptionsInput = {
          inputList: this.getInputAddOrEdit(),
        });

        if (inputedVal) {
          this.$com.fillData({
            inputList: this.AddOrEditOptionsInput.inputList,
            valueListObj: inputedVal,
          });
        }

        return new Promise((resovle, reject) => {
          this.$refs.AddOrEditDialogRef.render({
            title: type === 0 ? "新增" : "编辑",
            width: "500px",
            ok: async () => {
              try {
                const { inputList } = inputOptions;

                let requestOpt = this.$com.getValueByRules(inputList);

                // // 1:新增 2:编辑
                if (type === 1) {
                  console.log("inputedVal", inputedVal);

                  for (const [key, value] of Object.entries(inputedVal)) {
                    if (value === null || value === "" || value === undefined) {
                      //筛选条件可根据实际情况自行调整
                      Reflect.deleteProperty(inputedVal, key);
                    }
                  }
                  requestOpt = {
                    ...inputedVal,
                    ...requestOpt,
                  };
                }
                console.log("requestOpt", requestOpt);

                const { msg } = await this.$ajax({
                  apiKey: "studentCardSave",
                  data: requestOpt,
                });

                this.$toast({
                  msg,
                });

                resovle();
              } catch (error) {
                reject(error);
                throw error;
              }
            },
          });
        });
      } catch (error) {
        this.$catchError(error);
      }
    },
    //获取输入配置表
    getInputAddOrEdit(inputedValueObj) {
      let getInputAddOrEdit = [
        {
          type: "input",
          label: "卡号",
          placeholder: "请输入卡号",
          rules: ["required", "!null"],
          value: null,
          props: "card_number",
        },
      ];
      return getInputAddOrEdit;
    },
  },
};
</script>

<style lang="scss">
.FormDialogBox {
  .el-dialog__body {
    height: 94%;
    padding: 0 10px 20px;
    overflow: hidden;
    background: #f4f4f4;
  }
}
</style>
