<!--
 * @Date         : 2020-11-21 17:05:18
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-03 16:14:10
 * @FilePath     : \leXue_manage_pc\src\views\goods\selectSkuByGoodsID.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
    };
  },
  props: {
    initOptions: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    getTableOptions() {
      const { getDataID, onSelect } = this.initOptions; //选择模式

      return {
        authenKey: "goods_list",
        headers: [
          { label: "班级分类", props: "classify_name" },
          { label: "年级", props: "grade_name" },
          { label: "规格", props: "spec_name" },
          { label: "属性", props: "attr_name" },
        ],
        actionBtns: [
          {
            title: "选择",
            color: "cyan",
            isRender: true,
            onTap: (options) => {
              onSelect(options);
            },
          },
        ],
        getData: async (options) => {
          let { res } = await this.$ajax({
            apiKey: "getProductDetails",
            data: {
              id: getDataID,
            },
          });

          console.error("res", res);

          return {
            code: 1,
            res: {
              data: res.sku,
              last_page: 1,
              current_page: 1,
              total: 50,
              per_page: 50,
            },
          };
        },
      };
    },
  },
};
</script>
