<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-02 17:53:27
 * @FilePath     : \leXue_manage_pc\src\components_global\checkFile.vue

 check框框
 

-->
<template>
  <div class="d-flex align-items-center check-box" @click="handleSwitchCheckStatus(initOptions)">
    <div
      class="mr-15 animated iconfont "
      :class="!!initOptions.value ? 'icon-check active bounceIn' : 'icon-unCheck'"
    ></div>
    <div class="col ml-2 label" :style="labelStyle" v-if="initOptions.label">{{initOptions.label}}</div>
  </div>
</template>

<script>
export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {};
      }
    },
    label: {
      type: String,
      default: () => {
        return "";
      }
    },
    isSignleChoice: {},
    value: {}
  },
  methods: {
    //切换选中
    handleSwitchCheckStatus(initOptions) {
      const {value: lastVal} = initOptions;
      const currentVal = !lastVal;

      this.$emit("change", currentVal);
      this.$emit('click');

      if (!this.isSignleChoice) {
        this.$set(initOptions, "value", currentVal);
      }
    }
  },
  computed: {
    labelStyle() {
      let styleStringfy = "";
      let labelStyleObj = this.initOptions.labelStyle;

      if (labelStyleObj) {
        for (let key in labelStyleObj) {
          const item = labelStyleObj[key];

          styleStringfy += key + ":" + item + ";";
        }
      }
      // console.info('styleStringfy', styleStringfy);
      return styleStringfy;
    }
  }
};
</script>

<style lang="scss" scoped>
.check-box {
  color: #aaafb3;

  .icon-check {
    &.active {
      color: rgb(10, 116, 213) !important;
    }
  }
  .label {
    font-size: 0.88rem;
    color: #333;
  }
}
</style>