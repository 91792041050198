/*
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-06 16:40:25
 * @FilePath     : \leXue_manage_pc\src\assets\js\selfGlobalComponents.js
 
    全局组件

 */
const selfGlobalComponents = {
    install: function (Vue) {
        Vue.component('textFile', require('@/components_global/textFile.vue').default); //输入框
        Vue.component('checkFile', require('@/components_global/checkFile.vue').default); //选择框

        Vue.component('xx-img', require('@/components_global/xx-img.vue').default); //图片组件
        Vue.component('maskBg', require('@/components_global/maskBg.vue').default); //遮罩

        Vue.component('mainContentDiv', require('@/components/mainContentDiv.vue').default); //主内容盒子
        Vue.component('comBtn', require('@/components_global/comBtn.vue').default);//通用按钮

        Vue.component('centerDialog', require('@/components_global/centerDialog.vue').default); //中间弹窗
        Vue.component('inputCenter', require('@/components/inputCenter.vue').default); //超级表单
        Vue.component('tableList', require('@/components/tableList.vue').default); //超级表格
    }
}

export default selfGlobalComponents;
