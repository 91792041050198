<!--
 * @Date         : 2020-11-18 09:36:21
 * @LastEditors  : cxx
 * @LastEditTime : 2020-12-10 13:53:48
 * @FilePath     : \leXue_manage_pc\src\views\user\editMyUserInfo.vue
-->
<template>
  <mainContentDiv>
    <div class="w-50 pl-5 pt-5 mt-30 mb-40 mx-40">
      <inputCenter
        ref="inputCenter"
        :initOptions="initOptions_inputCenter"
      ></inputCenter>
      <comBtn class="mr-20" color="deepBlue" @click="handleSaveData()"
        >保存</comBtn
      >
    </div>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      initOptions_inputCenter: {
        inputList: [],
      },
    };
  },
  async mounted() {
    this.init();
  },

  methods: {
    async init() {
      try {
        let inputList = await this.getNativeTemplateinputCenter();

        this.initOptions_inputCenter = {
          inputList: inputList,
        };
      } catch (error) {
        this.$toast({
          color: "error",
          msg: error,
        });
      }
    },
    async handleSaveData() {
      try {
        if (this.isSubmit_edit === true) {
          return false;
        }
        const { inputList } = this.initOptions_inputCenter;
        let requestOptions = await this.$com.getValueByRules(inputList);

        this.isSubmit_edit = true;

        //提交
        const { msg, res } = await this.$ajax({
          responseAll: true,
          apiKey: "changePassword",
          data: requestOptions,
        });

        this.$router.replace("/");

         this.$toast({
          color: "success",
          msg,
        });
      } catch (error) {
        this.isSubmit_edit = false;
        this.$catchError(error);
      }
    },

    //原始列表数据
    getNativeTemplateinputCenter() {
      return [
        {
          type: "input",
          title: "旧密码",
          label: "请输入旧密码",
          maxLength: 20,
          rules: ["required", "!null"],
          value: null,
          props: "old_pd", // old_pd	是	string	旧密码
        },
        {
          type: "input",
          title: "新密码",
          label: "请输入新密码",
          maxLength: 20,
          rules: ["required", "null"],
          value: null,
          props: "pd", //pd	是	string	用户新密码
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>