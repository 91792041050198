<!--
 * @Date         : 2021-02-19 17:33:14
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-10 17:43:50
 * @FilePath     : \leXue_manage_pc\src\views\other\data_classStudent.vue
-->
<template>
  <dataBoardBox title="各开设班级人数分布总览">
    <div ref="classStudentData" style="height: 400px"></div>
  </dataBoardBox>
</template>

<script>
import echarts from "echarts";
import dataBoardBox from "@/views/other/dataBoardBox.vue";

export default {
  components: {
    dataBoardBox,
  },
  data() {
    return {
      studentCountList: [],
    };
  },
  beforeDestroy() {
    this.myChart.dispose();
  },
  mounted() {
    this.myChart = echarts.init(this.$refs.classStudentData);
    this.getStudenCount();
  },
  methods: {
    //获取班级人数总览
    async getStudenCount() {
      try {
        const { res } = await this.$ajax({
          apiKey: "getDataStatistics_studentCount",
        });

        this.renderBarChart(res.data); //渲染柱状图
      } catch (error) {
        this.$catchError(error);
      }
    },

    //渲染柱状图
    renderBarChart(dataList) {

      dataList.forEach(item => item['学生数量'] = item.student_count);

      var option = {
        color: ["#1891ff"],
        grid: {
          bottom: 100,
        },
        dataZoom: [
          {
            id: "dataZoomX",
            type: "slider",
            start: 0,
            end: 100,
            textStyle: true,
          },
        ],
        dataset: {
          dimensions: ["name", "学生数量"],
          source: dataList,
        },
        xAxis: {
          type: "category",
          axisLabel: {},
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {},
        series: [
          {
            type: "bar",
            barWidth: 30,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      };

      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>