<template>
  <div class="position-fixed z300 mask-modules" v-if="isMountedMask">
    <!-- <div class="position-fixed z300 mask-modules"> -->
    <transition
      enter-active-class="animated faster fadeIn"
      leave-active-class="animated faster fadeOut"
    >
      <div
        class="position-absolute mask"
        @click="removeMaskByTap()"
        @animationend="unMountedMask()"
        v-if="isRenderMask"
      ></div>
    </transition>
    <transition
      :enter-active-class="'animated faster ' + initOptions.slotAnimatedEnter"
      :leave-active-class="'animated faster ' + initOptions.slotAnimatedLeave"
    >
      <slot></slot>
      <!-- <div class="position-fixed z100" style=" bottom: 0; left: 0; right: 0; background-color: #fff" v-if="isRenderMask">213121212</div> -->
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      initOptions: {},
      isRenderMask: false,
      isMountedMask: null
    };
  },
  methods: {
    async render(initOptions = {}) {
      this.isMountedMask = true;
      this.initOptions = initOptions;

      await this.$nextTick();

      this.isRenderMask = true;
      this.addOrRemovePopstateEvent(true);
    },

    disRender() {
      const initOptions = this.initOptions;

      initOptions.removeCb && initOptions.removeCb();

      this.isRenderMask = false;
      this.addOrRemovePopstateEvent(false);
    },

    //点击遮罩移除遮罩
    removeMaskByTap() {
      const initOptions = this.initOptions;

      if (!initOptions.closeByBtn === true) {
        this.disRender();

        clearTimeout(this.removeMaskTimout);
        this.removeMaskTimout = setTimeout(() => {
          this.unMountedMask();
        }, 500);
      } else {
        console.info("不可点击通过点击遮罩关闭");
      }
    },

    //移除遮罩
    unMountedMask() {
      if (this.isRenderMask === false) {
        this.isMountedMask = false;
        console.info("动画结束", "");
      }
    },

    addOrRemovePopstateEvent(isAddEvent) {
      const _window = this.window || (this.window = window);

      if (isAddEvent) {
        _window.addEventListener("popstate", this.onPopState, true);
      } else {
        _window.removeEventListener("popstate", this.onPopState, true);
      }

      console.warn("isAddEvent", isAddEvent);
    },

    onPopState() {
      console.info("点击返回键，关闭遮罩", "");
      this.disRender();
    }
  }
};
</script>

<style lang="scss" scoped>
.mask-modules {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  .mask {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
}
</style>