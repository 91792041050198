<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableOptions: null,
    }
  },
  mounted() {
    this.tableOptions = this.getTableOptions()
  },
  methods: {
    getTableOptions() {
      return {
        authenKey: '',
        headers: [
          { label: '操作类型', props: 'oper_type_str' },
          { label: '记录类型', props: 'log_type_str' },
          { label: '订单编号', props: 'order_id' },
          { label: '操作前剩余金额', props: 'before_money' },
          { label: '操作后的剩余金额', props: 'money' },
          { label: '操作人', props: 'operator' },
          { label: '操作时间', props: 'created_at' },
        ],
        getData: async (options) => {
          const { parentID } = this.initOptions
          const ret = await this.$ajax({
            apiKey: 'ycfLogList',
            data: {
              ...options,
              parent_id: parentID || '',
            },
          })
          return ret
        },
      }
    },
  },
}
</script>
