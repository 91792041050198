/*
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-13 17:50:31
 * @FilePath     : \leXue_manage_pc\src\store\index.js
 */

import Vue from 'vue'
import Vuex from 'vuex'

import permitMap from '@/assets/js/permitMap.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    adminDetails: null,
    edittingData: null,
    powerObj: {},
    // 是否是超级管理员
    ifAdmin: false,
  },
  mutations: {
    //去登录
    toLogin(state, _this) {
      let _toLoginQuery = _this.toLoginQuery

      localStorage.removeItem('token')

      _this.$router.push({
        name: 'login',
        query: _toLoginQuery || {},
      })
    },

    //保存管理员详情
    saveAdminDetails(state, options) {
      const { adminInfo, powerObj } = options || {}

      state.adminDetails = adminInfo
      state.powerObj = powerObj
    },

    SETIfAdmin(state, data) {
      // console.log('data', data)
      state.ifAdmin = data == 1
    },
  },
  actions: {
    //获取角色权限
    async getRoleAuthen({ commit }, _this) {
      try {
        //获取管理权限详情
        const { res } = await _this.$ajax({
          apiKey: 'getAdminAuthen',
        })

        // console.log('获取角色权限', res)
        commit('SETIfAdmin', res.role_id)

        //筛选匹配出拥有权限的id
        let _permitMap = permitMap
        let powerObj = {}

        let getAdminPowerIDList = (roleList) => {
          roleList.forEach((item) => {
            const { children, id } = item
            const currentKey = _permitMap[id]

            currentKey && (powerObj[currentKey] = true)

            children && getAdminPowerIDList(children)
          })
        }

        const adminDetails = {
          adminInfo: res.admin,
          powerObj,
        }

        getAdminPowerIDList(res.role)
        commit('saveAdminDetails', adminDetails)

        return adminDetails
      } catch (error) {
        console.error('getRoleAuthen', error)
      }
    },
  },
  getters: {
    //获取是否有权限
    getAuthenIsPermitted: (state) => (authenKey) => {
      try {
        if (!state.powerObj) {
          return false
        }

        let isGetAuthen = true

        switch (typeof authenKey) {
          case 'object':
            const isArrary =
              Object.prototype.toString.call(authenKey) === '[object Array]'

            if (isArrary) {
              authenKey.forEach((item) => {
                if (!!state.powerObj[item] === false) {
                  isGetAuthen = false
                }
              })
            }

            break
          case 'string':
            isGetAuthen = !!state.powerObj[authenKey]
            break
        }

        return isGetAuthen
      } catch (error) {
        console.error('getAuthenIsPermitted', error)
      }
    },

    // 是否已加载在管理员详情
    isLoadedAdminDetails: (state) => () => {
      return state.adminDetails !== null
    },

    getUserInfoByStore: (state) => {
      let { adminDetails } = state

      return adminDetails || {}
    },
  },
})
