<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-05-31 16:57:13
 * @FilePath: /src/views/class/classList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>

    <!-- 新增/编辑班级 -->
    <addOrEditClass ref="addOrEditClass"></addOrEditClass>
  </mainContentDiv>
</template>

<script>
import addOrEditClass from "@/views/class/addOrEditClass.vue";

export default {
  components: {
    addOrEditClass,
  },
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tableOptions: null,
    };
  },
  destroyed() {
    this.$bus_unique.off("updateClassList");
  },
  mounted() {
    this.tableOptions = this.getTableOptions();

    this.$bus_unique.on("updateClassList", "classList", () => {
      this.$refs.tableList.init();
    });
  },
  methods: {
    getTableOptions() {
      const { onSelect } = this.initOptions;

      let tableConfign;

      if (onSelect) {
        tableConfign = {
          actionBtns: [
            {
              title: "选择",
              color: "cyan",
              onTap: (options) => {
                onSelect(options);
              },
            },
          ],
        };
      } else {
        tableConfign = {
          actionBtns: [
            {
              title: "去点名",
              color: "cyan",
              authenKey: "class_details",
              onTap: (options) => {
                try {
                  if (options.status === 2) {
                    throw "该班级已禁用";
                  }

                  this.$router.push({
                    name: "classDetails",
                    query: {
                      pageType: 2,
                      id: options.id,
                      className: `班级名称：${options.name}`,
                    },
                  });
                } catch (error) {
                  this.$catchError(error);
                }
              },
            },
            {
              title: "编辑",
              color: "deepBlue",
              authenKey: "class_edit",
              onTap: async (options) => {
                try {
                  if (options.status === 2) {
                    throw "该班级已禁用";
                  }

                  await this.$refs.addOrEditClass.render({
                    type: 1,
                    inputedVal: options,
                  });

                  this.$refs.tableList.init(); //刷新数据
                } catch (error) {
                  this.$catchError(error);
                }
              },
            },
            {
              title: "删除",
              color: "red",
              authenKey: "class_del",
              onDelete: async (options) => {
                return await this.$ajax({
                  apiKey: "delClass",
                  data: {
                    cid: options.id, //cid	是	int	班级id
                  },
                });
              },
            },
            {
              title: "出勤记录",
              authenKey: "class_onDutyStudentList",
              onTap: async (options) => {
                this.$router.push({
                  name: "ondutyStudentList",
                  query: {
                    classID: options.id,
                  },
                });
              },
            },
            {
              type: "multiState", //多状态按钮
              props: "status",
              authenKey: "class_isForbidden",
              options: [
                {
                  label: "启用",
                  value: 2,
                  color: "success",
                },
                {
                  label: "禁用",
                  value: 1,
                  color: "warning",
                },
              ],
              onTap: async (btnObj, tableItem) => {
                this.$resureDialog({
                  ok: async () => {
                    try {
                      const { id, status } = tableItem;
                      const afterStatus = Number(status) === 1 ? 2 : 1;

                      const { msg } = await this.$ajax({
                        apiKey: "offOrOnClass",
                        data: {
                          cid: id, //cid	是	int	班级id
                          status: afterStatus, //status	是	int	类型 1启用 2删除(暂无) 3禁用
                        },
                      });

                      tableItem.status = afterStatus;

                      this.$toast({
                        msg,
                      });
                      this.$refs.tableList.init(); //刷新数据
                    } catch (error) {
                      this.$catchError(error);
                    }
                  },
                });
              },
            },
          ],
        };
      }

      return {
        authenKey: "class_list",
        search: {
          inputList: [
            {
              type: "input",
              label: "班级名称",
              placeholder: "",
              value: "",
              props: "class_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "班主任",
              placeholder: "",
              value: "",
              props: "teacher_name",
              rules: ["!null"],
            },
            {
              type: "select",
              label: "班级类型",
              value: "",
              props: "type",
              rules: ["!null"],
              options: [
                {
                  label: "中心班",
                  value: 1,
                },
                {
                  label: "校内班",
                  value: 2,
                },
              ],
            },
            {
              type: "select",
              label: "班级状态",
              value: "",
              props: "status",
              rules: ["!null"],
              options: [
                {
                  label: "正常",
                  value: 1,
                },
                {
                  label: "禁用",
                  value: 2,
                },
              ],
            },
          ],
        },

        headers: [
          { label: "班级名称", props: "name" },
          { label: "产品名称", props: "pro_name", type: "text" },
          { label: "班级类型", props: "_typeName" },
          { label: "班主任", props: "teacher_name" },
          { label: "学生数量", props: "student_count" },
          { label: "开班时间", props: "start_time" },
          { label: "结班时间", props: "end_time" },
          { label: "总课时", props: "count" },
          { label: "扣课时数（一节课）", props: "clock_count" },
     //     { label: "扣家长课包余额数量", props: "clock_coursepack" },
          // { label: '备注', props: 'remark' },
          { label: "状态", props: "status_str" },
        ],
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
          {
            title: "开班",
            authenKey: "class_add",
            onTap: async () => {
              try {
                await this.$refs.addOrEditClass.render({
                  type: 0,
                  // inputedVal: {
                  //   //     			"id": 17,
                  //   // "name": "1212",
                  //   // "admin_id": "7",
                  //   // "status": 1,
                  //   // "count": 11,
                  //   // "remark": "2323",
                  //   // "start_time": "2020-12-03",
                  //   // "end_time": "2020-12-26",
                  //   // "teacher_name": "13888888883",
                  //   // "student_count": 0
                  // },
                });
                this.$refs.tableList.init(); //刷新数据
              } catch (error) {
                this.$catchError(error);
              }

              // this.$router.push('addOrEditClass');
            },
          },
          {
            title: "导出",
            color: "normal",
            authenKey: "class_export",
            onTap: async (thisBtn) => {
              try {
                await this.$ajax({
                  isExportFile: true, //导出文件
                  methods: "POST",
                  apiKey: "exportClass",
                  fileName: "班级列表.xls",
                  data: thisBtn.keyword || "",
                  submitByForm: true,
                });
              } catch (error) {
                this.$catchError(error);
              }
            },
          },
        ],
        ...tableConfign,
        getData: async (options) => {
          let ret = await this.$ajax({
            apiKey: "getClassList",
            data: options,
          });

          return ret;
        },
      };
    },
  },
};
</script>
