/*
 * @Date         : 2020-12-01 10:12:05
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-02 13:45:03
 * @FilePath     : \leXue_manage_pc\src\assets\js\exportFile.js
 */

/**
 * @description: 导出文件
 * @param {String} url      请求地址
 * @param {String} methods  请求方式 GET || POST
 * @param {Object} data     body参数
 * @param {String} fileName 导出后文字的名字
 */
export default function exportFile({
    url,
    methods = 'GET',
    data,
    fileName,
    submitByForm,
    token,
}) {
    return new Promise((reolve, reject) => {
        // 参数
        let params = "?";

        for (let key in data) {
            params = params + key + "=" + data[key] + "&";
        }

        var xhr = new XMLHttpRequest();

        xhr.open(methods, url + params); // url填写后台的接口地址，如果是post，在formData append参数（参考原文地址）
        xhr.setRequestHeader("token", token);
        xhr.responseType = "blob";
        xhr.send(submitByForm ? data : new FormData());

        xhr.onload = function (e) {
            if (this.status === 200) {
                var blob = this.response;

                if (window.navigator.msSaveOrOpenBlob) {
                    navigator.msSaveBlob(blob, fileName);
                } else {
                    function createObjectURL(object) {
                        return window.URL
                            ? window.URL.createObjectURL(object)
                            : window.webkitURL.createObjectURL(object);
                    }

                    var a = document.createElement("a");
                    var url = createObjectURL(blob);

                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
                reolve();
            } else {
                reject(this);
            }
        };

        xhr.onerror = reject;
    });

}
