<!--
 * @Description: 教学大纲-查看(课程主题-教案)
-->
<template>
  <div>
    <div class="lineBox">
      <!-- 备课教案 -->
      <div class="line_l">
        <Item
          :list.sync="dataItem.front_plan"
          :theme_id="dataItem.id"
          :status.sync="dataItem.upload_front_status"
          :operationButton="operationButton.front_plan_button"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Item from './showTopicsLessonPlan_item.vue'

export default {
  props: {
    dataItem: {
      type: Object,
      default: () => {},
    },
    // 按钮权限
    operationButton: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Item,
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.lineBox {
  display: flex;
  justify-content: space-between;
  padding: 32px 0;

  .line_l,
  .line_r {
    flex: 6;
    padding: 0 50px;
    min-height: 100px;
  }
  .line_l {
    border-right: 1px solid #eaeaea;
  }

  .notUploadBox {
    span {
      font-size: 14px;
      color: #475774;
      margin-right: 10px;
    }
  }
  .uploadListBox {
    table {
      border: 1px solid #eaeaea;
      margin-top: 16px;

      &:last-child {
        border-right: 0;
      }

      th,
      td {
        text-align: center;
        border-right: 1px solid #eaeaea;
        height: 50px;
        font-size: 14px;
      }
      th {
        background: #a0a8b6;
        color: #fff;
      }
      td {
        border-top: 1px solid #eaeaea;
        color: #606060;

        i {
          margin-left: 16px;
          font-size: 16px;
          color: #1891ff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
