<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-02 17:49:29
 * @FilePath     : \leXue_manage_pc\src\components_global\uploadAndPreviewImg\maskBg.vue


 使用手册

1) html 
<maskBg ref="maskBg">
    插槽内容
</maskBg>


2) js


弹出

this.$refs.maskBg.render({
  slotAnimatedEnter: "bounceIn",
  slotAnimatedLeave: "bounceOut",
  closeByBtn: true, //不必填 | 关闭弹窗的方式 | 不填：点击遮罩即可关闭  true：只能手动关闭
  removeCb: () => { //比比天 | 移除遮罩层后的回调

  }
});

手动关闭

this.$refs.maskBg.disRender();

-->



<template>
  <div class="position-fixed z300 mask-modules" v-if="isMountedMask">
    <transition
      enter-active-class="animated faster fadeIn"
      leave-active-class="animated faster fadeOut"
    >
      <div class="position-absolute mask" @click="removeMaskByTap()" v-if="isRenderMask"></div>
    </transition>
    <transition
      :enter-active-class="'animated faster ' + initOptions.slotAnimatedEnter"
      :leave-active-class="'animated faster ' + initOptions.slotAnimatedLeave"
    >
      <slot></slot>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      initOptions: {},
      isRenderMask: null,
      isMountedMask: null,
    };
  },

  activated() {
    const _isRenderMask = this.isRenderMask;

    if (_isRenderMask === false) {
      this.isRenderMask = true;
      this.isMountedMask = true;
      this.addOrRemovePopstateEvent(true);
    }
  },
  deactivated() {
    const _isRenderMask = this.isRenderMask;

    if (_isRenderMask === true) {
      this.isRenderMask = false;
      this.isMountedMask = false;
      this.addOrRemovePopstateEvent(false);
    }
  },

  methods: {
    async render(initOptions = {}) {
      this.isMountedMask = true;
      this.initOptions = initOptions;

      await this.$nextTick();

      this.isRenderMask = true;
      this.addOrRemovePopstateEvent(true);
      initOptions.mountedCb && initOptions.mountedCb();
    },

    disRender() {
      const initOptions = this.initOptions;

      initOptions.removeCb && initOptions.removeCb();

      this.isRenderMask = null;
      this.addOrRemovePopstateEvent(false);

      clearTimeout(this.timeOut_removeMask);
      this.timeOut_removeMask = setTimeout(() => {
        this.isMountedMask = null;
      }, 500);
    },

    //点击遮罩移除遮罩
    removeMaskByTap() {
      const initOptions = this.initOptions;

      if (!initOptions.closeByBtn === true) {
        this.disRender();

        clearTimeout(this.removeMaskTimout);
        this.removeMaskTimout = setTimeout(() => {
          this.unMountedMask();
        }, 500);
      } else {
        console.info("不可点击通过点击遮罩关闭");
      }
    },

    //移除遮罩
    unMountedMask() {
      if (this.isRenderMask === false) {
        this.isMountedMask = null;
      }
    },

    addOrRemovePopstateEvent(isAddEvent) {
      const _window = this.window || (this.window = window);

      if (isAddEvent) {
        _window.addEventListener("popstate", this.onPopState, true);
      } else {
        _window.removeEventListener("popstate", this.onPopState, true);
      }
    },

    onPopState() {
      console.info("点击返回键，关闭遮罩", "");
      this.disRender();
    },
  },
};
</script>

<style lang="scss" scoped>
.mask-modules {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  .mask {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
}
</style>