<!--
 * @Date         : 2021-03-02 16:06:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-01 11:00:42
 * @FilePath: /src/views/order/selectProductModules.vue
-->
<template>
  <div>
    <infoWrapper
      class="my-10"
      title="产品信息"
      :titleBtns="productTitleBtnList"
    >
      <div class="px-20 pt-10 w-75">
        <div
          class="col-3"
          v-for="(item, index) in inputList_parentInfo.inputList"
          :key="index"
        >
          <div class="mr-10 mb-10">
            <div
              class="my-5 com-title"
              v-if="item.label"
              :class="{
                active:
                  item.rules && item.rules.find((item) => item === 'required'),
              }"
            >
              {{ item.label }}
            </div>
            <!-- 搜索框 -->
            <el-select
              class="w-100"
              size="small"
              filterable
              clearable
              remote
              reserve-keyword
              v-if="item.type === 'select'"
              v-model="item.value"
              :placeholder="item.placeholder"
              :remote-method="item.remoteMethod"
              suffix-icon="el-icon-date"
              :disabled="item.disabled"
              @change="item.onChange && item.onChange($event, item)"
              @focus="item.onFocus && item.onFocus($event, item)"
            >
              <el-option
                v-for="item in item.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </infoWrapper>
    <div style="margin-top: -1rem">
      <mainContentDiv
        class="my-10"
        v-for="(item, index) in inputList_productInfo"
        :key="index"
      >
        <div class="d-flex justify-content-between">
          <div class="py-10 px-20 w-75">
            <inputCenter :initOptions="item"></inputCenter>
          </div>
          <div
            class="d-flex flex-column px-20 del-btn-box"
            v-if="index > 0 && (pageType === 0 || pageType === 1)"
          >
            <el-button
              class="ma-auto"
              size="mini"
              type="danger"
              @click="handleRemoveProduct(index, inputList_productInfo)"
              >删除</el-button
            >
          </div>
        </div>
      </mainContentDiv>
    </div>
  </div>
</template>

<script>
import infoWrapper from "@/components/infoWrapper.vue";
import validValue from "@/assets/js/validValue.js";
import debounce from "@/assets/js/debounce.js";

export default {
  components: {
    infoWrapper,
  },
  props: {
    selectedProductList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    pageType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      inputList_productInfo: [],
      labelValKeyList: [
        {
          label: "classify_name",
          value: "classify_id",
        },
        {
          label: "grade_name",
          value: "grade_id",
        },
        {
          label: "spec_name",
          value: "spec_id",
        },
        {
          label: "attr_name",
          value: "attr_id",
        },
      ],
      inputList_parentInfo: {
        inputList: this.getInputList_parentInfo(),
      },
    };
  },
  beforeCreate() {
    this._selectedProductIDObj = {}; //记录已选择的产品，用于去重
  },
  beforeMount() {
    console.log("selectedProductList", this.selectedProductList);
    const selectedProductList = this.selectedProductList;
    const inputList_productInfo = this.inputList_productInfo;
    const getInputProductConfig = this.getInputProductConfig;

    const pushProductConfig = (inputedValue = "") => {
      const productConfigSymbol = Symbol("productConfigSymbol");

      inputList_productInfo.push({
        inputList: getInputProductConfig(inputedValue, productConfigSymbol),
        productConfigSymbol,
      });
    };

    if (selectedProductList.length > 0) {
      selectedProductList.forEach((item) => {
        pushProductConfig(item);
      });
    } else {
      pushProductConfig();
    }
  },
  methods: {
    // 填充老师
    setTeacher_name(item) {
      console.log("填充老师", item);
      this.inputList_parentInfo.inputList[0].value = item.name;
      this.inputList_parentInfo.inputList[0].id = item.id;
    },

    // 对接老师
    getInputList_parentInfo() {
      const _tempValObj = {};
      let { pageType } = this.$route.query;
      pageType = Number(pageType);

      const inputList = [
        (_tempValObj.teacher_id = {
          type: "select",
          label: "对接老师",
          placeholder: "请选择对接老师",
          rules: ["required", "!null"],
          value: "",
          id: "",
          props: "name",
          props_id: "id",
          options: [],
          disabled: pageType === 0 || pageType === 1 ? false : true,
          remoteMethod: async (keyword) => {
            try {
              console.info("keyword", keyword);

              const {
                res: { data },
              } = await this.$ajax({
                apiKey: "teacherServiceList",
                data: {
                  name: keyword || "",
                },
              });

              const thisObj = _tempValObj.teacher_id;
              thisObj.options = data.map((item) => {
                const { id, name } = item;

                return {
                  label: name,
                  value: id,
                };
              });
            } catch (error) {
              this.$catchError(error);
            }
          },
          onChange: (val, thisObj) => {
            // console.log('val, thisObj', val, thisObj)
            thisObj.id = val;
          },
          onFocus: (event, thisObj) => {
            //如果选择的数据非法，则使用默认获取的所有options
            this.tryReInputAllSelectOptions(thisObj);
          },
        }),
      ];

      return inputList;
    },
    //回填所有的options
    tryReInputAllSelectOptions(thisObj) {
      const { options, value, allOptions } = thisObj;

      //如果选择的数据非法，则使用默认获取的所有options
      if (!value || options.length === 0) {
        options.length = 0;

        thisObj.remoteMethod();
      }
    },

    /**
     * @description: 获取产品配置
     * @param {*} currentProduct
     * @param {*} productConfigSymbol 当前产品配置的唯一标识，用于避免重复选择产品
     * @return {*}
     */
    getInputProductConfig(currentProduct, productConfigSymbol) {
      const _tempValObj = {};
      const pageType = this.pageType; //pageType  0：新增  1：编辑  2：详情  3：退款

      let productConfigList = [
        //选择产品
        (_tempValObj.product = {
          type: "select",
          label: "产品名称",
          placeholder: "请搜索/选择产品",
          rules: ["required", "!null"],
          value: "",
          props: "pro_id",
          col: "col-3",
          options: [],
          disabled: pageType === 0 || pageType === 1 ? false : true,
          remoteMethod: async (keyword) => {
            try {
              const {
                res: { data },
              } = await this.$ajax({
                apiKey: "getProdutList",
                data: {
                  name: keyword || "",
                  status: 1,
                },
              });

              _tempValObj.product.options = data.map((item) => {
                const { id, name, type_name } = item;

                return {
                  label: name,
                  value: id,
                  typeName: type_name,
                };
              });
            } catch (error) {
              this.$catchError(error);
            }
          },
          onClear: () => {
            const { product, skuConfig } = _tempValObj;

            //清空产品规格详情
            skuConfig.value.length = 0;
            skuConfig.key += 1; //特殊操作：使得级联选择器更换value时不会报错
            skuConfig.disabled = true;

            skuConfig.onClear();

            //清除当前选择的产品id
            const currentProductConfig = this.inputList_productInfo.find(
              (item) => item.productConfigSymbol === productConfigSymbol
            );
            currentProductConfig.currentProductID = null;

            //回填原始的options
            product.options = this.productOptions;
          },
          onChange: async (val, thisObj) => {
            try {
              const { skuConfig } = _tempValObj;
              const { options, value } = thisObj;

              const currentOpt = options.find((item) => item.value === value);

              if (!currentOpt) {
                return false;
              }

              // 阻止重复选择产品
              this.preventSelectSameProduct(
                value,
                thisObj,
                productConfigSymbol
              );

              skuConfig.value.length = 0;
              skuConfig.key += 1; //特殊操作：使得级联选择器更换value时不会报错

              await this.getSetProductSkuByID(value, _tempValObj);

              skuConfig.disabled = false;
            } catch (error) {
              this.$catchError(error);
            }
          },
        }),
        //选择产品规格
        (_tempValObj.skuConfig = {
          type: "cascader",
          expandTrigger: "hover",
          label: "规格明细",
          placeholder: "请选择产品规格明细",
          rules: ["required", "!null"],
          value: [],
          id: "",
          props: "sku_name",
          props_id: "sku_id",
          col: "col-9",
          disabled: pageType === 1 ? false : true,
          options: [],
          key: 0,
          onClear: () => {
            const { skuDetailConfigList_ableEdit, skuDetailConfigList } =
              _tempValObj;

            skuDetailConfigList_ableEdit.forEach((item) => {
              item.disabled = true;
            });

            skuDetailConfigList.forEach((item) => {
              item.value = null;
            });

            this.updateSelectedProductInfo();
          },
          onChange: (valueList) => {
            const { skuConfig, skuDetailConfigList_ableEdit } = _tempValObj;

            if (!valueList || valueList.length <= 0) {
              skuConfig.onClear();
              return false;
            }

            //填充sku配置详情
            this.setSkuConfigDetail(_tempValObj, valueList);

            //设置可编辑的部分为 可编辑
            skuDetailConfigList_ableEdit.forEach((item) => {
              item.disabled = false;
            });

            //更新选中的产品信息
            this.updateSelectedProductInfo();
          },
        }),
        ...(_tempValObj.skuDetailConfigList = [
          {
            type: "input",
            label: "原价",
            placeholder: "请输入原价",
            maxLength: 20,
            value: 0,
            rules: ["!null"],
            props: "originPrice",
            col: "col-3",
            disabled: true,
          },
          {
            type: "input",
            label: "原总课时",
            placeholder: "请输入原总课时",
            maxLength: 20,
            value: 0,
            rules: ["!null"],
            props: "originCount",
            col: "col-3",
            disabled: true,
          },
          // {
          //   type: "input",
          //   label: "原总课包",
          //   placeholder: "请输入原总课包",
          //   maxLength: 20,
          //   value: 0,
          //   rules: ["!null"],
          //   props: "originPack_num",
          //   col: "col-3",
          //   disabled: true,
          // },
          ...(_tempValObj.skuDetailConfigList_ableEdit = [
            {
              type: "input",
              label: "订单价格",
              placeholder: "请输入订单价格",
              maxLength: 20,
              value: 0,
              rules: ["required", "!null"],
              props: "price",
              col: "col-3",
              disabled: pageType === 1 ? false : true,
              onChange: (thisObj) => {
                if (thisObj.value) {
                  validValue.inputMoney(thisObj);
                  this.updateSelectedProductInfo();
                }
              },
              onClear: (thisObj) => {
                this.updateSelectedProductInfo();
              },
            },
            {
              type: "input",
              label: "订单课时",
              placeholder: "请输入订单课时",
              maxLength: 20,
              value: 0,
              rules: ["required", "!null"],
              props: "count",
              col: "col-3",
              disabled: pageType === 1 ? false : true,
              onChange: (thisObj) => {
                if (thisObj.value) {
                  validValue.inputMoney(thisObj);
                  this.updateSelectedProductInfo();
                }
              },
              onClear: (thisObj) => {
                this.updateSelectedProductInfo();
              },
            },
          ]),
          //新增时不显示
          ...(pageType !== 0
            ? [
                {
                  type: "input",
                  label: "剩余金额",
                  placeholder: "请输入剩余金额",
                  maxLength: 20,
                  value: 0,
                  rules: ["!null"],
                  props: "surplus_price",
                  col: "col-3",
                  disabled: true,
                },
                {
                  type: "input",
                  label: "剩余课时",
                  placeholder: "请输入剩余课时",
                  maxLength: 20,
                  value: 0,
                  rules: ["!null"],
                  props: "surplus_count",
                  col: "col-3",
                  disabled: true,
                },
                // {
                //   type: "input",
                //   label: "余额课包",
                //   placeholder: "请输入余额课包",
                //   maxLength: 20,
                //   value: 0,
                //   rules: ["!null"],
                //   props: "surplus_pack_num",
                //   col: "col-3",
                //   disabled: true,
                // },
              ]
            : []),
          //退款
          ...(pageType === 3
            ? (_tempValObj.refundInfoList = [
                {
                  type: "input",
                  label: "退回金额",
                  placeholder: "请输入退回金额",
                  maxLength: 20,
                  value: 0,
                  rules: ["required", "!null"],
                  props: "refundPrice",
                  col: "col-3",
                },
                // {
                //   type: "input",
                //   label: "退回课包",
                //   placeholder: "请输入退回课包",
                //   maxLength: 20,
                //   value: 0,
                //   rules: ["required", "!null"],
                //   props: "refund_pack_num",
                //   col: "col-3",
                // },
                {
                  type: "input",
                  label: "退回课时",
                  placeholder: "请输入退回课时",
                  maxLength: 20,
                  value: 0,
                  rules: ["required", "!null"],
                  props: "refundCount",
                  col: "col-3",
                },
              ])
            : []),
        ]),
      ];

      _tempValObj.product.remoteMethod().then(() => {
        if (currentProduct) {
          //回显产品信息
          this.reShowProductInfo(
            currentProduct,
            _tempValObj,
            productConfigList
          );
        }

        //保存当前的product 的 options的值，当product onClear()时，回填它的options;
        this.productOptions = JSON.parse(
          JSON.stringify(_tempValObj.product.options)
        );
      });

      return productConfigList;
    },

    //获取并设置sku配置
    async getSetProductSkuByID(value, _tempValObj) {
      const { skuConfig } = _tempValObj;

      //重新获取并设置产品规格详情
      let { res } = await this.$ajax({
        apiKey: "getProductDetails",
        data: {
          id: value,
        },
      });

      const { sku } = res;
      const rootArr = [];

      this.getProductSku(rootArr, sku);

      //可以选择产品规格
      skuConfig.options = rootArr;
      _tempValObj.skuList = sku;
    },

    //填充sku配置详情
    setSkuConfigDetail(_tempValObj, valueList) {
      const {
        skuConfig, //sku配置 ，规格
        skuList, //当前产品的skuList
        skuDetailConfigList, //显示sku详情 的 配置列表
      } = _tempValObj;
      const labelValKeyList = this.labelValKeyList;

      //找出当前sku
      const currentSku = skuList.find((itemSku) => {
        let isThisSku = true;

        labelValKeyList.forEach((item, index) => {
          if (itemSku[item.value] !== valueList[index]) {
            isThisSku = false;
          }
        });

        return isThisSku;
      });

      skuConfig.id = currentSku.id;

      //拷贝字段，用于sku详情显示
      console.log("currentSku-123123", currentSku);
      currentSku.originPrice = currentSku.surplus_price = currentSku.price;
      currentSku.originCount = currentSku.surplus_count = currentSku.count;
      currentSku.originPack_num = currentSku.surplus_pack_num =
        currentSku.pack_num;

      if (this.pageType === 2 || this.pageType === 3) {
        currentSku.surplus_count = this.selectedProductList[0].surplus_count;
        currentSku.surplus_pack_num =
          this.selectedProductList[0].surplus_pack_num;
      }

      //填充sku详情：价格、总课时，剩余课时，剩余价格
      this.$com.fillData({
        inputList: skuDetailConfigList,
        valueListObj: currentSku,
      });
    },

    //回显产品信息
    async reShowProductInfo(currentProduct, _tempValObj, productConfigList) {
      const { product, skuConfig } = _tempValObj;

      //回显：把当前选择的产品输入options里面，用于回显
      const productValKey = product.props;

      const isExistedThisProduct = product.options.find((item) => {
        return item.value === currentProduct[productValKey];
      });

      if (!isExistedThisProduct) {
        product.options.push({
          label: currentProduct.pro_name,
          value: currentProduct[productValKey],
        });
      }

      // 回显：已选择的产品规格
      const valueList = [];

      this.labelValKeyList.forEach((item) => {
        valueList.push(currentProduct[item.value]);
      });

      skuConfig.value = valueList;

      //获取并设置产品的sku
      await this.getSetProductSkuByID(
        currentProduct[productValKey],
        _tempValObj
      );

      //设置sku配置详情（此处会填充剩余价格A 剩余课时B，在回显的时候，会覆盖真正的 A和B）
      this.setSkuConfigDetail(_tempValObj, valueList);

      //回显：赋值真正的值 A和B
      const valueObj = {
        pro_id: currentProduct.pro_id, //产品id
        price: currentProduct.price, //价格
        count: currentProduct.count, //原价
        pack_num: currentProduct.pack_num, //原价
        surplus_price: currentProduct.surplus_price, //剩余价格
        surplus_count: currentProduct.surplus_count, //剩余课时
        surplus_pack_num: currentProduct.surplus_pack_num, //剩余课时
      };

      //赋值
      productConfigList.forEach((item) => {
        const { props, props_id } = item;
        const value = valueObj[props];
        const id = valueObj[props_id];

        if (props && value) {
          item.value = value;
        }
        if (props_id && id) {
          item.id = id;
        }
      });

      this.updateSelectedProductInfo();
    },

    /**
     * @description: 阻止选择同样的产品
     * @param {*} currentProductID 当前选择的产品id
     * @param {*} thisObj 当前配置项的（选择产品下拉框）
     * @param {*} productConfigSymbol 当前产品配置的唯一标识
     */
    preventSelectSameProduct(currentProductID, thisObj, productConfigSymbol) {
      const inputList_productInfo = this.inputList_productInfo;
      const isExistedThisProduct = inputList_productInfo.find(
        (item) => item.currentProductID === currentProductID
      );

      if (isExistedThisProduct) {
        thisObj.value = null;
        thisObj.onClear();

        throw "请勿重复选择本课程";
      }

      const currentProductConfig = inputList_productInfo.find(
        (item) => item.productConfigSymbol === productConfigSymbol
      );
      currentProductConfig.currentProductID = currentProductID;
    },

    //初始化方法，获取商品sku
    getProductSku(...options) {
      const getProductSku = this.getProductSku;
      const { render } = getProductSku;

      if (render) {
        return render(...options);
      } else {
        const labelValKeyList = this.labelValKeyList;
        const labelValKeyListLen = labelValKeyList.length;

        //整理数据，生成树状图数据

        const render = (parentChild, rootData, currentLevel = 0) => {
          const { label: currentLabelKey, value: currentValKey } =
            labelValKeyList[currentLevel];

          rootData.forEach((item) => {
            const label = item[currentLabelKey];
            const value = item[currentValKey];
            const hasThis = parentChild.find((item) => item.value === value);

            if (!hasThis) {
              const currentChild = {
                label,
                value,
              };

              parentChild.push(currentChild);

              if (currentLevel !== labelValKeyListLen - 1) {
                render(
                  (currentChild.children = []),
                  rootData,
                  currentLevel + 1
                );
              }
            }
          });
        };

        getProductSku.labelValKeyList = labelValKeyList;
        getProductSku.render = render;

        return render(...options);
      }
    },

    //配置设置已选择的产品信息
    updateSelectedProductInfo: debounce(function () {
      const productInfoList = this.inputList_productInfo;
      const orderProductInfo = {
        count: 0,
        price: 0,
        pack_num: 0,
        originPack_num: 0,
      };
      let buyyedProductQty = 0;

      productInfoList.forEach((item) => {
        try {
          const current = this.$com.getValueByRules(item.inputList);

          for (let key in orderProductInfo) {
            const value = current[key];

            if (value) {
              orderProductInfo[key] += Number(value);
            }
          }

          if (current.sku_id) {
            buyyedProductQty += 1;
          }
        } catch (error) {
          console.warn("nothing", error);
        }
      });

      orderProductInfo.qty = buyyedProductQty;

      this.$emit("updateProductInfo", orderProductInfo);
    }),

    //删除产品
    handleRemoveProduct(index, dataList) {
      this.$confirm("确定删除？").then(() => {
        dataList.splice(index, 1);

        this.updateSelectedProductInfo();
      });
    },

    //获取选择的产品
    getSelectedProduct(recordedObj) {
      const inputList_productInfo = this.inputList_productInfo;
      const valueList = [];

      inputList_productInfo.forEach((item, index) => {
        try {
          const current = this.$com.getValueByRules(item.inputList);

          valueList.push(current);
        } catch (error) {
          throw `请检查第${index + 1}个的产品；具体错误：${error}`;
        }
      });

      //新增和编辑订单，当产品总原价不等于当前总价时，需要记录信息 recordedObj。
      if (recordedObj) {
        let totalOriginPrice = 0; // 总原价（所有产品的原价相加）
        let currentTotalPrice = 0; // 当前总价（所有产品的现在的价格相加）

        valueList.forEach((item) => {
          const { originPrice, price } = item;

          totalOriginPrice += originPrice;
          currentTotalPrice += price;
        });

        //总原价 !== 当前总价 && 没有记录信息 => 提示记录信息
        if (
          totalOriginPrice !== currentTotalPrice &&
          Object.values(recordedObj).length <= 0
        ) {
          throw "产品总价与产品原总价不一致，请记录信息";
        }
      }

      return valueList;
    },
  },
  computed: {
    productTitleBtnList() {
      const pageType = this.pageType;

      //pageType 0：新增 1：编辑
      if (pageType === 0 || pageType === 1) {
        return [
          {
            title: "新增产品",
            onTap: () => {
              const productConfigSymbol = Symbol("productConfigSymbol");

              this.inputList_productInfo.push({
                inputList: this.getInputProductConfig("", productConfigSymbol),
                productConfigSymbol,
              });
            },
          },
        ];
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.del-btn-box {
  background-color: #f9f9f9;
  box-shadow: 0rem 0.22rem 0.53rem 0.02rem rgba(218, 218, 218, 0.5);
}
</style>
