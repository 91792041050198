<!--
 * @Date         : 2020-11-20 14:49:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-16 13:53:05
 * @FilePath     : \leXue_manage_pc\src\views\school\totalSchoolList.vue
-->
<template>
  <!--左边： 总校列表 -->
  <mainContentDiv class="h-100 left-container">
    <sideTable
      ref="sideTable"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></sideTable>

    <!-- 弹窗：新增/编辑学校 -->
    <centerDialog ref="schoolListDialog">
      <div class="w-50">
        <inputCenter :initOptions="initOptions_school"></inputCenter>
      </div>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
import sideTable from '@/components/sideTable.vue'

export default {
  components: {
    sideTable,
  },
  data() {
    return {
      tableOptions: null,
      initOptions_school: null,
    }
  },
  destroyed() {
    this.$bus_unique.off('updateAuthenList')
  },
  mounted() {
    this.tableOptions = this.getInputTable()

    this.$bus_unique.on('updateAuthenList', 'authenList', () => {
      this.$refs.tableList.init()
    })
  },
  methods: {
    //弹出新增/编辑学校对话框
    renderAddOrEditSchoolDialog(type, tableItem) {
      let inputOptions = (this.initOptions_school = {
        inputList: this.getInputSchool(tableItem),
      })

      // 已编辑的值回填
      if (tableItem) {
        this.$com.fillData({
          inputList: inputOptions.inputList,
          valueListObj: tableItem,
        })
      }

      this.$refs.schoolListDialog.render({
        title: ['', '新增总校', '编辑总校'][type],
        width: '500px',
        verifyInputListIsChange: inputOptions.inputList,
        closeOnClickModal: false,
        ok: async () => {
          try {
            const { inputList } = inputOptions

            let requestOpt = this.$com.getValueByRules(inputList)
            let apiKey

            switch (
              type // 1:新增 2:编辑
            ) {
              case 1:
                apiKey = 'addSchool'
                break
              case 2:
                apiKey = 'editSchool'
                requestOpt.id = tableItem.id
                break
            }

            const { msg } = await this.$ajax({
              apiKey,
              data: requestOpt,
            })

            this.$refs.sideTable.init()
            this.$toast({
              msg,
            })

            if (type === 2) {
              this.$emit('change', requestOpt)
            }
          } catch (error) {
            throw error
          }
        },
        cancel: () => {},
      })
    },

    getInputSchool(inputedValueObj) {
      const { full_region, region_id } = inputedValueObj || {}

      console.info('region_id', region_id)

      let inputList = [
        {
          ref: 'name',
          type: 'input',
          label: '总校名称',
          placeholder: '请输入学校',
          maxLength: 50,
          rules: ['required', '!null'],
          value: null,
          props: 'name',
        },
        {
          ref: 'areas',
          type: 'cascader',
          label: '区域',
          placeholder: full_region
            ? full_region.replace(/-/gim, '/')
            : '请选择省市区',
          rules: ['required', '!null'],
          value: region_id || null,
          props: 'region_id',
          lazyProps: {
            lazy: true,
            lazyLoad: async (node, resolve) => {
              console.info('node', node)

              try {
                const { parentID, value, level } = node
                const isLastLevel = level >= 2

                const {
                  res: { data },
                } = await this.$ajax({
                  apiKey: 'getRegionList',
                  data: {
                    parent_id: value || 1, //parent_id	否	int	父级id，默认1
                    paginate: 50,
                  },
                })

                let nodes = data.map((item) => {
                  const {
                    region_id,
                    region_name,
                    parent_id,
                    region_type,
                  } = item

                  return {
                    value: region_id,
                    label: region_name,
                    parentID: parent_id,
                    leaf: isLastLevel,
                  }
                })

                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                resolve(nodes)
              } catch (error) {
                this.$catchError(error)
              }
            },
          },
          onChange: (regionIDList, thisObj) => {
            thisObj.value = regionIDList
              ? regionIDList[regionIDList.length - 1]
              : null
          },
        },
      ]

      return inputList
    },
    getInputTable() {
      return {
        title: '总校列表',
        authenKey: 'school_list',
        header: {
          rightTopBtns: [
            {
              title: '新增总校',
              authenKey: 'school_add',
              onTap: (options) => {
                this.renderAddOrEditSchoolDialog(1)
              },
            },
          ],
          inputList: [
            {
              type: 'input',
              placeholder: '请输入总校名称',
              maxLength: 50,
              rules: ['required', '!null'],
              value: null,
              props: 'name',
              actionBtns: [
                {
                  type: 'filter',
                  title: '查询',
                },
              ],
            },
            {
              type: 'cascader',
              label: '区域',
              placeholder: '请选择省市区',
              rules: ['required', '!null'],
              value: null,
              props: 'region_ids',
              lazyProps: {
                lazy: true,
                multiple: true,
                lazyLoad: async (node, resolve) => {
                  console.info('node', node)

                  try {
                    const { parentID, value, level } = node
                    const isLastLevel = level >= 2

                    const {
                      res: { data },
                    } = await this.$ajax({
                      apiKey: 'getRegionList',
                      data: {
                        parent_id: value || 1, //parent_id	否	int	父级id，默认1
                        paginate: 50,
                      },
                    })

                    let nodes = data.map((item) => {
                      const {
                        region_id,
                        region_name,
                        parent_id,
                        region_type,
                      } = item

                      return {
                        value: region_id,
                        label: region_name,
                        parentID: parent_id,
                        leaf: isLastLevel,
                      }
                    })

                    // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                    resolve(nodes)
                  } catch (error) {
                    this.$catchError(error)
                  }
                },
              },
            },
          ],
        },
        body: {
          td: {
            checkType: 'single', //必填 | 选择的类型 | 参数：mutil(多选) single(单选)
            props: 'name',
            actionBtns: [
              {
                type: 'normal', //必填 | 按钮类型 | 参数: del(删除)
                iconfont: 'icon-edit', //必填 | 按钮的iconfont | 参数：iconfont.css
                authenKey: 'school_edit', //必填 | 权限配置key
                //必填 | 编辑的请求 | 类型：Promise
                onTap: (tableItem) => {
                  this.renderAddOrEditSchoolDialog(2, tableItem)
                },
              },
              {
                type: 'del', //必填 | 按钮类型 | 参数: del(删除)
                iconfont: 'icon-del', //必填 | 按钮的iconfont | 参数：iconfont.css
                authenKey: 'school_del', //必填 | 权限配置key
                //必填 | 删除 | 类型：Promise
                onDelete: async (tableItem) => {
                  const { id } = tableItem

                  const { msg } = await this.$ajax({
                    apiKey: 'delSchool',
                    data: {
                      id,
                    },
                  })
                  this.$emit('change', null)
                },
              },
            ],
            onTap: (options) => {
              try {
                const lastID = this.lastID
                const { id } = options

                if (lastID === id) {
                  return false
                }

                this.lastID = id
                this.$emit('change', options)
              } catch (error) {
                this.$catchError(error)
              }
            },
          },
        },
        getData: async (options) => {
          const { region_ids } = options

          if (region_ids) {
            options.region_ids = JSON.stringify(
              region_ids.map((item) => item[item.length - 1])
            )
          }

          return await this.$ajax({
            apiKey: 'getSchoolList',
            data: options,
          })
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.left-container {
  width: 13.55rem;
}
</style>
