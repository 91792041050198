<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-16 10:17:09
 * @FilePath     : \leXue_manage_pc\src\components_global\chooseAndUploadLoaclImg.vue


 选择并上传图片

 
-->
<template>
  <input
    type="file"
    class="d-none"
    accept="image/*"
    ref="fileDom"
    @change="uploadHeaderImg"
  />
</template>

<script>
import validValue from "@/assets/js/validValue.js";
export default {
  methods: {
    //上传头像
    async uploadHeaderImg(e) {
      const file = e.target.files[0];
      const isImg = validValue.isAssetTypeAnImage(file.type);

      try {
        if (!isImg) throw "请选择图片格式";

        console.info('file', file);

        const {
          res: { url },
          msg,
        } = await this.$ajax({
          apiKey: "uploadHeader",
          submitByForm: true,
          data: {
            file,
          },
        });

        if (!url) throw msg;

        this.$emit("change", url);
      } catch (error) {
        this.$toast({
          color: "error",
          msg: error,
        });
      }
    },
    chooseImg() {
      this.$refs.fileDom.click();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>