<!--
 * @Date         : 2021-03-01 17:16:47
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-02 10:44:11
 * @FilePath     : \leXue_manage_pc\src\components\infoWrapper.vue
-->
<template>
  <mainContentDiv>
    <div class="title-modules">
      <div
        class="d-flex align-items-center pl-20 py-10 title show-active-line"
        v-if="title"
      >
        <div class="text">
          {{ title }}
        </div>
        <div class="d-flex align-items-center">
          <div
            class="mx-10 cursor-pointer title-btn"
            v-for="(item, index) in titleBtns"
            :key="index"
            @click="item.onTap && item.onTap(item)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <slot> </slot>
    </div>
  </mainContentDiv>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    titleBtns: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.title-modules {
  .title {
    border-bottom: 1px solid #eaeaea;

    .text {
      font-size: 0.89rem;
      color: #333333;
    }

    &.show-active-line {
      position: relative;

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        content: "";
        display: block;
        width: 4px;
        height: 20px;
        background-color: #1891ff;
      }
    }

    .title-btn {
      padding-left: 0.55rem;
      padding-right: 0.55rem;
      background-color: #333744;
      border-radius: 0.2rem;
      border: solid 0.05rem #cccccc;
      line-height: 1.5rem;
      font-size: 0.7rem;
      color: #ffffff;
    }
  }
}
</style>