/*
 * @Description: crypto-js 加密解密
 * 使用:
 *  import Crypoto from '@/utils/CryptoJS'
 *
 *  const cry: any = new Crypoto()
 *
 *  const str = '123'
 *
 *  加密:
 *  const encryptData = cry.encrypt(str)
 *  console.log('encryptData', encryptData)
 *
 *  解密:
 *  const decryptData = cry.decrypt(encryptData)
 *  console.log('decryptData', decryptData)
 */
import CryptoJS from 'crypto-js'

const key = CryptoJS.enc.Utf8.parse('ozkoalasHelpKey0') // 十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('ozkoalasHelpKey1') // 十六位十六进制数作为密钥偏移量

/* 加密 */
function encrypt(word) {
  const srcs = CryptoJS.enc.Utf8.parse(word)
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encrypted.ciphertext.toString().toUpperCase()
}

/* 解密 */
function decrypt(word) {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(word)
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}

export default {
  encrypt,
  decrypt,
}
