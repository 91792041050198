<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-13 14:29:51
 * @FilePath: /src/views/order/orderList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>

    <!-- 弹窗：退款记录 -->
    <centerDialog ref="centerDialog_refundOrder">
      <div style="height: 60vh" v-if="initOptions_refundOrder">
        <refundOrderList
          ref="refundOrderList"
          :initOptions="initOptions_refundOrder"
        ></refundOrderList>
      </div>
    </centerDialog>

    <!-- 核销课时 -->
    <el-dialog
      :visible.sync="writeOffPeriodDialogVisible"
      class="FormDialogBox fileInfoFormDialogBox"
      width="990px"
      title="核销课时"
      destroy-on-close
      :close-on-click-modal="false"
      @close="writeOffPeriodDialogClose"
    >
      <el-form
        :model="writeOffPeriodForm"
        :rules="formRules"
        ref="formRef"
        label-width="auto"
        label-position="top"
        size="small"
      >
        <el-form-item label="订单编号：" prop="order_id">
          <el-input :value="writeOffPeriodForm.order_id" readonly></el-input>
        </el-form-item>
        <el-form-item label="核销课时数量：" prop="num">
          <el-input-number
            v-model="writeOffPeriodForm.num"
            :min="1"
            :max="writeOffPeriodForm.maxNum"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="备注：" style="width: 100%" prop="remark">
          <el-input
            v-model="writeOffPeriodForm.remark"
            placeholder="请输入"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="danger"
          size="mini"
          @click="writeOffPeriodDialogVisible = false"
        >
          关 闭
        </el-button>
        <el-button
          type="primary"
          size="mini"
          :loading="writeOffPeriodSubmitLoading"
          @click="onWriteOffPeriodSubmit"
        >
          核 销
        </el-button>
      </span>
    </el-dialog>
  </mainContentDiv>
</template>

<script>
import refundOrderList from "@/views/order/refundOrderList.vue";
import { throttle } from "@/assets/js/index";

export default {
  components: {
    refundOrderList,
  },
  props: {
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableOptions: null,
      initOptions_refundOrder: null,
      writeOffPeriodDialogVisible: false,
      writeOffPeriodForm: {
        oid: "",
        num: 0,
        remark: "",
        order_id: "",
        maxNum: 0,
      },
      formRules: {
        num: [
          {
            required: true,
            message: "请输入核销课时数量",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入备注",
            trigger: "blur",
          },
        ],
      },
      writeOffPeriodSubmitLoading: false,
    };
  },
  destroyed() {
    this.$bus_unique.off("updateOrderList");
  },
  mounted() {
    this.selectedTrList = [];
    this.tableOptions = this.getTableOptions();

    this.$bus_unique.on("updateOrderList", "orderList", () => {
      this.$refs.tableList.init();
    });

    // 判断是否有带家长手机号过来
    let { mobile } = this.$route.query;
    // console.log('mobile', mobile)
    if (mobile) {
      // console.log('this.tableOptions', this.tableOptions)
      for (let i of this.tableOptions.search.inputList) {
        if (i.props === "mobile") {
          i.value = mobile;
        }
      }
    }
  },
  methods: {
    getTableOptions() {
      const isSelect = this.isSelect; //选择模式

      let configureOptions;

      if (isSelect === true) {
        configureOptions = {
          actionBtns: [
            {
              title: "选择",
              color: "cyan",
              isRender: true,
              onTap: (options) => {
                this.$emit("change", options);
              },
            },
          ],
        };
      } else {
        configureOptions = {
          titleBtns: [
            {
              type: "filter",
              title: "查询",
            },
            {
              title: "新增订单",
              authenKey: "order_add",
              onTap: async () => {
                this.$router.push({
                  name: "addOrEditOrder",
                  query: {
                    pageType: 0,
                  },
                });
              },
            },
            {
              title: "导出订单",
              color: "normal",
              authenKey: "order_export",
              onTap: async (thisBtn) => {
                try {
                  await this.$ajax({
                    isExportFile: true, //导出文件
                    methods: "POST",
                    apiKey: "exportOrder",
                    fileName: "订单列表.xls",
                    data: thisBtn.keyword || "",
                    submitByForm: true,
                  });
                } catch (error) {
                  this.$catchError(error);
                }
              },
            },
            {
              title: "下载导入订单模板",
              color: "normal",
              authenKey: "order_importTemplate",
              onTap: async (thisBtn) => {
                try {
                  await this.$ajax({
                    isExportFile: true,
                    methods: "POST",
                    apiKey: "exportImportTemplate_order",
                    fileName: "订单导入模板.xls",
                    data: thisBtn.keyword || "",
                    submitByForm: true,
                  });
                } catch (error) {
                  this.$catchError(error);
                }
              },
            },
            {
              type: "import",
              title: "导入订单",
              color: "normal",
              authenKey: "order_import",
              onRequest: async (currentSelectedFile) => {
                const ret = await this.$ajax({
                  responseAll: true,
                  methods: "POST",
                  apiKey: "importTemplate_order",
                  submitByForm: true,
                  data: {
                    file: currentSelectedFile,
                  },
                });

                return ret;
              },
            },
            {
              title: "审核订单",
              authenKey: "order_check",
              color: "cyan",
              onTap: async () => {
                try {
                  if (this.isSubmitted_checkOrder === true) {
                    return false;
                  }

                  const selectedTrList = this.selectedTrList;
                  let orderIDList = [];

                  selectedTrList.forEach((item) => {
                    const { oid, check_status } = item;

                    if (oid && check_status === 2) {
                      orderIDList.push(oid);
                    }
                  });

                  if (orderIDList.length <= 0) {
                    throw "请至少选择一个【待审核】订单";
                  }

                  this.isSubmitted_checkOrder = true;

                  const { msg } = await this.$ajax({
                    apiKey: "checkOrder",
                    data: {
                      oids: JSON.stringify(orderIDList),
                    },
                  });

                  const $tableList = this.$refs.tableList;

                  $tableList.clearSelection(); //清空table中的多选
                  $tableList.init();

                  this.$toast({
                    msg,
                  });
                } catch (error) {
                  this.$catchError(error);
                } finally {
                  this.isSubmitted_checkOrder = false;
                }
              },
            },
          ],
          actionBtns: [
            {
              title: "查看",
              color: "cyan",
              authenKey: "order_details",
              onTap: (options) => {
                this.$router.push({
                  name: "addOrEditOrder",
                  query: {
                    pageType: 2,
                    id: options.oid,
                    studentID: options.sid,
                  },
                });
              },
            },
            {
              title: "编辑",
              color: "blue",
              authenKey: "order_edit",
              onTap: (options) => {
                try {
                  if (options.check_status !== 2) {
                    throw "该订单已审核通过， 不可编辑";
                  }

                  this.$router.push({
                    name: "addOrEditOrder",
                    query: {
                      pageType: 1,
                      id: options.oid,
                      studentID: options.sid,
                    },
                  });
                } catch (error) {
                  this.$catchError(error);
                }
              },
            },
            {
              title: "退款",
              color: "red",
              authenKey: "order_refund",
              onTap: async (options) => {
                try {
                  const { check_status, _surplusTotalPrice } = options;

                  if (check_status !== 1) {
                    throw "该订单未审核， 不可退款";
                  }

                  if (_surplusTotalPrice <= 0) {
                    throw "已无余额";
                  }

                  //权限校验
                  this.$com.authenCheck(this, [
                    {
                      key: "order_details",
                      rejectTips: "订单详情",
                    },
                    {
                      key: "student_details",
                      rejectTips: "学生详情",
                    },
                  ]);

                  this.$router.push({
                    name: "addOrEditOrder",
                    query: {
                      pageType: 3,
                      id: options.oid,
                      studentID: options.sid,
                    },
                  });
                } catch (error) {
                  this.$catchError(error);
                }
              },
            },
            {
              title: "退款记录",
              color: "cyan",
              authenKey: "order_refundRecordList",
              onTap: (options) => {
                this.handleRenderRefundListByID(options);
              },
            },
            {
              title: "删除",
              color: "red",
              authenKey: "order_del",
              onDelete: async (options) => {
                return await this.$ajax({
                  apiKey: "delOrder",
                  data: {
                    oid: options.oid, //oid	是	int	订单id
                  },
                });
              },
            },
            {
              title: "订单课时核销",
              color: "cyan",
              authenKey: "order_clock",
              onTap: (options) => {
                this.writeOffPeriodDialogShow(options);
              },
            },
          ],
        };
      }

      const { orderType } = this.$route.query;

      const tableOptions = {
        authenKey: "order_list",
        search: {
          inputList: [
            {
              type: "input",
              label: "订单编号",
              placeholder: "",
              value: "",
              props: "order_id", //order_id	否	string	订单编号
              rules: ["!null"],
            },
            {
              type: "input",
              label: "校付通订单编号",
              placeholder: "",
              value: "",
              props: "xft_order_sn",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "产品名称",
              placeholder: "",
              value: "",
              props: "pro_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "学生名称",
              placeholder: "",
              value: "",
              props: "student_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "家长名称",
              placeholder: "",
              value: "",
              props: "parent_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "家长手机号码",
              placeholder: "",
              value: "",
              maxLength: 11,
              props: "mobile",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "所属校区",
              placeholder: "",
              value: "",
              props: "xq_name", //xq_name	否	string	校区名称关键词
              rules: ["!null"],
            },
            {
              type: "input",
              label: "消费地区",
              placeholder: "",
              value: "",
              props: "area_name", //area_name	否	string	地区名称
              rules: ["!null"],
            },
            {
              type: "input",
              label: "客服老师名称",
              placeholder: "",
              value: "",
              props: "teacher_name",
              rules: ["!null"],
            },
            {
              type: "select",
              label: "订单类型",
              placeholder: "",
              value: "",
              props: "order_type",
              rules: ["!null"],
              options: [
                {
                  label: "乐学教务",
                  value: 1,
                },
                {
                  label: "七彩童年",
                  value: 2,
                },
              ],
            },
            {
              type: "select",
              label: "订单状态",
              placeholder: "",
              value: Number(orderType) || "",
              props: "check_status", //check_status	否	string	订单状态
              rules: ["!null"],
              options: [
                {
                  label: "待审核",
                  value: 2,
                },
                {
                  label: "已通过",
                  value: 1,
                },
              ],
            },
            {
              type: "radioBtnList",
              label: "时间筛选",
              value: 0,
              props: "time_type", //time_type	否	int	时间类型 1-本月 2-上月 3-本年 4-去年 5-自定义
              rules: ["!null"],
              options: [
                {
                  label: "全部",
                  value: 0,
                },
                {
                  label: "本月",
                  value: 1,
                },
                {
                  label: "上月",
                  value: 2,
                },
                {
                  label: "本年",
                  value: 3,
                },
                {
                  label: "去年",
                  value: 4,
                },
                {
                  label: "自定义",
                  value: 5,
                },
              ],
              onChange: (val, thisObj, inputList) => {
                //增加：自定义时间选择控件
                if (val === 5) {
                  const startAndEndTimeRange = this.getStartAndEndTimeRange();

                  startAndEndTimeRange.forEach((item) => {
                    inputList.push(item);
                  });
                }

                //移除：自定义时间选择控件
                const dateTimePickerIndex = inputList.findIndex(
                  (item) => item.ref === "dateTimePicker"
                );

                if (val < 5 && dateTimePickerIndex !== -1) {
                  inputList.splice(dateTimePickerIndex, 2);
                }
              },
            },
          ],
        },
        headers: [
          {
            label: "订单编号",
            props: "order_id",
          },
          { label: "校付通订单编号", props: "xft_order_sn" },
          { label: "创建时间", props: "created_at" },
          { label: "学生名称", props: "student_name" },
          { label: "家长名称", props: "_parentName", type: "text" },
          { label: "订单类型", props: "order_type_str", type: "text" },
          { label: "对接老师", props: "teacher_name", type: "text" },
          { label: "所属校区", props: "xq_name" },
          { label: "产品名称", props: "_buyyedProduct", type: "text" },
          { label: "规格明细", props: "specificationDetails", type: "text" },
          { label: "总金额（元）", props: "surplus_price_sum" },
          { label: "总课时", props: "count_sum" },
          { label: "剩余总课时", props: "surplus_count_sum" },
          { label: "订单状态", props: "check_status_str" },
        ],
        ...configureOptions,
        select: {
          type: "mutiple",
          authenKey: "order_check",
          onChange: (val) => {
            this.selectedTrList = val;
          },
        },
        getData: async (options) => {
          if (options.time_type === 0) {
            delete options.time_type;
          }

          let ret = await this.$ajax({
            apiKey: "getOrderList",
            data: options,
          });

          this.$emit("incomeChange", ret.res.income); //总收入

          // 处理规格明细
          for (let i of ret.res.list.data) {
            if (i.product[0]) {
              i.specificationDetails = `${i.product[0].classify_name} / ${i.product[0].grade_name} / ${i.product[0].spec_name} / ${i.product[0].attr_name}`;
            } else {
              i.specificationDetails = "-";
            }
          }

          console.log("ret", ret.res.list.data);

          return {
            code: ret.code,
            res: ret.res.list,
          };
        },
      };

      return tableOptions;
    },

    //查看退款记录
    handleRenderRefundListByID(orderInfo) {
      const { oid, pid } = orderInfo;

      console.info("orderInfo", orderInfo);

      this.initOptions_refundOrder = {
        orderID: oid,
        parentID: pid,
      };

      this.$refs.centerDialog_refundOrder.render({
        title: "退款记录",
        width: "800px",
        cancel: () => {
          this.initOptions_refundOrder = null;
        },
      });
    },

    getStartAndEndTimeRange() {
      return [
        {
          ref: "dateTimePicker",
          type: "dateTimePicker",
          label: "开始时间",
          placeholder: "请选择开始时间",
          rules: ["required", "!null"],
          value: null,
          props: "start_time", //start_time	否	string	开始时间，当时间类型为5，该参数必须，例如：2020-10-10 10:00:00
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd hh:mm:ss"
            );

            item.value = formatDate;
          },
        },
        {
          type: "dateTimePicker",
          label: "结束时间",
          placeholder: "请选择结束时间",
          maxLength: 40,
          rules: ["required", "!null"],
          value: null,
          props: "end_time", //end_time	否	string	结束时间，当时间类型为5，该参数必须 例如：2020-10-10 10:00:00
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd hh:mm:ss"
            );

            item.value = formatDate;
          },
        },
      ];
    },

    writeOffPeriodDialogShow(e) {
      console.log(e);
      let { oid, order_id, surplus_count_sum, check_status } = e;

      if (Number(check_status) !== 1) {
        this.$catchError("此订单不可销课");
        return;
      }

      if (Number(surplus_count_sum) === 0) {
        this.$catchError("暂无可核销课时");
        return;
      }

      this.writeOffPeriodForm.oid = oid;
      this.writeOffPeriodForm.order_id = order_id;
      this.writeOffPeriodForm.num = Number(surplus_count_sum);
      this.writeOffPeriodForm.maxNum = Number(surplus_count_sum);

      this.writeOffPeriodDialogVisible = true;
    },
    async onWriteOffPeriodSubmit() {
      throttle(async () => {
        // 验证-基本信息
        let formValidate = await this.$refs.formRef.validate();
        if (!formValidate) return false;
        console.log("form-ok", this.writeOffPeriodForm);

        try {
          this.writeOffPeriodSubmitLoading = true;

          let { msg, res } = await this.$ajax({
            apiKey: "orderClock",
            data: this.writeOffPeriodForm,
          });
          this.$toast({
            msg,
          });

          this.writeOffPeriodSubmitLoading = false;

          this.writeOffPeriodDialogVisible = false;

          const $tableList = this.$refs.tableList;
          $tableList.init();
        } catch (error) {
          this.writeOffPeriodSubmitLoading = false;
          this.$catchError(error);
        }
      }, 800);
    },
    writeOffPeriodDialogClose() {
      this.$refs.formRef.resetFields();
      this.writeOffPeriodForm = this.$options.data().writeOffPeriodForm;
    },
  },
};
</script>
