<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-07-04 10:20:50
 * @FilePath: /src/views/customer/studentList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
    <!-- 新增/编辑学生 -->
    <addOrEditStudent ref="addOrEditStudent"></addOrEditStudent>
    <!-- 详情 -->
    <el-dialog
      :visible.sync="dialogVisible"
      class="FormDialogBox"
      width="700px"
      append-to-body
      title="查看详情"
      fullscreen
    >
      <studentDetails
        v-if="dialogVisible"
        :dialog="true"
        :pageType="1"
        :studentID="studentID"
        :parentID="parentID"
        @closeDialog="dialogVisible = false"
      />
    </el-dialog>
    <!-- 档案资料录入 -->
    <FileInfo ref="fileInfoRef" />
    <!-- 学生卡片 -->
    <StudentCard ref="studentCardRef" />
  </mainContentDiv>
</template>

<script>
import addOrEditStudent from "./addOrEditStudent.vue";
import studentDetails from "./studentDetails.vue";
import FileInfo from "./fileInfo.vue";
import StudentCard from "./studentCard.vue";

export default {
  components: {
    addOrEditStudent,
    studentDetails,
    FileInfo,
    StudentCard,
  },
  data() {
    return {
      tableOptions: null,
      dialogVisible: false,
      studentID: null,
      parentID: null,
    };
  },
  destroyed() {
    this.$bus_unique.off("updateAuthenList");
  },
  mounted() {
    this.tableOptions = this.getTableOptions();

    this.$bus_unique.on("updateAuthenList", "authenList", () => {
      this.$refs.tableList.init();
    });
  },
  methods: {
    getTableOptions() {
      const { isSelect } = this.$route.query; //选择模式

      let getTableOptions = {
        authenKey: "student_list",
        search: {
          inputList: [
            {
              type: "input",
              label: "家长名称",
              placeholder: "",
              value: "",
              props: "parent_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "学生名称",
              placeholder: "",
              value: "",
              props: "student_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "家长手机号码",
              placeholder: "",
              value: "",
              props: "mobile",
              maxLength: 11,
              rules: ["!null"],
            },
            {
              type: "select",
              label: "年级",
              placeholder: "",
              value: "",
              props: "grade",
              rules: ["!null"],
              options: [
                {
                  value: "小班",
                },
                {
                  value: "中班",
                },
                {
                  value: "大班",
                },
                {
                  value: "学前班",
                },
                {
                  value: "一年级",
                },
                {
                  value: "二年级",
                },
                {
                  value: "三年级",
                },
                {
                  value: "四年级",
                },
                {
                  value: "五年级",
                },
                {
                  value: "六年级",
                },
                {
                  value: "艺术班",
                },
                {
                  value: "初一",
                },
                {
                  value: "初二",
                },
                {
                  value: "初三",
                },
                {
                  value: "高一",
                },
                {
                  value: "高二",
                },
                {
                  value: "高三",
                },
              ],
            },
            {
              type: "input",
              label: "总校区",
              placeholder: "",
              value: "",
              props: "zxq_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "备注",
              placeholder: "",
              value: "",
              props: "remark",
              rules: ["!null"],
            },
            {
              type: "select",
              label: "级别",
              placeholder: "",
              value: "",
              props: "has_order",
              rules: ["!null"],
              options: [
                {
                  label: "全部",
                  value: 0,
                },
                {
                  label: "老生",
                  value: 1,
                },
                {
                  label: "新生",
                  value: 2,
                },
              ],
            },
            {
              type: "radioBtnList",
              label: "入读时间范围",
              value: 0,
              props: "time_type", //time_type	否	int	时间类型 1-本月 2-上月 3-本年 4-去年 5-自定义
              rules: ["!null"],
              options: [
                {
                  label: "全部",
                  value: 0,
                },
                {
                  label: "本月",
                  value: 1,
                },
                {
                  label: "上月",
                  value: 2,
                },
                {
                  label: "本年",
                  value: 3,
                },
                {
                  label: "去年",
                  value: 4,
                },
                {
                  label: "自定义",
                  value: 5,
                },
              ],
              onChange: (val, thisObj, inputList) => {
                //增加：自定义时间选择控件
                if (val === 5) {
                  const startAndEndTimeRange = this.getStartAndEndTimeRange();

                  startAndEndTimeRange.forEach((item) => {
                    inputList.push(item);
                  });
                }

                //移除：自定义时间选择控件
                const dateTimePickerIndex = inputList.findIndex(
                  (item) => item.ref === "dateTimePicker"
                );

                if (val < 5 && dateTimePickerIndex !== -1) {
                  inputList.splice(dateTimePickerIndex, 2);
                }
              },
            },
          ],
        },

        headers: [
          { label: "学生名称", props: "name" },
          { label: "家长", props: "_parStudentRelation", type: "text" },
          { label: "年级", props: "grade" },
          { label: "入读时间", props: "enrollment_time" },
          { label: "备注", props: "remark" },
        ],
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
          {
            title: "新增学生",
            authenKey: "student_add",
            onTap: async () => {
              await this.$refs.addOrEditStudent.render({
                type: 1,
                // inputedVal: {
                //   parent_name: "李赛",
                //   pid: 1,
                //   relation: "父女",
                //   rid: 1,
                //   name: "李小小",
                //   city: 1212,
                //   birth: "2020-10-11",
                //   gender: 1,
                //   school: "真小学",
                //   subSchool: "分校区",
                //   sid: 12,
                //   id: 21,
                //   grade: "初二",
                //   card: 440507199505191637,
                //   remark: "备注",
                // },
              });
              this.$refs.tableList.init(); //刷新数据
            },
          },
          {
            title: "导出",
            color: "normal",
            authenKey: "student_export",
            onTap: async (thisBtn) => {
              try {
                await this.$ajax({
                  isExportFile: true, //导出文件
                  methods: "POST",
                  apiKey: "exportStudentList",
                  fileName: "学生列表.xls",
                  data: thisBtn.keyword || "",
                  submitByForm: true,
                });
              } catch (error) {
                this.$catchError(error);
              }
            },
          },
          {
            title: "下载学生导入模板",
            color: "normal",
            authenKey: "student_importTemplate",
            onTap: async () => {
              try {
                await this.$ajax({
                  isExportFile: true, //导出文件
                  methods: "POST",
                  apiKey: "importStudentListTemplate",
                  fileName: "学生导入模板.xls",
                });
              } catch (error) {
                this.$catchError(error);
              }
            },
          },
          {
            type: "import",
            title: "导入学生",
            color: "cyan",
            authenKey: "student_import",
            onRequest: async (currentSelectedFile) => {
              const ret = await this.$ajax({
                responseAll: true,
                methods: "POST",
                apiKey: "importStudentList",
                submitByForm: true,
                data: {
                  file: currentSelectedFile,
                },
              });

              return ret;
            },
          },
          {
            title: "下载档案资料导入模板",
            color: "normal",
            authenKey: "studentFilesInternal_import",
            onTap: async () => {
              try {
                await this.$ajax({
                  isExportFile: true, //导出文件
                  methods: "POST",
                  apiKey: "archives_export_Template",
                  fileName: "档案资料导入模板.xls",
                });
              } catch (error) {
                this.$catchError(error);
              }
            },
          },
          {
            type: "import",
            title: "导入档案资料",
            color: "cyan",
            authenKey: "studentFilesInternal_import",
            onRequest: async (currentSelectedFile) => {
              const ret = await this.$ajax({
                responseAll: true,
                methods: "POST",
                apiKey: "archives_export_import",
                submitByForm: true,
                data: {
                  file: currentSelectedFile,
                },
              });

              return ret;
            },
          },
        ],
        actionBtns: [
          {
            title: "查看",
            color: "cyan",
            authenKey: "student_details",
            onTap: (options) => {
              this.studentID = options.id;
              this.dialogVisible = true;

              // this.$router.push({
              //   name: "studentDetails",
              //   query: {
              //     pageType: 1,
              //     studentID: options.id, //学生id
              //   },
              // });
            },
          },
          {
            title: "编辑",
            color: "cyan",
            authenKey: "student_edit",
            onTap: async (options) => {
              console.log("options", options);
              await this.$refs.addOrEditStudent.render({
                type: 2,
                inputedVal: options,
              });
              this.$refs.tableList.init(); //刷新数据
            },
          },
          {
            title: "新增订单",
            color: "cyan",
            authenKey: "order_add",
            onTap: async (options) => {
              this.$router.push({
                name: "addOrEditOrder",
                query: {
                  pageType: 0,
                },
                params: { studentInfo: options },
              });
            },
          },
          {
            title: "删除",
            color: "red",
            authenKey: "student_del",
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: "delStudent",
                data: {
                  id: options.id,
                },
              });
            },
          },
          {
            title: "档案资料",
            color: "deepBlue",
            authenKey: "studentFilesInternal_edit",
            onTap: async (options) => {
              console.log("options", options);
              this.$refs.fileInfoRef._show(options.id);
            },
          },
          {
            title: "学生卡片",
            color: "warning",
            authenKey: "StudentCard",
            onTap: async (options) => {
              console.log("options", options);
              this.$refs.studentCardRef._show(options);
            },
          },
        ],
        getData: async (options) => {
          const ret = await this.$ajax({
            apiKey: "getStudentList",
            data: options,
          });

          ret.res.data.forEach((item) => {
            const { school_info } = item;

            if (school_info) {
              const { name, xq_name } = school_info;

              item.school_name = name;
              item.xq_name = xq_name;
            }

            let _parStudentRelation = "";

            item.parent_info.forEach((item) => {
              _parStudentRelation += `${item.name}(${item.student_relation_name})\n\r`;
            });

            item._parStudentRelation = _parStudentRelation;
          });

          return ret;
        },
      };

      isSelect &&
        getTableOptions.actionBtns.unshift({
          title: "选择",
          color: "deepBlue",
          isRender: true,
          onTap: (options) => {
            this.$bus_unique.emit("selectedStudent", options);
          },
        });

      return getTableOptions;
    },

    getStartAndEndTimeRange() {
      return [
        {
          ref: "dateTimePicker",
          type: "dateTimePicker",
          label: "开始时间",
          placeholder: "请选择开始时间",
          rules: ["required", "!null"],
          value: null,
          props: "start_time", //start_time	否	string	开始时间，当时间类型为5，该参数必须，例如：2020-10-10 10:00:00
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd hh:mm:ss"
            );

            item.value = formatDate;
          },
        },
        {
          type: "dateTimePicker",
          label: "结束时间",
          placeholder: "请选择结束时间",
          maxLength: 40,
          rules: ["required", "!null"],
          value: null,
          props: "end_time", //end_time	否	string	结束时间，当时间类型为5，该参数必须 例如：2020-10-10 10:00:00
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd hh:mm:ss"
            );

            item.value = formatDate;
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.FormDialogBox {
  .el-dialog__body {
    height: 94%;
    padding: 0 10px 20px;
    overflow: hidden;
    background: #f4f4f4;
  }
}
</style>
