<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-07-04 10:45:55
 * @FilePath: /src/components/inputCenter.vue


 超级form表单

      <inputCenter  :initOptions="initOptions_inputCenter"
        ></inputCenter
      >

      调用

      data(){
        return {
          initOptions_inputCenter: [
            form -- 单行文本输入框
            form -- 多行文本输入
            form -- select
            form -- radio
            form -- 日期时间选择器
            form -- cascader  级联选择 
            form -- 上传单个图片
          ]
        }
      }
 
-->

<template>
  <div class="d-flex flex-wrap" style="margin-right: -1.25rem">
    <div
      v-for="(item, index) in selfData.inputList"
      :key="'inputList' + index"
      :class="item.col || 'col-12'"
    >
      <div class="mr-20 my-10" v-if="!item.hide">
        <div
          class="d-flex align-items-center pb-10 com-title"
          v-if="item.label"
          :class="
            item.rules &&
            item.rules.find((item) => item === 'required') &&
            'active'
          "
        >
          {{ item.label }}
        </div>
        <!-- 
          form -- 单行文本输入框

          配置

          {
            type: "input",
            label: "管理员名称",
            placeholder: "请输入管理员名称",
            maxLength: 50,
            rules: ["required", "!null"],
            value: null,
            props: "name",
            onChange: (thisObj, e) => {

            },
            onClear: (thisObj, e) => {

            },
            onInput: (thisObj, e) => {

            },
          } 
          
          -->

        <textFile v-if="item.type === 'input'" :initOptions="item"> </textFile>
        <!-- 
          form -- 多行文本输入
          
          
          配置

          {
            type: "textarea",
            label: "升级规则",
            placeholder: "请输入升级规则",
            maxLength: 40,
            rules: ["required", "!null"],
            value: null,
            props: "details",
          },
          
        -->
        <el-input
          type="textarea"
          v-else-if="item.type === 'textarea'"
          v-model="item.value"
          show-word-limit
          :placeholder="item.placeholder"
          :maxlength="item.maxlength || 100"
        >
        </el-input>

        <!-- 
          form -- select

          配置

          {
            type: "select",
            label: "班主任",
            placeholder: "请搜索/选择班主任",
            rules: ["required", "!null"],
            value: "",
            id: "",
            props: "teacher_name",
            props_id: "admin_id",
            col: "col-3",
            options: [], //select的options
            remoteMethod: async (keyword) => {  //远程搜索,

            },
            onChange: (val, thisObj) => {

            },
            onClear: (val, thisObj) => {

            }
          },
         -->
        <el-select
          class="w-100"
          size="small"
          filterable
          clearable
          v-else-if="item.type === 'select'"
          v-model="item.value"
          :multiple="item.multiple"
          :placeholder="item.placeholder"
          :remote="!!item.remoteMethod"
          :remote-method="item.remoteMethod"
          :suffix-icon="item.suffixIcon"
          :disabled="item.disabled"
          @change="item.onChange && item.onChange($event, item)"
          @clear="item.onClear && item.onClear($event, item)"
          @focus="item.onFocus && item.onFocus($event, item)"
        >
          <el-option
            v-for="item in item.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>

        <!-- 
          
          form -- radio

          {
            type: "radio",
            label: "支付类型",
            placeholder: "请选择支付类型",
            rules: ["!null"],
            value: 3,
            props: "pay_type",
            options: [
              {
                label: "现金",
                value: 1,
              },
              {
                label: "银行卡",
                value: 2,
              },
            ],
            col: "col-6", //显示的宽度
          },
        -->
        <el-radio-group
          class="w-100"
          v-if="item.type === 'radio'"
          v-model="item.value"
          :disabled="item.readonly || item.disabled"
          @change="item.onChange && item.onChange($event, item)"
        >
          <el-radio
            v-for="(item, index) in item.options"
            :key="index"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>

        <el-checkbox
          class="w-100"
          v-if="item.type === 'checkbox'"
          v-model="item.value"
          :disabled="item.readonly || item.disabled"
          @change="item.onChange && item.onChange($event, item)"
          :true-label="1"
          :false-label="0"
          >{{ item.label }}</el-checkbox
        >

        <!-- 
          form -- 日期时间选择器
          
          {
            type: "datePicker",
            label: "开班时间",
            placeholder: "请选择开班时间",
            rules: ["required", "!null"],
            value: null,
            props: "start_time", //start_time	是	string	开班时间
            col: "col-3",
            onChange: (val, item) => {
              const timeStamp = new Date(val).getTime();
              const formatDate = this.$com.formatDate(
                new Date(timeStamp),
                "yyyy-MM-dd"
              );

              item.value = formatDate;
            },
          }

        -->
        <el-date-picker
          class="w-100"
          type="date"
          size="small"
          placeholder="选择日期"
          v-else-if="item.type === 'datePicker'"
          v-model="item.value"
          @change="item.onChange && item.onChange($event, item)"
        >
        </el-date-picker>

        <!-- 日期时间选择器 -->
        <el-date-picker
          class="w-100"
          type="datetime"
          size="small"
          v-else-if="item.type === 'dateTimePicker'"
          v-model="item.value"
          :placeholder="item.placeholder"
          @change="item.onChange && item.onChange($event, item)"
        >
        </el-date-picker>

        <!-- 
          form -- cascader  级联选择 

          {
            type: "cascader",
            expandTrigger: "hover",
            label: "规格明细",
            placeholder: "请选择产品规格明细",
            rules: ["required", "!null"],
            value: [],
            id: "",
            props: "pro_sku_name",
            props_id: "pro_sku",
            col: "col",
            disabled: true,
            options: [],
            key: 0,
            onChange: (valueList) => {
              
            }
        -->
        <el-cascader
          class="w-100"
          size="small"
          v-else-if="item.type === 'cascader'"
          v-model="item.value"
          :placeholder="item.placeholder"
          :options="item.options"
          :clearable="true"
          :disabled="item.disabled"
          :key="item.key"
          :expandTrigger="item.expandTrigger || 'click'"
          :props="item.lazyProps"
          :filterable="item.filterable || false"
          :filter-method="item.filterMethods"
          @expand-change="
            item.onExpandChange && item.onExpandChange($event, item)
          "
          @change="item.onChange && item.onChange($event, item)"
          @clear="item.onClear && item.onClear($event, item)"
        ></el-cascader>

        <!-- 
          form -- 上传单个图片

          {
            type: "headerImg",
            label: "头像",
            placeholder: "请上传管理员头像",
            value: "",
            rules: ["!null"],
            props: "avatar",
          },
        -->

        <div class="upload-header-img-bar" v-if="item.type === 'headerImg'">
          <div
            class="position-relative cursor-pointer header-img"
            :class="!item.value && 'add-icon'"
            @click="!item.readonly && handleTriggerUploadHeaderImg(item)"
          >
            <img
              class="imgCover"
              v-if="item.value"
              :src="imgAddHostUrl(item.value || item.defaultVal)"
            />
          </div>
          <chooseAndUploadLoaclImg
            ref="chooseLoactImgDom"
            @change="saveUploadImgData($event)"
          ></chooseAndUploadLoaclImg>
        </div>
        <!-- 上传/预览本地图片 -->
        <div v-else-if="item.isUploadImgList === true">
          <div style="margin-bottom: -1rem">
            <uploadAndViewImg
              ref="upLoadedImgs"
              class="my-3"
              :propsUploadedImgList="item.value"
              :initOptions_uploadImg="item.initOptions_uploadImg"
              @uploadedImgList="item.value = $event"
            ></uploadAndViewImg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chooseAndUploadLoaclImg from "@/components_global/chooseAndUploadLoaclImg.vue";
import uploadAndViewImg from "@/components_global/uploadAndPreviewImg/uploadAndViewImg.vue";

export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    chooseAndUploadLoaclImg,
    uploadAndViewImg,
  },
  mounted() {
    console.info("this.initOptions", this.initOptions);
  },
  methods: {
    handleTriggerUploadHeaderImg(headerImgObj) {
      this.$refs.chooseLoactImgDom[0].chooseImg();

      this.headerImgObj = headerImgObj;
    },
    saveUploadImgData(e) {
      this.headerImgObj.value = e;

      console.info("e", e);
    },
    //图片增加域名
    imgAddHostUrl(imgUrl) {
      return /(http|assets)/.test(imgUrl) ? imgUrl : this.$imgBaseUrl + imgUrl;
    },
  },
  computed: {
    selfData() {
      const initOptions = this.initOptions;
      const { inputList, valueListObj, readonly } = initOptions;

      console.info("valueListObj", valueListObj);
      if (valueListObj) {
        this.$com.fillData({
          inputList,
          valueListObj,
        });
      }

      if (readonly) {
        inputList.forEach((item) => {
          item.readonly = true;

          if (item.onTap) {
            delete item.onTap;
          }
        });
      }

      console.info("initOptions", initOptions);

      return initOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
.com-title {
  &.active::before {
    content: "*";
    color: #ff1818;
    line-height: 1rem;
  }

  line-height: 1rem;
  font-size: 0.8rem;
  color: #a5a9ae;
}

.upload-header-img-bar {
  font-size: 0.75rem;
  color: #010101;

  .header-img {
    width: 5.625rem;
    height: 5.688rem;
    border: 1px dashed #ddd;
    border-radius: 0.25rem;
    overflow: hidden;

    &.add-icon::before,
    &.add-icon::after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      content: "";
      display: block;
      width: 1rem;
      height: 2px;
      background-color: #ddd;
    }
    &::after {
      transform: rotate(90deg);
    }

    img {
      border: none;
    }
  }
}
</style>
