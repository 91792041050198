<!--
 * @Description: 教学大纲-查看(课程主题-考核表)
-->
<template>
  <div class="lineBox">
    <div class="item" v-for="(item, index) in dataItem.assess" :key="item.id">
      <div class="item_t">
        <span>{{ item.assess_name }}</span>
        <el-button
          v-if="ifOperationButton('KHBXZ', index)"
          size="mini"
          type="warning"
          @click="onDownload(item)"
        >
          下载<i class="el-icon-download el-icon--right"></i>
        </el-button>
        <el-button
          v-if="ifOperationButton('KHBPF', index)"
          size="mini"
          type="success"
          @click="onScore(item.id)"
        >
          评分<i class="el-icon-s-claim el-icon--right"></i>
        </el-button>
      </div>
      <div class="item_b">
        <span>{{ item.is_score ? '已' : '未' }}评分</span>
        <template v-if="item.is_score">
          <span>评分时间：{{ item.score_time }}</span>
          <span>
            最后评分人：{{ item.operator }}
            <template v-if="item.is_master_score">（是主管理员）</template>
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataItem: {
      type: Object,
      default: () => {},
    },
    // 按钮权限
    operationButton: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    // 下载
    onDownload(item) {
      this.$confirm('是否下载此文件？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let { assess_name, id } = item

          try {
            await this.$ajax({
              isExportFile: true, //导出文件
              submitByForm: true,
              methods: 'POST',
              apiKey: 'assess_export',
              fileName: assess_name + '.xls',
              data: {
                id,
              },
            })
          } catch (error) {
            this.$catchError(error)
          }
        })
        .catch(() => {})
    },

    // 评分
    onScore(id) {
      this.$router.push({
        name: 'evaluationScore',
        query: {
          id,
        },
      })
    },

    // 权限按钮判断
    ifOperationButton(name, index) {
      // console.log('this.operationButton', this.operationButton)
      if (!this.operationButton.length) return false
      if (!this.operationButton[index]) return false
      let item = this.operationButton[index].find((i) => {
        return i.code === name
      })
      // console.log('权限按钮判断-item', item)
      return item ? true : false
    },
  },
}
</script>

<style lang="scss" scoped>
.lineBox {
  padding: 30px 30px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .item {
    width: 48%;
    padding: 10px;
    padding-bottom: 30px;

    &:nth-child(odd) {
      border-right: 1px solid #eaeaea;
    }

    .item_t {
      span {
        color: #606060;
        font-size: 14px;
        margin-right: 20px;
      }
    }

    .item_b {
      background: #f0f2f5;
      padding: 6px 14px;
      margin-top: 10px;
      display: inline-block;

      span {
        color: #606060;
        font-size: 14px;
        margin-right: 14px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
