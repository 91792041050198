<!--
 * @Date         : 2021-03-02 17:12:08
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-03 16:04:30
 * @FilePath     : \leXue_manage_pc\src\views\class\absenceStudentList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableOptions: null,
    };
  },
  destroyed() {
    this.$bus_unique.off("updateAdminList");
  },
  beforeMount() {
    this.tableOptions = this.getTableOptions();

    //监听：更新管理员列表
    this.$bus_unique.on("updateAdminList", "adminList", () => {
      this.$refs.tableList.init();
    });
  },
  methods: {
    getTableOptions() {
      return {
        authenKey: "class_absenceStudentList",
        headers: [
          {
            label: "学生姓名",
            props: "student_name",
          },
          {
            label: "家长姓名",
            props: "__parentName",
          },
          {
            label: "联系电话",
            props: "__mobile",
          },
        ],

        getData: async (options) => {
          const { id } = this.initOptions;

          let requestRet = await this.$ajax({
            apiKey: "getAbsenceStudentList",
            data: {
              ...options,
              attend_id: id,
            },
          });

          requestRet.res.data.forEach((item) => {
            const { parent_info } = item;

            item.__parentName = parent_info.parent_name;
            item.__mobile = parent_info.mobile;
          });

          return requestRet;
        },
      };
    },
  },
};
</script>
