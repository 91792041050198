<!--
 * @Date         : 2020-11-18 09:36:19
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-13 10:44:55
 * @FilePath     : \leXue_manage_pc\src\404.vue
-->
<template>
  <div class="ma-20">
    <div>404 找不到页面了</div>
    <el-button type="primary" @click="$router.replace('/')">返回首页</el-button>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>