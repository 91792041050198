<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-05 17:45:06
 * @FilePath     : \leXue_manage_pc\src\views\member\memberLevel.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
    <centerDialog ref="centerDialog">
      <inputCenter :initOptions="initOptions_input"></inputCenter>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
      initOptions_input: null,
    };
  },
  created() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    // 弹出新增/编辑会员等级
    renderEditDialog({ type, inputedVal }) {
      try {
        let inputOptions = (this.initOptions_input = {
          inputList: this.getInputStudent(),
        });

        if (inputedVal) {
          this.$com.fillData({
            inputList: this.initOptions_input.inputList,
            valueListObj: inputedVal,
          });
        }

        return new Promise((resovle, reject) => {
          this.$refs.centerDialog.render({
            title: type === 0 ? "新增等级" : "编辑等级",
            width: "600px",
            ok: async () => {
              try {
                const { inputList } = inputOptions;

                let requestOpt = this.$com.getValueByRules(inputList);

                // 1:新增 2:编辑
                if (type === 1) {
                  const { id } = inputedVal;
                  requestOpt.id = id;
                }

                const { msg } = await this.$ajax({
                  apiKey: "addOrEditMemberLevel",
                  data: requestOpt,
                });

                this.$toast({
                  msg,
                });

                resovle();
              } catch (error) {
                reject(error);
                throw error;
              }
            },
          });
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    //获取输入配置表
    getInputStudent(inputedValueObj) {
      let getInputStudent = [
        {
          type: "input",
          label: "等级名称",
          placeholder: "请输入等级名称",
          maxLength: 40,
          rules: ["required", "!null"],
          value: null,
          props: "name",
        },
        {
          type: "textarea",
          label: "升级规则",
          placeholder: "请输入升级规则",
          maxLength: 40,
          rules: ["required", "!null"],
          value: null,
          props: "details",
        },
      ];

      return getInputStudent;
    },

    getTableOptions() {
      return {
        authenKey: "memberLevel_list",
        search: {
          inputList: [
            {
              type: "input",
              label: "等级名称",
              placeholder: "",
              value: "",
              props: "name",
              rules: ["!null"],
            },
          ],
        },
        headers: [
          { label: "等级名称", props: "name" },
          { label: "所占比重", props: "level_rale" },
          { label: "升级规则", props: "details" },
        ],
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
          {
            title: "新增",
            authenKey: "memberLevel_add",
            onTap: async () => {
              await this.renderEditDialog({
                type: 0,
              });

              this.$refs.tableList.init();
            },
          },
        ],
        actionBtns: [
          {
            title: "编辑",
            color: "deepBlue",
            authenKey: "memberLevel_edit",
            onTap: async (options) => {
              await this.renderEditDialog({
                type: 1,
                inputedVal: options,
              });

              this.$refs.tableList.init();
            },
          },
          {
            title: "删除",
            color: "red",
            authenKey: "memberLevel_del",
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: "delMemberLevel",
                data: {
                  id: options.id, //cid	是	int	班级id
                },
              });
            },
          },
        ],
        getData: async (options) => {
          let ret = await this.$ajax({
            apiKey: "getMemberLevelList",
            data: {
              ...options,
              status: 2,
            },
          });

          return ret;
        },
      };
    },
  },
};
</script>

