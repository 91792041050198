<!--
 * @Description: 导出
-->
<template>
  <div>
    <el-dialog
      v-if="dialogShow"
      title="请选择导出的周期"
      :visible.sync="dialogShow"
      width="400px"
      destroy-on-close
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-date-picker
        style="width: 100%"
        v-model="date"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="dialogSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogShow: false,
      options: null,
      pickerOptions: {},
      date: [],
    };
  },
  created() {},
  methods: {
    // 显示
    showDialog(options) {
      this.dialogClone();
      this.options = options;
      this.dialogShow = true;
    },
    dialogClone() {
      this.options = null;
      this.date = [];
    },
    async dialogSave() {
      if (!this.date.length) return this.$message.error("请选择日期");

      try {
        console.log("options", this.options);

        let { name, id } = this.options;

        this.dialogShow = false;

        // 导出
        await this.$ajax({
          isExportFile: true, //导出文件
          methods: "POST",
          apiKey: "teacherExportTask",
          fileName: name + "_每日任务统计进度" + ".xlsx",
          data: {
            teacher_id: id,
            start_time: this.date[0],
            end_time: this.date[1],
          },
          submitByForm: true,
        });
      } catch (error) {
        this.$catchError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
