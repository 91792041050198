/*
 * @Date         : 2021-04-02 17:28:46
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-09-21 14:49:46
 */

const httpDomainName = 'https://lxjw-api-dev.ozkoalas.cn' //测试
// const httpDomainName = 'https://lxjw-api-test.ozkoalas.cn'; //测试2
// const httpDomainName = 'https://lxjw-api.ozkoalas.cn'; //正式

const uploadImgServerUrl = httpDomainName + '/api/oss/test_upload' //富文本上传图片地址

export default {
  uploadImgServerUrl,
  httpDomainName,
}
