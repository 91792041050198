/*
 * @Description: 日期计算、处理
 */
var shortMonth = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]
var defMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const dateFunc = {
  getDuration (date) {
    // how many days of this month
    const dt = new Date(date)
    // var month = dt.getMonth()
    dt.setMonth(dt.getMonth() + 1)
    dt.setDate(0)
    return dt.getDate()
  },
  changeDay (date, num) {
    const dt = new Date(date)
    return new Date(dt.setDate(dt.getDate() + num))
  },
  getStartDate (date) {
    // return first day of this month
    // console.log(new Date(date.getFullYear(), date.getMonth(), 1,0,0))
    return new Date(date.getFullYear(), date.getMonth(), 1)
  },
  getEndDate (date) {
    // get last day of this month
    const dt = new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0) // 1st day of next month
    return new Date(dt.setDate(dt.getDate() - 1)) // last day of this month
  },
  // 获取当前周日期数组
  getDates (date) {
    var newDate = date
    var timesStamp = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      0,
      0,
      0
    ).getTime()
    // var timesStamp = newDate.getTime();
    var currenDay = newDate.getDay()
    var dates = []
    for (var i = 0; i < 7; i++) {
      dates.push(
        new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - ((currenDay + 6) % 7)))
      )
    }
    return dates
  },
  format (date, format, monthNames) {
    monthNames = monthNames || defMonthNames
    if (typeof date === 'string') {
      date = new Date(date.replace(/-/g, '/'))
    } else {
      date = new Date(date)
    }

    var map = {
      M: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      m: date.getMinutes(),
      s: date.getSeconds(),
      q: Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds()
    }

    format = format.replace(/([yMdhmsqS])+/g, (all, t) => {
      var v = map[t]
      if (v !== undefined) {
        if (all === 'MMMM') {
          return monthNames[v - 1]
        }
        if (all === 'MMM') {
          return shortMonth[v - 1]
        }
        if (all.length > 1) {
          v = '0' + v
          v = v.substr(v.length - 2)
        }
        return v
      } else if (t === 'y') {
        return String(date.getFullYear()).substr(4 - all.length)
      }
      return all
    })
    return format
  }
}

module.exports = dateFunc
