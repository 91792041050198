<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-03 09:58:52
 * @FilePath: /src/views/member/memberRechargeRecordList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <div style="margin: -20px 0 20px">
      <el-radio-group v-model="type" @input="typeRadioGroup">
        <el-radio :label="3">课时</el-radio>
        <el-radio :label="1">钱包（废弃）</el-radio>
        <el-radio :label="2">课包（废弃）</el-radio>
      </el-radio-group>
    </div>

    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tableOptions: null,
      type: 3,
    };
  },
  computed: {
    numlabelTitle() {
      let title = "";
      switch (this.type) {
        case 1:
          title = "金额";
          break;

        case 2:
          title = "课包";
          break;

        default:
          title = "课时";
          break;
      }
      return title;
    },
    numlabelKey() {
      let title = "";
      switch (this.type) {
        case 1:
          title = "give_money";
          break;

        case 2:
          title = "pack_num";
          break;

        default:
          title = "hour_num";
          break;
      }
      return title;
    },
  },
  mounted() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    getTableOptions() {
      let getTableOptions = {
        authenKey: "member_rechargeRecordList",
        headers: [
          {
            label: `充值金额`,
            props: "recharge_money",
          },
          {
            label: `${this.numlabelTitle}数量`,
            props: this.numlabelKey,
          },
          { label: "创建时间", props: "created_at" },
        ],
        getData: async (options) => {
          if (options.time_type === 0) {
            delete options.time_type;
          }

          const { parentID } = this.initOptions;

          console.log("type", this.type);

          let apiKey = "";
          switch (this.type) {
            case 1:
              apiKey = "getMemberRechargeRecordList";
              break;

            case 2:
              apiKey = "coursePackRechargeLogIndex";
              break;

            default:
              apiKey = "class_hour_recharge_log_index";
              break;
          }

          let ret = await this.$ajax({
            apiKey,
            data: {
              ...options,
              pid: parentID || "", //pid	否	int	家长id
            },
          });

          return ret;
        },
      };

      return getTableOptions;
    },

    typeRadioGroup(e) {
      this.tableOptions = this.getTableOptions();
      this.$refs.tableList.init();
    },
  },
};
</script>
