<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-06-23 16:01:45
 * @FilePath: \src\views\school\subSchoolList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions && currentGetDataOptions"
      :initOptions="tableOptions"
    ></tableList>

    <!-- 弹窗：新增/编辑 -->
    <centerDialog ref="centerDialog" class="center-dialog">
      <div class="overflow-hidden">
        <div
          class="d-flex flex-wrap"
          style="margin-right: -0.625rem"
          v-if="initOptions_school"
        >
          <div
            class="col-6"
            v-for="(item, index) in initOptions_school.inputList"
            :key="index"
          >
            <div class="mr-10 mb-10">
              <div
                class="my-5 com-title"
                v-if="item.label"
                :class="{
                  active:
                    item.rules &&
                    item.rules.find((item) => item === 'required'),
                }"
              >
                {{ item.label }}
              </div>
              <!-- 单行文本 -->
              <textFile v-if="item.type === 'input'" :initOptions="item">
              </textFile>
              <!-- 搜索框 -->
              <el-select
                class="w-100"
                size="small"
                filterable
                remote
                reserve-keyword
                v-else-if="item.type === 'select'"
                v-model="item.value"
                :placeholder="item.placeholder"
                :remote-method="item.remoteMethod"
              >
                <el-option
                  v-for="item in item.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <!-- 级联选择器 -->
              <el-cascader
                class="w-100"
                size="small"
                v-else-if="item.type === 'cascader'"
                :placeholder="item.placeholder"
                :value="item.value"
                :props="item.lazyProps"
                @change="item.onChange && item.onChange($event, item)"
              ></el-cascader>
            </div>
          </div>
          <div class="col-6">
            <div class="my-5 com-title active">{{ schoolTypeObj.label }}</div>
            <el-checkbox
              v-for="(item, index) in schoolTypeObj.options"
              v-model="item.value"
              :key="index"
              :indeterminate="item.isIndeterminate"
              @change="handleCheckAllGrade($event, item)"
              >{{ item.label }}</el-checkbox
            >
          </div>
          <div
            class="col-12 my-5"
            v-for="(item, index) in schoolTypeObj.options"
            :key="'schoolTypeObj' + index"
          >
            <el-checkbox-group
              v-model="item.checkedValueList"
              @change="handleCheckGrade($event, item)"
            >
              <el-checkbox
                v-for="(item, index) in item.children"
                :key="'children' + index"
                :label="item"
                >{{ item }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
export default {
  props: {
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableOptions: null,
      currentGetDataOptions: null,
      initOptions_school: null,
      schoolTypeObj: null, //学校类型
    }
  },
  mounted() {
    // console.log('mounted')
    this.tableOptions = this.getTableOptions()
  },
  methods: {
    updateSubSchool(options) {
      // console.log('options', options)
      let currentGetDataOptions = null
      if (options) {
        currentGetDataOptions = this.currentGetDataOptions
        const { name } = (this.currentGetDataOptions = options)

        this.tableOptions.title = name

        if (currentGetDataOptions) {
          this.$refs.tableList.init()
        }
      } else {
        this.tableOptions = this.getTableOptions()
        this.currentGetDataOptions = null
      }
    },

    // 弹出新增/编辑学校
    renderAddOrEditSchoolDialog(type, inputedValueObj) {
      try {
        let dialogTitle = ''
        let inputOptions

        switch (
          type // 1:新增 2:编辑
        ) {
          case 1:
            dialogTitle = '新增校区'
            inputOptions = {
              inputList: this.getInputSchool(),
            }
            break
          case 2:
            dialogTitle = '编辑校区'
            inputOptions = {
              inputList: this.getInputSchool(inputedValueObj),
            }
            break
        }

        this.initOptions_school = inputOptions
        this.schoolTypeObj = this.getSchoolTypeObj(inputedValueObj) //学校类型

        return new Promise((resovle, reject) => {
          this.$refs.centerDialog.render({
            title: dialogTitle,
            width: '600px',
            verifyInputListIsChange: inputOptions.inputList,
            ok: async () => {
              try {
                const { inputList } = inputOptions

                let requestOpt = this.$com.getValueByRules(inputList)
                let apiKey

                //学校类型和年级
                let {
                  typeList,
                  gradeList,
                } = this.filterOutSchoolTypeAndGradeList(this.schoolTypeObj)

                if (typeList.length <= 0) {
                  throw '请至少选择一个学校类型'
                }

                switch (
                  type // 1:新增 2:编辑
                ) {
                  case 1:
                    apiKey = 'addSubSchool'
                    break
                  case 2:
                    const { id, parent_id } = inputedValueObj

                    apiKey = 'editSubSchool'

                    requestOpt.id = id
                    requestOpt.parent_id = parent_id
                    break
                }

                requestOpt.areaIDs = JSON.stringify(requestOpt.areaIDs) //区域id
                requestOpt.types = JSON.stringify(typeList) //types	是	array	类型集合
                requestOpt.grades = JSON.stringify(gradeList) //grades	是	array	年级集合

                const { msg } = await this.$ajax({
                  apiKey,
                  data: requestOpt,
                })

                this.$refs.tableList.init() //刷新数据
                this.$toast({
                  msg,
                })

                resovle()
              } catch (error) {
                reject(error)
                throw error
              }
            },
            cancel: () => {},
          })
        })
      } catch (error) {
        this.$catchError(error)
      }
    },

    //筛选学校类型和年级
    filterOutSchoolTypeAndGradeList(schoolTypeObj) {
      const { options } = schoolTypeObj

      const typeList = [] //已选择的学校类型（小学，初中，高中）
      let gradeList = [] //已选择的年级（一年级，二年级，初一初二等）

      options.forEach((item) => {
        const { checkedValueList, label } = item
        const checkedValueListLen = checkedValueList.length

        if (checkedValueListLen > 0) {
          gradeList = gradeList.concat(checkedValueList)
          typeList.push(label)
        }
      })

      console.info('typeList', typeList)
      console.info('gradeList', gradeList)

      return {
        typeList,
        gradeList,
      }
    },

    // 选择该学校类型的所有年级
    handleCheckAllGrade(val, thisSchoolType) {
      let { checkedValueList, children } = thisSchoolType

      thisSchoolType.checkedValueList = val ? children : []

      thisSchoolType.isIndeterminate = false //变为半选中状态

      // console.info("val", val);
      // console.info("checkedValueList", checkedValueList);
      // console.info("thisSchoolType", thisSchoolType);
    },

    //选择年级
    handleCheckGrade(checkedValArr, thisSchoolType) {
      const { children } = thisSchoolType
      const checkedValArrLen = checkedValArr.length
      const childLen = children.length

      thisSchoolType.value = checkedValArrLen === childLen

      thisSchoolType.isIndeterminate =
        checkedValArrLen > 0 && checkedValArrLen < childLen
    },

    //学校类型
    getSchoolTypeObj(inputedValueObj) {
      let schoolTypeObj = {
        label: '类型',
        options: [
          {
            label: '幼儿园',
            value: false,
            isIndeterminate: false,
            checkedValueList: [],
            children: ['小班', '中班', '大班', '学前班'],
          },
          {
            label: '小学',
            value: false,
            isIndeterminate: false,
            checkedValueList: [],
            children: [
              '一年级',
              '二年级',
              '三年级',
              '四年级',
              '五年级',
              '六年级',
              '艺术班',
            ],
          },
          {
            label: '初中',
            value: false,
            isIndeterminate: false,
            checkedValueList: [],
            children: ['初一', '初二', '初三'],
          },
          {
            label: '高中',
            value: false,
            isIndeterminate: false,
            checkedValueList: [],
            children: ['高一', '高二', '高三'],
          },
           {
            label: '大学',
            value: false,
            isIndeterminate: false,
            checkedValueList: [],
            children: ['大学'],
          },
           {
            label: '已就业',
            value: false,
            isIndeterminate: false,
            checkedValueList: [],
            children: ['已就业'],
          },
          // {
          //   label: "初中",
          //   value: true,
          // },
          // {
          //   label: "高中",
          //   value: true,
          // },
        ],
      }

      //填充已输入的数据
      if (inputedValueObj) {
        const { grade, type } = inputedValueObj

        schoolTypeObj.options.forEach((item) => {
          const { children, checkedValueList } = item

          children.forEach((item) => {
            const currentGrade = item
            const foundGrade = grade.find((item) => item === currentGrade)

            foundGrade && checkedValueList.push(currentGrade)
          })

          const checkedValueListLen = checkedValueList.length

          if (checkedValueListLen > 0) {
            const childrenLen = children.length

            if (checkedValueListLen === childrenLen) {
              item.value = true
            } else {
              item.isIndeterminate = true
            }
          }
        })
      }

      return schoolTypeObj
    },

    //获取输入配置表
    getInputSchool(inputedValueObj) {
      let _totalSchoolName

      const { name, id } = this.currentGetDataOptions
      const { area, areaIDs, xq_name } = inputedValueObj || {}

      console.info('area', area)

      return [
        (_totalSchoolName = {
          ref: 'name',
          type: 'select',
          label: '总校名称',
          placeholder: '请输入总校名称',
          maxLength: 50,
          rules: ['required', '!null'],
          value: name,
          id: id,
          props: 'fatherName',
          props_id: 'id',
          options: [],
          remoteMethod: async (keyword) => {
            try {
              console.info('keyword', keyword)

              const {
                res: { data },
              } = await this.$ajax({
                apiKey: 'getSchoolList',
                data: {
                  name: keyword,
                },
              })

              _totalSchoolName.options = data.map((item) => {
                const { id, name } = item

                return {
                  label: name,
                  value: id,
                }
              })
            } catch (error) {
              this.$catchError(error)
            }
          },
        }),
        {
          ref: 'name',
          type: 'input',
          label: '校区名称',
          placeholder: '请输入校区名称',
          maxLength: 50,
          rules: ['required', '!null'],
          value: xq_name || null,
          props: 'name',
        },
        {
          ref: 'areas',
          type: 'cascader',
          label: '区域',
          placeholder: area ? area.join('/') : '请选择省市区',
          rules: ['required', '!null'],
          value: areaIDs || null,
          props: 'areaIDs',
          lazyProps: {
            lazy: true,
            lazyLoad: async (node, resolve) => {
              console.info('node', node)

              try {
                const { parentID, value, level } = node
                const isLastLevel = level >= 2

                const {
                  res: { data },
                } = await this.$ajax({
                  apiKey: 'getRegionList',
                  data: {
                    parent_id: value || 1, //parent_id	否	int	父级id，默认1
                    paginate: 50,
                  },
                })

                let nodes = data.map((item) => {
                  const {
                    region_id,
                    region_name,
                    parent_id,
                    region_type,
                  } = item

                  return {
                    value: region_id,
                    label: region_name,
                    parentID: parent_id,
                    leaf: isLastLevel,
                  }
                })

                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                resolve(nodes)
              } catch (error) {
                this.$catchError(error)
              }
            },
          },
          onChange: (regionIDList, thisObj) => {
            thisObj.value = regionIDList

            console.info('regionIDList', regionIDList)
            console.info('thisObj', thisObj)
          },
        },
      ]
    },

    getTableOptions() {
      const isSelect = this.isSelect //选择模式

      let configureOptions

      if (isSelect === true) {
        configureOptions = {
          actionBtns: [
            {
              title: '选择',
              color: 'cyan',
              isRender: true,
              onTap: (options) => {
                this.$emit('change', options)
              },
            },
          ],
        }
      } else {
        configureOptions = {
          titleBtns: [
            {
              title: '查询',
              type: 'filter',
              onTap: (options) => {
                this.renderAddOrEditSchoolDialog(1)
              },
            },
            {
              title: '新增校区',
              authenKey: 'subSchool_add',
              onTap: (options) => {
                this.renderAddOrEditSchoolDialog(1)
              },
            },
          ],
          actionBtns: [
            {
              title: '编辑',
              color: 'cyan',
              authenKey: 'subSchool_edit',
              onTap: (options) => {
                this.renderAddOrEditSchoolDialog(2, options)
              },
            },
            {
              title: '删除',
              color: 'red',
              authenKey: 'subSchool_del',
              onDelete: async (options) => {
                return await this.$ajax({
                  responseAll: true,
                  apiKey: 'delSubSchool',
                  data: {
                    id: options.id,
                  },
                })
              },
            },
          ],
        }
      }

      return {
        title: '分校区',
        authenKey: 'subSchool_list',
        search: {
          inputList: [
            // {
            //   type: "select",
            //   label: "学校类型",
            //   placeholder: "请选择学校类型",
            //   value: "",
            //   props: "type",
            //   rules: ["!null"],
            //   options: [
            //     {
            //       value: "小学",
            //     },
            //     {
            //       value: "初中",
            //     },
            //     {
            //       value: "高中",
            //     },
            //   ],
            // },
            {
              type: 'input',
              label: '校区名称',
              placeholder: '请输入校区名称',
              value: '',
              props: 'name',
              rules: ['!null'],
            },
          ],
        },
        headers: [
          { label: '校区名称', props: 'xq_name' },
          { label: '类型', props: '_typeStr' },
          { label: '所属省市区', props: '_area' },
          { label: '年级', props: '_gradeStr' },
        ],
        ...configureOptions,
        hidePaginate: true,
        getData: async (options) => {
          const { id } = this.currentGetDataOptions
          const { res } = await this.$ajax({
            apiKey: 'getSubSchoolList',
            data: {
              ...options,
              id,
            },
          })

          res.forEach((item) => {
            item._typeStr = item.type.join(' ') //初中类型
            item._gradeStr = item.grade.join(' ') //年级id
            item._area = item.area.join(' ') //年级id
          })

          return {
            code: 1,
            res: {
              data: res,
              last_page: 1,
              current_page: 1,
              total: res.length,
              per_page: 50,
            },
          }
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.com-title {
  &.active::before {
    content: '*';
    color: #ff1818;
    line-height: 1rem;
  }

  line-height: 1rem;
  font-size: 0.6rem;
  color: #333744;
}
</style>
