<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-02 16:52:24
 * @FilePath     : \leXue_manage_pc\src\components\mainContentDiv.vue

 内容外盒
 
-->


<template>
  <div class="main-content-div">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.main-content-div {
  height: 100%;
  background-color: #fff;
  border-radius: 0.25rem;
  overflow: hidden;
}
</style>