<!--
 * @Date         : 2020-11-26 13:54:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-29 10:01:24
 * @FilePath     : \leXue_manage_pc\src\views\customer\addOrEditParent.vue
-->
<template>
  <!-- 弹窗：新增/编辑 -->
  <centerDialog ref="centerDialog" class="center-dialog">
    <div class="overflow-hidden">
      <div
        class="d-flex flex-wrap"
        style="margin-right: -0.625rem"
        v-if="initOptions_input"
      >
        <div
          class="col-6"
          v-for="(item, index) in initOptions_input.inputList"
          :key="index"
        >
          <div class="mr-10 mb-10">
            <div
              class="my-5 com-title"
              v-if="item.label"
              :class="{
                active:
                  item.rules && item.rules.find((item) => item === 'required'),
              }"
            >
              {{ item.label }}
            </div>
            <!-- 输入框 -->
            <textFile v-if="item.type === 'input'" :initOptions="item">
            </textFile>
            <!-- 搜索框 -->
            <el-select
              class="w-100"
              size="small"
              filterable
              clearable
              remote
              reserve-keyword
              v-else-if="item.type === 'select'"
              v-model="item.value"
              :placeholder="item.placeholder"
              :remote-method="item.remoteMethod"
              suffix-icon="el-icon-date"
              @change="item.onChange && item.onChange($event, item)"
              @focus="item.onFocus && item.onFocus($event, item)"
            >
              <el-option
                v-for="item in item.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- 单选 -->
            <el-radio-group
              v-if="item.type === 'radioGroup'"
              v-model="item.value"
            >
              <el-radio
                v-for="(item, index) in item.options"
                :key="index"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
            <!-- 日期选择 -->
            <el-date-picker
              class="w-100"
              type="date"
              size="small"
              placeholder="选择日期"
              v-else-if="item.type === 'datePicker'"
              v-model="item.value"
              @change="item.onChange && item.onChange($event, item)"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
  </centerDialog>
</template>

<script>
export default {
  data() {
    return {
      initOptions_input: null,
    };
  },

  methods: {
    // 弹出新增/编辑学校
    render({ type, inputedVal }) {
      try {
        let inputOptions = (this.initOptions_input = {
          inputList: this.getInputStudent(),
        });

        if (inputedVal) {
          this.$com.fillData({
            inputList: inputOptions.inputList,
            valueListObj: inputedVal,
          });
        }

        return new Promise((resovle, reject) => {
          this.$refs.centerDialog.render({
            title: ["新增家长", "编辑家长"][type],
            width: "600px",
            verifyInputListIsChange: inputOptions.inputList, //关闭前的数据校验，如果数据发生了改变，则提醒关闭不保存数据
            ok: async () => {
              try {
                const { inputList } = inputOptions;

                let requestOpt = this.$com.getValueByRules(inputList);

                switch (
                  type // 1:新增 2:编辑
                ) {
                  case 0:
                    break;
                  case 1:
                    const { id } = inputedVal;

                    requestOpt.id = id;
                    break;
                }

                const { msg } = await this.$ajax({
                  apiKey: "saveParent",
                  data: requestOpt,
                });

                this.$toast({
                  msg,
                });
                resovle();
              } catch (error) {
                reject(error);
                throw error;
              }
            },
            cancel: () => {},
          });
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    //获取输入配置表
    getInputStudent(inputedValueObj) {
      let _tempValObj = (this._tempValObj = {}); //临时变量

      let getInputStudent = [
        {
          type: "input",
          label: "家长名称",
          placeholder: "请输入家长名称",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "name",
        },
        {
          type: "input",
          label: "手机号",
          placeholder: "请输入手机号",
          maxLength: 11,
          rules: ["required", "!null", "mobile"],
          value: null,
          props: "mobile",
        },
        {
          type: "input",
          label: "住址",
          placeholder: "请输入住址",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "address",
        },
        {
          type: "radioGroup",
          label: "性别",
          placeholder: "请选择性别",
          rules: ["!null"],
          value: 1,
          props: "gender",
          options: [
            {
              label: "男",
              value: 1,
            },
            {
              label: "女",
              value: 2,
            },
          ],
        },

        {
          type: "input",
          label: "紧急联系人",
          placeholder: "请输入紧急联系人",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "contacts",
        },
        {
          type: "input",
          label: "紧急联系人手机号",
          placeholder: "请输入紧急联系人手机号",
          maxLength: 11,
          rules: ["!null", , "mobile"],
          value: null,
          props: "c_mobile",
        },
        {
          type: "input",
          label: "家长身份证",
          placeholder: "请输入家长身份证",
          maxLength: 18,
          rules: ["!null"],
          value: null,
          props: "card",
        },
        {
          type: "input",
          label: "工作单位",
          placeholder: "请输入工作单位",
          maxLength: 18,
          rules: ["!null"],
          value: null,
          props: "company",
        },
        //选择所属老师
        (_tempValObj.belongTeacher = {
          type: "select",
          label: "所属老师",
          placeholder: "请搜索/选择所属老师",
          rules: ["!null"],
          value: "",
          id: "",
          props: "teacher_name",
          props_id: "teacher_id",
          options: [],
          remoteMethod: async (keyword, success) => {
            try {
              console.info("keyword", keyword);

              const {
                res: { data },
              } = await this.$ajax({
                apiKey: "teacherServiceList",
                data: {
                  name: keyword || "",
                  status: 1, //status	否	int	管理员状态 1-正常 2-禁用
                },
              });

              const thisObj = _tempValObj.belongTeacher;

              thisObj.options = data.map((item) => {
                const { id, name } = item;

                return {
                  label: name,
                  value: id,
                };
              });
            } catch (error) {
              this.$catchError(error);
            }
          },
          onChange: (val, thisObj) => {
            thisObj.id = val;
          },
          onFocus: (event, thisObj) => {
            this.tryReInputAllSelectOptions(thisObj);
          },
        }),
        {
          type: "input",
          label: "备注",
          placeholder: "请输入备注",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "remark",
        },
        {
          type: "input",
          inputType: "number",
          label: "原存费",
          placeholder: "请输入原存费",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "ycf_balance",
        },
      ];

      return getInputStudent;
    },
    //回填所有的options
    tryReInputAllSelectOptions(thisObj) {
      const { options, value, allOptions } = thisObj;

      //如果选择的数据非法，则使用默认获取的所有options
      if (!value || options.length === 0) {
        options.length = 0;

        thisObj.remoteMethod();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
