<!--
 * @Date         : 2020-12-07 17:12:23
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-29 14:34:38
 * @FilePath     : \leXue_manage_pc\src\components\footerBtnModules.vue


  底部按钮模块

  使用
  
  <footerBtnModules :btnList="curentPageFooterBtnList" />
  
  import footerBtnModules from "@/components/footerBtnModules.vue";

  components: {
    footerBtnModules,
  },

  methods: {
    curentPageFooterBtnList(){
      return [
        {
          title: "保存编辑",
          authenKey: "role_edit", //权限key
          onTap: () => {
            this.handleSubmit(pageType);
          },
        },
      ]
    }
  }
-->
<template>
  <!-- 模块：底部操作按钮 -->
  <div
    class="mt-20 footer-btn-modules"
    v-if="isLoadedAdminDetails && getPermiseBtnQty > 0"
  >
    <div class="d-flex align-items-center justify-content-end mr-20 h-100">
      <div class="mr-5" v-for="(item, index) in btnList" :key="index">
        <el-button
          :class="item.type ? item.type + '-btn' : 'submit-btn'"
          v-if="item.isRender"
          @click="item.onTap && item.onTap()"
        >
          {{ item.title }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    btnList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      getPermiseBtnQty: 0,
    };
  },
  computed: {
    //是否加载了管理员权限详情
    isLoadedAdminDetails() {
      const getters = this.$store.getters;
      const getAuthenIsPermitted = getters.getAuthenIsPermitted;
      const isLoadedAdminDetails = getters.isLoadedAdminDetails();
      const btnList = this.btnList;

      if (isLoadedAdminDetails === true) {
        btnList.forEach((item) => {
          const { authenKey } = item;
          const isGetAuthen = authenKey
            ? getAuthenIsPermitted(authenKey)
            : true;

          if (isGetAuthen === true) {
            this.$set(item, "isRender", true);
            this.getPermiseBtnQty = 1;
          }
        });
      }

      return isLoadedAdminDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-button {
  line-height: 1.5rem;
  padding: 0 0.5rem;
  background-color: #333744;
  border-radius: 0.2rem;
  font-size: 0.7rem;
  color: #ffffff;
}

// 模块：底部按钮
.footer-btn-modules {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-bottom: -1.25rem;
  height: 2.5rem;
  background-color: #ffffff;
  box-shadow: 0rem 0.2rem 0.48rem 0.02rem rgba(218, 218, 218, 0.5);
  border-radius: 0.2rem;

  .submit-btn {
    background-color: #1891ff;
  }

  .warning-btn {
    background-color: #ebb460;
  }
}
</style>
