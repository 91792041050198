<!--
 * @Date         : 2020-12-08 10:57:48
 * @LastEditors  : cxx
 * @LastEditTime : 2021-02-19 11:15:47
 * @FilePath     : \leXue_manage_pc\src\views\finance\finaceOrderDetails.vue
-->

<template>
  <div class="h-100 d-flex flex-column" v-if="isLoadedAdminDetails">
    <!-- 模块：基础信息 -->
    <div class="mb-10">
      <mainContentDiv>
        <div class="choose-student-modules">
          <div class="d-flex align-items-center px-20 header-bar">
            <div class="mr-10 py-10 modules-title">基础信息</div>
          </div>
          <div class="d-flex flex-wrap px-20 my-20" v-if="selectStudentInfoObj">
            <div
              class="col-3 my-5"
              v-for="(item, index) in studentInfoList"
              :key="index"
            >
              <div class="mr-10 label">{{ item.label }}</div>
              <div class="value">
                {{ selectStudentInfoObj[item.props] || "--" }}
              </div>
            </div>
          </div>
        </div>
      </mainContentDiv>
    </div>

    <!-- 模块：退款信息 -->
    <div class="mb-10" v-if="inputRefundList">
      <mainContentDiv>
        <div class="choose-student-modules">
          <div class="d-flex align-items-center px-20 header-bar">
            <div class="mr-10 py-10 modules-title">退款信息</div>
          </div>
          <div class="py-10">
            <div class="pl-20 d-flex flex-wrap">
              <div
                class="mr-20 py-10"
                v-for="(item, index) in inputRefundList"
                :key="index"
                :class="item.col || 'col-3'"
              >
                <div
                  class="my-5 com-title"
                  v-if="item.label"
                  :class="{
                    active:
                      item.rules &&
                      item.rules.find((item) => item === 'required'),
                  }"
                >
                  {{ item.label }}
                </div>
                <!-- 输入框 -->
                <textFile v-if="item.type === 'input'" :initOptions="item">
                </textFile>
                <!-- 日期选择 -->
                <el-date-picker
                  class="w-100"
                  type="datetime"
                  size="small"
                  placeholder="选择日期"
                  v-else-if="item.type === 'datePicker'"
                  v-model="item.value"
                  @change="item.onChange && item.onChange($event, item)"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
      </mainContentDiv>
    </div>

    <!-- 模块：选择产品 -->
    <div class="col">
      <mainContentDiv class="d-flex flex-column">
        <div class="choose-student-modules">
          <div class="d-flex align-items-center px-20 header-bar">
            <div class="mr-10 py-10 modules-title">产品信息</div>
            <el-button
              class="my-5"
              size="small"
              type="primary"
              v-if="isAbleEdit"
              @click="handleSelectGoods"
              >选择产品</el-button
            >
          </div>
        </div>

        <!-- 模块：选择产品 -->
        <div class="col px-20 mt-20">
          <selectGoods
            ref="selectGoods"
            v-if="pageType !== null"
            :selectedGoodsList="selectedGoodsList"
            :initOptions="initOptions_selectGoods"
          >
          </selectGoods>
        </div>

        <!-- 模块：汇总信息 -->
        <div class="ma-20 py-10 totall-info-modules">
          <div
            class="d-flex justify-content-end my-5 px-20 info-bar"
            v-for="(item, index) in infoBarList"
            :key="index"
          >
            <div class="label">{{ item.label }}:</div>
            <div class="pl-10 text-right value" :class="item.valueCssClass">
              {{ totalInfoObj[item.props] }}
            </div>
          </div>
        </div>
      </mainContentDiv>
    </div>
  </div>
</template>

<script>
import selectGoods from "@/components/selectGoods.vue";

export default {
  components: {
    selectGoods,
  },
  data() {
    return {
      selectStudentInfoObj: null,
      studentInfoList: [
        {
          label: "姓名",
          props: "name",
        },
        {
          label: "家长姓名",
          props: "_parStudentRelation",
        },
        // {
        //   label: "与亲属关系",
        //   props: "relation_name",
        // },
        {
          label: "年级",
          props: "grade",
        },
        {
          label: "身份证",
          props: "card",
        },
        {
          label: "所属学校",
          props: "s_name",
        },
        {
          label: "所属校区",
          props: "xq_name",
        },
      ],
      pageType: null,
      selectedGoodsList: [],
      inputRefundList: null,
      initOptions_selectGoods: {},
      initOptions_refundOrder: {},
    };
  },
  methods: {
    async init() {
      let { pageType, id, studentID } = this.$route.query;

      this.pageType = pageType = Number(pageType);

      //pageType  1：编辑  2：详情  3：退款
      if (pageType === 1 || pageType === 2 || pageType === 3) {
        const initOptions_selectGoods = this.initOptions_selectGoods;
        const { getAuthenIsPermitted } = this.$store.getters;

        if (pageType === 2) {
          initOptions_selectGoods.readonly = true;
          initOptions_selectGoods.tableHeaderExpand = [
            { label: "剩余课时", props: "surplus_count" },
            ...(getAuthenIsPermitted("finance_orderPrice")
              ? [{ label: "剩余金额", props: "surplus_price" }]
              : []),
          ];
        }

        if (pageType === 3) {
          initOptions_selectGoods.readonly = true;
          initOptions_selectGoods.tableHeaderExpand = [
            { label: "剩余课时", props: "surplus_count" },
            ...(getAuthenIsPermitted("finance_orderPrice")
              ? { label: "剩余金额", props: "surplus_price" }
              : []),
            { label: "退回课时", props: "refundCount", type: "input" },
            ...(getAuthenIsPermitted("finance_refundPrice")
              ? { label: "退回金额", props: "refundPrice", type: "input" }
              : []),
          ];

          //获取退款配置表
          this.inputRefundList = this.getRefundInputList();
        }

        this.getAndeSetStudentInfoByID(studentID); //获取：学生信息
        this.getBuyyedGoodsListByID(id, pageType); //获取：已购买产品
      }
    },
    //获取：学生信息
    async getAndeSetStudentInfoByID(id) {
      try {
        const { res } = await this.$ajax({
          apiKey: "getStudentDetails",
          data: {
            id, //id	是	int	学生id
          },
        });

        const studentInfo = res[0];

        // studentInfo.parent_name =
        //   studentInfo.parent[0] && studentInfo.parent[0].name;

        this.selectStudentInfoObj = studentInfo;
      } catch (error) {
        this.$catchError(error);
      }
    },

    //获取：已购买产品
    async getBuyyedGoodsListByID(id, pageType) {
      try {
        const { res } = await this.$ajax({
          apiKey: "getFinanceInfo",
          data: {
            oid: id, //oid	是	int	订单id
          },
        });

        const productList = res.product;
        const selectedGoodsList = this.selectedGoodsList;

        productList.forEach((item) => {
          const {
            pro_id: goodsID,
            pro_name: name,
            sku_id: skuID,
            oid: orderID = "",
            id,
            classify_name,
            grade_name,
            spec_name,
            attr_name,
            surplus_count,
            surplus_price,
            price,
            number: qty,
            count,
          } = item;

          selectedGoodsList.push({
            goodsID,
            name,
            skuID,
            orderID,
            refundOrderID: id, //退款单号id
            classify_name,
            grade_name,
            spec_name,
            attr_name,
            surplus_count,
            surplus_price,
            price,
            qty: 1,
            count,
          });
        });

        //退款
        if (pageType === 3) {
          const $set = this.$set;

          selectedGoodsList.forEach((item) => {
            $set(item, "refundCount", "");
            $set(item, "refundPrice", "");
          });
        }

        console.info("selectedGoodsList", selectedGoodsList);
      } catch (error) {
        this.$catchError(error);
      }
    },

    //选择我商品
    handleSelectGoods() {
      this.$refs.selectGoods.handleSelectAttrByDialog();
    },

    //获取退款列表
    getRefundInputList() {
      const timeStamp = new Date().getTime();
      const dateStr = this.$com.formatDate(
        new Date(timeStamp),
        "yyyy-MM-dd hh:mm:ss "
      );

      return [
        {
          type: "datePicker",
          label: "退款日期",
          placeholder: "请选择退款日期",
          maxLength: 40,
          rules: ["required", "!null"],
          value: dateStr,
          props: "refund_date",
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd hh:mm:ss"
            );

            item.value = formatDate;
          },
        },
        {
          type: "input",
          label: "退款理由",
          placeholder: "请输入退款理由",
          maxLength: 40,
          rules: ["required", "!null"],
          value: null,
          props: "refund_reason",
          col: "col",
        },
      ];
    },

    //提交
    async handleSubmit() {
      try {
        if (this.isSubmitted_order === true) {
          return false;
        }

        const selectStudentInfoObj = this.selectStudentInfoObj;

        if (!selectStudentInfoObj) throw "请选择学生信息";

        const { pid: parentID, id: studentID } = selectStudentInfoObj;
        const selectedGoodsList = this.selectedGoodsList;

        const skuList = selectedGoodsList.map((item) => {
          const { goodsID, skuID, price, qty, count } = item;

          return {
            pro_id: goodsID, //pro_id	是	int	产品id
            sku_id: skuID, //sku_id	是	int	产品sku id
            price, //price	是	decimal	总价格
            number: qty, //number	是	int	产品数量
            count, //count	是	int	总课时
          };
        });

        this.isSubmitted_order = true;

        //提交
        const { msg } = await this.$ajax({
          apiKey: "addOrder",
          data: {
            pid: parentID, // pid	是	int	家长id
            sid: studentID, // sid	是	int	学生id
            skus: JSON.stringify(skuList), // skus	是	array	产品集skus [ {pro_id , sku_id , price , number , count } , {…} , .. ]
          },
        });

        this.$bus_unique.emit("updateOrderList");
        this.$router.back(-1);

        this.$toast({
          msg,
        });
      } catch (error) {
        this.isSubmitted_order = false;
        this.$catchError(error);
      }
    },

    //提交退款
    async handeSubmitRefund(id) {
      try {
        let requestOpt = this.$com.getValueByRules(this.inputRefundList);
        let refundSkuList = [];

        this.selectedGoodsList.forEach((item) => {
          const { refundOrderID, refundCount, refundPrice } = item;

          if (refundPrice && refundCount) {
            refundSkuList.push({
              op_id: refundOrderID, //op_id	是	int	订单关联产品id
              refund_count: refundCount, //refund_count	是	int	退回课时
              refund_price: refundPrice, //refund_price	是	decimal	退款金额
            });
          }
        });

        if (refundSkuList.length <= 0) {
          throw "请至少输入一个完整的退回课时和退回金额";
        }

        requestOpt.oid = id;
        requestOpt.refund_products = JSON.stringify(refundSkuList);

        const { msg } = await this.$ajax({
          apiKey: "submitRefundOrder",
          data: requestOpt,
        });

        this.$router.back(-1);
        this.$bus_unique.emit("updateOrderList");

        this.$toast({
          msg,
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    //查看退款记录
    handleRenderRefundListByID(id) {
      const initOptions_refundOrder = this.initOptions_refundOrder;
      const { pid } = this.selectStudentInfoObj;

      initOptions_refundOrder.orderID = id;
      initOptions_refundOrder.parentID = pid;

      this.$refs.centerDialog_refundOrder.render({
        title: "退款记录",
        width: "800px",
      });
    },
  },
  computed: {
    isLoadedAdminDetails() {
      const isLoadedAdminDetails = this.$store.getters.isLoadedAdminDetails();

      if (isLoadedAdminDetails === true && !this.isInitted) {
        console.error("rerererer");
        this.init();
        this.isInitted = true;
      }

      return isLoadedAdminDetails;
    },

    //总信息
    totalInfoObj() {
      const totalInfoObj = {
        qty: 0,
        count: 0,
        price: 0,
      };

      const selectedGoodsList = this.selectedGoodsList;
      const pageType = this.pageType;

      if (pageType === 3) {
        selectedGoodsList.forEach((item) => {
          const { refundCount, refundPrice } = item;

          totalInfoObj.count += Number(refundCount);
          totalInfoObj.price += Number(refundPrice);
        });
      } else {
        selectedGoodsList.forEach((item) => {
          const { qty, count, price } = item;

          totalInfoObj.qty += Number(qty);
          totalInfoObj.count += Number(count);
          totalInfoObj.price += price * qty;
        });
      }

      return totalInfoObj;
    },

    //合计价格等信息
    infoBarList() {
      const pageType = this.pageType;
      let infoBarList = [];
      const { getAuthenIsPermitted } = this.$store.getters;

      //pageType 0:新增 2:查看 3：退款
      if (pageType === 0 || pageType === 2) {
        infoBarList = [
          {
            props: "qty",
            label: "数量",
          },
          {
            props: "count",
            label: "课时",
          },
          ...(getAuthenIsPermitted("finance_orderPrice")
            ? [
                {
                  props: "price",
                  label: "合计价格",
                  valueCssClass: "blue",
                },
              ]
            : []),
        ];
      } else if (pageType === 3) {
        infoBarList = [
          {
            props: "count",
            label: "退回总课时",
          },
          ...(getAuthenIsPermitted("finance_refundPrice")
            ? [
                {
                  props: "price",
                  label: "退回总金额",
                  valueCssClass: "blue",
                },
              ]
            : []),
        ];
      }

      console.info("infoBarList", infoBarList);

      return infoBarList;
    },

    //是否可编辑
    isAbleEdit() {
      const pageType = this.pageType;

      //pageType 2:查看 3：退款
      if (pageType === 2 || pageType === 3) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
//选择学生信息
.choose-student-modules {
  .header-bar {
    border-bottom: 1px solid #eaeaea;
    font-size: 0.8rem;
    color: #606060;
  }
  .label {
    font-size: 0.8rem;
    color: #999;
  }
  .value {
    font-size: 0.8rem;
  }
}

//模块：汇总信息
.totall-info-modules {
  background-color: #f0f2f5;

  .info-bar {
    .label {
      font-size: 0.7rem;
      color: #333333;
    }

    .value {
      min-width: 80px;
      font-size: 0.8rem;
      color: #333333;

      &.blue {
        color: #409eff;
      }
    }
  }
}
</style>