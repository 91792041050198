
<template>
  <div class="position-relative imgCover IMGBOX" @click="$emit('click')">
    <!-- 加载中 -->
    <div
      class="position-absolute ma-auto iconfont icon-loading center-icon"
      v-if="showLoadingIcon"
    ></div>
    <!-- 加载失败 -->
    <div
      class="position-absolute ma-auto iconfont icon-load-fail center-icon"
      v-if="isLoadedImgFail"
    ></div>
    <!-- 加载成功 -->
    <img
      class="imgCover img-box"
      :class="{ active: isLoadedImgSuccess, autoSize: autoSize }"
      :style="{ 'border-radius': borderRadius }"
      :src="imgSrc"
      :lazy-load="false"
      @error="imgLoadError"
      @load="imgLoadSuccess"
    />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
    },
    //不限定图片的尺寸，用图片自身的撑开
    autoSize: {
      type: String,
    },
    borderRadius: {
      type: String,
    },
  },
  data() {
    return {
      showLoadingIcon: null,
      isLoadedImgSuccess: null,
      isLoadedImgFail: null,
    };
  },
  async created() {
    //显示loading动画
    this.loadingAnimateTimer = setTimeout(() => {
      if (this.showLoadingIcon === null) {
        this.showLoadingIcon = true;
      }
    }, 200);
  },
  methods: {
    //清除加载动画
    removeLoadingAnimate() {
      clearTimeout(this.loadingAnimateTimer);
      this.showLoadingIcon = null;
    },

    imgLoadSuccess(e) {
      this.isLoadedImgFail = false;
      this.isLoadedImgSuccess = true;

      this.$emit("load");
      this.$emit("complete");
      this.removeLoadingAnimate(); //清除加载动画
    },

    imgLoadError(e) {
      console.error("imgLoadError", e);
      this.isLoadedImgFail = true;

      this.$emit("error");
      this.$emit("complete");
      this.removeLoadingAnimate(); //清除加载动画
    },
  },
  computed: {
    imgSrc() {
      const src = this.src;
      const imgBaseUrl = this.$imgBaseUrl;

      console.info("src", src);

      if (/(http|\/img\/|\/icon\/|data:)/.test(src)) {
        return src;
      } else {
        return imgBaseUrl + src;
      }

      return src;
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes rotate360 {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.iconfont {
  font-size: 44rpx;
}

.icon-loading {
  animation: rotate360 2s 0.2s linear infinite;
}

.center-icon {
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.img-box {
  opacity: 0;
  transition: all 0.4s;

  &.active {
    opacity: 1;
  }

  &.autoSize {
    width: initial;
    height: initial;
    display: block;
  }
}
</style>
