/*
 * @Date         : 2020-11-18 09:36:19
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-11-04 14:52:16
 * @FilePath     : \leXue_manage_pc\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VuePageStack from 'vue-page-stack';
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/iconfont.css';
import '@/assets/css/xx-base.scss';
import '@/assets/css/animate.css';

import appConfig from '@/assets/js/appConfig.js'; //配置项（域名等）
import selfGlobalComponents from '@/assets/js/selfGlobalComponents.js'; //全局组件
import globalMethods from '@/assets/js/globalMethods.js'; //全局公用方法
import Common from '@/assets/js/common.js'; 
import { CommonBus, UniqueBus } from '@/assets/js/bus.js';
import { Ajax } from '@/assets/js/ajax.js';
// 全局方法
import globalFun from '@/assets/js/index.js'

Vue.prototype.$bus = new CommonBus();          //发布订阅 事件总线
Vue.prototype.$bus_unique = new UniqueBus();   //单一bus（仅供订阅一次）
Vue.prototype.$com = new Common(Vue);          //通用方法
Vue.prototype.$imgBaseUrl = appConfig.httpDomainName; //图片域名
Vue.prototype.$globalFun = globalFun

const newAjax = new Ajax(Vue.prototype);
Vue.prototype.$ajax = function (options) { return newAjax.request(options, this) }; //全局请求

Vue.use(ElementUI);
Vue.use(selfGlobalComponents);
Vue.use(globalMethods);
Vue.use(VuePageStack, { router }); //页面栈插件，能缓存页面

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
