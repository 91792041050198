<!--
 * @Date         : 2020-12-08 11:35:37
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-06 16:20:34
 * @FilePath     : \leXue_manage_pc\src\components\selectGoods.vue

 选择产品
 
-->
<template>
  <div class="h-100">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>

    <!-- 弹窗：选择产品 -->
    <centerDialog ref="centerDialog_chooseGoods">
      <div
        class="d-flex flex-column"
        style="height: 60vh"
        v-if="initOptions_goods"
      >
        <div
          class="choose-student-modules"
          v-if="studentInfoList.length > 0 && currentStudentInfo"
        >
          <div class="d-flex align-items-center px-20 header-bar">
            <div class="mr-10 py-10 modules-title">基础信息</div>
          </div>
          <div class="d-flex flex-wrap px-20 my-20">
            <div
              class="col-3 my-5"
              v-for="(item, index) in studentInfoList"
              :key="index"
            >
              <div class="mr-10 label">{{ item.label }}</div>
              <div class="value">
                {{ currentStudentInfo[item.props] || "--" }}
              </div>
            </div>
          </div>
        </div>
        <!-- //产品列表 -->
        <goodsList
          class="col"
          ref="goodsList"
          :initOptions="initOptions_goods"
        ></goodsList>
      </div>
    </centerDialog>

    <!-- 弹窗：选择sku -->
    <centerDialog ref="centerDialog_chooseSku">
      <div style="height: 30vh" v-if="initOptions_sku">
        <selectSkuByGoodsID
          ref="selectSkuByGoodsID"
          :initOptions="initOptions_sku"
        ></selectSkuByGoodsID>
      </div>
    </centerDialog>
  </div>
</template>

<script>
import goodsList from "@/views/goods/goodsList.vue";
import selectSkuByGoodsID from "@/views/goods/selectSkuByGoodsID.vue";

export default {
  components: {
    goodsList,
    selectSkuByGoodsID,
  },
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },

    //被选中的产品
    selectedGoodsList: {
      type: Array,
      default: () => {
        return [];
      },
    },

    //当前的学生信息
    currentStudentInfo: {},
    
    //学生信息的配置表
    studentInfoList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      initOptions_goods: null,
      initOptions_sku: null,
      tableOptions: null,
    };
  },
  mounted() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    //弹窗选择属性
    handleSelectAttrByDialog() {
      this.initOptions_goods = {
        isSelect: true,

        //选择产品
        onSelect: (optionsGoods) => {
          console.info("选择optionsGoods", optionsGoods.name);

          this.initOptions_sku = {
            getDataID: optionsGoods.id,
            skuList: optionsGoods.sku,

            //根据产品选择sku
            onSelect: (optionsSku) => {
              try {
                const { id: goodsID, name } = optionsGoods;
                const {
                  id: skuID,
                  classify_name,
                  grade_name,
                  spec_name,
                  attr_name,
                  price,
                  count,
                } = optionsSku;

                const toAddGoods = {
                  goodsID,
                  name,
                  skuID,
                  classify_name,
                  grade_name,
                  spec_name,
                  attr_name,
                  price,
                  qty: 1,
                  count,
                };

                let selectedGoodsList = this.selectedGoodsList;

                //检验是否存在改sku
                const existedSkuObj = selectedGoodsList.find((item) => {
                  for (let key in toAddGoods) {
                    if (item[key] !== toAddGoods[key]) {
                      return false;
                      break;
                    }
                  }
                  return true;
                });

                if (existedSkuObj) {
                  throw "请勿重复添加";
                }

                //赋值
                selectedGoodsList.push(toAddGoods);

                this.$refs.centerDialog_chooseGoods.hide(); //关闭弹窗：选择产品
                this.$refs.centerDialog_chooseSku.hide(); //关闭弹窗：选择sku
              } catch (error) {
                this.$catchError(error);
              }
            },
          };

          //弹出：选择规格
          this.$refs.centerDialog_chooseSku.render({
            title: "选择规格",
            width: "800px",
            top: "40vh",
            cancel: () => {
              this.initOptions_sku = null; // 销毁选择商品实例
            },
          });
        },
      };

      //弹出：选择产品
      this.$refs.centerDialog_chooseGoods.render({
        title: "选择产品",
        width: "800px",
        cancel: () => {
          this.initOptions_goods = null; // 销毁选择商品实例
        },
      });
    },

    getTableOptions() {
      let { readonly, tableHeaderExpand } = this.initOptions;

      const tableOptions = {
        authenKey: "goods_list",
        headers: [
          { label: "产品名称", props: "name" },
          { label: "班级", props: "classify_name" },
          { label: "年级", props: "grade_name" },
          { label: "规格", props: "spec_name" },
          { label: "属性", props: "attr_name" },
          { label: "价格", props: "price", type: !readonly && "input" },
          // { label: "数量", props: "qty", type: !readonly && "input" },
          { label: "总课时", props: "count", type: !readonly && "input" },
        ],
        actionBtns: !readonly && [
          {
            title: "删除",
            color: "red",
            authenKey: "goods_del",
            onDelete: async (options, tableItem, tableItemIndex) => {
              this.selectedGoodsList.splice(tableItemIndex, 1);

              return {
                msg: "删除成功",
              };
            },
          },
        ],
        hidePaginate: true, //隐藏分页器
        getData: async (options) => {
          return {
            code: 1,
            res: {
              data: this.selectedGoodsList,
              last_page: 1,
              current_page: 1,
              total: 30,
              per_page: 30,
            },
          };
        },
      };

      //拓展表格
      if (tableHeaderExpand) {
        tableOptions.headers = tableOptions.headers.concat(tableHeaderExpand);
      }

      console.info("tableOptions", tableOptions);

      return tableOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
//选择学生信息
.choose-student-modules {
  .header-bar {
    border-bottom: 1px solid #eaeaea;
    font-size: 0.8rem;
    color: #606060;
  }
  .label {
    font-size: 0.8rem;
    color: #999;
  }
  .value {
    font-size: 0.8rem;
  }
}
</style>