<!--
 * @Date         : 2020-11-20 14:49:56
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-15 14:38:38
 * @FilePath     : \leXue_manage_pc\src\views\school\schoolList.vue
-->
<template>
<div class="d-flex h-100">
    <!-- 总校区 -->
    <totalSchoolList @change="handleSelectTotalSchool"></totalSchoolList>

    <!-- 分校区 -->
    <subSchoolList class="col ml-10" ref="subSchoolList"></subSchoolList>
  </div>
</template>

<script>
import totalSchoolList from "./totalSchoolList.vue";
import subSchoolList from "./subSchoolList.vue";

export default {
  components: {
    totalSchoolList,
    subSchoolList,
  },
  methods: {
    handleSelectTotalSchool(options) {
      this.$refs.subSchoolList.updateSubSchool(options);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>