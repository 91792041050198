/*
 * @Date         : 2020-11-18 09:36:19
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-04 11:55:37
 * @FilePath: /src/assets/js/api.js
 * 
 
    后端的数据接口地址，配合 ajax.js使用

 */
export default {
  login: "/api/admin/login", //登录
  loginOut: "/api/admin/logout", //退出登录
  uploadHeader: "/api/oss/upload", //上传头像

  //权限
  getPowerList: "/api/role/power_list", //获取权限列表
  getRoleList: "/api/role/index", //权限列表
  getRoleDetails: "/api/role/info", //角色详情与权限
  editRole: "/api/role/edit", //角色编辑
  addRole: "/api/role/add", // 角色添加
  delRole: "/api/role/del", // 角色删除

  //操作日志
  getOperateLogList: "/api/admin_log/index",

  //管理员
  getAdminList: "/api/admin/index", // 列表
  addAdmin: "/api/admin/add", // 新增
  getAdminDetailsByID: "/api/admin/info", // 详情
  editAdmin: "/api/admin/edit", // 编辑
  delAdmin: "/api/admin/del", // 删除
  changePassword: "/api/admin/change_pd", // 修改密码
  getAdminAuthen: "/api/admin/role", //获取管理员权限
  changeAdminStatus: "/api/admin/change_status", //获取管理员权限
  change_admin_pd: "/api/admin/change_admin_pd", //获取管理员权限

  //总校
  getSchoolList: "/api/school/index", //列表
  getSchoolDetails: "/api/school/info", //详情
  addSchool: "/api/school/add", //新增
  editSchool: "/api/school/edit", //编辑
  delSchool: "/api/school/del", //删除

  //分校区
  getSubSchoolList: "/api/school/xq_index", //列表
  getSubSchoolDetails: "/api/school/xq_info", //详情
  addSubSchool: "/api/school/xq_add", //新增
  editSubSchool: "/api/school/xq_edit", //编辑
  delSubSchool: "/api/school/xq_del", //删除

  //地址列表
  getRegionList: "/api/region/index", //获取地址列表

  //学校类型
  getSchoolTypeList: "/api/school/type_index", //列表
  addSchoolType: "/api/school/type_add", //添加
  editSchoolType: "/api/school/type_edit", //编辑
  delSchoolType: "/api/school/type_del", //删除

  // 年级
  getGradeList: "/api/school/grade_index", //列表
  addGrade: "/api/school/grade_add", //添加
  editGrade: "/api/school/grade_edit", //编辑
  delGrade: "/api/school/grade_del", //删除
  getAddGradeOptionList: "/api/school/grade_list", //年级添加列表

  // 区域
  getAreaList: "/api/school/area_index", //列表
  addArea: "/api/school/area_add", //添加
  editArea: "/api/school/area_edit", //编辑
  delArea: "/api/school/area_del", //删除

  //产品
  // getProdutList: "/api/product/index", //列表
  getProdutList: {
    url: "/api/product/index",
    transformData: (oldData) => {
      oldData.res.data.forEach((item, index) => {
        let { type } = item;
        item._typeName = type === 1 ? "中心班" : "校内班";
      });

      return oldData;
    },
  },
  getProductDetails: "/api/product/info", //详情

  addProduct: "/api/product/add", //新增
  editProduct: "/api/product/edit", //编辑
  delProduct: "/api/product/del", //删除
  transIsOnSale: "/api/product/changeSale", //上架/下架产品
  transIsForbidden: "/api/product/changeStatus", //启用/禁用产品
  getSingleGoodsList: "/api/product/single_list", //获取单产品列表

  //多产品
  getMutilProductList: "/api/product/mul_index", //列表
  getMutilProductInfo: "/api/product/mul_info", //详情
  addOrEditMutilProduct: "/api/product/mul_save", //新增/编辑
  delMutilProduct: "/api/product/mul_del", //删除

  //产品班级分类
  getProductClassfiyList: "/api/product/classify_index", //分类列表
  addClassfiy: "/api/product/classify_add", //新增
  editClassfiy: "/api/product/classify_edit", //编辑
  delClassfiy: "/api/product/classify_del", //删除

  //产品年级分类
  getGradeList: "/api/product/grade_index", //列表
  addGrade: "/api/product/grade_add", //添加
  editGrade: "/api/product/grade_edit", //编辑
  delGrade: "/api/product/grade_del", //删除

  //产品规格
  getSpecList: "/api/product/spec_index", //列表
  addSpec: "/api/product/spec_add", //添加
  editSpec: "/api/product/spec_edit", //编辑
  delSpec: "/api/product/spec_del", //删除

  //产品属性
  getAttrList: "/api/product/attr_index", //列表
  addAttr: "/api/product/attr_add", //添加
  editAttr: "/api/product/attr_edit", //编辑
  delAttr: "/api/product/attr_del", //删除

  //产品类型
  getProductTypeList: "/api/product/type_index", //列表
  getProductTypeDetails: "/api/product/type_info", //详情
  addProductType: "/api/product/type_add", //新增
  editProductType: "/api/product/type_edit", //编辑
  delProductType: "/api/product/type_del", //删除

  //适用人群
  getTheCrowdLit: "/api/product/people_index", //列表
  getCrowdDetails: "/api/product/people_info", //详情
  addCrowd: "/api/product/people_add", //新增
  editCrowd: "/api/product/people_edit", //编辑
  delCrowd: "/api/product/people_del", //删除

  //客户标签
  getCustomerLabelList: "/api/customer/label_index", //列表
  getCustomerLabelDetails: "/api/customer/label_info", //详情
  addCustomerLabel: "/api/customer/label_add", //新增
  editCustomerLabel: "/api/customer/label_edit", //编辑
  delCustomerLabel: "/api/customer/label_del", //删除

  //客户
  getCustomerList: "/api/customer/teacher_list", //列表
  transCustomerForbidden: "/api/customer/label_info", //启用/禁用客户
  addLabelToCustomer: "/api/customer/mul_label", //单个/多个客户贴标签

  // 家长
  getParentList: {
    url: "/api/customer/index",
    transformData: (oldData) => {
      oldData.res.data.forEach((item) => {
        item.student = item.student.join("；\r\n");
      });
      return oldData;
    },
  }, //列表

  getParentDetails: "/api/customer/info", //详情
  delParent: "/api/customer/del", //添加
  saveParent: "/api/customer/save", //新增/编辑家长
  exportParentList: "/api/customer/export", //导出家长列表
  importParentListTemplate: "/api/customer/template", //导入家长列表模板
  importParentList: "/api/customer/import", //导入家长列表
  ycfLogList: "/api/customer/ycf_log", //原存费记录列表
  account_merge: "/api/customer/account_merge", //账户合并

  //历史跟进客服
  getHistoryServicerList: "/api/customer/servicer_list", //列表
  delHistoryServicer: "/api/customer/del_servicer", //删除

  //学生
  getStudentList: "/api/student/index", //
  getStudentDetails: {
    //详情
    url: "/api/student/info",
    transformData: (oldData) => {
      const data = oldData.res;
      const { school_info, parent_info } = data;

      if (school_info) {
        const { name, xq_name } = school_info;

        data.s_name = name;
        data.xq_name = xq_name;

        oldData.res = [oldData.res];
      }

      if (parent_info) {
        let _parStudentRelation = "";

        parent_info.forEach((item) => {
          _parStudentRelation += `${item.name}(${item.student_relation_name})\n\r`;
        });

        data._parStudentRelation = _parStudentRelation;
      }

      data.sid = data.id; //学生id

      return oldData;
    },
  },
  // addStudent: '/api/student/add', //新增
  // editStudent: '/api/student/edit', //编辑
  saveStudent: "/api/student/save",
  delStudent: "/api/student/del", //删除
  exportStudentList: "/api/student/export", //导出学生列表
  importStudentListTemplate: "/api/student/template", //导入学生列表模板
  importStudentList: "/api/student/import", //导入学生列表
  saveArchives: "/api/student/save_archives", //编辑学生档案
  archives_export: "/api/student/archives_export", //导出学生档案
  archives_export_import: "/api/student/archives_internal_import", //导入学生档案
  archives_export_Template: "/api/student/archives_template", //导入学生档案模板
  // 学生卡片管理
  studentCardList: "/api/student_card/index",
  studentCardInfo: "/api/student_card/info",
  studentCardSave: "/api/student_card/save",
  studentCardDel: "/api/student_card/del",

  //学生关系
  getRelationList: "/api/student/relation_index", //列表
  addRelation: "/api/student/relation_add", //新增
  editRelation: "/api/student/relation_edit", //编辑
  delRelation: "/api/student/relation_del", //删除

  //订单
  getOrderList: {
    //列表
    url: "/api/order/index",
    transformData: (oldData) => {
      oldData.res.list.data.forEach((item) => {
        const { product, pid, parent_name } = item;

        //购买了的产品
        let _buyyedProduct = "";
        let _specificationDetails = "";
        let _surplusTotalPrice = 0;

        product.forEach((item) => {
          const { pro_name, surplus_price } = item;

          _buyyedProduct += `${pro_name}\n\r`;
          _surplusTotalPrice += Number(surplus_price);
          _specificationDetails += `${item.classify_name} / ${item.grade_name} / ${item.spec_name} / ${item.attr_name}`;
        });

        item._buyyedProduct = _buyyedProduct;
        item._specificationDetails = _specificationDetails;
        item._surplusTotalPrice = _surplusTotalPrice;

        //家长名称
        item._parentName = parent_name;
      });

      return oldData;
    },
  },
  getOrderDetails: "/api/order/info", //详情
  addOrder: "/api/order/add", //新增
  editOrder: "/api/order/edit", //编辑
  delOrder: "/api/order/del", //删除
  submitRefundOrder: "/api/order/refund", //退款
  getRefundOrderList: {
    //退款订单列表
    url: "/api/order/refund_index",
    transformData: (oldData) => {
      return oldData;
    },
  },
  checkOrder: "/api/order/check", //审核订单
  exportOrder: "/api/order/order_export", //导出订单
  exportRefundOrder: "/api/order/refund_order_export", //导出退款订单
  exportImportTemplate_order: "/api/order/order_template", //订单导入模板
  importTemplate_order: "/api/order/order_import", //导入订单
  getRefundOrderNoCheckeQty: "/api/order/refund_order_nocheck_count", // 待处理退款单数量
  checkRefundOrder: "/api/order/refund_order_check", //审核退款订单
  orderClock: "/api/student/order_clock", // 订单核销课时

  //财务
  getIncomeList: {
    //收入列表
    url: "/api/finance/index",
    transformData: (oldData) => {
      oldData.res.list.data.forEach((item) => {
        const { product, parent_name } = item;
        let _buyyedProduct = ""; //购买的产品
        let _consumeAmount = 0; //消费金额

        product.forEach((item) => {
          const { pro_name, price } = item;

          _buyyedProduct += `${pro_name}\n\r`;
          _consumeAmount += Number(price);
        });

        item._buyyedProduct = _buyyedProduct;
        item._consumeAmount = _consumeAmount;

        //家长名称
        item._parentName = parent_name;
      });

      return oldData;
    },
  },
  getFinanceInfo: "/api/finance/info", //详情
  getOutgoingList: "/api/finance/refund_index", //支出列表

  //班级
  getClassList: {
    //列表
    url: "/api/teach/class_index",
    transformData: (oldData) => {
      oldData.res.data.forEach((item, index) => {
        let { status, ass_ids, product, type } = item;

        if (typeof ass_ids === "string") {
          ass_ids = JSON.parse(ass_ids);
        }

        item.ass_names = ass_ids.map((item) => Number(item));
        item.__productName = product[0].product_name;

        item._typeName = type === 1 ? "中心班" : "校内班";
      });

      return oldData;
    },
  },
  getClassInfo: "/api/teach/class_info", //获取班级信息
  // addClass: '/api/teach/class_add', //开设与编辑班级
  classClock: "/api/student/class_clock", // 班级核销课时

  // editClass: '/api/teach/class_edit', //开设与编辑班级
  saveClass: "/api/teach/class_save", //新增/编辑班级
  delClass: "/api/teach/class_del", //删除
  offOrOnClass: "/api/teach/class_change_status", // 禁用/启用
  exportClass: "/api/teach/class_export", //导出班级
  exportClassStudent: "/api/teach/class_student_export", //导出班级学生

  getAbleAddToClassStudentList: "/api/teach/student_add_index", //获取可新增到班级的学生列表

  getClassStudent: {
    //获取班级学生
    url: "/api/teach/student_index",
    transformData: (oldData) => {
      const statusTextList = ["", "正常", "转班", "结班", "退班"];

      oldData.res.data.forEach((item) => {
        const { status } = item;

        item.isAbleUse = status === 1;
        item._statusText = statusTextList[status]; // status	int	学生状态 1-正常 2-转班 3-结班 4-退班
      });

      return oldData;
    },
  },
  addClassStudent: "/api/teach/student_save", //增加班级学生
  removeStudentOutOfCurrentClass: "/api/teach/student_del", //移除班级学生
  moveStudentToOtherClass: "/api/teach/student_change_class", //学生换班
  unBindTeacherOnClass: "/api/teach/teacher_unbind", //老师解绑
  removeClassStudent: "/api/teach/old_student_del", //删除班级学生

  getClockCountList: "/api/teach/clock_index", //获取核销课时记录

  getOndutyStudentList: "/api/teach/attend_index", //出勤学生
  getAbsenceStudentList: "/api/teach/absence_index", //缺勤学生
  teachBuke: "/api/teach/buke", //学生补课
  exportAttend: "/api/teach/export_attend", //班级考勤导出

  saveArchivesInternal: "/api/student/save_archives_internal", //编辑学生档案（内部使用）
  archivesInternalExport: "/api/student/archives_internal_export", //导出学生档案（内部使用）

  //会员资料
  getMemberList: "/api/member/index", //列表
  getMemberDetails: "/api/member/info", //会员详情
  delMember: "/api/member/del", //删除会员
  changeMemberLevel: "/api/member/change_level", //修改会员等级
  getMemberRechargeRecordList: "/api/member/recharge_log_index", //获取会员充值记录
  getMemberConsumeList: "/api/member/consume_log_index", //获取会员消费记录
  recordConsumption: "/api/member/consume", //记录会员消费
  exportMemberLiist: "/api/member/export", //导出会员资料
  balanceRemind: "/api/member/balance_remind", //余额提醒

  coursePackRechargeLogIndex: "/api/course_pack/recharge_log_index", //课包充值记录列表
  coursePackConsumeLogIndex: "/api/course_pack/consume_log_index", //课包消费记录列表
  coursePackConsume: "/api/course_pack/consume", // 补录课包消费记录
  coursePackBalanceRemind: "/api/course_pack/balance_remind", //课包余额提醒
  changeCoursePack: "/api/member/change_course_pack", // 修改会员余额课包
  changeCoursePackLogList: "/api/member/change_log_list", // 课包/课时修改记录
  changeClassHour: "/api/member/change_class_hour", // 修改会员余额课时
  classHourConsume: "/api/class_hour/consume", // 补录课时订单记录

  //会员等级管理
  getMemberLevelList: "/api/member/level_index", //会员等级列表
  addOrEditMemberLevel: "/api/member/level_save", //新增/编辑会员等级
  getMemberLevelDetails: "/api/member/level_info", //获取会员等级详情
  delMemberLevel: "/api/member/level_del", //删除会员等级

  //会员充值规则
  getMemberRechargeRuleList: "/api/member/rule_index", //获取会员充值规则列表
  addOrEditMemberRechargeRule: "/api/member/rule_save", //新增/编辑会员充值规则
  getMemberRechargeRuleDetails: "/api/member/rule_info", //获取会员充值规则详情
  delMemberRechargeRule: "/api/member/rule_del", //删除会员充值规则
  changeMemberRuleIsUseAble: "/api/member/change_status", //启用 / 禁用会员规格

  //课包-充值规则
  getMemberRechargeRuleListCoursePack: "/api/course_pack/rule_index",
  addOrEditMemberRechargeRuleCoursePack: "/api/course_pack/rule_save",
  getMemberRechargeRuleDetailsCoursePack: "/api/course_pack/rule_info",
  delMemberRechargeRuleCoursePack: "/api/course_pack/rule_del",
  changeMemberRuleIsUseAbleCoursePack: "/api/course_pack/change_status",

  //会员充值
  getExamineRechargeMemberList: {
    //充值审核列表
    url: "/api/member/check_index",
    transformData: (oldData) => {
      oldData.res.data.forEach((item) => {
        const { parent_info, status } = item;

        if (parent_info) {
          item.__parentName = parent_info.name;
          item.__parentGender = parent_info.gender_str;
          item.__parentMobile = parent_info.mobile;
          item.__adminName = parent_info.admin_name;
        }

        item._isCheckedStatus = status === 1;
      });
      return oldData;
    },
  },
  rechargeMember: "/api/member/recharge", //充值
  checkMemberRecharge: "/api/member/check", //审核
  delRechargeMember: "/api/member/check_del", //删除
  computeRechargeGiveMoney: "/api/member/give_money", //计算
  memberCheckExport: "/api/member/check_export", //导出

  //课包-会员充值
  getExamineRechargeMemberListCoursePack: {
    //充值审核列表
    url: "/api/course_pack/check_index",
    transformData: (oldData) => {
      oldData.res.data.forEach((item) => {
        const { parent_info, status } = item;

        if (parent_info) {
          item.__parentName = parent_info.name;
          item.__parentGender = parent_info.gender_str;
          item.__parentMobile = parent_info.mobile;
          item.__adminName = parent_info.admin_name;
        }

        item._isCheckedStatus = status === 1;
      });
      return oldData;
    },
  },
  rechargeMemberCoursePack: "/api/course_pack/recharge", //充值
  checkMemberRechargeCoursePack: "/api/course_pack/check", //审核
  delRechargeMemberCoursePack: "/api/course_pack/check_del", //删除
  computeRechargeGiveMoneyCoursePack: "/api/course_pack/give_money", //计算
  memberCheckExportCoursePack: "/api/course_pack/check_export", //导出

  //课时-会员充值
  class_hour_list: {
    //充值审核列表
    url: "/api/class_hour/check_index",
    transformData: (oldData) => {
      oldData.res.data.forEach((item) => {
        const { parent_info, status } = item;

        if (parent_info) {
          item.__parentName = parent_info.name;
          item.__parentGender = parent_info.gender_str;
          item.__parentMobile = parent_info.mobile;
          item.__adminName = parent_info.admin_name;
        }

        item._isCheckedStatus = status === 1;
      });
      return oldData;
    },
  },
  class_hour_recharge: "/api/class_hour/recharge", //充值
  class_hour_check: "/api/class_hour/check", //审核
  class_hour_check_del: "/api/class_hour/check_del", //删除
  class_hour_give_money: "/api/class_hour/give_money", //计算
  class_hour_check_export: "/api/class_hour/check_export", //导出
  class_hour_consume_log_index: "/api/class_hour/consume_log_index", //课时消费记录列表
  class_hour_recharge_log_index: "/api/class_hour/recharge_log_index", //课时充值记录列表

  // 数据统计
  getPaymentList: "/api/index/income_count",
  getDataStatistics_todaySale: "/api/index/today_count", //今日销售数据
  getOrderCount: "/api/index/order_count", //获取订单统计
  getDataStatistics_overview: "/api/index/data_overview", //数据预览
  getDataStatistics_incomeOutcome: "/api/index/income_refund_overview", //收入和退款总览
  getDataStatistics_studentCount: "/api/index/class_student_count", //学生数量
  getDataStatistics_productMoneyCount: "/api/index/product_money_count", //产品销售金额总览

  //退款理由
  getRefundReasonList: "/api/order/refund_reason_index", //获取退款理由
  addOrEditRefundReason: "/api/order/refund_reason_save", //增加/编辑退款理由
  delRefundReason: "/api/order/refund_reason_del", //删除退款理由

  //教师列表
  teacherServiceList: {
    url: "/api/teacher/index",
    transformData: (oldData) => {
      oldData.res.data.forEach((item) => {
        const { submit_plan } = item;
        // console.log("submit_plan", submit_plan);
        item.submit_plan_str = submit_plan === 1 ? "需要" : "不需要";
      });
      return oldData;
    },
  },
  teacherServiceSave: "/api/teacher/save", //新增/编辑
  teacherServiceInfo: "/api/teacher/info", //详情
  teacherServiceDel: "/api/teacher/del", //删除
  teacherServiceChangeStatus: "/api/teacher/change_status", //启用/禁用
  teacherServiceLog: "/api/teacher_log/index", //日志
  // teacherServiceScheduleTasksStatisticsExportItem: "/api/teacher/exportItem", // 排课任务概述进度统计导出
  teacherExportTask: "/api/teacher/exportTask", // 教师每日任务统计导出

  // 部门列表
  departmentList: "/api/teacher/department_index", //列表
  departmentSave: "/api/teacher/department_save", //新增/编辑
  departmentInfo: "/api/teacher/department_info", //详情
  departmentDel: "/api/teacher/department_del", //删除

  // 教研组
  teachingList: "/api/teaching/group_index", //列表
  teachingSave: "/api/teaching/group_save", //新增/编辑
  teachingInfo: "/api/teaching/group_info", //详情
  teachingChangeStatus: "/api/teaching/group_change_status", //启用/禁用
  teachingDel: "/api/teaching/group_del", //删除

  // 课程体系
  curriculumSystemList: "/api/teaching/system_index", //列表
  curriculumSystemSave: "/api/teaching/system_save", //新增/编辑
  curriculumSystemInfo: "/api/teaching/system_info", //详情
  curriculumSystemDel: "/api/teaching/system_del", //删除

  // 考核表
  assessInfo: "/api/assess/info", //详情
  assessEdit: "/api/assess/edit", //新增/编辑
  assessExport: "/api/assess/export", //导出

  // 教学大纲
  outlineList: "/api/teaching/outline_index", // 列表
  outline_info: "/api/teaching/outline_info", // 详情
  outline_theme_info: "/api/teaching/outline_theme_info", // 查看页面用
  outline_del: "/api/teaching/outline_del", // 删除
  outline_begin: "/api/teaching/outline_begin", // 启用
  outline_end: "/api/teaching/outline_end", // 结束
  outline_basic_save: "/api/teaching/outline_basic_save", // 基础信息保存
  outline_theme_save: "/api/teaching/outline_theme_save", // 课程主题保存
  outline_theme_del: "/api/teaching/outline_theme_del", // 课程主题删除
  outline_plan_upload: "/api/teaching/outline_plan_upload", // 上传教案
  outline_plan_export: "/api/teaching/outline_plan_export", // 下传教案
  outline_plan_reject: "/api/teaching/outline_plan_reject", // 驳回教案
  outline_plan_remind: "/api/teaching/outline_plan_remind", // 提醒上传教案
  outline_plan_del: "/api/teaching/outline_plan_del", // 删除教案
  assess_score: "/api/teaching/assess_score", // 考核表评分
  assess_info: "/api/teaching/assess_info", // 考核表详情
  assess_export: "/api/teaching/assess_export", // 考核表导出
  outline_mp_save: "/api/teaching/outline_mp_save", // 美篇保存
  outline_mp_del: "/api/teaching/outline_mp_del", // 美篇删除
  outline_log_index: "/api/teaching/outline_log_index", // 操作日志列表
  assess_tj_export: "/api/teaching/assess_tj_export", // 统计数据
  assess_tj_export_pd: "/api/teaching/assess_tj_export_pd", // 统计数据导出判断
  operation_button: "/api/teaching/operation_button", // 详情可操作按钮

  // 排课表
  courseList: "/api/course/index", // 列表
  courseSave: "/api/course/save", // 保存
  courseDel: "/api/course/del", // 删除
  courseBasic_info: "/api/course/basic_info", // 基本详情
  courseinfo: "/api/course/info", // 详情
  courseEvent_save: "/api/course/event_save", // 事件插入
  courseclass_operate_select: "/api/course/class_operate_select", // 课程操作选项
  courseclass_edit: "/api/course/class_edit", // 课程编辑
  courseclass_info: "/api/course/class_info", // 课程详情
  courseclass_del: "/api/course/class_del", // 课程删除
  courseremind_info: "/api/course/remind_info", // 推送设置详情
  courseremind_edit: "/api/course/remind_edit", // 推送设置编辑
  courseExport: "/api/course/export", // 导出

  // 教师班级统计
  finance_teacher_class_count: "/api/finance/teacher_class_count", // 教师班级统计
  finance_class_count: "/api/finance/class_count", // 班级统计详情
  finance_teacher: "/api/finance/teacher", // 班主任列表

  // 项目模板
  projectTemplateList: "/api/task_template/index", // 列表
  projectTemplateSave: "/api/task_template/save", // 保存
  projectTemplateDel: "/api/task_template/del", // 删除
  projectTemplateGetInfo: "/api/task_template/info", // 详情
  projectTemplateChangeStatus: "/api/task_template/changeStatus", // 启用、禁用

  // 任务概述
  taskTemplateGetTaskIntro: "/api/task_template/getTaskIntro", // 获取内容
  taskTemplateSetTaskIntro: "/api/task_template/setTaskIntro", // 保存

  // 优惠券管理
  couponlist: "/api/coupon/index", // 列表
  couponDetails: "/api/coupon/info", // 详情
  couponSave: "/api/coupon/save", // 新增/编辑
  couponDel: "/api/coupon/del", // 删除
  couponSnCreate: "/api/coupon/sn_create", // 生成券码

  couponSnList: "/api/coupon/sn_list", // 券码列表
  couponSnDel: "/api/coupon/sn_del", // 券码删除
  couponSnSend: "/api/coupon/sn_send", // 发送券码
};
