<!--
 * @Description: 教研组列表
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
    <centerDialog ref="centerDialog">
      <inputCenter :initOptions="initOptions_input"></inputCenter>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
      initOptions_input: null,
    }
  },
  created() {
    this.tableOptions = this.getTableOptions()
  },
  methods: {
    // 弹出新增/编辑
    async renderEditDialog({ type, inputedVal }) {
      try {
        let inputOptions = (this.initOptions_input = {
          inputList: this.getInputRechargeLevel(),
        })

        if (inputedVal) {
          // console.log('inputedVal', inputedVal)
          // console.log(
          //   'this.initOptions_input.inputList',
          //   this.initOptions_input.inputList
          // )
          // 管理员回填
          let arr = inputedVal.teacher.map((item) => {
            const { teacher_id, name } = item
            return {
              label: name,
              value: teacher_id,
            }
          })
          this.initOptions_input.inputList[2].options = arr

          this.$com.fillData({
            inputList: this.initOptions_input.inputList,
            valueListObj: inputedVal,
          })
        }

        this.$refs.centerDialog.render({
          title: type === 0 ? '新增' : '编辑',
          width: '1000px',
          verifyInputListIsChange: inputOptions.inputList,
          ok: async () => {
            try {
              const { inputList } = inputOptions

              let requestOpt = this.$com.getValueByRules(inputList)
              console.log('requestOpt', requestOpt)

              // 1:新增 2:编辑
              if (type === 1) {
                const { id } = inputedVal
                requestOpt.id = id
              }

              // 多个数组时转换
              if (typeof requestOpt.teacher_ids == 'string') {
                requestOpt.teacher_ids = JSON.parse(requestOpt.teacher_ids)
              }

              // 新增/编辑
              const { msg } = await this.$ajax({
                apiKey: 'teachingSave',
                submitByForm: true,
                data: requestOpt,
              })

              this.$toast({
                msg,
              })

              this.$refs.tableList.init()
            } catch (error) {
              throw error
            }
          },
          cancel: () => {},
        })
      } catch (error) {
        this.$catchError(error)
      }
    },

    //获取输入配置表
    getInputRechargeLevel(inputedVal) {
      const _tempValObj = {}

      let getInputRechargeLevel = [
        {
          type: 'input',
          label: '名称',
          placeholder: '请输入名称',
          rules: ['required', '!null'],
          value: null,
          props: 'name',
          col: 'col-4',
        },
        (_tempValObj.assTeacher = {
          type: 'select',
          label: '教师',
          placeholder: '请搜索/选择教师',
          rules: ['required', '!null'],
          value: [],
          id: '',
          multiple: true, //多选
          props: 'teacher_ids',
          props_id: 'teacher_ids', //ass_ids	是	array	助教id集
          col: 'col-3',
          options: [],
          remoteMethod: async (keyword) => {
            try {
              const {
                res: { data },
              } = await this.$ajax({
                apiKey: 'teacherServiceList',
                data: {
                  name: keyword || '', //name	否	string	管理员名称
                  // status: 1, //status	否	int	管理员状态 1-正常 2-禁用
                },
              })

              const assTeacherList = (_tempValObj.assTeacher.options = data.map(
                (item) => {
                  const { id, name, status } = item

                  return {
                    disabled: status === 2,
                    label: name,
                    value: id,
                  }
                }
              ))

              return assTeacherList
            } catch (error) {
              this.$catchError(error)
            } finally {
            }
          },
          onChange: (valList, thisObj) => {
            try {
              // console.log('valList, thisObj', valList, thisObj)

              //赋值
              thisObj.id = JSON.stringify(valList)

              let arr = [...thisObj.options].filter((x) =>
                [...valList].some((y) => y === x.value)
              )
              // console.log('arr', arr)
              _tempValObj.belongTeacher.options = arr
            } catch (error) {
              this.$catchError(error)
            }
          },
        }),
        (_tempValObj.belongTeacher = {
          type: 'select',
          label: '主管理员',
          placeholder: '请选择主管理员',
          rules: ['required', '!null'],
          value: '',
          id: '',
          props: 'master_teacher_id',
          props_id: 'master_teacher_id',
          col: 'col-3',
          options: [],
          onChange: (val, thisObj) => {
            try {
              //赋值
              thisObj.id = val
            } catch (error) {
              this.$catchError(error)
            }
          },
        }),
      ]

      _tempValObj.assTeacher.remoteMethod() //获取所有老师

      return getInputRechargeLevel
    },

    getTableOptions() {
      return {
        authenKey: 'investigatingList',
        search: {
          inputList: [
            {
              type: 'input',
              label: '教研组名称',
              placeholder: '',
              value: '',
              props: 'name',
              rules: ['!null'],
            },
          ],
        },
        headers: [
          { label: '教研组名称', props: 'name' },
          { label: '教师名称', props: 'teacher_name_str' },
          { label: '主管理员', props: 'master_teacher_name' },
          { label: '状态', props: 'status_str' },
        ],
        titleBtns: [
          {
            type: 'filter',
            title: '查询',
          },
          {
            title: '新增',
            authenKey: 'investigatingAdd',
            onTap: () => {
              this.renderEditDialog({
                type: 0,
              })
            },
          },
        ],
        actionBtns: [
          {
            type: 'multiState', //多状态按钮
            props: 'status',
            authenKey: 'investigatingChangeAbleUseStatus',
            options: [
              {
                label: '启用',
                value: 2,
                color: 'success',
              },
              {
                label: '禁用',
                value: 1,
                color: 'warning',
              },
            ],
            onTap: async (btnObj, tableItem) => {
              this.$resureDialog({
                ok: async () => {
                  try {
                    const { id, status } = tableItem
                    const afterStatus = Number(status) === 1 ? 2 : 1

                    // 启用/禁用
                    const { msg } = await this.$ajax({
                      apiKey: 'teachingChangeStatus',
                      data: {
                        id: id,
                        status: afterStatus,
                      },
                    })

                    tableItem.status = afterStatus

                    this.$toast({
                      msg,
                    })
                    this.$refs.tableList.init()
                  } catch (error) {
                    this.$catchError(error)
                  }
                },
              })
            },
          },
          {
            title: '编辑',
            color: 'deepBlue',
            authenKey: 'investigatingEdit',
            onTap: async (options) => {
              // console.log('options', options)
              let { id } = options
              let { res } = await this.$ajax({
                apiKey: 'teachingInfo',
                data: {
                  id,
                },
              })
              console.log('res', res)

              // 数据处理
              let teacher_ids = []
              for (let i of res.teacher) {
                if (i.is_master === 1) res.master_teacher_id = i.teacher_id
                teacher_ids.push(i.teacher_id)
              }
              res.teacher_ids = teacher_ids
              // console.log('teacher_ids', teacher_ids)

              this.renderEditDialog({
                type: 1,
                inputedVal: res,
              })
            },
          },
          {
            title: '删除',
            color: 'red',
            authenKey: 'investigatingDetails',
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: 'teachingDel',
                data: {
                  id: options.id, //cid	是	int	班级id
                },
              })
            },
          },
        ],
        getData: async (options) => {
          console.log('获取列表', options)
          // 获取列表
          let ret = await this.$ajax({
            apiKey: 'teachingList',
            data: {
              ...options,
            },
          })

          return ret
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
