<!--
 * @Description: 教学大纲-查看(课程主题-基本信息)
-->
<template>
  <div>
    <div class="topics_item_tabs">
      <div class="tabs_item ">
        基本信息
      </div>
    </div>
    <div class="topics_item_content">
      <div class="itemLine">
        <div class="itemLine_label">课程名称</div>
        <div class="itemLine_value">{{ dataItem.name }}</div>
      </div>
      <div class="itemLine">
        <div class="itemLine_label">备课老师</div>
        <div class="itemLine_value">{{ dataItem.bk_teacher_str }}</div>
      </div>
      <div class="itemLine">
        <div class="itemLine_label">任课老师</div>
        <div class="itemLine_value">{{ dataItem.rk_teacher_str }}</div>
      </div>
      <div class="itemLine">
        <div class="itemLine_label">时间段</div>
        <div class="itemLine_value">
          {{ dataItem.begin_date }} 至 {{ dataItem.end_date }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.topics_item_tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tabs_item {
    width: 100%;
    background: #f0f2f5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    color: #606060;
    cursor: pointer;
  }
}

.topics_item_content {
  display: flex;
  flex-wrap: wrap;
  padding: 22px 0 10px;
  background: #f9f9f9;

  .itemLine {
    width: 25%;
    padding-left: 30px;
    font-size: 14px;
    margin-bottom: 22px;

    .itemLine_label {
      color: #999;
    }
    .itemLine_value {
      margin-top: 2px;
      color: #333333;
    }
  }
}
</style>
