<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-02 16:09:43
 * @FilePath     : \leXue_manage_pc\src\components\noAuthenTips.vue

  提示没有权限
  
-->


<template>
  <div class="pa-10">
    <mainContentDiv class=" no-authen-tips">
      <div class="mx-auto" style="width: 10rem; margin-top: 8rem;">
        <div class="position-relative center-icon">
          <xx-img
            class="position-absolute login-bg"
            :src="require('@/assets/img/no-authen-tips.png')"
          />
        </div>
      </div>
      <div class="mt-40 text-center tip-text">
          暂无查看此页面的权限，请向管理员申请
      </div>
    </mainContentDiv>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.no-authen-tips {
  .center-icon {
    padding-bottom: 106%;
  }

  .tip-text{
	font-size: 1rem;
	font-weight: bold;
	color: #9a9fb4;  }
}
</style>