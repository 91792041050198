import { render, staticRenderFns } from "./photoView.vue?vue&type=template&id=67a50f29&scoped=true&"
import script from "./photoView.vue?vue&type=script&lang=js&"
export * from "./photoView.vue?vue&type=script&lang=js&"
import style0 from "./photoView.vue?vue&type=style&index=0&id=67a50f29&prod&lang=css&"
import style1 from "./photoView.vue?vue&type=style&index=1&id=67a50f29&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67a50f29",
  null
  
)

export default component.exports