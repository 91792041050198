<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-02 17:49:16
 * @FilePath     : \leXue_manage_pc\src\components_global\uploadAndPreviewImg\chooseCameraOrImgFrame.vue

 上滑 选择图片的方式
 
-->


<template>
  <maskBg ref="maskBg">
    <section class="position-absolute choose-upload-ways-modules" v-if="isRenderMainContent">
      <div class="way-btn-list">
        <div class="way-btn" @click.stop="android_openCarmera">打开相机</div>
        <div class="way-btn" @click.stop="android_openLocationImg">打开本地相册</div>
      </div>
    </section>
  </maskBg>
</template>

<script>
import maskBg from "./maskBg.vue";

export default {
  components: {
    maskBg
  },
  data() {
    return {
      showMask: false,
      isRenderMainContent: null
    };
  },
  mounted() {},
  methods: {
    async render(initOptions = {}) {
      await this.$refs.maskBg.render({
        slotAnimatedEnter: "slideInUp",
        slotAnimatedLeave: "slideOutDown",
        removeCb: () => {
          this.isRenderMainContent = false;
          initOptions.cancel && initOptions.cancel();
        }
      });

      this.isRenderMainContent = true;
      this.initOptions = initOptions;
      // this.$nextTick(this.initBScroll);
    },

    disRender() {
      this.$refs.maskBg.disRender();
    },

    android_openCarmera() {
      this.$emit("android_openCarmera");
    },
    android_openLocationImg() {
      this.$emit("android_openLocationImg");
    }
  }
};
</script>

<style lang="scss" scoped>
// 选择打开上传的方式
.choose-upload-ways-modules {
  left: 0;
  right: 0;
  bottom: 0;

  .way-btn-list {
    margin-bottom: 0.5rem;
    .way-btn {
      margin: 0 0.5rem 0.5rem;
      padding-left: 0.75rem;
      line-height: 3;
      border-radius: 0.5rem;
      font-size: 0.7rem;
      color: #000;
      background-color: #fff;
    }
  }
}
</style>