<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-06 15:16:05
 * @FilePath     : \leXue_manage_pc\src\components_global\textFile.vue


  输入框

  {
    type: "input",
    label: "管理员名称",
    placeholder: "请输入管理员名称",
    maxLength: 50,
    rules: ["required", "!null"],
    value: null,
    props: "name",
    onChange: (thisObj, e) => {

    },
    onClear: (thisObj, e) => {

    },
    onInput: (thisObj, e) => {

    },
  } 
          
-->

<template>
  <div
    class="d-flex align-items-center input-modules"
    :class="styleType"
    :style="cssStyle"
    @click="handleTapTextFile(initOptions)"
  >
    <el-input
      clearable
      size="small"
      v-model="initOptions.value"
      :autofocus="initOptions.autofocus"
      :type="initOptions.inputType || 'text'"
      :placeholder="initOptions.placeholder || initOptions.label"
      :readonly="initOptions.readonly"
      :maxlength="initOptions.maxLength || 50"
      :disabled="initOptions.disabled"
      @keyup.enter="handleConfirm(initOptions)"
      @blur="$emit('blur')"
      @change="
        initOptions.onChange && initOptions.onChange(initOptions, $event)
      "
      @clear="initOptions.onClear && initOptions.onClear(initOptions, $event)"
      @input="initOptions.onInput && initOptions.onInput(initOptions, $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    //必填 | 初始参数配置 | 参数：文件 ↑ 顶部
    initOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    //可选 | 自定义css样式 | 参数：css样式
    cssStyle: {
      type: String,
    },
    //可选 | 样式风格 | 参数：bottomLine(底部带边框) borderLine(带边框)
    styleType: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  methods: {
    handleConfirm({ value: inputedValue }) {
      this.$emit("confirm", inputedValue ? inputedValue.trim() : "");
    },

    handleTapTextFile(initOptions) {
      const { onTap } = initOptions;

      onTap && onTap(initOptions);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-modules {
  &.bottomLine {
    border-bottom: 1px solid #ddd;
  }

  &.borderLine {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1px solid #dcdee0;
    border-radius: 0.25rem;
  }

  .input {
    line-height: 2rem;
    font-size: 0.8rem;
    color: #333;

    &::-webkit-input-placeholder {
      color: #999;
    }
  }
  .icon-close {
    color: #aaafb3;
  }
}
</style>