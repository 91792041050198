<!--
 * @Description: 用于上传 图片/文件 列表
-->
<template>
  <el-card
    shadow="never"
    :style="{ width: uploadWidth, border: ifBorder ? '' : 0 }"
  >
    <!-- 右侧按钮 -->
    <div class="rBtn" v-if="fileList.length > 0">
      <el-button
        v-if="!ifEdit"
        @click="ifEdit = true"
        type="primary"
        size="mini"
        icon="el-icon-edit"
        >管理</el-button
      >
      <template v-else>
        <el-button
          type="danger"
          size="mini"
          v-if="selectedList.length > 0"
          @click="del()"
          >批量删除</el-button
        >
        <el-button type="primary" size="mini" @click="cancelEdit"
          >取消</el-button
        >
      </template>
    </div>
    <!-- 上传按钮 -->
    <el-upload
      class="upload-demo"
      :action="action"
      :headers="headers"
      :multiple="ifMultiple"
      :accept="ifImage ? 'image/*' : accept"
      :before-upload="beforeAvatarUpload"
      :on-success="handleAvatarSuccess"
      :on-error="onError"
      :limit="maxCount"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :show-file-list="false"
    >
      <el-button
        v-if="fileList.length < maxCount"
        size="small"
        type="primary"
        icon="el-icon-upload"
        >上传文件</el-button
      >
      <div slot="tip" class="el-upload__tip" v-if="ifPromptWords">
        {{
          promptWords ||
            `支持扩展名：.rar .zip .doc .docx .pdf .jpg... 文件不能超过 ${requireSize}MB`
        }}
      </div>
    </el-upload>
    <!-- 文件列表 -->
    <div class="uploadList" :style="{ 'max-height': uploadMaxHeight + 'px' }">
      <!-- 有内容 -->
      <transition-group tag="div" name="el-list" v-if="fileList.length > 0">
        <div class="item" v-for="(item, i) in fileList" :key="item.url">
          <!-- 复选框 -->
          <div class="item_l" v-if="ifEdit" @click="onSelected(item)">
            <i
              v-if="ifSelected(item)"
              class="el-icon-success"
              style="color: #5FADFF;"
            ></i>
            <i v-else class="el-icon-circle-check" style="color: #999;"></i>
          </div>
          <div class="item_r">
            <!-- 图片文件预览 -->
            <div class="item_r_info">
              <el-image
                v-if="ifImageType(item)"
                style=" flex-shrink: 0;"
                ref="imgRef"
                :src="item.url"
                fit="contain"
                :style="`width: 70px; height: 70px;`"
                :preview-src-list="[item.url]"
              >
                <!-- 错误 -->
                <div slot="error" class="image-slot">
                  <svg-icon name="image-load-fail" />
                </div>
                <!-- 加载 -->
                <div slot="placeholder" class="image-slot">
                  <i class="el-icon-loading"></i>
                </div>
              </el-image>
              <i
                v-else
                class="folderIcon el-icon-folder-opened"
                @click="showFolder(item.url)"
              ></i>
              <span>{{ item.file_name }}</span>
            </div>
            <!-- 按钮区 -->
            <div class="item_r_btn">
              <template v-if="listSorting">
                <el-tooltip effect="dark" content="上移" placement="top-start">
                  <el-button
                    type="primary"
                    icon="el-icon-caret-top"
                    circle
                    size="small"
                    @click="upGo(i)"
                    :disabled="i === 0"
                  ></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="下移" placement="top-start">
                  <el-button
                    type="primary"
                    icon="el-icon-caret-bottom"
                    circle
                    size="small"
                    @click="downGo(i)"
                    :disabled="i === fileList.length - 1"
                  ></el-button>
                </el-tooltip>
              </template>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="small"
                @click="del(i)"
                style="margin-left: 20px;"
              ></el-button>
            </div>
          </div>
        </div>
      </transition-group>
      <!-- 无内容 -->
      <el-divider v-else>请上传文件</el-divider>
    </div>
    <p class="numberOf">文件个数: {{ fileList.length }}</p>
  </el-card>
</template>

<script>
import appConfig from '@/assets/js/appConfig.js'

export default {
  name: 'Uplads',
  // 数据双向绑定
  model: {
    prop: 'v_fileList',
    event: 'returnBack',
  },
  props: {
    // 文件数据
    v_fileList: {
      type: Array,
      default: function() {
        return []
      },
    },
    // 限制文件大小(MB)
    requireSize: {
      type: Number,
      default: 100,
    },
    // 上传最大个数
    maxCount: {
      type: Number,
      default: 100,
    },
    // 是否支持多选文件(true支持)
    ifMultiple: {
      type: Boolean,
      default: true,
    },
    // 是否只支持上传图片(true只能上传图片)
    ifImage: {
      type: Boolean,
      default: false,
    },
    // 文件类型
    accept: {
      type: String,
      default: '',
    },
    // 文件上传顺序(true新的再前面)
    reverse: {
      type: Boolean,
      default: true,
    },
    // 是否支持列表排序(true支持)
    listSorting: {
      type: Boolean,
      default: true,
    },
    // 是否有外部边框(true显示)
    ifBorder: {
      type: Boolean,
      default: true,
    },
    // 组件宽度
    uploadWidth: {
      type: String,
      default: '600px',
    },
    // 组件最大高度
    uploadMaxHeight: {
      type: [Number, String],
      default: 600,
    },
    // 是否显示提示提示文字/ true显示
    ifPromptWords: {
      type: Boolean,
      default: true,
    },
    // 提示文字
    promptWords: {
      type: String,
      default: null,
    },
  },
  computed: {
    fileList: {
      set(val) {
        // 将子组件改变的值传递给父组件
        this.$emit('returnBack', val)
      },
      get() {
        // 获取父组件的值
        return this.v_fileList
      },
    },
  },
  data() {
    return {
      // 必选参数，上传的地址（根据接口）
      action: appConfig.httpDomainName + '/api/oss/upload',
      // 设置上传的请求头部
      headers: {
        token: localStorage.getItem('token') || '',
      },
      // 管理按钮
      ifEdit: false,
      // 选中的文件
      selectedList: [],
    }
  },
  created() {},
  methods: {
    // 列表文件类型判断
    ifImageType(val) {
      // console.log(val)

      if (val.raw) {
        // 未上传
        return val.raw.type.split('/')[0] === 'image'
      } else {
        // 已上传
        const ifImage = this.$globalFun.getFileType(val.url)
        return ifImage === 'image'
      }
    },

    // 判断是否选中
    ifSelected(val) {
      return this.selectedList.indexOf(val) !== -1
    },

    // 选中
    onSelected(e) {
      // console.log(e)
      if (this.selectedList.indexOf(e) === -1) {
        this.selectedList.push(e)
      } else {
        const i = this.selectedList.findIndex((item) => {
          return e.uid === item.uid
        })
        this.selectedList.splice(i, 1)
      }
    },

    // 文件预览
    showFolder(url) {
      window.open(url)
    },

    // 上传文件之前的钩子
    beforeAvatarUpload(file) {
      // console.log('beforeAvatarUpload', file)
      const isLt2M = file.size / 1024 / 1024 < this.requireSize
      const type = file.type.split('/')
      const ext = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
      const isTypeOk = ext.indexOf(type[1]) >= 0

      if (!isLt2M) {
        this.$message.warning(`上传文件大小不能超过 ${this.requireSize}MB!`)
        return false
      }
      if (this.ifImage && !isTypeOk) {
        this.$message.warning(`上传图片只支持 ${ext.join(' / ')} 格式！`)
        return false
      }
    },

    // 最大限制
    handleExceed(files, fileList) {
      this.$message.warning(`最多只支持 ${this.maxCount}个文件`)
    },

    // 上传成功
    handleAvatarSuccess(response, file, fileList) {
      // console.log('-handleAvatarSuccess-response', response)
      // console.log('handleAvatarSuccess-file', file)
      // console.log('handleAvatarSuccess-fileList', fileList)

      // 存储到数组中(根据接口返回的信息改)
      if (response.code === -1) {
        return this.$message.error('上传失败')
      }

      const newObj = {
        url: response.res.url,
        file_name: response.res.file_name,
        status: 'success',
      }

      if (this.reverse) {
        this.fileList.unshift(newObj)
      } else {
        // 倒序
        this.fileList.push(newObj)
      }

      this.$message.success('上传成功')
    },
    // 上传失败
    onError(error) {
      console.log('error', error)
      this.$message.error('上传失败')
    },

    // 上移
    upGo(index) {
      this.fileList[index] = this.fileList.splice(
        index - 1,
        1,
        this.fileList[index]
      )[0]
    },

    // 下移
    downGo(index) {
      this.fileList[index] = this.fileList.splice(
        index + 1,
        1,
        this.fileList[index]
      )[0]
    },

    // 取消管理
    cancelEdit() {
      this.ifEdit = false
      // 清空选中
      this.selectedList = []
    },

    // 删除
    async del(index) {
      // 模态框
      const confirmResult = await this.$confirm('是否删除文件？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)
      // 取消
      if (confirmResult !== 'confirm') return

      if (index !== undefined) {
        this.fileList.splice(index, 1)
        this.selectedList.splice(index, 1)
      } else {
        // 批量删除
        this.fileList = this.fileList.filter(
          (x) => !this.selectedList.find((item) => x.uid === item.uid)
        )
        this.cancelEdit()
      }

      this.$message.success('删除成功！')
    },
  },
}
</script>

<style lang="scss" scoped>
.el-card {
  position: relative;
}
.rBtn {
  position: absolute;
  right: 20px;
  top: 20px;
}

.uploadList {
  margin-top: 10px;
  overflow: auto;
  overflow-x: hidden;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #c0ccda;
    margin-top: 10px;
    padding: 10px 20px;

    .item_l {
      font-size: 24px;
      margin-left: -4px;
      margin-right: 16px;
      cursor: pointer;
    }

    .item_r {
      flex: 1;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item_r_info {
        display: flex;
        align-items: center;
        flex: 6;
        width: 60%;
        margin-right: 10px;

        .folderIcon {
          color: #999;
          font-size: 36px;
          padding: 10px;
          cursor: pointer;
        }

        span {
          margin-left: 14px;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }
      }

      .item_r_btn {
        flex-shrink: 0;
      }
    }
  }

  .el-divider {
    margin: 40px 0;

    .el-divider__text {
      color: #999;
    }
  }
}

.numberOf {
  float: right;
  font-size: 12px;
  padding: 6px 0;
  color: #666;
}
</style>
