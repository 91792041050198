<!--
 * @Description: 优惠券
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
    <!-- 新增or编辑 -->
    <centerDialog ref="AddOrEditDialogRef">
      <inputCenter :initOptions="AddOrEditOptionsInput"></inputCenter>
    </centerDialog>
    <!-- 生成券码 -->
    <centerDialog ref="couponGenerateDialogRef">
      <inputCenter :initOptions="couponGenerateOptionsInput"></inputCenter>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tableOptions: null,
      AddOrEditOptionsInput: null,
      couponGenerateOptionsInput: null,
    };
  },
  destroyed() {},
  mounted() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    getTableOptions() {
      let getTableOptions = {
        authenKey: "couponList",
        search: {
          inputList: [
            {
              type: "input",
              label: "优惠券名称",
              placeholder: "",
              value: "",
              props: "title",
              rules: ["!null"],
            },
            {
              type: "select",
              label: "状态",
              placeholder: "",
              value: 0,
              props: "status",
              rules: ["!null"],
              options: [
                {
                  label: "全部",
                  value: 0,
                },
                {
                  label: "上架",
                  value: 1,
                },
                {
                  label: "下架",
                  value: 2,
                },
              ],
            },
          ],
        },
        headers: [
          { label: "名称", props: "title" },
          { label: "面额", props: "coupon_price" },
          { label: "满多少可用", props: "consumption" },
          { label: "总数量", props: "total_num" },
          { label: "已发数量", props: "send_num" },
          { label: "有效期开始时间", props: "start_time" },
          { label: "有效期结束时间", props: "end_time" },
          { label: "状态", props: "status_str" },
          // { label: "说明 ", props: "remark" },
        ],
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
          {
            title: "新增",
            authenKey: "couponAdd",
            onTap: async () => {
              await this.AddOrEditDialog({
                type: 0,
              });

              this.$refs.tableList.init();
            },
          },
        ],
        actionBtns: [
          {
            title: "编辑",
            color: "cyan",
            authenKey: "couponEdit",
            onTap: async (options) => {
              await this.AddOrEditDialog({
                type: 1,
                inputedVal: options,
              });

              this.$refs.tableList.init();
            },
          },
          {
            title: "删除",
            color: "red",
            authenKey: "couponDel",
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: "couponDel",
                data: {
                  id: options.id,
                },
              });
            },
          },
          {
            title: "生成券码",
            color: "deepBlue",
            authenKey: "couponGenerate",
            onTap: async (options) => {
              console.log("options", options);
              await this.couponGenerateDialog(options.id);
            },
          },
        ],
        getData: async (options) => {
          const ret = await this.$ajax({
            apiKey: "couponlist",
            data: options,
          });

          return ret;
        },
      };

      return getTableOptions;
    },

    // 弹出新增/编辑
    AddOrEditDialog({ type, inputedVal }) {
      try {
        let inputOptions = (this.AddOrEditOptionsInput = {
          inputList: this.getInputAddOrEdit(),
        });

        if (inputedVal) {
          this.$com.fillData({
            inputList: this.AddOrEditOptionsInput.inputList,
            valueListObj: inputedVal,
          });
        }

        return new Promise((resovle, reject) => {
          this.$refs.AddOrEditDialogRef.render({
            title: type === 0 ? "新增" : "编辑",
            width: "500px",
            ok: async () => {
              try {
                const { inputList } = inputOptions;

                let requestOpt = this.$com.getValueByRules(inputList);

                // 1:新增 2:编辑
                if (type === 1) {
                  const { id } = inputedVal;
                  requestOpt.id = id;
                }

                const { msg } = await this.$ajax({
                  apiKey: "couponSave",
                  data: requestOpt,
                });

                this.$toast({
                  msg,
                });

                resovle();
              } catch (error) {
                reject(error);
                throw error;
              }
            },
          });
        });
      } catch (error) {
        this.$catchError(error);
      }
    },
    //获取输入配置表
    getInputAddOrEdit(inputedValueObj) {
      let getInputAddOrEdit = [
        {
          type: "input",
          label: "优惠券名称",
          placeholder: "请输入优惠券名称",
          maxLength: 40,
          rules: ["required", "!null"],
          value: null,
          props: "title",
        },
        {
          type: "input",
          label: "面额",
          placeholder: "请输入面额",
          maxLength: 40,
          rules: ["required", "!null", "num"],
          value: null,
          props: "coupon_price",
        },
        {
          type: "input",
          label: "满多少可用",
          placeholder: "请输入满多少可用 (0无限制)",
          maxLength: 40,
          rules: ["required", "!null", "num"],
          value: null,
          props: "consumption",
        },
        {
          type: "input",
          label: "总数",
          placeholder: "请输入总数",
          maxLength: 40,
          rules: ["required", "!null", "num"],
          value: null,
          props: "total_num",
        },
        {
          type: "dateTimePicker",
          label: "有效期开始时间",
          placeholder: "请选择有效期开始时间",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "start_time",
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd hh:mm:ss"
            );

            item.value = formatDate;
          },
        },
        {
          type: "dateTimePicker",
          label: "有效期结束时间",
          placeholder: "请选择有效期结束时间",
          maxLength: 40,
          rules: ["!null"],
          value: null,
          props: "end_time",
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd hh:mm:ss"
            );

            item.value = formatDate;
          },
        },
        {
          type: "radio",
          label: "状态",
          placeholder: "请选择状态",
          rules: ["required", "!null"],
          value: 1,
          props: "status",
          options: [
            {
              label: "上架",
              value: 1,
            },
            {
              label: "下架",
              value: 2,
            },
          ],
        },
      ];

      return getInputAddOrEdit;
    },

    // 弹出生成券码
    couponGenerateDialog(id) {
      try {
        let inputOptions = (this.couponGenerateOptionsInput = {
          inputList: this.getInputcouponGenerate(),
        });

        return new Promise((resovle, reject) => {
          this.$refs.couponGenerateDialogRef.render({
            title: "生成券码",
            width: "500px",
            ok: async () => {
              try {
                const { inputList } = inputOptions;

                let requestOpt = this.$com.getValueByRules(inputList);
                requestOpt.id = id;
                // console.log("requestOpt", requestOpt);

                const { msg } = await this.$ajax({
                  apiKey: "couponSnCreate",
                  data: requestOpt,
                });

                this.$toast({
                  msg,
                });

                resovle();
              } catch (error) {
                reject(error);
                throw error;
              }
            },
          });
        });
      } catch (error) {
        this.$catchError(error);
      }
    },
    //获取输入配置表
    getInputcouponGenerate(inputedValueObj) {
      let getInputAddOrEdit = [
        {
          type: "input",
          label: "生成券码数量",
          placeholder: "请输入生成券码数量",
          maxLength: 40,
          rules: ["required", "!null", "num"],
          value: null,
          props: "num",
        },
      ];

      return getInputAddOrEdit;
    },
  },
};
</script>

<style lang="scss">
.FormDialogBox {
  .el-dialog__body {
    height: 94%;
    padding: 0 10px 20px;
    overflow: hidden;
    background: #f4f4f4;
  }
}
</style>
