<!--
 * @Description: 课时统计
-->
<template>
  <mainContentDiv class="pa-20">
    <!-- <el-form :model="query" inline @submit.native.prevent>
      <el-form-item label="班级名称：">
        <el-input
          v-model="query.name"
          placeholder="请输入班级名称"
          clearable
          size="mini"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="getList('place')"
          >筛选</el-button
        >
      </el-form-item>
    </el-form> -->
    <div class="teachersBox">
      <p>班主任姓名：</p>
      <div class="teachers_list">
        <div
          class="teachers_item"
          :class="{ teachersItemSelect: teachersItem === item.id }"
          v-for="item in teachersList"
          :key="item.id"
          @click="onTeachersItem(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      height="88%"
      ref="tableRef"
    >
      <!-- 动态配置 -->
      <template v-for="(item, index) in tableColumnList">
        <el-table-column
          :key="index"
          v-if="item.prop === 'attend'"
          :label="item.label"
          align="center"
        >
          <el-table-column
            align="center"
            width="120"
            :prop="item.prop"
            :index="item.index"
          >
            <template slot="header" slot-scope="scope">
              <span>出勤人数</span>
              <br />
              <span
                v-if="
                  tableData[0].attend[scope.column.index].attend_price !== false
                "
                >/课时总费(元)
              </span>
            </template>
            <template slot-scope="scope">
              <div
                :class="{
                  dateblue: scope.row.attend[scope.column.index].attend_count,
                }"
              >
                <span>{{
                  scope.row.attend[scope.column.index].attend_count
                }}</span>
                <span
                  v-if="
                    tableData[0].attend[scope.column.index].attend_price !==
                      false
                  "
                  >/{{ scope.row.attend[scope.column.index].attend_price }}
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :key="index"
          v-else
          :label="item.label"
          :prop="item.prop"
          :index="item.index"
          align="center"
          fixed="left"
          min-width="160"
        >
          <template slot-scope="scope">
            {{ scope.row[scope.column.property] }}</template
          >
        </el-table-column>
      </template>
      <!-- 按钮 -->
      <el-table-column label="操作" align="center" width="80" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" @click="onShow(scope.row.id)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
  </mainContentDiv>
</template>

<script>
export default {
  name: "classStatisticsIndex",
  data() {
    return {
      query: {
        name: "",
      },
      teachersItem: null,
      teachersList: [],
      tableData: [],
      tableColumnList: [],
    };
  },
  created() {
    this.getTeachersList();
  },
  methods: {
    async getTeachersList() {
      try {
        let { res } = await this.$ajax({
          apiKey: "finance_teacher",
          data: {},
        });
        console.log("获取班主任列表", res);
        this.teachersList = res;
        this.teachersItem = this.teachersList[0].id;
        this.getList();
      } catch (error) {
        this.$catchError(error);
      }
    },
    async getList() {
      try {
        console.log("this.teachersItem", this.teachersItem);
        let { res } = await this.$ajax({
          apiKey: "finance_teacher_class_count",
          data: {
            teacher_id: this.teachersItem,
          },
        });
        console.log("获取列表", res);

        this.tableData = res;

        // 处理动态表头
        let classColumnList = [];
        res[0].attend.forEach((item, index) => {
          classColumnList.push({
            index,
            prop: "attend",
            label: `第${index + 1}课时`,
          });
        });
        console.log("classColumnList", classColumnList);

        this.tableColumnList = [
          {
            label: "班级名称",
            prop: "name",
          },
          {
            label: "应到人数",
            prop: "student_count",
          },
          ...classColumnList,
        ];
        console.log("tableColumnList", this.tableColumnList);

        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    onTeachersItem(e) {
      this.teachersItem = e;
      this.getList();
    },

    onShow(id) {
      console.log("详情", id);

      try {
        //权限校验
        this.$com.authenCheck(this, [
          {
            key: "classStatisticsDetails",
            rejectTips: "课时统计详情",
          },
        ]);

        this.$router.push({
          name: "classStatisticsDetails",
          query: {
            id,
          },
        });
      } catch (error) {
        this.$catchError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.teachersBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  p {
    flex-shrink: 0;
    font-size: 14px;
    color: #606266;
  }

  .teachers_list {
    flex: 6;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;

    .teachers_item {
      width: 70px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      font-size: 13px;
      color: #909090;
      border: 1px solid #e5e5e5;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .teachersItemSelect {
      color: #2c6cec;
      border-color: #2c6cec;
    }
  }
}

.dateblue {
  color: #2c6cec;
}
</style>
