<!--
 * @Date         : 2020-12-12 16:26:04
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-02 17:57:46
 * @FilePath     : \leXue_manage_pc\src\views\test\testIndex.vue
-->
<template>
  <div>
1212
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>