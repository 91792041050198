<!--
 * @Description: 地图选址-腾讯地图
-->
<template>
  <div class="mapLocationBox">
    <!-- 输入框 -->
    <div
      :class="
        disabled ? 'mapLocation_input_disabled' : 'mapLocation_input_pointer'
      "
    >
      <el-input
        placeholder="请选择地址"
        v-model="checkedMapData.title"
        readonly
        :disabled="disabled"
        :size="size"
      >
        <i
          v-if="clearable"
          slot="suffix"
          class="el-input__icon el-icon-circle-close"
          @click.stop="onRemove"
        ></i>
        <el-button
          slot="append"
          icon="el-icon-map-location"
          @click="onMapLocation"
          >选取地址</el-button
        >
      </el-input>
    </div>
    <!-- 地图选址对话框 -->
    <el-dialog
      class="mapLocationDialog"
      title="选取地址"
      :visible.sync="mapLocationShow"
      width="888px"
      :append-to-body="appendToBody"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="handleClose"
    >
      <!-- 选中内容显示 -->
      <div class="showInfo">
        <div class="showInfoLine">
          <div class="showInfoLine_label">维度：</div>
          <div class="showInfoLine_value">{{ latitude || '-' }}</div>
        </div>
        <div class="showInfoLine">
          <div class="showInfoLine_label">经度：</div>
          <div class="showInfoLine_value">{{ longitude || '-' }}</div>
        </div>
        <div class="showInfoLine">
          <div class="showInfoLine_label">地址：</div>
          <div class="showInfoLine_value">{{ addressTitle || '-' }}</div>
        </div>
      </div>
      <div class="page-section">
        <!-- 搜索输入框 -->
        <div class="searchBox">
          <el-input
            placeholder="请输入地址关键字"
            v-model="searchKeyword"
            class="select-input"
            size="mini"
            @input="searchByKeywordInput"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <!-- <el-button slot="append" icon="el-icon-search" @click="onSearchByKeyword"></el-button> -->
          </el-input>
          <ul class="suggestionList" v-if="suggestionShow">
            <template v-if="suggestionList.length">
              <li
                v-for="(item, index) in suggestionList"
                :key="index"
                @click="onSetSuggestion(index)"
              >
                {{ item.address }}-{{ item.title }}
              </li>
            </template>
            <el-divider v-else>搜索不到此地址</el-divider>
          </ul>
        </div>
        <!-- 地图 -->
        <div id="container" ref="containerRef"></div>
        <!-- 定位按钮 -->
        <div class="positioning" @click.stop="onToGetLocation" title="定位">
          <i class="el-icon-place"></i>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mapLocationShow = false">取 消</el-button>
        <el-button type="primary" @click="onMapLocationSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'TxMapLocation',
  data() {
    return {
      // 选址对话框显示隐藏
      mapLocationShow: false,
      // 地图
      map: null,
      markerLayer: null,
      // 本机位置信息
      ipLocation: null,
      // 选中的经纬度，地址
      latitude: '',
      longitude: '',
      addressTitle: '',
      // 搜索
      search: null,
      suggest: null,
      searchKeyword: '', // 搜索文本
      suggestionShow: false,
      suggestionList: [], // 储存搜索后的内容
    }
  },
  // 数据双向绑定
  model: {
    prop: 'checkedMap',
    event: 'returnBack',
  },
  props: {
    // 选中的地址
    checkedMap: {
      type: Object,
      default: () => {},
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否可清空
    clearable: {
      type: Boolean,
      default: true,
    },
    // 尺寸：medium / small / mini
    size: {
      type: String,
      default: 'medium',
    },
    // 嵌套的 Dialog 必须指定
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  computed: {
    // 处理选中的地址
    checkedMapData: {
      set(val) {
        // 将子组件改变的值传递给父组件
        this.$emit('returnBack', val)
      },
      get() {
        // 获取父组件的值
        return this.checkedMap
      },
    },
  },
  methods: {
    // 显示选址对话框
    onMapLocation() {
      if (this.disabled) return
      this.mapLocationShow = true

      // 地图初始化
      this.initMap()
    },

    // 关闭对话框
    handleClose() {
      // console.log('关闭对话框')
      // 地图销毁
      this.map && this.map.destroy()
      this.selectedRemove()
      this.suggestionRemove()

      // 返回给父级
      this.$emit('mapLocationClose')
    },

    // 确定
    async onMapLocationSave() {
      if (!this.latitude && !this.longitude && !this.addressTitle)
        return this.$message.warning('请选择地点')

      this.checkedMapData.title = this.addressTitle
      this.checkedMapData.latitude = this.latitude
      this.checkedMapData.longitude = this.longitude
      // console.log('this.checkedMapData', this.checkedMapData)

      this.mapLocationShow = false
      // 返回给父级
      this.$emit('mapLocationSave', this.checkedMapData)
    },

    // 地图配置
    async initMap() {
      /* 新建一个IP定位类 */
      var ipLocation = new TMap.service.IPLocation()

      /* 获取本机位置,未给定ip地址则默认使用请求端的ip */
      const { result } = await ipLocation.locate()
      // console.log('result', result)
      this.ipLocation = result

      /* 初始化地图 */
      this.map = new TMap.Map('container', {
        // 设置地图中心点坐标
        center: new TMap.LatLng(
          this.ipLocation.location.lat,
          this.ipLocation.location.lng
        ),
        // 设置地图旋转角度
        rotation: 0,
        // 设置俯仰角度（0~45）
        pitch: 0,
        // 设置地图缩放级别
        zoom: 18,
        // 3D / 2D 显示模式控制
        viewMode: '2D',
        // 地图底图，BaseMap目前只支持 矢量底图(vector) 、卫星底图(satellite) 、路况底图(traffic) ，可以使用数组形式实现多种底图叠加。默认为 Vector ，如果传入null地图不显示任何地物
        baseMap: [
          {
            type: 'vector',
          },
        ],
      })
      // console.log('map', this.map)

      /* 创建并初始化 MultiMarker/点标注功能 */
      this.markerLayer = new TMap.MultiMarker({
        map: this.map, // 指定地图容器
        // 样式自定义
        styles: {
          // 创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myLocation: new TMap.MarkerStyle({
            width: 23, // 点标记样式宽度（像素）
            height: 23, // 点标记样式高度（像素）
            src: 'https://webapi.amap.com/theme/v1.3/markers/n/loc.png', // 图片路径
            // 焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        // 点标记数据数组
        geometries: [
          // 我的位置
          {
            styleId: 'myLocation',
            id: '2',
            position: new TMap.LatLng(
              this.ipLocation.location.lat,
              this.ipLocation.location.lng
            ),
          },
        ],
      })
      // console.log('markerLayer', markerLayer)

      /* 点击地图拾取坐标 */
      this.map.on('click', async (evt) => {
        // console.log('evt', evt)

        // 经纬度
        const latitude = evt.latLng.getLat().toFixed(6)
        const longitude = evt.latLng.getLng().toFixed(6)
        console.log('latitude', latitude)
        console.log('longitude', longitude)

        // 拾取POI
        console.log('poi', evt.poi)

        // 坐标至地址转换
        // 新建一个正逆地址解析类
        var geocoder = new TMap.service.Geocoder()
        const location = new TMap.LatLng(latitude, longitude)
        const { result } = await geocoder.getAddress({ location })
        // console.log('坐标至地址转换-result', result.address)
        // 赋值坐标，地点
        if (evt.poi) {
          this.addressTitle = result.address + '-' + evt.poi.name
        } else {
          this.addressTitle = result.address
        }
        this.latitude = latitude
        this.longitude = longitude

        // 插入坐标点
        this.addMarker(latitude, longitude)

        // 返回给父级
        this.$emit('mapLocationClick', evt)
      })

      /* 搜索配置 */
      this.searchInit()

      // 数据回填
      this.dataBackfill()

      // 返回给父级
      this.$emit('mapLocationInit')
    },

    /* 搜索配置 */
    searchInit() {
      // 新建一个地点搜索类
      this.search = new TMap.service.Search({ pageSize: 8 })
      // 新建一个关键字输入提示类
      this.suggest = new TMap.service.Suggestion({
        pageSize: 8, // 返回结果每页条目数
        regionFix: true, // 搜索无结果时是否固定在当前城市
      })
    },
    // 点击搜索（有提示默认定位第一个）
    async onSearchByKeyword() {
      if (this.suggestionList.length) {
        this.onSetSuggestion(0)
      }
    },
    // 以当前所输入关键字获取输入提示
    async searchByKeywordInput() {
      if (this.searchKeyword) {
        this.$globalFun.throttle(() => {
          this.suggest
            .getSuggestions({
              keyword: this.searchKeyword,
              location: this.map.getCenter(),
            })
            .then((result) => {
              const { data } = result
              // console.log('搜索关键字获取-data', data)
              this.suggestionList = data
              this.suggestionShow = true
            })
            .catch((error) => {
              console.error('searchByKeywordInput-err', error)
            })
        })
      } else {
        this.suggestionRemove()
      }
    },
    // 点击输入提示后，于地图中用点标记绘制该地点，并显示信息窗体，包含其名称、地址等信息
    onSetSuggestion(index) {
      const item = this.suggestionList[index]
      // console.log('item', item)
      const lat = item.location.lat.toFixed(6)
      const lng = item.location.lng.toFixed(6)

      // 设置中心点
      this.map.setCenter(new TMap.LatLng(lat, lng))

      // 插入坐标点
      this.addMarker(lat, lng)

      // 赋值坐标，地点
      this.addressTitle = item.address + '-' + item.title
      this.latitude = lat
      this.longitude = lng

      this.searchKeyword = this.addressTitle
      this.suggestionShow = false

      // 返回给父级
      this.$emit('mapLocatiSetSuggestion', item)
    },
    // 清空搜索内容
    suggestionRemove() {
      this.searchKeyword = ''
      this.suggestionList = []
      this.suggestionShow = false
    },

    // 数据回填
    dataBackfill() {
      const checkedMapData = this.checkedMapData
      if (
        checkedMapData.title &&
        checkedMapData.latitude &&
        checkedMapData.longitude
      ) {
        // 设置中心点
        this.map.setCenter(
          new TMap.LatLng(checkedMapData.latitude, checkedMapData.longitude)
        )
        // 插入坐标点
        this.addMarker(checkedMapData.latitude, checkedMapData.longitude)
        // 赋值坐标，地点
        this.addressTitle = checkedMapData.title
        this.latitude = checkedMapData.latitude
        this.longitude = checkedMapData.longitude
      } else {
        this.selectedRemove()
        this.dataRemove()
      }
    },

    // 插入坐标点
    addMarker(latitude, longitude) {
      // 添加点标记
      this.markerLayer.updateGeometries([
        {
          styleId: 'marker',
          id: '1',
          position: new TMap.LatLng(latitude, longitude),
        },
      ])
    },

    // 地址选择框清空
    onRemove() {
      this.selectedRemove()
      this.dataRemove()

      // 返回给父级
      this.$emit('mapLocationRemove')
    },
    // 清除选中的数据
    selectedRemove() {
      this.latitude = ''
      this.longitude = ''
      this.addressTitle = ''
    },
    // 清除源数据
    dataRemove() {
      this.checkedMapData = {
        title: '',
        latitude: '',
        longitude: '',
      }
    },

    // 回到当前位置
    onToGetLocation() {
      this.map.setCenter(
        new TMap.LatLng(
          this.ipLocation.location.lat,
          this.ipLocation.location.lng
        )
      )
      this.map.setZoom(18)
    },
  },
}
</script>

<style lang="scss" scoped>
.mapLocationBox {
  ::v-deep .mapLocation_input_pointer {
    .el-icon-circle-close,
    button {
      cursor: pointer !important;
    }
  }
  ::v-deep .mapLocation_input_disabled {
    .el-icon-circle-close,
    button {
      cursor: no-drop;
    }
  }
  .el-icon-circle-close {
    font-size: 18px;
  }
}

.mapLocationDialog {
  .showInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;

    .showInfoLine {
      width: 190px;
      display: flex;
      align-items: center;
      border: 1px solid #c7c7c7;
      overflow: hidden;

      .showInfoLine_label {
        background: #409eff;
        width: 77px;
        text-align: center;
        color: #fff;
        flex-shrink: 0;
      }
      .showInfoLine_value {
        flex: 6;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }

      .showInfoLine_label,
      .showInfoLine_value {
        height: 28px;
        line-height: 28px;
        font-size: 13px;
        padding: 0 10px;
      }

      &:nth-child(2) {
        margin: 0 14px;
      }
      &:nth-child(3) {
        flex: 4;
      }
    }
  }

  ::v-deep .el-dialog__body {
    padding: 10px 20px;
  }

  .page-section {
    width: 100%;
    height: 500px;
    position: relative;

    #container {
      width: 100%;
      height: 100%;
    }

    .positioning {
      cursor: pointer;
      z-index: 666666666666;
      position: absolute;
      right: 30px;
      bottom: 30px;
      width: 40px;
      height: 40px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 10px 0px rgba(33, 33, 33, 0.5);

      i {
        font-size: 24px;
        font-weight: bold;
        color: #000;
      }
    }
  }

  .searchBox {
    position: absolute;
    background: #fff;
    width: 300px;
    padding: 10px;
    z-index: 9999;
    top: 22px;
    left: 22px;
    box-shadow: 0px 0px 2px 2px #ccc9c9;

    .suggestionList {
      list-style-type: none;
      padding: 0;
      padding-top: 6px;
      margin: 0;

      li {
        border-bottom: 1px solid #eee;
        cursor: pointer;
        padding: 8px;

        &:last-child {
          border-bottom: 0;
          padding-bottom: 2px;
        }
      }
    }
  }
}
</style>
