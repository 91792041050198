<!--
 * @Description: 教学大纲
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
      initOptions_input: null,
    }
  },
  destroyed() {
    this.$bus_unique.off('updateSyllabusList')
  },
  mounted() {
    // 更新列表
    this.$bus_unique.on('updateSyllabusList', 'syllabusList', () => {
      this.$refs.tableList.init()
    })
  },
  created() {
    this.tableOptions = this.getTableOptions()
  },
  methods: {
    getTableOptions(a) {
      let dataObj = {
        authenKey: 'syllabusList',
        search: {
          inputList: [
            {
              type: 'input',
              label: '教学大纲名称',
              placeholder: '',
              value: '',
              props: 'name',
              rules: ['!null'],
            },
            {
              type: 'input',
              label: '课程体系名称',
              placeholder: '',
              value: '',
              props: 'teaching_system_name',
              rules: ['!null'],
            },
            {
              type: 'input',
              label: '教研组名称',
              placeholder: '',
              value: '',
              props: 'teaching_group_name',
              rules: ['!null'],
            },
            {
              type: 'select',
              label: '状态',
              placeholder: '',
              value: '',
              props: 'status',
              rules: ['!null'],
              options: [
                {
                  label: '待启用',
                  value: 1,
                },
                {
                  label: '启用中',
                  value: 2,
                },
                {
                  label: '已结束',
                  value: 3,
                },
              ],
            },
          ],
        },
        headers: [
          { label: '教学大纲名称', props: 'name' },
          { label: '课程体系名称', props: 'teaching_system_name' },
          { label: '教研组名称', props: 'teaching_group_name' },
          { label: '产品', props: 'product_name_str' },
          { label: '时间段', props: 'timeQuantum' },
          { label: '状态', props: 'status_str' },
        ],
        titleBtns: [
          {
            type: 'filter',
            title: '查询',
          },
          {
            title: '新增',
            authenKey: 'syllabusAdd',
            onTap: () => {
              this.$router.push({
                name: 'addOrEditSyllabus',
                query: {
                  pageType: 0,
                },
              })
            },
          },
        ],
        actionBtns: [
          {
            title: '查看',
            // color: 'deepBlue',
            authenKey: 'syllabusDetails',
            onTap: async (options) => {
              console.log('查看-options', options)
              this.$router.push({
                name: 'showSyllabus',
                query: {
                  id: options.id,
                },
              })
            },
          },
          {
            title: '编辑',
            color: 'deepBlue',
            authenKey: 'syllabusEdit',
            onTap: async (options) => {
              if (options.status_str === '已结束') {
                throw this.$catchError('已结束的教程大纲无法编辑')
              }

              this.$router.push({
                name: 'addOrEditSyllabus',
                query: {
                  pageType: 1,
                  id: options.id,
                },
              })
            },
          },
          {
            type: 'multiState', //多状态按钮
            props: 'status',
            authenKey: 'syllabusStart',
            options: [
              {
                label: '启用',
                value: 1,
                color: 'success',
              },
              {
                label: '结束',
                value: 2,
                color: 'danger',
              },
            ],
            onTap: async (btnObj, tableItem) => {
              // console.log(btnObj, tableItem)
              this.$resureDialog({
                ok: async () => {
                  console.log('tableItem', tableItem)
                  try {
                    const { id, status } = tableItem

                    if (status === 1) {
                      // 启用
                      const { msg } = await this.$ajax({
                        apiKey: 'outline_begin',
                        data: {
                          id,
                        },
                      })
                      this.$toast({
                        msg,
                      })
                    } else if (status === 2) {
                      // 结束
                      const { msg } = await this.$ajax({
                        apiKey: 'outline_end',
                        data: {
                          id,
                        },
                      })
                      this.$toast({
                        msg,
                      })
                    }
                    this.$refs.tableList.init()
                  } catch (error) {
                    this.$catchError(error)
                  }
                },
              })
            },
          },
          {
            title: '删除',
            color: 'red',
            authenKey: 'syllabusDel',
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: 'outline_del',
                data: {
                  id: options.id,
                },
              })
            },
          },
          {
            title: '统计数据',
            color: 'deepBlue',
            authenKey: 'syllabusStatistics',
            onTap: async (thisBtn) => {
              console.log('thisBtn', thisBtn)
              try {
                // 查询是否可导出
                await this.$ajax({
                  methods: 'POST',
                  apiKey: 'assess_tj_export_pd',
                  data: {
                    id: thisBtn.id,
                  },
                })

                let { res } = await this.$ajax({
                  isExportFile: true, //导出文件
                  methods: 'POST',
                  apiKey: 'assess_tj_export',
                  fileName: thisBtn.name + '统计数据.xls',
                  data: {
                    id: thisBtn.id,
                  },
                  submitByForm: true,
                })
                console.log('res', res)
              } catch (error) {
                // console.log('error', error)
                this.$catchError(error)
              }
            },
          },
        ],
        getData: async (options) => {
          try {
            console.log('获取列表', options)
            // 获取列表
            let ret = await this.$ajax({
              apiKey: 'outlineList',
              data: {
                ...options,
              },
            })
            // console.log('ret', ret.res.data)
            // 处理时间段
            for (let i of ret.res.data) {
              i.timeQuantum = `${i.begin_date} 至 ${i.end_date}`
            }

            return ret
          } catch (error) {
            this.$catchError(error)
          }
        },
      }
      return dataObj
    },
  },
}
</script>

<style lang="scss" scoped></style>
