<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-12-11 09:48:56
 * @FilePath     : \leXue_manage_pc\src\components_global\comBtn.vue
-->
<template>
  <div
    class="d-inline-block btn"
    v-if="currentColor"
    :class="size"
    :style="`color: ${currentColor.text}; background-color: ${currentColor.bg}`"
    @click="$emit('click')"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    bgcolor: {
      type: String,
      default: () => {
        return '#ebebeb'
      },
    },
    color: {
      type: String,
      default: () => {
        return 'normal'
      },
    },
    size: {
      type: String,
      default: () => {
        return 'middle'
      },
    },
  },
  computed: {
    currentColor() {
      const colorObj = {
        blue: {
          bg: '#1891ff',
          text: '#fff',
        },
        deepBlue: {
          bg: '#0177d5',
          text: '#fff',
        },
        red: {
          bg: '#f9463f',
          text: '#fff',
        },
        cyan: {
          bg: '#1aa97b',
          text: '#fff',
        },
        normal: {
          bg: '#ebebeb',
          text: '#333333',
        },
        warning: {
          bg: '#EBB563',
          text: '#FFF',
        },
      }

      return colorObj[this.color]
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: #fff;
  cursor: pointer;

  &.middle {
    line-height: 1.813rem;
  }

  &.small {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    line-height: 1.5rem;
    font-size: 0.7rem;
  }
}
</style>
