<!--
 * @Date         : 2021-03-09 16:25:45
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-10 17:44:29
 * @FilePath     : \leXue_manage_pc\src\views\other\data_productSaleRange.vue
-->

<template>
  <dataBoardBox title="各产品销售金额分布总览">
    <div class="mr-20" slot="rightTopBox">
      <!-- <el-date-picker
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker> -->
    </div>
    <div ref="productSaleRange" style="height: 400px"></div>
  </dataBoardBox>
</template>

<script>
import echarts from "echarts";
import dataBoardBox from "@/views/other/dataBoardBox.vue";

export default {
  components: {
    dataBoardBox,
  },
  data() {
    return {};
  },
    beforeDestroy() {
    this.myChart.dispose();
  },
  mounted() {
    this.myChart = echarts.init(this.$refs.productSaleRange);
    this.getStudenCount();
  },
  methods: {
    //获取班级人数总览
    async getStudenCount() {
      try {
        const { res } = await this.$ajax({
          apiKey: "getDataStatistics_productMoneyCount",
        });

        this.renderBarChart(res.data); //渲染柱状图
      } catch (error) {
        this.$catchError(error);
      }
    },

    //渲染柱状图
    renderBarChart(dataList) {
      dataList.forEach(item => item['金额'] = item.money);

      var option = {
        color: ["#fe8e02"],
        grid: {
          bottom: 100,
        },
        dataZoom: [
          {
            id: "dataZoomX",
            type: "slider",
            start: 0,
            end: 100,
            textStyle: true,
          },
        ],
        dataset: {
          dimensions: ["name", "金额"],
          source: dataList,
        },
        xAxis: {
          type: "category",
          axisLabel: {},
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {},
        series: [
          {
            type: "bar",
            barWidth: 30,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      };

      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>