<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-11-12 11:24:18
 * @FilePath     : \leXue_manage_pc\src\components_global\centerDialog.vue

中间的弹窗

 <centerDialog ref="centerDialog_authenList">
  插槽内容
</centerDialog>


js调用

this.$refs.centerDialog_authenList.render({
  title: '选择角色', //可选 | 弹窗标题
  width: "70%", //可选 | 弹窗宽度
  before: () => { //可选 | 打开弹窗之前的回调
    
  }
  ok: async () => { //可选 | 点击确定按钮的回调 | 
    await 
  },
  cancel: async () => { //可选 | 关闭弹窗的回调 | 
    await
  }
});


-->

<template>
  <!-- 
    :close-on-click-modal="
      dialogOptions.closeOnClickModal !== undefined
        ? dialogOptions.closeOnClickModal
        : true
    "
 -->
  <el-dialog
    v-if="dialogOptions !== null"
    :visible.sync="dialogOptions.isRender"
    :width="dialogOptions.width || '30%'"
    :title="dialogOptions.title"
    :append-to-body="true"
    :top="dialogOptions.top || '15vh'"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
    @close="handleTapActionBtn(0)"
  >
    <!-- :close-on-press-escape="dialogOptions.closeOnPressEscape" -->
    <slot></slot>
    <span
      slot="footer"
      class="dialog-footer"
      v-if="initOptions.cancel || initOptions.ok"
    >
      <comBtn v-if="initOptions.cancel" @click="handleTapActionBtn(0, true)"
        >取消</comBtn
      >
      <comBtn
        v-if="initOptions.ok"
        class="ml-10"
        color="blue"
        @click="handleTapActionBtn(1)"
        >确定</comBtn
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogOptions: null,
      initOptions: {},
    }
  },
  methods: {
    async render(initOptions = {}) {
      const {
        before,
        verifyInputListIsChange,
      } = (this.initOptions = initOptions)

      before && (await before())

      this.dialogOptions = {
        ...initOptions,
        isRender: true,
      }

      if (verifyInputListIsChange) {
        this.verifyInputListIsChange_rawVal = this.getCurrentInputList(
          verifyInputListIsChange
        )
      }
    },
    async handleTapActionBtn(btnType, useBeforeClose) {
      try {
        if (this.isSubmitted === true) {
          console.warn('已点击ok', '')
          return false
        }

        await this.$checkTapTooFaster()

        const { cancel, ok, complete } = this.initOptions

        this.isSubmitted = true

        clearTimeout(this.submitedTimeout)

        switch (
          btnType //0：取消  1：确定
        ) {
          case 0:
            useBeforeClose && (await this.verifyRenderCloseTips()) //检验是否需要弹出关闭前的提示
            cancel && (await cancel())
            break
          case 1:
            ok && (await ok())
            break
        }

        this.dialogOptions.isRender = false

        this.submitedTimeout = setTimeout(() => {
          this.isSubmitted = false
        }, 1000)

        complete && complete()
      } catch (error) {
        this.isSubmitted = false
        this.$catchError(error)
      }
    },

    async handleBeforeClose(done) {
      try {
        await this.verifyRenderCloseTips() //检验是否需要弹出关闭前的提示

        done && done()
      } catch (error) {
        console.error('error', error)
      }
    },

    //检验是否需要弹出关闭前的提示
    async verifyRenderCloseTips() {
      const { verifyInputListIsChange, beforClose } = this.dialogOptions

      if (verifyInputListIsChange) {
        return new Promise(async (resolve, reject) => {
          try {
            if (
              this.getCurrentInputList(verifyInputListIsChange) ===
              this.verifyInputListIsChange_rawVal
            ) {
              resolve()
            } else {
              await this.$confirm('当前操作尚未保存', '提示', {
                cancelButtonText: '关闭',
                confirmButtonClass: 'warn-class',
                confirmButtonText: '不保存',
                type: 'warning',
                showClose: false, //不显示右上角的关闭按钮
                closeOnPressEscape: false,
              })

              resolve()
            }
          } catch (error) {
            if (error === 'cancel') {
              error = null
            }
            reject(error)
          }
        })
      }

      beforClose && (await beforClose())
    },

    getCurrentInputList(inputList) {
      let inputedValList = inputList.map((item) => {
        return {
          value: item.value || '',
          id: item.id || '',
        }
      })

      return JSON.stringify(inputedValList)
    },
    show() {
      this.dialogOptions.isRender = true
    },
    hide() {
      this.dialogOptions.isRender = false
    },
  },
}
</script>

<style>
.warn-class {
  border: 1px solid red !important;
  background-color: red !important;
}
</style>
