<!--
 * @Author: WZC
 * @Date: 2022-11-21 10:37:39
 * @FilePath: /src/views/customer/fileInfo.vue
 * @Description: 档案资料录入
-->
<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      class="FormDialogBox fileInfoFormDialogBox"
      width="990px"
      title="档案资料"
      v-if="dialogVisible"
      destroy-on-close
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <el-form
        inline
        :model="form"
        :rules="formRules"
        ref="formRef"
        label-width="auto"
        label-position="top"
        size="small"
      >
        <el-form-item label="学生名称：">
          <el-input :value="form.name" readonly></el-input>
        </el-form-item>
        <el-form-item label="班级：">
          <el-input :value="form.class || '-'" readonly></el-input>
        </el-form-item>
        <el-form-item label="学校：">
          <el-input :value="form.school_info.name" readonly></el-input>
        </el-form-item>
        <el-form-item label="校区：">
          <el-input :value="form.school_info.xq_name" readonly></el-input>
        </el-form-item>
        <el-form-item label="已读第几期：" prop="yddjq">
          <el-input v-model="form.yddjq" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="营销顾问：" prop="yxgw_teacher_id">
          <el-select
            v-model="form.yxgw_teacher_id"
            placeholder="请选择"
            filterable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in teacherList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学管师：" prop="xgs_teacher_id">
          <el-select
            v-model="form.xgs_teacher_id"
            placeholder="请选择"
            filterable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in teacherList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="外在形象：" prop="wzxx" style="width: 100%">
          <el-input
            v-model="form.wzxx"
            placeholder="说明是否适合上镜"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item label="普通话能力：" prop="pthnl" style="width: 100%">
          <el-input
            v-model="form.pthnl"
            placeholder="说明是否适合上镜"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item label="孩子对课程喜爱程度：" prop="kcxacd">
          <div class="formItemLabelDiv">课堂表现是否积极（1-10分）</div>
          <el-input-number
            v-model="form.kcxacd"
            :min="1"
            :max="10"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="孩子对视频出镜热情：" prop="spcjrq">
          <div class="formItemLabelDiv">参与录制视频的热情度（1-10分）</div>
          <el-input-number
            v-model="form.spcjrq"
            :min="1"
            :max="10"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="是否参与过校园TV (如有的请登记)："
          prop="cyxytvqk"
          style="width: 100%"
        >
          <!-- <div class="multipleLinesBox">
            <div
              class="multipleLines_item"
              v-for="(item, index) in form.list1"
              :key="index"
            >
              <span>{{ index + 1 }}、</span>
              <el-input
                v-model="form.list1[index]"
                placeholder="请输入"
              ></el-input>
              <i
                v-if="index > 0"
                class="el-icon-delete"
                @click="multipleLinesItemDel('list1', index)"
              ></i>
            </div>
            <el-button type="primary" @click="multipleLinesAdd('list1')"
              >新增</el-button
            >
          </div> -->
          <el-input
            v-model="form.cyxytvqk"
            placeholder="请输入"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否参与过广播站 (如有的请登记)："
          prop="cygbzqk"
          style="width: 100%"
        >
          <el-input
            v-model="form.cygbzqk"
            placeholder="请输入"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否参与过研学活动："
          prop="yxhd"
          style="width: 100%"
        >
          <el-input
            v-model="form.yxhd"
            placeholder="请输入"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否参与过舞台展示："
          prop="wtzs"
          style="width: 100%"
        >
          <el-input
            v-model="form.wtzs"
            placeholder="请输入"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否参与过朗诵考级："
          prop="lskj"
          style="width: 100%"
        >
          <el-input
            v-model="form.lskj"
            placeholder="请输入"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item label="其他经历：" prop="qtjl" style="width: 100%">
          <el-input
            v-model="form.qtjl"
            placeholder="请输入"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item label="学校班级的职位：" prop="bjzw" style="width: 100%">
          <el-input
            v-model="form.bjzw"
            placeholder="大队委/班长等"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="对孩子学习课程的需求："
          prop="xxkcxq"
          style="width: 100%"
        >
          <el-input
            v-model="form.xxkcxq"
            placeholder="营销顾问填写"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="学管师回访记录："
          prop="xgs_hfjl"
          style="width: 100%"
        >
          <el-input
            v-model="form.xgs_hfjl"
            placeholder="请输入"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div class="dialog-footer-l">
          <el-button type="warning" size="mini" @click="onExport"
            >导 出</el-button
          >
        </div>
        <div class="dialog-footer-r">
          <el-button type="danger" size="mini" @click="dialogVisible = false"
            >关 闭</el-button
          >
          <el-button type="primary" size="mini" @click="onSubmit"
            >确 定</el-button
          >
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import appConfig from "@/assets/js/appConfig.js";

export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        yddjq: "",
        wzxx: "",
        pthnl: "",
        kcxacd: "",
        spcjrq: "",
        cyxytvqk: "",
        cygbzqk: "",
        yxhd: "",
        wtzs: "",
        lskj: "",
        qtjl: "",
        bjzw: "",
        xxkcxq: "",
        xgs_hfjl: "",
        yxgw_teacher_id: "",
        xgs_teacher_id: "",
        // list2: [""],
      },
      formRules: {
        // yddjq: [
        //   {
        //     required: true,
        //     message: "请输入已读第几期",
        //     trigger: "blur",
        //   },
        // ],
        // yxgw_teacher_id: [
        //   {
        //     required: true,
        //     message: "请选择营销顾问",
        //     trigger: "change",
        //   },
        // ],
        // xgs_teacher_id: [
        //   {
        //     required: true,
        //     message: "请选择学管师",
        //     trigger: "change",
        //   },
        // ],
        // wzxx: [
        //   {
        //     required: true,
        //     message: "请输入外在形象",
        //     trigger: "blur",
        //   },
        // ],
        // pthnl: [
        //   {
        //     required: true,
        //     message: "请输入普通话能力",
        //     trigger: "blur",
        //   },
        // ],
        // kcxacd: [
        //   {
        //     required: true,
        //     message: "请输入孩子对课程喜爱程度",
        //     trigger: "blur",
        //   },
        // ],
        // spcjrq: [
        //   {
        //     required: true,
        //     message: "请输入孩子对视频出镜热情",
        //     trigger: "blur",
        //   },
        // ],
        // cyxytvqk: [
        //   {
        //     required: true,
        //     message: "请输入是否参与过校园TV",
        //     trigger: "blur",
        //   },
        // ],
        // cygbzqk: [
        //   {
        //     required: true,
        //     message: "请输入是否参与过广播站",
        //     trigger: "blur",
        //   },
        // ],
        // yxhd: [
        //   {
        //     required: true,
        //     message: "请输入是否参与过研学活动",
        //     trigger: "blur",
        //   },
        // ],
        // wtzs: [
        //   {
        //     required: true,
        //     message: "请输入是否参与过舞台展示",
        //     trigger: "blur",
        //   },
        // ],
        // lskj: [
        //   {
        //     required: true,
        //     message: "请输入是否参与过朗诵考级",
        //     trigger: "blur",
        //   },
        // ],
        // qtjl: [
        //   {
        //     required: true,
        //     message: "请输入其他经历",
        //     trigger: "blur",
        //   },
        // ],
        // bjzw: [
        //   {
        //     required: true,
        //     message: "请输入学校班级的职位",
        //     trigger: "blur",
        //   },
        // ],
        // xxkcxq: [
        //   {
        //     required: true,
        //     message: "请输入对孩子学习课程的需求",
        //     trigger: "blur",
        //   },
        // ],
        // xgs_hfjl: [
        //   {
        //     required: true,
        //     message: "请输入学管师回访记录",
        //     trigger: "blur",
        //   },
        // ],
      },
      teacherList: [],
    };
  },
  created() {},
  methods: {
    async onSubmit() {
      // 验证-基本信息
      let formValidate = await this.$refs.formRef.validate();
      if (!formValidate) return false;
      console.log("form-ok", this.form);

      try {
        let { msg, res } = await this.$ajax({
          apiKey: "saveArchivesInternal",
          data: this.form,
        });
        this.$toast({
          msg,
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    onExport() {
      this.$confirm("是否导出？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let { res } = await this.$ajax({
            apiKey: "archivesInternalExport",
            data: {
              id: this.form.id,
            },
          });
          // console.log("res", res.path);

          saveAs(
            appConfig.httpDomainName + res.path,
            this.form.name + "-档案资料.pdf"
          );
          this.$message.success("导出成功");
        })
        .catch(() => {});
    },

    // 获取老师列表
    async getteacherList() {
      try {
        let { res } = await this.$ajax({
          apiKey: "teacherServiceList",
          data: {
            paginate: 100,
            status: 1,
          },
        });
        console.log("获取老师列表", res);
        this.teacherList = res.data;
      } catch (error) {
        this.$catchError(error);
      }
    },

    // multipleLinesItemDel(type, index) {
    //   this.$confirm("是否删除？", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).then(async () => {
    //     let dataItem = this.form[type];
    //     dataItem.splice(index, 1);
    //   });
    // },
    // multipleLinesAdd(type) {
    //   let dataItem = this.form[type];
    //   if (!dataItem[dataItem.length - 1]) {
    //     return this.$message.warning(`请勿放空！`);
    //   }
    //   dataItem.push("");
    // },

    async _show(id) {
      this.getteacherList();

      const { res } = await this.$ajax({
        apiKey: "getStudentDetails",
        data: {
          id,
        },
      });
      console.log("获取详情", res);

      this.form = Object.assign(res[0].internal, {
        id: res[0].id,
        name: res[0].name,
        class: res[0].class,
        school_info: res[0].school_info,
      });
      console.log("form", this.form);
      if (this.form.yxgw_teacher_id) {
        this.form.yxgw_teacher_id = Number(this.form.yxgw_teacher_id);
      }
      if (this.form.xgs_teacher_id) {
        this.form.xgs_teacher_id = Number(this.form.xgs_teacher_id);
      }

      this.dialogVisible = true;
    },

    dialogClose() {
      this.$refs.formRef.resetFields();
      this.form = this.$options.data().form;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .fileInfoFormDialogBox {
  .el-dialog__body {
    background: #fff !important;
    padding: 0 30px 10px;

    .el-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .el-form-item {
        width: 48%;
        margin-right: 0;

        .formItemLabelDiv {
          font-size: 12px;
          color: #999;
          margin-top: -12px;
          margin-bottom: 4px;
        }
      }
    }

    .el-form--label-top .el-form-item__label {
      padding-bottom: 2px !important;
    }

    .el-select {
      width: 100%;
    }
  }

  .el-dialog__footer {
    background: #eee;
    padding: 20px;
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.multipleLinesBox {
  .multipleLines_item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    i {
      margin-left: 10px;
      font-size: 20px;
      color: red;
      cursor: pointer;
    }
  }
}
</style>
