<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-10 10:49:33
 * @FilePath     : \leXue_manage_pc\src\views\customer\parentHistoryServicerList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>

    <!-- 新增/编辑家长 -->
    <addOrEditParent ref="addOrEditParent"></addOrEditParent>
  </mainContentDiv>
</template>

<script>
import addOrEditParent from "./addOrEditParent.vue";

export default {
  components: {
    addOrEditParent,
  },
  props: {
    initOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableOptions: null,
    };
  },
  mounted() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    getTableOptions() {
      let configureOptions = {
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
        ],

        actionBtns: [
          {
            title: "删除",
            color: "red",
            authenKey: "parent_delHistoryTeacher",
            onDelete: async (options) => {
              return await this.$ajax({
                responseAll: true,
                apiKey: "delHistoryServicer",
                data: {
                  id: options.id,
                },
              });
            },
          },
        ],
      };

      return {
        authenKey: "parent_historyTeacherList",
        search: {
          inputList: [
            {
              type: "input",
              label: "客服名称",
              placeholder: "",
              value: "",
              props: "name",
              rules: ["!null"],
            },
          ],
        },
        headers: [
          { label: "客服名称", props: "teacher_name" },
          { label: "添加时间", props: "created_at" },
        ],
        ...configureOptions,
        getData: async (options) => {
          const { parentID } = this.initOptions;
          const ret = await this.$ajax({
            apiKey: "getHistoryServicerList",
            data: {
              ...options,
              pid: parentID || "",
            },
          });
          return ret;
        },
      };
    },
  },
};
</script>
