<!--
 * @Description: 券码列表
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
    <!-- 发送券码 -->
    <centerDialog ref="couponSendDialogRef">
      <inputCenter :initOptions="couponSendOptionsInput"></inputCenter>
    </centerDialog>
    <!-- 生成二维码 -->
    <centerDialog ref="qrCodeDialogRef">
      <div class="qrCodeDialogBox">
        <div ref="qrcodeRef" id="qrcode"></div>
        <p>
          <strong>{{ qrCodeDialogItem.coupon_sn }}</strong>
        </p>
        <p>
          满 {{ qrCodeDialogItem.consumption }} 减
          {{ qrCodeDialogItem.coupon_price }}
        </p>
      </div>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  components: {},
  data() {
    return {
      tableOptions: null,
      couponSendOptionsInput: null,
      qrCodeDialogItem: {
        name: "",
        coupon_sn: "",
      },
      qrcode: "",
    };
  },
  destroyed() {},
  mounted() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    getTableOptions() {
      let getTableOptions = {
        authenKey: "couponCodeList",
        search: {
          inputList: [
            {
              type: "input",
              label: "优惠券名称",
              placeholder: "",
              value: "",
              props: "title",
              rules: ["!null"],
            },
            {
              type: "select",
              label: "状态",
              placeholder: "",
              value: 0,
              props: "status",
              rules: ["!null"],
              options: [
                {
                  label: "全部",
                  value: 0,
                },
                {
                  label: "未使用",
                  value: 1,
                },
                {
                  label: "已使用",
                  value: 2,
                },
                {
                  label: "已过期",
                  value: 3,
                },
              ],
            },
          ],
        },
        headers: [
          { label: "名称", props: "title" },
          { label: "面额", props: "coupon_price" },
          { label: "满多少可用", props: "consumption" },
          { label: "有效期开始时间", props: "start_time" },
          { label: "有效期结束时间", props: "end_time" },
          { label: "状态", props: "status_str" },
          { label: "领券时间", props: "get_time" },
          { label: "优惠券码", props: "coupon_sn" },
          { label: "使用时间", props: "used_time" },
          { label: "绑定用户名称", props: "name" },
          { label: "绑定用户手机号", props: "mobile" },
        ],
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
        ],
        actionBtns: [
          {
            title: "删除",
            color: "red",
            authenKey: "couponCodeDel",
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: "couponSnDel",
                data: {
                  id: options.id,
                },
              });
            },
          },
          {
            title: "发送券码",
            color: "deepBlue",
            authenKey: "couponCodeSend",
            onTap: async (options) => {
              console.log("options", options);
              if (options.user_id > 0 || options.status !== 1) {
                return this.$message.error(`此券码已失效`);
              }
              await this.couponSendDialog(options.id);
              this.$refs.tableList.init();
            },
          },
          {
            title: "生成二维码",
            color: "deepBlue",
            authenKey: "couponCodeSend",
            onTap: async (options) => {
              console.log("options", options);
              if (options.user_id > 0 || options.status !== 1) {
                return this.$message.error(`此券码已失效`);
              }
              this.qrCodeDialogItem = options;

              await this.$refs.qrCodeDialogRef.render({
                title: options.title,
                width: "500px",
              });

              this.$nextTick(() => {
                if (this.qrcode) {
                  // 有新的二维码地址了，先把之前的清除掉
                  this.$refs.qrcodeRef.innerHTML = "";
                }
                this.qrcode = new QRCode(this.$refs.qrcodeRef, {
                  text: options.coupon_sn,
                  width: "128",
                  height: "128",
                });
              });
            },
          },
        ],
        getData: async (options) => {
          const ret = await this.$ajax({
            apiKey: "couponSnList",
            data: options,
          });

          return ret;
        },
      };

      return getTableOptions;
    },

    // 弹出发送券码
    couponSendDialog(id) {
      try {
        let inputOptions = (this.couponSendOptionsInput = {
          inputList: this.getInputcouponSend(),
        });

        return new Promise((resovle, reject) => {
          this.$refs.couponSendDialogRef.render({
            title: "发送券码",
            width: "500px",
            ok: async () => {
              try {
                const { inputList } = inputOptions;

                let requestOpt = this.$com.getValueByRules(inputList);
                requestOpt.id = id;
                // console.log("requestOpt", requestOpt);

                const { msg } = await this.$ajax({
                  apiKey: "couponSnSend",
                  data: requestOpt,
                });

                this.$toast({
                  msg,
                });

                resovle();
              } catch (error) {
                reject(error);
                throw error;
              }
            },
          });
        });
      } catch (error) {
        this.$catchError(error);
      }
    },
    //获取输入配置表
    getInputcouponSend(inputedValueObj) {
      let _tempValObj = (this._tempValObj = {}); //临时变量

      let getInputAddOrEdit = [
        //家长名称
        (_tempValObj.parent = {
          type: "select",
          label: "家长名称",
          placeholder: "请搜索/选择家长名称",
          rules: ["required", "!null"],
          value: "",
          id: "",
          props: "parent_name",
          props_id: "pid",
          options: [],
          remoteMethod: async (keyword) => {
            try {
              const {
                res: { data },
              } = await this.$ajax({
                apiKey: "getParentList",
                data: {
                  keyword: keyword || "",
                },
              });

              _tempValObj.parent.options = data.map((item) => {
                const { id, name, mobile } = item;

                return {
                  label: `${name}(${mobile})`,
                  value: id,
                };
              });
            } catch (error) {
              this.$catchError(error);
            }
          },
          onChange: (val, thisObj) => {
            thisObj.id = val;
          },
          onFocus: (event, thisObj) => {
            //如果选择的数据非法，则使用默认获取的所有options
            this.tryReInputAllSelectOptions(thisObj);
          },
        }),
      ];

      _tempValObj.parent.remoteMethod();

      return getInputAddOrEdit;
    },
  },
};
</script>

<style lang="scss">
.FormDialogBox {
  .el-dialog__body {
    height: 94%;
    padding: 0 10px 20px;
    overflow: hidden;
    background: #f4f4f4;
  }
}

.qrCodeDialogBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  #qrcode {
    margin-bottom: 20px;
  }
}
</style>
