<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-03 14:02:08
 * @FilePath: /src/components/sideBar.vue

 侧边导航栏
 
-->

<template>
  <div class="d-flex flex-column overflow-hidden side-bar">
    <div
      class="d-flex align-items-center py-30 pl-20 overflow-hidden cursor-pointer home-btn"
      @click="handleRouterToHome()"
    >
      <div class="side-title">乐学后台系统</div>
    </div>
    <div class="col overflow-hidden side-nav-list">
      <div class="h-100" style="margin-right: -21px; overflow-y: scroll">
        <el-menu
          class="overflow-hidden el-menu-vertical-demo"
          @select="handelSelect"
          background-color="#333744"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <div v-for="(item, index) in sideList" :key="'sideList' + index">
            <template v-if="item.children && item.children.length > 0">
              <el-submenu :index="item.pathName">
                <template slot="title">
                  <div class="d-flex align-items-center">
                    <i class="mr-10 iconfont" :class="item.iconfont"></i>
                    <span class="position-relative nav-item nav-class-item">
                      {{ item.title }}
                      <span
                        class="position-absolute qty-icon"
                        v-if="computeSideClassTotallQty(item)"
                        >{{ computeSideClassTotallQty(item) }}</span
                      >
                    </span>
                  </div>
                </template>

                <el-menu-item-group>
                  <el-menu-item
                    v-for="(itemChild, indexChild) in item.children"
                    :key="'items' + indexChild"
                    :index="itemChild.pathName"
                  >
                    <span class="position-relative nav-item">
                      <span>{{ itemChild.title }}</span>
                      <span
                        class="position-absolute qty-icon"
                        v-if="sideNavQtyObj[itemChild.props]"
                        >{{ itemChild.propsLabel || ""
                        }}{{ sideNavQtyObj[itemChild.props] }}</span
                      >
                    </span>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </template>
          </div>
          <!-- 配置看 kaola-project-admin（考拉项目管理系统）文档 -->
          <el-menu-item @click="goQuestion">
            <i class="el-icon-question"></i>
            <span slot="title">帮助中心</span>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from "@/assets/js/CryptoJS.js";

export default {
  data() {
    return {
      // 侧边栏数量值
      sideNavQtyObj: {},
    };
  },
  mounted() {
    //监听：更新未审核订单数量
    this.$bus_unique.on("updateUnCheckOrderQty", "sideBar", () => {
      this.setRefundOrderNoCheckQty();
    });
  },

  methods: {
    goQuestion() {
      // console.log(this.userInfo)
      const dataItem = {
        name: "乐学后台管理系统",
        code: "LXGL",
        userName: this.userInfo.name,
        userMobile: this.userInfo.mobile,
      };
      const encryptData = CryptoJS.encrypt(JSON.stringify(dataItem));
      window.open("https://help.ozkoalas.cn?dataItem=" + encryptData);
    },

    //设置未审核的退款订单数量
    async setRefundOrderNoCheckQty() {
      try {
        const { res } = await this.$ajax({
          apiKey: "getRefundOrderNoCheckeQty",
        });

        this.$set(this.sideNavQtyObj, "unRefundOrderQty", res.count);
      } catch (error) {
        this.$catchError(error);
      }
    },

    handelSelect(pathName) {
      this.$pageStack.getStack().length = 0; //销毁所有页面栈

      console.info("pathName", pathName);

      this.$router.push({
        name: pathName,
      });
    },

    //计算当前分类下有多少个未读消息
    computeSideClassTotallQty(thisSideClass) {
      const sideNavQtyObj = this.sideNavQtyObj;

      if (Object.keys(sideNavQtyObj).length <= 0) {
        return false;
      }

      let totalQty = 0;

      thisSideClass.children.forEach((item) => {
        const { props } = item;
        const value = sideNavQtyObj[props];

        if (value) {
          totalQty += value;
        }
      });

      return totalQty;
    },

    handleRouterToHome() {
      this.$bus_unique.emit("backToHome");
    },
  },
  computed: {
    sideList() {
      const getters = this.$store.getters;
      const isLoadedAdminDetails = getters.isLoadedAdminDetails;
      const getAuthenIsPermitted = getters.getAuthenIsPermitted;

      if (!isLoadedAdminDetails()) {
        return [];
      }

      let sideList = [
        {
          title: "班级管理",
          pathName: "班级管理",
          iconfont: "icon-studentClass",
          children: [
            {
              title: "班级列表",
              authenKey: "class_list",
              pathName: "classList",
            },
          ],
        },
        {
          title: "订单管理",
          pathName: "订单管理",
          iconfont: "icon-order",
          props: "unRefundOrderQty",
          children: [
            {
              title: "订单列表",
              authenKey: "order_list",
              pathName: "orderList",
            },
            {
              title: "退款订单列表",
              authenKey: "order_refundRecordList",
              pathName: "refundOrderList",
              props: "unRefundOrderQty",
              propsLabel: "待审核",
            },
          ],
        },
        {
          title: "财务管理",
          pathName: "财务管理",
          iconfont: "icon-chart",
          children: [
            {
              title: "财务统计报表",
              authenKey: "finance_list",
              pathName: "financeIndex",
            },
            {
              title: "课时统计",
              authenKey: "classStatisticsList",
              pathName: "classStatisticsIndex",
            },
          ],
        },
        {
          title: "产品管理",
          pathName: "产品管理",
          iconfont: "icon-class",
          children: [
            {
              title: "单产品列表",
              authenKey: "goods_list",
              pathName: "goodsList",
            },
            // {
            //   title: "产品组合列表",
            //   authenKey: "goods_list",
            //   pathName: "mutilGoodsList",
            // },
          ],
        },
        {
          title: "学校管理",
          pathName: "学校管理",
          iconfont: "icon-school",
          children: [
            {
              title: "学校列表",
              authenKey: "school_list",
              pathName: "schoolList",
            },
          ],
        },
        {
          title: "客户管理",
          pathName: "客户管理",
          iconfont: "icon-user",
          children: [
            {
              title: "家长列表",
              authenKey: "parent_list",
              pathName: "parentList",
            },
            {
              title: "学生列表",
              authenKey: "student_list",
              pathName: "studentList",
            },
            {
              title: "学生与家长关系",
              authenKey: "studentAndParentRelation_list",
              pathName: "relationshipList",
            },
            {
              title: "学生卡管理",
              authenKey: "studentCardList",
              pathName: "studentCardList",
            },
          ],
        },
        {
          title: "会员管理",
          pathName: "会员管理",
          iconfont: "icon-user",
          children: [
            {
              title: "会员资料",
              authenKey: "member_list",
              pathName: "memberList",
            },
            {
              title: "会员等级",
              authenKey: "memberLevel_list",
              pathName: "memberLevel",
            },
            {
              title: "余额课时充值",
              authenKey: "Classhour_list",
              pathName: "classhourList",
            },
            {
              title: "（废弃）钱包充值规则",
              authenKey: "memberRechargeRules_list",
              pathName: "rechargeRuleList",
            },
            {
              title: "（废弃）钱包充值",
              authenKey: "memberRecharge_list",
              pathName: "recharge",
            },
            {
              title: "（废弃）余额课包充值规则",
              authenKey: "course_packRules_list",
              pathName: "coursePackPechargeRuleList",
            },
            {
              title: "（废弃）余额课包充值",
              authenKey: "course_pack_list",
              pathName: "coursePackRecharge",
            },
          ],
        },
        {
          title: "教师管理",
          pathName: "教师管理",
          iconfont: "icon-user",
          children: [
            {
              title: "教师列表",
              authenKey: "teacherServiceList",
              pathName: "teacherServiceList",
            },
            {
              title: "部门管理",
              authenKey: "departmentList",
              pathName: "teacherDepartment",
            },
            {
              title: "操作日志",
              authenKey: "teacherServiceLog",
              pathName: "teacherLog",
            },
          ],
        },
        {
          title: "教学管理",
          pathName: "教学管理",
          iconfont: "icon-class",
          children: [
            {
              title: "教学大纲",
              authenKey: "syllabusList",
              pathName: "syllabus",
            },
            {
              title: "教研组列表",
              authenKey: "investigatingList",
              pathName: "investigating",
            },
            {
              title: "课程体系",
              authenKey: "curriculumSystemList",
              pathName: "curriculumSystem",
            },
            {
              title: "考核模板",
              authenKey: "inspectionTemplateDetails",
              pathName: "inspectionTemplate",
            },
          ],
        },
        {
          title: "排课管理",
          pathName: "排课管理",
          iconfont: "icon-class",
          children: [
            {
              title: "排课表列表",
              authenKey: "arrangingTableList",
              pathName: "arrangingTable",
            },
          ],
        },
        {
          title: "教务小程序管理",
          pathName: "教务小程序管理",
          iconfont: "icon-class",
          children: [
            {
              title: "项目模板",
              authenKey: "projectTemplateList",
              pathName: "projectTemplate",
            },
            {
              title: "任务概述",
              authenKey: "taskDescriptionList",
              pathName: "taskDescription",
            },
          ],
        },
        {
          title: "优惠券管理",
          pathName: "优惠券管理",
          iconfont: "icon-class",
          children: [
            {
              title: "优惠券列表",
              authenKey: "couponList",
              pathName: "couponList",
            },
            {
              title: "券码列表",
              authenKey: "couponCodeList",
              pathName: "couponCodeList",
            },
          ],
        },
        {
          title: "系统管理",
          pathName: "系统管理",
          iconfont: "icon-setting",
          children: [
            {
              title: "角色管理",
              authenKey: "role_list",
              pathName: "authenList",
            },
            {
              title: "管理员管理",
              authenKey: "admin_list",
              pathName: "adminList",
            },
            {
              title: "退款理由",
              authenKey: "refundReason_list",
              pathName: "refundReasonList",
            },
            {
              title: "操作记录",
              authenKey: "operateLog",
              pathName: "operateLog",
            },
          ],
        },
      ];

      let filterSideList = (authenList) => {
        return authenList.filter((item) => {
          const { authenKey, children } = item;

          if (children) {
            item.children = filterSideList(children);
          }

          return authenKey ? getAuthenIsPermitted(authenKey) === true : true;
        });
      };

      let afterFilter = filterSideList(sideList);

      if (getAuthenIsPermitted("order_refund_check")) {
        //设置未审核的退款订单数量
        this.setRefundOrderNoCheckQty();
      }

      return afterFilter;
    },
    userInfo() {
      return this.$store.getters.getUserInfoByStore || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.home-btn {
  color: #fff;
}

.side-bar {
  left: 0;
  bottom: 0;
  background-color: #333744;
  width: 13rem;

  .side-title {
    font-size: 1.24rem;
    font-weight: bold;
    color: #ffffff;
  }

  .side-item {
    height: 3.5rem;
    color: rgba(255, 255, 255, 0.4);

    &.active {
      background-color: #424858;
      color: #fff;
    }
  }

  .iconfont {
    font-weight: 500;
    color: #fff;
  }
}

.side-nav-list {
  .nav-item {
    .qty-icon {
      top: 0;
      right: 0;
      transform: translate3d(100%, -70%, 0);
      min-width: 1rem;
      min-height: 1rem;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      border-radius: 0.5rem;
      line-height: initial;
      background-color: red;
      text-align: center;
      font-size: 0.6rem;
      color: #fff;
    }
  }

  .nav-class-item .qty-icon {
    top: 18px;
  }
}
</style>
