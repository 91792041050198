<!--
 * @Date         : 2020-12-07 15:55:35
 * @LastEditors  : cxx
 * @LastEditTime : 2020-12-08 14:29:20
 * @FilePath     : \leXue_manage_pc\src\views\goods\addOrEditMutilGoods.vue
-->
<template>
  <div class="h-100 d-flex flex-column">
    <mainContentDiv class="col d-flex flex-column">
      <div class="w-50 pa-20">
        <div v-for="(item, index) in initOptions_input.inputList" :key="index">
          <div
            class="my-10 com-title"
            :class="{
              active:
                item.rules && item.rules.find((item) => item === 'required'),
            }"
          >
            {{ item.label }}
          </div>
          <!-- 输入框 -->
          <textFile :initOptions="item"> </textFile>
        </div>
      </div>

      <div class="col pa-20">
        <tableList
          ref="tableList"
          v-if="tableOptions"
          :initOptions="tableOptions"
        ></tableList>

        <!-- 弹窗：选择产品 -->
        <centerDialog ref="centerDialog_chooseGoods">
          <div style="height: 60vh" v-if="initOptions_goods">
            <goodsList
              ref="goodsList"
              :initOptions="initOptions_goods"
            ></goodsList>
          </div>
        </centerDialog>

        <!-- 弹窗：选择sku -->
        <centerDialog ref="centerDialog_chooseSku">
          <div style="height: 60vh" v-if="initOptions_sku">
            <selectSkuByGoodsID
              ref="selectSkuByGoodsID"
              :initOptions="initOptions_sku"
            ></selectSkuByGoodsID>
          </div>
        </centerDialog>
      </div>
    </mainContentDiv>

    <!-- 模块：底部操作按钮 -->
    <footerBtnModules :btnList="curentPageFooterBtnList" />
  </div>
</template>

<script>
import goodsList from "@/views/goods/goodsList.vue";
import selectSkuByGoodsID from "@/views/goods/selectSkuByGoodsID.vue";
import footerBtnModules from "@/components/footerBtnModules.vue";

export default {
  components: {
    goodsList,
    selectSkuByGoodsID,
    footerBtnModules,
  },
  data() {
    return {
      initOptions_input: {
        inputList: [
          {
            type: "input",
            label: "组合名称",
            placeholder: "请输入组合名称",
            maxLength: 40,
            rules: ["required", "!null"],
            value: null,
            props: "name",
            col: "col-12",
          },
          {
            type: "input",
            label: "价格",
            placeholder: "请输入价格",
            maxLength: 40,
            rules: ["required", "!null", "num"],
            value: null,
            props: "price", //price	是	decimal	价格
            col: "col-12",
          },
        ],
      },
      initOptions_goods: null,
      initOptions_sku: null,
      tableOptions: null,
      selectedGoodsList: [],
    };
  },
  async mounted() {
    this.tableOptions = this.getTableOptions();

    let { pageType, id } = this.$route.query;

    this.pageType = pageType = Number(pageType);

    // pageType 1: 编辑 2：详情
    if (pageType === 1 || pageType === 2) {
      const { res } = await this.$ajax({
        apiKey: "getMutilProductInfo",
        data: {
          mul_id: id, //mul_id	是	int	组合产品id
        },
      });

      const goodsConposeInfo = res[0];

      //填充上次的数据
      this.$com.fillData({
        inputList: this.initOptions_input.inputList,
        valueListObj: goodsConposeInfo,
      });

      let productList = this.selectedGoodsList;

      //sku解析
      goodsConposeInfo.product.forEach((item) => {
        const { name, id: goodsID, sku } = item;

        sku.forEach((item) => {
          const {
            id: skuID,
            classify_name,
            grade_name,
            spec_name,
            attr_name,
            price,
          } = item;

          productList.push({
            name,
            goodsID,
            skuID,
            classify_name,
            grade_name,
            spec_name,
            attr_name,
            price,
          });
        });
      });
    }
  },
  methods: {
    //弹窗选择属性
    handleSelectAttrByDialog() {
      this.initOptions_goods = {
        isSelect: true,

        //选择产品
        onSelect: (optionsGoods) => {
          console.info("选择optionsGoods", optionsGoods.name);

          this.initOptions_sku = {
            getDataID: optionsGoods.id,
            skuList: optionsGoods.sku,

            //根据产品选择sku
            onSelect: (optionsSku) => {
              try {
                const { id: goodsID, name } = optionsGoods;
                const {
                  id: skuID,
                  classify_name,
                  grade_name,
                  spec_name,
                  attr_name,
                  price,
                } = optionsSku;

                const toAddGoods = {
                  goodsID,
                  name,
                  skuID,
                  classify_name,
                  grade_name,
                  spec_name,
                  attr_name,
                  price,
                };

                let selectedGoodsList = this.selectedGoodsList;

                //检验是否存在改sku
                const existedSkuObj = selectedGoodsList.find((item) => {
                  for (let key in toAddGoods) {
                    if (item[key] !== toAddGoods[key]) {
                      return false;
                      break;
                    }
                  }
                  return true;
                });

                console.info("existedSkuObj", existedSkuObj);

                if (existedSkuObj) {
                  throw "请勿重复添加";
                }

                //赋值
                selectedGoodsList.push(toAddGoods);

                this.$refs.centerDialog_chooseGoods.hide(); //关闭弹窗：选择产品
                this.$refs.centerDialog_chooseSku.hide(); //关闭弹窗：选择sku
              } catch (error) {
                this.$catchError(error);
              }
            },
          };

          //弹出：选择规格
          this.$refs.centerDialog_chooseSku.render({
            title: "选择规格",
            width: "800px",
            cancel: () => {
              this.initOptions_sku = null; // 销毁选择商品实例
            },
          });
        },
      };

      //弹出：选择产品
      this.$refs.centerDialog_chooseGoods.render({
        title: "选择产品",
        width: "800px",
        cancel: () => {
          this.initOptions_goods = null; // 销毁选择商品实例
        },
      });
    },

    async handleSubmit(id) {
      try {
        const toSubmitOptions = this.$com.getValueByRules(
          this.initOptions_input.inputList
        );
        const skuList = this.selectedGoodsList.map((item) => {
          return {
            pro_id: item.goodsID, //pro_id	是	int	产品id
            sku_id: item.skuID, //sku_id	是	int	sku id
          };
        });

        if (skuList.length <= 0) {
          throw "请至少选择一个产品";
        }

        if (id) {
          toSubmitOptions.mul_id = id; //mul_id	否	int	组合产品id 无参数为新增
        }

        toSubmitOptions.pros_skus = JSON.stringify(skuList); //pros_skus	是	array	[ { pro_id , sku_id } , { … } ]

        const { msg } = await this.$ajax({
          apiKey: "addOrEditMutilProduct",
          data: toSubmitOptions,
        });

        this.$bus_unique.emit("updateMutilGoodsList");
        this.$router.back(-1);
        this.$toast({
          color: "success",
          msg,
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    getTableOptions() {
      let configureOptions = {
        titleBtns: [
          {
            title: "添加产品",
            authenKey: "goods_add",
            onTap: () => {
              this.handleSelectAttrByDialog();
            },
          },
        ],
        actionBtns: [
          {
            title: "删除",
            color: "red",
            authenKey: "goods_del",
            onDelete: async (options, tableItem, tableItemIndex) => {
              this.selectedGoodsList.splice(tableItemIndex, 1);

              return {
                msg: "删除成功",
              };
            },
          },
        ],
      };

      return {
        authenKey: "goods_list",
        headers: [
          { label: "产品名称", props: "name" },
          { label: "班级", props: "classify_name" },
          { label: "年级", props: "grade_name" },
          { label: "规格", props: "spec_name" },
          { label: "属性", props: "attr_name" },
          { label: "价格", props: "price" },
        ],
        ...configureOptions,
        hidePaginate: true,
        getData: async (options) => {
          return {
            code: 1,
            res: {
              data: this.selectedGoodsList,
              last_page: 1,
              current_page: 1,
              total: 30,
              per_page: 30,
            },
          };
        },
      };
    },
  },
  computed: {
    //页面底部的操作按钮
    curentPageFooterBtnList() {
      let btnList = [];
      let { pageType, id } = this.$route.query;

      switch (
        Number(pageType) //0: 新增 1：编辑
      ) {
        case 0:
          btnList = [
            {
              title: "提交产品组合",
              onTap: () => {
                this.handleSubmit(id);
              },
            },
          ];
          break;
        case 1:
          btnList = [
            {
              title: "保存编辑",
              onTap: () => {
                this.handleSubmit(id);
              },
            },
          ];
          break;
      }
      return btnList;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
