<!--
 * @Date         : 2020-11-24 14:55:23
 * @LastEditors  : cxx
 * @LastEditTime : 2020-11-24 14:55:34
 * @FilePath     : \leXue_manage_pc\src\views\customer\addOrEditCustomer.vue
-->
<template>
    <div>
q21
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>