<!--
 * @Date         : 2020-11-19 17:23:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-11-13 14:46:55
 * @FilePath     : \leXue_manage_pc\src\views\other\operateLog.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
    };
  },
  mounted() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    getTableOptions() {
      return {
        authenKey: "operateLog",
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
        ],
        search: {
          inputList: [
            {
              type: "input",
              label: "操作人",
              placeholder: "",
              value: "",
              props: "name",
              rules: ["!null"],
            },
          ],
        },
        headers: [
          {
            label: "操作人",
            props: "name",
          },
          {
            label: "操作时间",
            props: "log_time",
          },
          {
            label: "操作内容",
            props: "log_info",
          },
          {
            label: "登录ip",
            props: "log_ip",
          },
        ],
        getData: async (options) => {
          const ret = await this.$ajax({
            apiKey: "getOperateLogList",
            data: options,
          });

          const { formatDate } = this.$com;

          //时间戳转化为标准时间
          ret.res.data.forEach((item) => {
            const { log_time } = item;

            item.log_time = formatDate(
              new Date(log_time * 1000),
              "yyyy-MM-dd hh:mm"
            );
          });

          return ret;
        },
      };
    },
  },
};
</script>
