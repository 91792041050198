<template>
  <!--
    cxx
    v1版本
    使用手册：
    html: {
    <photoView
      v-if="viewBigPhoto"
      :options="{imgList: uploadedImgList, initImgIndex: currentShowBigImgIndex}"
      @closePhotoView="closePhotoView"
    ></photoView>
    }
    data: {
      toViewImgList: ['imgUrl', 'imgUrl'],
      toViewInitImgIndex: 0,
    }
  -->
    <div class="position-fixed photo-viewer fadeIn">
      <!-- 照片查看器--v2 -->
      <Swiper class="img-container" ref="mySwiper" :options="swiperOption">
        <SwiperSlide
          class="position-relative"
          v-for="(item, index) in options.imgList"
          :key="'imgList' + index"
        >
          <img class="position-absolute img-item" :src="item" />
        </SwiperSlide>
        <div class="swiper-pagination" slot="pagination"></div>
      </Swiper>
    </div>
</template>

<script>
import "@/assets/css/animate.css";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import 'swiper/swiper-bundle.css'

export default {
  props: {
    // v2版本
    options: {
      type: Object,
      defalut: {}
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: ".swiper-pagination"
        },
        on: {
          click: () => {
            this.$emit("closePhotoView");
          }
        }
      }
    };
  },
  mounted() {
    let initImgIndex = this.options.initImgIndex;
    console.info("this.$refs.mySwiper", this.$refs.mySwiper);
    if (initImgIndex !== 0) {
      this.swiperConstructor.slideTo(initImgIndex, 0, false);
    }
  },
  computed: {
    swiperConstructor() {
      return this.$refs.mySwiper.$swiper;
    }
  }
};
</script>
<style>
.swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.8);
}
.swiper-pagination-bullet-active {
  background: #f9bc68;
}
</style>
<style lang="scss" scoped>
.photo-viewer {
  z-index: 1500;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  .img-container {
    height: 100%;
    .img-item {
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 100%;
    }
  }
}
</style>