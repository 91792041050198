<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-18 14:27:26
 * @FilePath     : \leXue_manage_pc\src\views\refundReason\refundReasonList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>

    <!-- 弹窗：新增/编辑 -->
    <centerDialog ref="centerDialog" class="center-dialog">
      <inputCenter
        v-if="initOptions_refundReason"
        :initOptions="initOptions_refundReason"
      >
      </inputCenter>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
      initOptions_refundReason: null,
    };
  },
  mounted() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    // 弹出新增/编辑退款理由
    renderEditDialog({ type, inputedVal }) {
      try {
        let inputOptions = (this.initOptions_refundReason = {
          inputList: this.getInputList_refundReason(),
        });

        if (inputedVal) {
          this.$com.fillData({
            inputList: inputOptions.inputList,
            valueListObj: inputedVal,
          });
        }

        this.$refs.centerDialog.render({
          title: type === 0 ? "新增退款理由" : "编辑退款理由",
          width: "600px",
          verifyInputListIsChange: inputOptions.inputList,
          ok: async () => {
            try {
              const { inputList } = inputOptions;

              let requestOpt = this.$com.getValueByRules(inputList);

              // 0: 新增 1：编辑
              if (type === 1) {
                const { id } = inputedVal;
                requestOpt.id = id;
              }

              const { msg } = await this.$ajax({
                apiKey: "addOrEditRefundReason",
                data: requestOpt,
              });

              this.$toast({
                msg,
              });

              this.$refs.tableList.init();
            } catch (error) {
              throw error;
            }
          },
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    //获取输入配置表
    getInputList_refundReason() {
      return [
        {
          ref: "name",
          type: "input",
          label: "退款理由",
          placeholder: "请输入退款理由",
          maxLength: 50,
          rules: ["required", "!null"],
          value: null,
          props: "name",
        },
      ];
    },

    getTableOptions() {
      return {
        authenKey: "refundReason_list",
        search: {
          inputList: [
            {
              type: "input",
              label: "退款理由",
              placeholder: "请输入退款理由",
              value: "",
              props: "name",
              rules: ["!null"],
            },
          ],
        },
        headers: [
          { label: "退款理由", props: "name" },
          { label: "编辑时间", props: "updated_at" },
        ],
        titleBtns: [
          {
            title: "查询",
            type: "filter",
          },
          {
            title: "新增",
            authenKey: "refundReason_add",
            onTap: (options) => {
              this.renderEditDialog({
                type: 0,
              });
            },
          },
        ],
        actionBtns: [
          {
            title: "编辑",
            color: "cyan",
            authenKey: "refundReason_edit",
            onTap: (options) => {
              this.renderEditDialog({
                type: 1,
                inputedVal: options,
              });
            },
          },
          {
            title: "删除",
            color: "red",
            authenKey: "refundReason_del",
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: "delRefundReason",
                data: {
                  id: options.id,
                },
              });
            },
          },
        ],
        getData: async (options) => {
          const ret = await this.$ajax({
            apiKey: "getRefundReasonList",
            data: {
              ...options,
            },
          });
          return ret;
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.com-title {
  &.active::before {
    content: "*";
    color: #ff1818;
    line-height: 1rem;
  }

  line-height: 1rem;
  font-size: 0.6rem;
  color: #333744;
}
</style>


